import * as actions from "../../actionTypes/index";

const initialState = {
  isLoading: false,
  doctorInviteInfo:[],
  sharedUsersList:[],
  pendingInvitesList:[]

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.DOCTOR_INFO_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DOCTOR_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DOCTOR_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        doctorInviteInfo:action.payLoad
      };

      //ACCEPT INVITE
      case actions.ACCEPT_INVITE_START:
        return {
          ...state,
          isLoading: true,
        };
      case actions.ACCEPT_INVITE_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case actions.ACCEPT_INVITE_SUCCESS:
        return {
          ...state,
          isLoading: false,

        };
        //SHARED USERS
      case actions.SHARED_USERS_START:
        return {
          ...state,
          isLoading: true,
        };
      case actions.SHARED_USERS_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case actions.SHARED_USERS_SUCCESS:

        return {
          ...state,
          isLoading: false,
          sharedUsersList:action.payLoad

        };

             //PENDING INVITE
      case actions.PENDING_INVITES_START:
        return {
          ...state,
          isLoading: true,
        };
      case actions.PENDING_INVITES_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case actions.PENDING_INVITES_SUCCESS:

        return {
          ...state,
          isLoading: false,
          pendingInvitesList:action.payLoad

        };

        //CANCEL PERMISSION
        case actions.CANCEL_INVITES_START:
          return {
            ...state,
            isLoading: true,
          };
        case actions.CANCEL_INVITES_FAILED:
          return {
            ...state,
            isLoading: false,
          };
        case actions.CANCEL_INVITES_SUCCESS:

          return {
            ...state,
            isLoading: false,

          };
            //ACTIVE PERMISSION
        case actions.ACTIVE_INVITES_START:
          return {
            ...state,
            isLoading: true,
          };
        case actions.ACTIVE_INVITES_FAILED:
          return {
            ...state,
            isLoading: false,
          };
        case actions.ACTIVE_INVITES_SUCCESS:

          return {
            ...state,
            isLoading: false,

          };

             //REMOVE PERMISSION
             case actions.REMOVE_INVITES_START:
              return {
                ...state,
                isLoading: true,
              };
            case actions.REMOVE_INVITES_FAILED:
              return {
                ...state,
                isLoading: false,
              };
            case actions.REMOVE_INVITES_SUCCESS:

              return {
                ...state,
                isLoading: false,

              };

    default:
      return state;
  }
};
