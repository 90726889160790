import * as actions from "../actionTypes/index";

const initialState = {
  isLoading: false,
  token: null,
  grxToken:null,
  safari:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
      };
    case actions.USER_LOGGED_OUT:
      return {
        ...state,
        user: {},
        token: null,
      };
    case actions.REGISTER_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
      };
      case actions.SAFARI_CHECK:
        return {
          ...state,
          isLoading: false,
          safari: action.payload,
        };
    default:
      return state;
  }
};
