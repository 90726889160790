export const FETCH_VITAL_INFO_START = "FETCH_VITAL_INFO_START";
export const FETCH_VITAL_INFO_FAILED = "FETCH_VITAL_INFO_FAILED";
export const FETCH_VITAL_INFO_SUCCESS = "FETCH_VITAL_INFO_SUCCESS";

export const SAVE_VITAL_INFO_START = "SAVE_VITAL_INFO_START";
export const SAVE_VITAL_INFO_FAILED = "SAVE_VITAL_INFO_FAILED";
export const SAVE_VITAL_INFO_SUCCESS = "SAVE_VITAL_INFO_SUCCESS";

export const FETCH_SOCIAL_HISTORY_START = "FETCH_SOCIAL_HISTORY_START";
export const FETCH_SOCIAL_HISTORY_FAILED = "FETCH_SOCIAL_HISTORY_FAILED";
export const FETCH_SOCIAL_HISTORY_SUCCESS = "FETCH_SOCIAL_HISTORY_SUCCESS";

export const SAVE_SOCIAL_HISTORY_START = "SAVE_SOCIAL_HISTORY_START";
export const SAVE_SOCIAL_HISTORY_FAILED = "SAVE_SOCIAL_HISTORY_FAILED";
export const SAVE_SOCIAL_HISTORY_SUCCESS = "SAVE_SOCIAL_HISTORY_SUCCESS";

export const FETCH_HOSPITAL_ADMISSION_HISTORY_START =
  "FETCH_HOSPITAL_ADMISSION_HISTORY_START";
export const FETCH_HOSPITAL_ADMISSION_HISTORY_FAILED =
  "FETCH_HOSPITAL_ADMISSION_HISTORY_FAILED";
export const FETCH_HOSPITAL_ADMISSION_HISTORY_SUCCESS =
  "FETCH_HOSPITAL_ADMISSION_HISTORY_SUCCESS";


export const SAVE_HOSPITAL_ADMISSION_HISTORY_START =
  "SAVE_HOSPITAL_ADMISSION_HISTORY_START";
export const SAVE_HOSPITAL_ADMISSION_HISTORY_FAILED =
  "SAVE_HOSPITAL_ADMISSION_HISTORY_FAILED";
export const SAVE_HOSPITAL_ADMISSION_HISTORY_SUCCESS =
  "SAVE_SOCIAL_HISTORY_SUCCESS";

export const FETCH_SURGICAL_HISTORY_START = "FETCH_SURGICAL_HISTORY_START";
export const FETCH_SURGICAL_HISTORY_FAILED = "FETCH_SURGICAL_HISTORY_FAILED";
export const FETCH_SURGICAL_HISTORY_SUCCESS = "FETCH_SURGICAL_HISTORY_SUCCESS";

export const SAVE_SURGICAL_HISTORY_START = "SAVE_SURGICAL_HISTORY_START";
export const SAVE_SURGICAL_HISTORY_FAILED = "SAVE_SURGICAL_HISTORY_FAILED";
export const SAVE_SURGICAL_HISTORY_SUCCESS = "SAVE_SURGICAL_HISTORY_SUCCESS";

export const FETCH_FAMILY_HISTORY_SUCCESS = "FETCH_FAMILY_HISTORY_SUCCESS";
export const FETCH_FAMILY_HISTORY_FAILED = "FETCH_FAMILY_HISTORY_FAILED";
export const FETCH_FAMILY_HISTORY_START = "FETCH_FAMILY_HISTORY_START";

export const SAVE_FAMILY_HISTORY_SUCCESS = "SAVE_FAMILY_HISTORY_SUCCESS";
export const SAVE_FAMILY_HISTORY_FAILED = "SAVE_FAMILY_HISTORY_FAILED";
export const SAVE_FAMILY_HISTORY_START = "SAVE_FAMILY_HISTORY_START";

export const FETCH_COMMON_HISTORY_SUCCESS = "FETCH_COMMON_HISTORY_SUCCESS";
export const FETCH_COMMON_HISTORY_FAILED = "FETCH_COMMON_HISTORY_FAILED";
export const FETCH_COMMON_HISTORY_START = "FETCH_COMMON_HISTORY_START";

export const SAVE_COMMON_HISTORY_SUCCESS = "SAVE_COMMON_HISTORY_SUCCESS";
export const SAVE_COMMON_HISTORY_FAILED = "SAVE_COMMON_HISTORY_FAILED";
export const SAVE_COMMON_HISTORY_START = "SAVE_COMMON_HISTORY_START";

export const FETCH_BG_START = "FETCH_BG_START"
export const FETCH_BG_SUCCESS = "FETCH_BG_SUCCESS"
export const  FETCH_BG_FAILED = "FETCH_BG_FAILED"

export const FETCH_BP_START = "FETCH_BP_START"
export const FETCH_BP_SUCCESS = "FETCH_BP_SUCCESS"
export const  FETCH_BP_FAILED = "FETCH_BP_FAILED"

export const FETCH_PO_START = "FETCH_PO_START"
export const FETCH_PO_SUCCESS = "FETCH_PO_SUCCESS"
export const  FETCH_PO_FAILED = "FETCH_PO_FAILED"

export const FETCH_WS_START = "FETCH_WS_START"
export const FETCH_WS_SUCCESS = "FETCH_WS_SUCCESS"
export const  FETCH_WS_FAILED = "FETCH_WS_FAILED"

export const FETCH_TEMP_START = "FETCH_TEMP_START"
export const FETCH_TEMP_SUCCESS = "FETCH_TEMP_SUCCESS"
export const  FETCH_TEMP_FAILED = "FETCH_TEMP_FAILED"


export const FETCH_MEDICAL_CONDITION_START = "FETCH_MEDICAL_CONDITION_START"
export const FETCH_MEDICAL_CONDITION_SUCCESS = "FETCH_MEDICAL_CONDITION_SUCCESS"
export const  FETCH_MEDICAL_CONDITION_FAILED = "FETCH_MEDICAL_CONDITION_FAILED"
