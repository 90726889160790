import React, { Component } from "react";
import { withRouter } from "react-router-dom"
import {connect} from "react-redux";

import { DatePicker } from "antd";
import {
  fetchSocialHistory,
  saveSocialHistory,
} from "../../../redux/actions/history";
import { useDispatch, useSelector } from "react-redux";
import ShowToast from "../../../utils/show-toast";
import updateDate from "../../../utils/updateDate";
// import mapStateToProps from "react-redux/lib/connect/mapStateToProps";
import $ from 'jquery';
import socialHistoryTransformer from "../../../utils/transformers/history/social-history-transformer";


class SocialHistoryNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptionSmoke: null,
      selectedOptionDrink: null,
      smokingYears: null,
      smokingPacks: null,
      smokingComments: null,
      quitSmokeDate: null,
      lastSmoked: null,
      quitComments: null,
      drinkSocially:null,
      drinksPerDay: null,
      drinkingComments: null,
      quitDrinkingDate: null,
      lastDrink: null,
      // quitDrinkingComments: '',

    };
  }

  componentDidMount() {

    this.setState({selectedOptionSmoke:"option1",selectedOptionDrink:"option1"})
    if(this.props.social.data === null) {

      this.props.dispatch(fetchSocialHistory());
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {

    if(nextProps.social.data){

      if (nextProps.social.data.smoking.valid === false && nextProps.social.data.smoking.quitTime){

        this.setState({selectedOptionSmoke:"option3",
          quitSmokeDate: nextProps.social.data.smoking.quitTime,
          lastSmoked: nextProps.social.data.smoking.lastSmoked,
          quitComments: nextProps.social.data.smoking.comments,
        })
        if(nextProps.social.data.smoking.quitTime !== null) {

          updateDate(nextProps.social.data.smoking.quitTime,"quitSmokeDate", nextProps.social.isLoading);
        }
      if(nextProps.social.data.smoking.lastSmoked !== null) {

        updateDate(nextProps.social.data.smoking.lastSmoked, "lastSmokeDate", nextProps.social.isLoading);
        }
      this.setState({quitComments:nextProps.social.data.smoking.comments})
      } else if (nextProps.social.data.smoking.valid) {
        this.setState({
          selectedOptionSmoke:"option2",
          smokingYears:nextProps.social.data.smoking.smokingTime ,
          smokingPacks:nextProps.social.data.smoking.packsPerDay,
          smokingComments: nextProps.social.data.smoking.comments,

        })
      } else if (nextProps.social.data.smoking.valid === false && nextProps.social.data.smoking.quitTime === null) {
        this.setState({
          selectedOptionSmoke:"option1",
        })
      }
      if (nextProps.social.data.drinking.valid === false && nextProps.social.data.drinking.quitTime){

        this.setState({selectedOptionDrink:"option3",
          quitDrinkingDate: nextProps.social.data.drinking.quitTime,
          lastDrink: nextProps.social.data.drinking.lastDrink,
        })
        if(nextProps.social.data.drinking.quitTime !== null) {
          updateDate(nextProps.social.data.drinking.quitTime,"quitDrinkingDate", nextProps.social.isLoading);
        }
      if(nextProps.social.data.drinking.lastDrink !== null) {
        updateDate(nextProps.social.data.drinking.lastDrink, "lastDrink", nextProps.social.isLoading);
        }

      } else if (nextProps.social.data.drinking.valid) {
        this.setState({
          selectedOptionDrink:"option2",
          drinksPerDay:nextProps.social.data.drinking.drinksPerDay ,
          drinkSocially:nextProps.social.data.drinking.drinkSocially,
          drinkingComments: nextProps.social.data.drinking.comments,

        })
      } else if (nextProps.social.data.drinking.valid === false && nextProps.social.data.drinking.quitTime === null) {
        this.setState({
          selectedOptionDrink:"option1",
        })
      }
    }
  }

  handleOptionChange = (changeEvent) => {

    this.setState({
      selectedOptionSmoke: changeEvent.target.value,
    });
    if(changeEvent.target.value === "option3" && this.state.quitSmokeDate !== null) {

      updateDate(this.state.quitSmokeDate, "quitSmokeDate", false);
    }
    if(changeEvent.target.value === "option3" && this.state.lastSmoked !== null) {
      updateDate(this.state.lastSmoked, "lastSmokeDate", false);
    }
  };

  handleOptionChangeDrink = (changeEvent) => {

    this.setState({
      selectedOptionDrink: changeEvent.target.value,
    });
    if(changeEvent.target.value === "option3" && this.state.quitDrinkingDate !== null) {

      updateDate(this.state.quitDrinkingDate, "quitDrinkingDate", false);
    }
    if(changeEvent.target.value === "option3" && this.state.lastDrink !== null) {
      updateDate(this.state.lastDrink, "lastDrink", false);
    }
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    if (this.state.selectedOptionSmoke === "option3" && this.state.selectedOptionDrink === "option1") {
      this.setState({

        smokingYears: null,
        smokingPacks: null,
        smokingComments: null,
        quitDrinkingDate: null,
        lastDrink: null,
        drinkSocially: null,
        drinksPerDay: null,
        drinkingComments: null,
      });
      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2",
          smokingTime: null,
          packsPerDay: null,
          quitTime: this.state.quitSmokeDate,
          lastSmoked: this.state.lastSmoked,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments !== null?this.state.drinkingComments :undefined,
          drinkSocially: this.state.drinkSocially? this.state.drinkSocially:false,
          drinksPerDay: this.state.drinksPerDay? this.state.drinksPerDay :undefined,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2",
        }
      }));

    }else if(this.state.selectedOptionSmoke === "option3" && this.state.selectedOptionDrink === "option2"){
      this.setState({

        smokingYears: null,
        smokingPacks: null,
        smokingComments: null,
        quitDrinkingDate: null,
        lastDrink: null,
      });
      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2",
          smokingTime: null,
          packsPerDay: null,
          quitTime: this.state.quitSmokeDate,
          lastSmoked: this.state.lastSmoked,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments !== null?this.state.drinkingComments :undefined,
          drinkSocially: this.state.drinkSocially? this.state.drinkSocially:false,
          drinksPerDay: this.state.drinksPerDay? this.state.drinksPerDay :undefined,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2" &&
              this.state.drinkSocially !== null && this.state.drinksPerDay !== null,
        }
      }));
    }
    else if(this.state.selectedOptionSmoke === "option3" && this.state.selectedOptionDrink === "option3"){
      this.setState({

        smokingYears: null,
        smokingPacks: null,
        smokingComments: null,
        drinkSocially: null,
        drinksPerDay: null,
        drinkingComments: null,
      });
      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2",
          smokingTime: null,
          packsPerDay: null,
          quitTime: this.state.quitSmokeDate,
          lastSmoked: this.state.lastSmoked,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments !== null?this.state.drinkingComments :undefined,
          drinkSocially: this.state.drinkSocially? this.state.drinkSocially:false,
          drinksPerDay: this.state.drinksPerDay? this.state.drinksPerDay :undefined,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2",
        }
      }));
    } else if (this.state.selectedOptionSmoke === "option2" && this.state.selectedOptionDrink === "option1") {
      this.setState({

        quitSmokeDate: null,
        lastSmoked: null,
        quitComments: null,
        quitDrinkingDate: null,
        lastDrink: null,
        drinkSocially: null,
        drinksPerDay: null,
        drinkingComments: null,

      });
      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2"
              && this.state.smokingYears !== null && this.state.smokingPacks !== null,
          smokingTime: this.state.smokingYears,
          packsPerDay: this.state.smokingPacks,
          quitTime: null,
          lastSmoked: null,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments !== null?this.state.drinkingComments :undefined,
          drinkSocially: this.state.drinkSocially? this.state.drinkSocially:false,
          drinksPerDay: this.state.drinksPerDay? this.state.drinksPerDay :undefined,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2",
        }
      }));
    } else if (this.state.selectedOptionSmoke === "option2" && this.state.selectedOptionDrink === "option2") {
      this.setState({

        quitSmokeDate: null,
        lastSmoked: null,
        quitComments: null,
        quitDrinkingDate: null,
        lastDrink: null,
      });
      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2"
              && this.state.smokingYears !== null && this.state.smokingPacks !== null,
          smokingTime: this.state.smokingYears,
          packsPerDay: this.state.smokingPacks,
          quitTime: null,
          lastSmoked: null,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments !== null?this.state.drinkingComments :undefined,
          drinkSocially: this.state.drinkSocially? this.state.drinkSocially:false,
          drinksPerDay: this.state.drinksPerDay? this.state.drinksPerDay :undefined,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2" &&
              this.state.drinkSocially !== null && this.state.drinksPerDay !== null,
        }
      }));
    } else if (this.state.selectedOptionSmoke === "option2" && this.state.selectedOptionDrink === "option3") {
      this.setState({

        quitSmokeDate: null,
        lastSmoked: null,
        quitComments: null,
        drinkSocially: null,
        drinksPerDay: null,
        drinkingComments: null,
      });
      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2"
              && this.state.smokingYears !== null && this.state.smokingPacks !== null,
          smokingTime: this.state.smokingYears,
          packsPerDay: this.state.smokingPacks,
          quitTime: null,
          lastSmoked: null,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments !== null?this.state.drinkingComments :undefined,
          drinkSocially: this.state.drinkSocially? this.state.drinkSocially:false,
          drinksPerDay: this.state.drinksPerDay? this.state.drinksPerDay :undefined,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2",
        }
      }));
    } else if (this.state.selectedOptionSmoke === "option1" && this.state.selectedOptionDrink === "option1") {
      this.setState({
      smokingYears: null,
          smokingPacks: null,
          smokingComments: null,
          quitSmokeDate: null,
          lastSmoked: null,
          quitComments: null,
        quitDrinkingDate: null,
        lastDrink: null,
        drinkSocially: null,
        drinksPerDay: null,
        drinkingComments: null,
      });

      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2",
          smokingTime: null,
          packsPerDay: null,
          quitTime: null,
          lastSmoked: null,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: null,
          drinkSocially: null,
          drinksPerDay: null,
          lastDrink: null,
          quitTime: null,
          valid: this.state.selectedOptionDrink === "option2",
        }
      }));
    } else if (this.state.selectedOptionSmoke === "option1"&& this.state.selectedOptionDrink === "option2") {
      this.setState({
      smokingYears: null,
          smokingPacks: null,
          smokingComments: null,
          quitSmokeDate: null,
          lastSmoked: null,
          quitComments: null,
        quitDrinkingDate: null,
        lastDrink: null,
      });

      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2",
          smokingTime: null,
          packsPerDay: null,
          quitTime: null,
          lastSmoked: null,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: this.state.drinkingComments,
          drinkSocially: this.state.drinkSocially,
          drinksPerDay: this.state.drinksPerDay,
          lastDrink: null,
          quitTime: null,
          valid: this.state.selectedOptionDrink === "option2" &&
              this.state.drinkSocially !== null && this.state.drinksPerDay !== null,
        }
      }));
    } else if (this.state.selectedOptionSmoke === "option1"&& this.state.selectedOptionDrink === "option3") {
      this.setState({
      smokingYears: null,
          smokingPacks: null,
          smokingComments: null,
          quitSmokeDate: null,
          lastSmoked: null,
          quitComments: null,
        drinkSocially: null,
        drinksPerDay: null,
        drinkingComments: null,
      });

      await this.props.dispatch(saveSocialHistory({smoking: {
          valid: this.state.selectedOptionSmoke === "option2",
          smokingTime: null,
          packsPerDay: null,
          quitTime: null,
          lastSmoked: null,
          comments: this.state.selectedOptionSmoke === "option2" ?
              this.state.smokingComments: this.state.selectedOptionSmoke === "option3"? this.state.quitComments:null,
        },
        drinking:{
          comments: null,
          drinkSocially: null,
          drinksPerDay: null,
          lastDrink: this.state.lastDrink? this.state.lastDrink:undefined,
          quitTime: this.state.quitDrinkingDate? this.state.quitDrinkingDate:undefined,
          valid: this.state.selectedOptionDrink === "option2",
        }
      }));
    }




    ShowToast("Success!", "success");
    await this.props.dispatch(fetchSocialHistory());
  };
  detectChange = (event) => {


    this.setState({
      [event.target.name]: new Date(event.target.value)
    })
  }
  commentChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  //
  render() {

    return (
      <div className="container my-5">
        <form autoComplete="off" onSubmit={this.handleFormSubmit}>
          <h1 className="text-center mb-4 "></h1>
          <fieldset>
            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h5 className="vital-info-field-head ">Do you smoke? </h5>
              </div>
            </div>
            <div className="row  justify-content-center my-5">
              <div className="col-md-2 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline">
                  <input
                    className=""
                    type="radio"
                    id="neverSmoke"
                    name="neverSmoke"
                    value="option1"
                    checked={this.state.selectedOptionSmoke === "option1"}
                    onChange={this.handleOptionChange}
                  />
                  <label
                    className="form-check-label  ml-3"
                    htmlFor="inlineCheckbox1"
                  >
                    Never
                  </label>
                </div>
              </div>
              <div className="col-md-2 col-10 d-flex justify-content-start">
                <div className="form-check  form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    id="Radios2"
                    value="option2"
                    checked={this.state.selectedOptionSmoke === "option2"}
                    onChange={this.handleOptionChange}
                  />
                  <label
                    className="form-check-label  ml-3"
                    htmlFor="inlineCheckbox1"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div className="col-md-2 col-10 d-flex justify-content-start">
                <div className="form-check  form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    id="Radios3"
                    value="option3"
                    checked={this.state.selectedOptionSmoke === "option3"}
                    onChange={this.handleOptionChange}
                  />
                  <label
                    className="form-check-label  ml-3"
                    htmlFor="inlineCheckbox2"
                  >
                    No, but I quit
                  </label>
                </div>
              </div>
            </div>
            {this.state.selectedOptionSmoke === "option3" ? (
              <div>
                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">When did you quit?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <input
                      type="date"
                      id="quitSmokeDate"
                      name="quitSmokeDate"
                      className="datePicker"
                      // value={this.dateSetter}
                        onChange={this.detectChange}
                    />
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">when was the last time you smoked?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <input
                      type="date"
                      id="lastSmokeDate"
                      name="lastSmoked"
                      className="datePicker"
                      onChange={this.detectChange}
                    />
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">Comments:</p>
                  </div>
                  <div className="col-md-4 col-10">
                    {" "}
                    <div className="form-group">
                      <div className="">
                        <textarea
                          name="quitComments"
                          placeholder="Message"
                          className="form-control input-md msg msg"
                          rows="4"
                          type="text"
                          defaultValue={this.state.quitComments}
                          onChange={this.commentChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.selectedOptionSmoke === "option2" ? (
              <div>
                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">If so, for how many years?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <div className="form-group">
                      <div className="input-group ">
                        <input
                          name="smokingYears"
                          className="form-control input-md msg"
                          type="number"
                          defaultValue={this.state.smokingYears}
                          onChange={this.commentChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">How many packs per day?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <div className="form-group">
                      <div className="input-group ">
                        <input
                          name="smokingPacks"
                          className="form-control input-md msg"
                          type="number"
                          defaultValue={this.state.smokingPacks}
                          onChange={this.commentChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">Comments:</p>
                  </div>
                  <div className="col-md-4 col-10">
                    {" "}
                    <div className="form-group">
                      <div className="">
                        <textarea
                          name="smokingComments"
                          placeholder="Message"
                          className="form-control input-md msg msg"
                          rows="4"
                          type="text"
                          defaultValue={this.state.smokingComments}
                          onChange={this.commentChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h5 className=" vital-info-field-head">
                  Do you drink alcohol?
                </h5>
              </div>
            </div>
            <div className="row  justify-content-center my-5">
              <div className="col-md-2 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline">
                  <input
                    className=""
                    type="radio"
                    id="Radios1"
                    value="option1"
                    checked={this.state.selectedOptionDrink === "option1"}
                    onChange={this.handleOptionChangeDrink}
                  />
                  <label
                    className="form-check-label  ml-3"
                    htmlFor="inlineCheckbox1"
                  >
                    Never
                  </label>
                </div>
              </div>
              <div className="col-md-2 col-10 d-flex justify-content-start">
                <div className="form-check  form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    id="Radios2"
                    value="option2"
                    checked={this.state.selectedOptionDrink === "option2"}
                    onChange={this.handleOptionChangeDrink}
                  />
                  <label
                    className="form-check-label  ml-3"
                    htmlFor="inlineCheckbox2"
                  >
                    yes
                  </label>
                </div>
              </div>
              <div className="col-md-2 col-10 d-flex justify-content-start">
                <div className="form-check  form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    id="Radios3"
                    value="option3"
                    checked={this.state.selectedOptionDrink === "option3"}
                    onChange={this.handleOptionChangeDrink}
                  />
                  <label
                    className="form-check-label  ml-3"
                    htmlFor="inlineCheckbox2"
                  >
                    No, but I quit
                  </label>
                </div>
              </div>
            </div>

            {this.state.selectedOptionDrink === "option3" ? (
              <div>
                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">When did you quit?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <input
                      type="date"
                      id="quitDrinkingDate"
                      name="quitDrinkingDate"
                      className="datePicker"
                      onChange={this.detectChange}
                    />
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">
                      when was the last time you had a drink?:
                    </p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <input
                      type="date"
                      id="lastDrink"
                      name="lastDrink"
                      className="datePicker"
                      onChange={this.detectChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.selectedOptionDrink === "option2" ? (
              <div>
                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">How many drinks per day?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <div className="form-group">
                      <div className="input-group ">
                        <input
                          name="drinksPerDay"
                          className="form-control input-md msg"
                          type="number"
                          onChange={this.commentChange}
                          defaultValue={this.state.drinksPerDay}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">Do you drink socially?:</p>
                  </div>
                  <div className="col-md-4 col-10 ">
                    <div className="form-check form-check-inline">
                      <input

                          className=""
                          type="radio"
                          id="exampleRadios3"
                          onChange={()=> {this.setState({drinkSocially: true})}}
                          checked={this.state.drinkSocially===true}
                      />
                      <label
                        className="form-check-label  ml-3"
                        htmlFor="inlineCheckbox1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                          className=""
                          type="radio"
                          id="exampleRadios3"
                          onChange={()=> {this.setState({drinkSocially: false})}}
                          checked={this.state.drinkSocially===false}

                      />
                      <label
                        className="form-check-label  ml-3"
                        htmlFor="inlineCheckbox1"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row  justify-content-center my-2">
                  <div className="col-md-4 col-10 d-flex align-items-center">
                    <p className=" ">Comments:</p>
                  </div>
                  <div className="col-md-4 col-10">
                    {" "}
                    <div className="form-group">
                      <div className="">
                        <textarea
                          name="drinkingComments"
                          placeholder="Message"
                          className="form-control input-md msg msg"
                          rows="4"
                          type="text"
                          onChange={this.commentChange}
                          defaultValue={this.state.drinkingComments}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row justify-content-center">
              <div className="col-8 mt-3 d-flex justify-content-end">
                <button className="bg-filter-btn mx-1 add-info-btn" type="submit">
                  Save
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}
function mapStateToProps (state) {

  return {
    social: state.history.socialHistory,
  }

}

export default withRouter(connect (mapStateToProps)(SocialHistoryNew));
