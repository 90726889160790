import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  doctorInfo,
  acceptInvitationDoctor,
} from "../../../redux/actions/Share/share";
import { Spin } from "antd";
import ShowToast from "../../../utils/show-toast";
import { decrypt, encrypt } from "../../../utils/encryption";

const DoctorShareInviteModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const doc = useSelector((state) => state.share.doctorInviteInfo);
  const id = useSelector((state) => state.doctor.userId);
  const user = useSelector((state) => state.user.userDetails);
  const shareToken = useSelector((state) => state.doctor.shareToken);
  const [isLoading, setIsLoading] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const docInfo = useSelector((state) => {return (state.share.doctorInviteInfo)});


  useEffect(() => {

    FetchDocInfo();
    if (props.modal) {
      document.getElementById("modal-btn").click();
    }
    return(
      setModalClose(false)
    )
  }, [shareToken, props.modal]);

  var url = window.location;
  var Url_token = new URLSearchParams(url.search).get("token");
  var urlToken = Url_token && Url_token.slice(0, 228);
  
   let share_token =localStorage.getItem(encrypt("pendingInviteAcceptToken"));
   share_token=share_token!==null && share_token.length>0 ?decrypt(share_token):null;

    const token = share_token && share_token !== "" ? share_token: urlToken;


  const FetchDocInfo = async () => {
    let res;
    if(token !== null && token !== ""){

    res = await dispatch(doctorInfo({ token }));
  }
  };
  const handleModalClose = () => {
    history.push("/dashboard");
  };

  const handleAcceptInvite = async () => {
    setIsLoading(true);
    const res = await dispatch(acceptInvitationDoctor({ token }));
    setIsLoading(false);
    setModalClose(true)
  };

  return (
    <div>
      <div>
        <button
          type="button "
          className="btn btn-primary submit logout d-none"
          id="modal-btn"
          data-toggle="modal"
          data-target="#exampleModalCenter"
        ></button>
      </div>

      <div
        className="modal fade fading"
        id="exampleModalCenter"
        tabIndex="-1"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="exampleModalLongTitle"
              >
              Data Request Notification
              </h5>
              <button
                type="button"
                className="close"
                id="btn-close"
                data-dismiss="modal"
                onClick={handleModalClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid overflow-auto">
                Doctor {docInfo.firstName} {docInfo.lastName} has requested to view your Voyager Data, Please Select
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Ignore
              </button>
              <div>
                <Spin spinning={isLoading}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAcceptInvite}
                    data-dismiss={modalClose?"modal":null}
                  >
                    Accept
                  </button>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorShareInviteModal;
