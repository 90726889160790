import * as actions from "../actionTypes/history";
import {
  API_BASE_URL,
  fetchVitalInfoURL,
  saveVitalInfoURL,
  fetchSocialHistoryURL,
  fetchHistoryURL,
  saveSocialHistoryURL,
  saveHospitalAdmissionHistoryURL,
  fetchHospitalAdmissionHistoryURL,
  fetchSurgicalHistoryURL,
  fetchFamilyHistoryURL,
  saveSurgicalHistoryURL,
  saveFamilyHistoryURL,
  updateFamilyHistoryURL,
  saveCommonHistoryURL
} from "../../utils/urls";
import http from "../../utils/http-service";
import {encrypt,decrypt} from "../../utils/encryption";
import {userHistory} from "react-router-dom";
import {logout} from "./auth";

const fetchVitalInfoStart = () => {
  return { type: actions.FETCH_VITAL_INFO_START };
};

const fetchVitalInfoFailed = () => {
  return { type: actions.FETCH_VITAL_INFO_FAILED };
};

const fetchVitalInfoSuccess = (payload) => {
  return { type: actions.FETCH_VITAL_INFO_SUCCESS, payload };
};

export const fetchVitalInfo = () => async (dispatch) => {
  try {
    dispatch(fetchVitalInfoStart());
    let vital_info = "vital_info";
    vital_info = encrypt(vital_info);


    const result = await http.get(`${API_BASE_URL}${fetchHistoryURL}${vital_info}`, {});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;


      let fetdata = result.data.data;


      fetdata=decrypt(fetdata);
      fetdata=JSON.parse(fetdata);

      dispatch(fetchVitalInfoSuccess({ vitalInfo: fetdata }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchVitalInfoFailed());
    throw error;
  }
};

const saveVitalInfoStart = () => {
  return { type: actions.SAVE_VITAL_INFO_START };
};

const saveVitalInfoFailed = () => {
  return { type: actions.SAVE_VITAL_INFO_FAILED };
};

const saveVitalInfoSuccess = (payload) => {
  return { type: actions.SAVE_VITAL_INFO_SUCCESS, payload };
};

export const saveVitalInfo = (data) => async (dispatch) => {
  try {
    dispatch(saveVitalInfoStart());
    let vitalInfo=data;
    vitalInfo=JSON.stringify(vitalInfo)
    vitalInfo=encrypt(vitalInfo);

    const result = await http.post(`${API_BASE_URL}${saveVitalInfoURL}`, {"data":vitalInfo});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

      dispatch(saveVitalInfoSuccess({ vitalInfo: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveVitalInfoFailed());
    throw error;
  }
};

const fetchSocialHistoryStart = () => {
  return { type: actions.FETCH_SOCIAL_HISTORY_START };
};

const fetchSocialHistoryFailed = () => {
  return { type: actions.FETCH_SOCIAL_HISTORY_FAILED };
};

const fetchSocialHistorySuccess = (payload) => {
  return { type: actions.FETCH_SOCIAL_HISTORY_SUCCESS, payload };
};

export const fetchSocialHistory = () => async (dispatch) => {
  try {
    dispatch(fetchSocialHistoryStart());
    let social_history = "social_history";
    social_history = encrypt(social_history);
    const result = await http.get(
        `${API_BASE_URL}${fetchHistoryURL}${social_history}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

      dispatch(fetchSocialHistorySuccess({ socialHistory: data}));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchSocialHistoryFailed());
    throw error;
  }
};

const saveSocialHistoryStart = () => {
  return { type: actions.SAVE_SOCIAL_HISTORY_START };
};

const saveSocialHistoryFailed = () => {
  return { type: actions.SAVE_SOCIAL_HISTORY_FAILED };
};

const saveSocialHistorySuccess = (payload) => {

  return { type: actions.SAVE_SOCIAL_HISTORY_SUCCESS, payload };
};

export const saveSocialHistory = (data) => async (dispatch) => {
  try {

    let socialHistory=data;
    socialHistory=JSON.stringify(socialHistory)
    socialHistory=encrypt(socialHistory);
    const result = await http.post(
        `${API_BASE_URL}${saveSocialHistoryURL}`,
        {"data":socialHistory}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

      dispatch(saveSocialHistorySuccess({ socialHistory: data }));
    }
    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveSocialHistoryFailed());
    throw error;
  }
};

const fetchHospitalAdmissionHistoryStart = () => {
  return { type: actions.FETCH_HOSPITAL_ADMISSION_HISTORY_START };
};

const fetchHospitalAdmissionHistoryFailed = () => {
  return { type: actions.FETCH_HOSPITAL_ADMISSION_HISTORY_FAILED };
};

const fetchHospitalAdmissionHistorySuccess = (payload) => {
  return { type: actions.FETCH_HOSPITAL_ADMISSION_HISTORY_SUCCESS, payload };
};

export const fetchHospitalAdmissionHistory = () => async (dispatch) => {
  try {
    dispatch(fetchHospitalAdmissionHistoryStart());
    let hospital_admissions = "hospital_admissions";
    hospital_admissions = encrypt(hospital_admissions);
    const result = await http.get(
        `${API_BASE_URL}${fetchHistoryURL}${hospital_admissions}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      let hosData = result.data.data;

      hosData = decrypt(hosData);
      hosData = JSON.parse(hosData);
      dispatch(
          fetchHospitalAdmissionHistorySuccess({ hospitalAdmissions: hosData })
      );
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchHospitalAdmissionHistoryFailed());
    throw error;
  }
};

const saveHospitalAdmissionHistoryStart = () => {
  return { type: actions.SAVE_HOSPITAL_ADMISSION_HISTORY_START };
};

const saveHospitalAdmissionHistoryFailed = () => {
  return { type: actions.SAVE_HOSPITAL_ADMISSION_HISTORY_FAILED };
};

const saveHospitalAdmissionHistorySuccess = (payload) => {
  return { type: actions.SAVE_HOSPITAL_ADMISSION_HISTORY_SUCCESS, payload };
};

export const saveHospitalAdmissionHistory = (data) => async (dispatch) => {
  try {
    dispatch(saveHospitalAdmissionHistoryStart());
    let hospitalAdmissions=data;
    hospitalAdmissions=JSON.stringify(hospitalAdmissions)
    hospitalAdmissions=encrypt(hospitalAdmissions);
    const result = await http.post(
        `${API_BASE_URL}${saveHospitalAdmissionHistoryURL}`,
        {"data":hospitalAdmissions}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data)

      dispatch(
          saveHospitalAdmissionHistorySuccess({ hospitalAdmissions: data })
      );
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveHospitalAdmissionHistoryFailed());
    throw error;
  }
};

export const updateHospitalAdmission = (data, _id)=> async (dispatch) => {
  try{
    let id = _id;
    id = encrypt(id);
    let hospital = data;
    hospital = JSON.stringify(hospital);
    hospital = encrypt(hospital);
    return await http.put(`${API_BASE_URL}${saveHospitalAdmissionHistoryURL}?id=${id}`, {"data":hospital})
  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}
export const deleteHospitalAdmission = (_id)=> async (dispatch) => {
  try{
    let id = _id;
    id = encrypt(id);
    return await http.delete(`${API_BASE_URL}${saveHospitalAdmissionHistoryURL}?id=${id}`)
  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}

const fetchSurgicalHistoryStart = () => {
  return { type: actions.FETCH_SURGICAL_HISTORY_START };
};

const fetchSurgicalHistoryFailed = () => {
  return { type: actions.FETCH_SURGICAL_HISTORY_FAILED };
};

const fetchSurgicalHistorySuccess = (payload) => {
  return { type: actions.FETCH_SURGICAL_HISTORY_SUCCESS, payload };
};

export const fetchSurgicalHistory = () => async (dispatch) => {
  try {
    dispatch(fetchSurgicalHistoryStart());
    let surgical_history = "surgical_history";
    surgical_history = encrypt(surgical_history);
    const result = await http.get(
        `${API_BASE_URL}${fetchHistoryURL}${surgical_history}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      let sur = data;
      data = decrypt(data);
      data = JSON.parse(data);
      dispatch(fetchSurgicalHistorySuccess({ surgicalHistory: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchSurgicalHistoryFailed());
    throw error;
  }
};



const saveSurgicalHistoryStart = () => {
  return { type: actions.SAVE_SURGICAL_HISTORY_START };
};

const saveSurgicalHistoryFailed = () => {
  return { type: actions.SAVE_SURGICAL_HISTORY_FAILED };
};

const saveSurgicalHistorySuccess = (payload) => {
  return { type: actions.SAVE_SURGICAL_HISTORY_SUCCESS, payload };
};

export const saveSurgicalHistory = (data) => async (dispatch) => {
  try {
    dispatch(saveSurgicalHistoryStart());
    let surgicalHistory=data;
    surgicalHistory=JSON.stringify(surgicalHistory)
    surgicalHistory=encrypt(surgicalHistory);
    const result = await http.post(
        `${API_BASE_URL}${saveSurgicalHistoryURL}`,
        {"data":surgicalHistory}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

      dispatch(saveSurgicalHistorySuccess({ surgicalHistory: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveSurgicalHistoryFailed());
    throw error;
  }
};
export const updateSurgicalHistory = (data, _id)=> async (dispatch) => {
  try{
    let id = _id;
    id = encrypt(id);
    let updatedData=data;
    updatedData=JSON.stringify(updatedData)
    updatedData=encrypt(updatedData);
    return await http.put(`${API_BASE_URL}${saveSurgicalHistoryURL}?id=${id}`, {"data":updatedData})

  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}
export const deleteSurgicalHistory = (_id)=> async (dispatch) => {
  try{
    let id = _id;
    id = encrypt(id);
    return await http.delete(`${API_BASE_URL}${saveSurgicalHistoryURL}?id=${id}`)

  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}

const fetchFamilyHistoryStart = () => {
  return { type: actions.FETCH_FAMILY_HISTORY_START };
};

const fetchFamilyHistoryFailed = () => {
  return { type: actions.FETCH_FAMILY_HISTORY_FAILED };
};

const fetchFamilyHistorySuccess = (payload) => {
  return { type: actions.FETCH_FAMILY_HISTORY_SUCCESS, payload };
};
export const fetchFamilyHistory = () => async (dispatch) => {
  try {
    dispatch(fetchFamilyHistoryStart());
    let family_history = "family_history";
    family_history = encrypt(family_history);
    const result = await http.get(
        `${API_BASE_URL}${fetchHistoryURL}${family_history}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data = JSON.parse(data);
      dispatch(fetchFamilyHistorySuccess({ familyHistory: data}));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchFamilyHistoryFailed());
    throw error;
  }
};


const saveFamilyHistoryStart = () => {
  return { type: actions.SAVE_FAMILY_HISTORY_START };
};

const saveFamilyHistoryFailed = () => {
  return { type: actions.SAVE_FAMILY_HISTORY_FAILED };
};

const saveFamilyHistorySuccess = (payload) => {
  return { type: actions.SAVE_FAMILY_HISTORY_SUCCESS, payload };
};

export const saveFamilyHistoryData = (data) => async (dispatch) => {

  try {
    dispatch(saveFamilyHistoryStart());

    let family = data;

    family=JSON.stringify(family);
    family=encrypt(family)
    const result = await http.post(
        `${API_BASE_URL}${saveFamilyHistoryURL}`,
        {"data": family}
    );

    if (result.status === 200) {

      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

      dispatch(saveFamilyHistorySuccess({ familyHistory: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveFamilyHistoryFailed());
    throw error;
  }
};
export const updateFamilyHistory = (data, _id)=> async (dispatch) => {
  try{

    let id = _id;
    id = encrypt(id);
    let updateFamilyRecord = data;
    updateFamilyRecord = JSON.stringify(updateFamilyRecord);

    updateFamilyRecord = encrypt(updateFamilyRecord);
    const result = await http.put(`${API_BASE_URL}${saveFamilyHistoryURL}?id=${id}`, {"data":updateFamilyRecord});


  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}
export const deleteFamilyHistory = (_id)=> async (dispatch) => {
  try{
    let id = _id;

    id = encrypt(id);

    return await http.delete(`${API_BASE_URL}${saveFamilyHistoryURL}?id=${id}`)

  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}

//Common History


const saveCommonHistoryStart = () => {
  return { type: actions.SAVE_COMMON_HISTORY_START };
};

const saveCommonHistoryFailed = () => {
  return { type: actions.SAVE_COMMON_HISTORY_FAILED };
};

const saveCommonHistorySuccess = (payload) => {
  return { type: actions.SAVE_COMMON_HISTORY_SUCCESS, payload };
};


export const saveCommonHistory = (data) => async(dispatch) => {
  try {
    dispatch(saveCommonHistoryStart());


    let common={"data": data}
    common=JSON.stringify(common)
    common=encrypt(common)

    // const result = await http.post(`${API_BASE_URL}/history/commonCondition`, {"data": common});
    const result = await http.post(`${API_BASE_URL}${saveCommonHistoryURL}`, {"data": common});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);
      dispatch(saveCommonHistorySuccess({ commonHistory: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveCommonHistoryFailed());
    throw error;
  }

}
export const updateCommonHistory = (data, id) => async(dispatch) => {

  let updatedData=data;
  updatedData=JSON.stringify(updatedData);
  updatedData=encrypt(updatedData);
  let updateId=id;
  // updateId=JSON.stringify(updateId);
  updateId=encrypt(updateId);


  // return http.put(`${API_BASE_URL}/history/commonCondition?id=${id}`,data)
  try {
  return http.put(`${API_BASE_URL}${saveCommonHistoryURL}?id=${updateId}`,{"data": updatedData})
}catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
}
}
export const deleteCommonHistory = (params) => async(dispatch) => {
  params = encrypt(params);
  try{
    return http.delete(`${API_BASE_URL}${saveCommonHistoryURL}?id=${params}`,{params})
  }catch(error){
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }

}
const fetchCommonHistoryStart = () => {
  return { type: actions.FETCH_COMMON_HISTORY_START };
};

const fetchCommonHistoryFailed = () => {
  return { type: actions.FETCH_COMMON_HISTORY_FAILED };
};

const fetchCommonHistorySuccess = (payload) => {
  return { type: actions.FETCH_COMMON_HISTORY_SUCCESS, payload };
};

export const fetchCommonHistory = () => async (dispatch) => {

  try {
    dispatch(fetchCommonHistoryStart());
    let common_history = "common_history";
    common_history = encrypt(common_history);
    const result = await http.get(
        `${API_BASE_URL}${fetchHistoryURL}${common_history}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data= JSON.parse(data);


      dispatch(fetchCommonHistorySuccess({ commonHistory: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchCommonHistoryFailed());
    throw error;
  }
}

const fetchMedicalConditionStart = () => {
  return { type: actions.FETCH_MEDICAL_CONDITION_START };
};

const fetchMedicalConditionFailed = () => {
  return { type: actions.FETCH_MEDICAL_CONDITION_FAILED };
};

const fetchMedicalConditionSuccess = (payload) => {
  return { type: actions.FETCH_MEDICAL_CONDITION_SUCCESS, payload };
};


export const fetchMedicalConditions=() => async(dispatch) => {
  try {
    dispatch(fetchMedicalConditionStart());

    let result = await http.get(`${API_BASE_URL}/medications/e_allCondition`);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data= JSON.parse(data);


      dispatch(fetchMedicalConditionSuccess({ medicalCondition: data }));
      return data;
    }

    // return result;
  } catch(error){
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}
