import React from 'react';

const PatientAddModal = () => {
    return (
       <div>
<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
<i className="fas fa-user-plus"></i>
</button>


<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Add Patient</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Send</button>
      </div>
    </div>
  </div>
</div>
</div>
    );
};

export default PatientAddModal;