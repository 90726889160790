// import React,{useState} from "react";

// const Chart7 = ({dataSource}) => {

//   return (
//     <div className="container-fluid">
//       <input type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control' ></input>
//       <div className="row justify-content-between text-center mt-3">
//         <div className="col-3 ">
//           <p className={`bg-reading-chart-heads-color-purple font-bold  ${heading}`}> Date</p>{" "}
//         </div>
//         <div className="col">
//           <p className={`bg-reading-chart-heads-color-purple font-bold  ${heading}`}> Time</p>{" "}
//         </div>{" "}

//         <div className="col">
//           <p className={`bg-reading-chart-heads-color-purple font-bold  ${heading}`}> Taken</p>{" "}
//         </div>{" "}
//         <div className="col">
//           <p className={`bg-reading-chart-heads-color-purple font-bold  ${heading}`}> Reading</p>{" "}
//         </div>
//       </div>
//       {dataSource.dataSource && dataSource.dataSource.length > 0 && dataSource.dataSource
//       .filter((value)=>value.time.toLowerCase().includes(search.toLowerCase()))
//       .map((row, index)=>(
//       <div className="row justify-content-between text-center" key={index+1}>
//         <div className="col-3">
//           <p className="bg-reading-chart-heads-color-gray"> {row.time}</p>{" "}
//         </div>
//         <div className="col">
//           <p className="bg-reading-chart-heads-color-gray"> {row.clock}</p>{" "}
//         </div>{" "}
//         <div className="col">
//           <p className="bg-reading-chart-heads-color-gray"> {row.takenTime}</p>{" "}
//         </div>{" "}
//         <div className="col">
//           <p className="bg-reading-chart-heads-color-gray"> {row.bgValue}</p>{" "}
//         </div>
//       </div>
//       ))}
//     </div>
//   );
// };

//  export default Chart7;

 import React,{useState} from 'react';
import { Table } from 'antd';



const columns = [
  {
    title: 'Date',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Time',
    dataIndex: 'clock',
    key: 'clock',
  },
  {
    title: 'Taken',
    dataIndex: 'takenTime',
    key: 'takenTime',
  },
  {
    title: 'Reading',
    dataIndex: 'bgValue',
    key: 'bgValue',
  }
];

const Chart7 = ({dataSource}) => {
  const [search,setSearch]=useState("")
  let heading=dataSource.themeType==="bg"?"text-voyger-purple":dataSource.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
  let arr=dataSource.dataSource && dataSource.dataSource.length > 0 && dataSource.dataSource
          .filter((value)=>value.time.includes(search))
  return (
    <div className=" mr-2 w-full bg-white justify-evenly items-center po-reading rounded-lg  px-1 flex flex-col">
        <input type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control  d-block d-lg-none  mt-2' ></input>
        <div className="w-100  mt-2 position-relative bg-readings">
        <input style={{width:"30%",top:"2%"}} type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control position-absolute d-none d-lg-block z-ind' ></input>
        <Table dataSource={arr}  columns={columns} pagination={{width:"50%", defaultPageSize: 5,showQuickJumper:false,size:"small",showSizeChanger:false,position:["topRight"]}} />
        </div>
    </div>
   );
}

export default Chart7;
