// import React, { useLayoutEffect, useEffect } from "react";
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import $ from "jquery";

// am4core.useTheme(am4themes_animated);

// export default function Chart(props) {

//   $(function() {
//     $('g[style*="cursor: pointer"]').css('display', 'none');
// });

// //  let arr =[...props.arr]

//   let chart;
//   useEffect(() => {

//       chart = am4core.create(props.id && props.id!==""?props.id:"historicalChartId", am4charts.XYChart);
//     chart.paddingRight = 20;
//     chart.paddingLeft = 5;
//     chart.fontSize = 14;
//     let color1 = "#3498db";
//     let color2 = "#3498db";
//     let color3 = "#3498db";
//     let name = "Readings";
//     let name2 = "Readings";
//     let name3 = "Readings";




//     let data = props.data.reverse();

//     chart.data = props.data;

//     ////("propschart1",props.data);


//     // Create axes
//     var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

//     dateAxis.renderer.grid.template.location = 0;
//     dateAxis.renderer.minGridDistance = 30;
//     // dateAxis.dateFormats.setKey("day", "MM-dd");
//     dateAxis.dateFormats.setKey("day", "[font-size: 9px]dd-MM");
//     dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy");

//     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

//     // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
//     // Create series1
//     var series1 = chart.series.push(new am4charts.LineSeries());
//     series1.dataFields.valueY = "reading";
//     series1.dataFields.dateX = "date";
//     series1.tooltip.label.fontSize = 15;
//     series1.strokeWidth = 2;
//     series1.stroke = "#3498db";
//     // series1.tensionX = 1;
//     // series1.tensionY = 1;
//     series1.fill = "#ad3860";
//     series1.name = name;

//     //Bullets Added
//     var bullet = series1.bullets.push(new am4charts.CircleBullet());
//     bullet.circle.stroke = am4core.color("#ad3860");
//     bullet.circle.radius = 4;
//     bullet.tooltipText = "{dateX}:  {valueY}";

//     // Tooltip
//     series1.tooltip.label.textAlign = "middle";
//     series1.tooltip.pointerOrientation = "down";
//     series1.tooltip.dy = -5;

//     //Scrollbar Added
//     chart.scrollbarX = new am4core.Scrollbar();
//     chart.scrollbarX.parent = chart.bottomAxesContainer;
//     chart.scrollbarX.startGrip.background.fill = am4core.color("#ad3860");
//     chart.scrollbarX.endGrip.background.fill = am4core.color("#ad3860");
//     chart.scrollbarX.thumb.background.fill = am4core.color("#ad3860");
//     chart.scrollbarX.startGrip.width = 10;
//     chart.scrollbarX.startGrip.height = 10;
//     chart.scrollbarX.endGrip.width = 10;
//     chart.scrollbarX.endGrip.height = 10;
//     chart.scrollbarX.minHeight = 4;
//     chart.scrollbarY = new am4core.Scrollbar();
//     chart.scrollbarY.parent =chart.rightAxesContainer;
//     chart.scrollbarY.startGrip.background.fill = am4core.color("#ad3860");
//     chart.scrollbarY.endGrip.background.fill = am4core.color("#ad3860");
//     chart.scrollbarY.thumb.background.fill = am4core.color("#ad3860");
//     chart.scrollbarY.startGrip.width = 10;
//     chart.scrollbarY.startGrip.height = 10;
//     chart.scrollbarY.endGrip.width = 10;
//     chart.scrollbarY.endGrip.height = 10;
//     chart.scrollbarY.minWidth = 4;
//     chart.cursor = new am4charts.XYCursor();

//     // am4core.options.autoDispose = true;

//   },[props.data]);

//   return <div id={props.id && props.id!==""?props.id:"historicalChartId"} style={{ width: "100%", height: "100%" }}></div>;
// }
// // export default Chart;


import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
// import Tooltip from "../../utils/Tooltip";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default function Historic({
  backgroundColor = "bg-white",
  dataPoints,
  color1 = "#3498db",
  color2 = "#3498db",
  color3 = "#3498db",
  name = "Readings",
  name2 = "Readings",
  name3 = "Readings",
  id,
  text
}) {

  let arr=[dataPoints]


  useEffect(() => {
    let chart;

    // set chart container
    chart = am4core.create(id, am4charts.XYChart);

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    // dateAxis.dateFormats.setKey("day", "MM-dd");
    dateAxis.dateFormats.setKey("day", "[font-size: 9px]dd-MM");
    dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy");

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled =false;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "reading";
    series.strokeWidth = 3;
    series.stroke = am4core.color(color1);
    series.fill = "#ad3860";
    // series.sequencedInterpolation = true;
    series.tooltipText = "{valueY}";
    // series.bullets.push(new am4charts.CircleBullet());
    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#ad3860");
    bullet.circle.radius = 4;
    bullet.tooltipText = "{dateX}:  {valueY}";
    // series.fillOpacity = 0.3;
    series.defaultState.transitionDuration = 1000;
    series.name = name;

    // if (arr.length > 0 && arr[0].reading) {
      const series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.dateX = "date";
      series2.dataFields.valueY = "";
      series2.strokeWidth = 3;
      series2.stroke = am4core.color("#63b3ed");
      series2.fill = "#fa8c16";
      series2.sequencedInterpolation = true;
      series2.tooltipText = "{valueY}";
      // series2.bullets.push(new am4charts.CircleBullet());
      var bullet = series2.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#ad3860");
      bullet.circle.radius = 4;
      // series2.fillOpacity = 0.3;
      series2.defaultState.transitionDuration = 1000;
      series2.name = name2;
      // Create axes



    // }

    // if (arr.length > 0 && arr[0].reading3) {
    // const series3 = chart.series.push(new am4charts.LineSeries());
    // series3.dataFields.dateX = "date";
    // series3.dataFields.valueY = "reading3";
    // series3.strokeWidth = 3;
    // series3.stroke = am4core.color("#2b6cb0");
    // series3.fill = "#0D92D2";
    // series3.sequencedInterpolation = true;
    // series3.tooltipText = "{valueY}";
    // // series3.bullets.push(new am4charts.CircleBullet());
    // var bullet = series3.bullets.push(new am4charts.CircleBullet());
    // bullet.circle.stroke = am4core.color("#0D92D2");
    // bullet.circle.radius = 4;
    // // series3.fillOpacity = 0.3;
    // series3.defaultState.transitionDuration = 1000;
    // series3.name = name3;
    // }
    chart.data = arr;
        // Tooltip
        series.tooltip.label.textAlign = "middle";
        series.tooltip.pointerOrientation = "down";
        series.tooltip.dy = -5;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.scrollbarX = new am4core.Scrollbar();
    // add cursor new
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.startGrip.background.fill = am4core.color("#ad3860");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#ad3860");
    chart.scrollbarX.thumb.background.fill = am4core.color("#ad3860");
    chart.scrollbarX.startGrip.width = 10;
    chart.scrollbarX.startGrip.height = 10;
    chart.scrollbarX.endGrip.width = 10;
    chart.scrollbarX.endGrip.height = 10;
    chart.scrollbarX.minHeight = 4;

    // Add legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, dataPoints);

  return (
    <div id={id && id!==""?id:"historicalChartId"} style={{ width: "100%", height: "100%" }}></div>
  );
}
