import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteChronicCondition,
  fetchChronicCondition,
  saveChronicCondition,
  updateChronicCondition,
} from "../../../redux/actions/emergency";
import ShowToast from "../../../utils/show-toast";
import updateDate from "../../../utils/updateDate";
import moment from "moment";
import $ from "jquery";

class ChronicConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      description: "",
      date: "",
      editMode: false,
      chronic: [],
      chronicData: [],
      deleteModal: false,
      toDelete: "",
      chronicModal: false,
    };
  }
  componentDidMount() {
    let chronic = [
      {
        name: "",
        description: "",
        date: "",
      },
    ];
    this.setState({ chronic: chronic });
    if (this.props.chronic && this.props.chronic.data === null) {
      this.props.dispatch(fetchChronicCondition());
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.chronic && props.chronic.data !== null) {
      let chronic = props.chronic.data;

      state.chronicData = chronic;
    }
  }

  handleChange = (event, i) => {
    this.setState({ [event.target.name]: event.target.value });
    let target = event.target.name;
    let chronic = this.state.chronic;
    if (target === "name") {
      chronic[i].name = event.target.value;
      this.setState({ chronic: chronic });
    } else if (target === "description") {
      chronic[i].description = event.target.value;
      this.setState({ chronic: chronic });
    } else if (target === "date") {
      chronic[i].date = new Date(event.target.value);
      this.setState({ chronic: chronic });
    }
  };
  addValue = (event) => {
    let chronic = this.state.chronic;

    let obj = {
      name: "",
      description: "",
      date: "",
    };
    chronic.push(obj);
    this.setState({ chronic: chronic });
  };
  removeIndex = (index) => {
    let newData = this.state.chronic;

    newData.splice(index, 1);

    this.setState({ chronic: newData });
  };
  submitChronicCondition = async (event) => {
    event.preventDefault();

    let data = this.state.chronic;
    await this.props.dispatch(saveChronicCondition(data));
    ShowToast("Success!", "success");
    $("form").trigger("reset");
    this.setState({
      id: "",
      name: "",
      description: "",
      date: "",
      editMode: false,
      chronic: [],
      chronicData: [],
      deleteModal: false,
      toDelete: "",
      chronicModal: false,
    });
    await this.props.dispatch(fetchChronicCondition());
  };
  updateChronicConditionData = async (event) => {
    event.preventDefault();
    await this.props.dispatch(
      updateChronicCondition(
        {
          name: this.state.name,
          description: this.state.description,
          date: new Date(this.state.date),
        },
        this.state.id
      )
    );
    ShowToast("Success!", "success");
    $("form").trigger("reset");
    this.setState({
      id: "",
      name: "",
      description: "",
      date: "",
      editMode: false,
      chronic: [],
      chronicData: [],
      deleteModal: false,
      toDelete: "",
      chronicModal: false,
    });
    await this.props.dispatch(fetchChronicCondition());
  };

  startChronicConditionData = (event, id, tag) => {
    let obj;
    for (let index = 0; index < this.state.chronicData.length; index++) {
      if (id === this.state.chronicData[index].id) {
        obj = this.state.chronicData[index];
      }
    }
    let aller = [
      { name: obj.name, description: obj.description, date: obj.date },
    ];

    this.setState({
      chronic: aller,
      id: id,
      name: obj.name,
      description: obj.description,
      date: obj.date,
    });
    updateDate(obj.date, "date", false);
  };
  deleteChronicConditionRecord = async (id) => {
    await this.props.dispatch(deleteChronicCondition(id));
    ShowToast("Success!", "success");
    this.setState({ deleteModal: false });
    await this.props.dispatch(fetchChronicCondition());
  };
  addNewCondition = () => {
    let chronic = [
      {
        name: "",
        description: "",
        date: "",
      },
    ];
    this.setState({ chronic: chronic, chronicModal: true });
    updateDate("", "date", false);
  };
  deleteModal = (id) => {
    this.setState({ deleteModal: true, toDelete: id });
  };
  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  };
  render() {
    const { chronic, chronicData } = this.state;

    return (
      <>
        <div className="text-right">
          <button
            type="button"
            className="add-info-btn"
            data-toggle="modal"
            data-target="#chronicModal"
            onClick={this.addNewCondition}
          >
            <span className="d-flex justify-content-center align-items-center">
              <i className="fas fa-plus mr-2"></i>
              <span>Add Chronic Condition</span>
            </span>
          </button>
        </div>

        <div
          className={`modal big_modal_dialog ${
            this.state.chronicModal ? "d-block modal-fading" : " d-none"
          }`}
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {this.state.editMode
                    ? "Update Chronic Condition"
                    : "Add Chronic Condition"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({ editMode: false, chronicModal: false })
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={
                  this.state.editMode === true
                    ? this.updateChronicConditionData
                    : this.submitChronicCondition
                }
              >
                <div className="modal-body">
                  <div className="container-fluid">
                    {chronic !== null &&
                      chronic !== undefined &&
                      chronic.length > 0 &&
                      chronic.map((section, index) => (
                        <>
                          <div className="container-fluid" key={section}>
                            <div
                              className={
                                chronic.length > 1
                                  ? "d-block mb-3 text-right"
                                  : "d-none"
                              }
                            >
                              <div className="row justify-content-md-end justify-content-center">
                                <div className="col-md-3 col-10">
                                  <button
                                    type="button"
                                    className="remove-btn"
                                    onClick={(section) => {
                                      this.removeIndex(index);
                                    }}
                                  >
                                    remove
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center ">
                              <div className="col-md-12 col-10">
                                <h6 className=" ">Name :</h6>
                              </div>
                            </div>
                            <div className="row   justify-content-center ">
                              <div className="col-md-12 col-10">
                                {" "}
                                <div className="form-group">
                                  <div className="styled-select">
                                    <select
                                      name="name"
                                      id="name"
                                      value={section.name}
                                      onChange={(event) =>
                                        this.handleChange(event, index)
                                      }
                                      placeholder="Select a Blood Group"
                                      className="form-control input-md msg"
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="Anemia">Anemia</option>
                                      <option value="Arthritis">
                                        Arthritis
                                      </option>
                                      <option value="Asthma/Bronchtis/Emphysema">
                                        Asthma/Bronchtis/Emphysema
                                      </option>
                                      <option value="Bleeding/Bruising">
                                        Bleeding/Bruising
                                      </option>
                                      <option value="Blood Disorder">
                                        Blood Disorder
                                      </option>
                                      <option value="Cancer">Cancer</option>
                                      <option value="Depression/Emotional Problems">
                                        Depression/Emotional Problems
                                      </option>
                                      <option value="Drug/Alcohol Dependency">
                                        Drug/Alcohol Dependency
                                      </option>
                                      <option value="Epilepsy/Seizues">
                                        Epilepsy/Seizues
                                      </option>
                                      <option value="Hay Fever / Sinus Problems">
                                        Hay Fever / Sinus Problems
                                      </option>
                                      <option value="Hepatitis">
                                        Hepatitis
                                      </option>
                                      <option value="Immune Disorders">
                                        Immune Disorders
                                      </option>
                                      <option value="Intestinals Problems">
                                        Intestinals Problems
                                      </option>
                                      <option value="Kidney Disease">
                                        Kidney Disease
                                      </option>
                                      <option value="Liver Disease">
                                        Liver Disease
                                      </option>
                                      <option value="Lung Disease">
                                        Lung Disease
                                      </option>
                                      <option value="Skin Disease">
                                        Skin Disease
                                      </option>
                                      <option value="Stroke">Stroke</option>
                                      <option value="Stomach Ulcers">
                                        Stomach Ulcers
                                      </option>
                                      <option value="Thyroid Disease">
                                        Thyroid Disease
                                      </option>
                                      <option value="Other">Other</option>
                                      <option value="Heart">Heart</option>
                                    </select>
                                    <i className="fa fa-chevron-down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div></div> */}
                            <div className="row justify-content-center mt-3 ">
                              <div className="col-md-12 col-10">
                                <h6 className=" ">Description:</h6>
                              </div>
                            </div>
                            <div className="row justify-content-center ">
                              <div className="col-md-12 col-10 ">
                                <div className="form-group">
                                  <div className="">
                                    <input
                                      name="description"
                                      placeholder="Description"
                                      value={section.description}
                                      onChange={(event) =>
                                        this.handleChange(event, index)
                                      }
                                      className="form-control input-md msg"
                                      type="text"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                             {/* <div></div> */}
                             <div className="row justify-content-center mt-3 ">
                              <div className="col-md-12 col-10">
                                <h6 className=" ">Date:</h6>
                              </div>
                            </div>
                            <div className="row justify-content-center ">
                              <div className="col-md-12 col-10 ">
                                <div className="form-group">
                                  <div className="">
                                    <input
                                      // name="description"
                                      // placeholder="Description"
                                      // value={section.description}
                                      // onChange={(event) =>
                                      //   this.handleChange(event, index)
                                      // }
                                      // className="form-control input-md msg"
                                      // type="text"
                                      // required
                                      name="date"
                                      id="date"
                                      // value={this.state.date}
                                      onChange={(event) =>
                                        this.handleChange(event, index)
                                      }

                                      type="date"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>
                        </>
                      ))}
                  </div>
                  {this.state.editMode === false ? (
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <button
                          className="add-btn"
                          type="button "
                          onClick={this.addValue}
                        >
                          Add Fields
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="modal-footer">
                  <button type="submit" className="modal-save-btn">
                    {this.state.editMode === true ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-success">
                <table className="table table-hover" id="task-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Date</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chronicData !== null &&
                      chronicData !== undefined &&
                      chronicData &&
                      chronicData.length > 0 &&
                      chronicData.map((chronic, index) => (
                        <tr>
                          <td>{chronic.name}</td>
                          <td>{chronic.description}</td>
                          <td>
                            {moment(new Date(chronic.date)).format(
                              " DD-MM-YYYY"
                            )}
                          </td>
                          <td>
                            <button
                              type="button"
                              className=""
                              data-toggle="modal"
                              data-target="#chronicModal"
                              onClick={(event) => {
                                this.startChronicConditionData(
                                  event,
                                  `${chronic.id}`,
                                  `date${index}`
                                );
                                this.setState({ editMode: true });
                                this.setState({ chronicModal: true });
                              }}
                            >
                              <i className="fas fa-edit text-primary"></i>
                            </button>

                            <button
                              type="button"
                              className=" ml-4"
                              onClick={() => this.deleteModal(`${chronic.id}`)}
                            >
                              <span className="">
                                <i className="fas fa-trash-alt text-danger"></i>
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div
                  tabindex="-1"
                  id="myModal-delete"
                  className={`modal  ${
                    this.state.deleteModal ? "d-block fading" : " d-none"
                  }`}
                >
                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box">
                          <i className="fas fa-trash-alt text-danger"></i>
                        </div>
                        <h4 className="modal-heading w-100 text-black">
                          Are you sure?
                        </h4>
                        <button
                          type="button"
                          className="close"
                          onClick={this.closeDeleteModal}
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Do you really want to delete these records? This
                          process cannot be undone.
                        </p>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.closeDeleteModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(event) => {
                            this.deleteChronicConditionRecord(
                              this.state.toDelete
                            );
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    chronic: state.emergency.chronicCondition,
  };
}
export default withRouter(connect(mapStateToProps)(ChronicConditions));
