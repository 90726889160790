import React, { useState } from "react";
import GlucoRxLogo from "../../assets/glucorxwhite.svg";
import { default as Titles } from "../../utils/document-titles";
import { forgotPassword } from "../../redux/actions/auth";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { BackgroundImage } from "../../assets";
import ShowToast from "../../utils/show-toast";
import { Link, useHistory } from "react-router-dom";

export default function Login() {
  document.title = Titles.forgotPassword;

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {

    try {
      setIsLoading(true);
      e.preventDefault();
      const result =await dispatch(forgotPassword({ email }));

      ShowToast(result.data.message, "success");
      history.push("/auth/login");
      setIsLoading(false);

    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className='container-fluid d-flex flex-column forget-background ' >

        <div className="row justify-center align-item-center my-auto" >
            <div className="col-md-7 col-11 forget-field-box align-items-center py-5">
               <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>
               <p className=' text-white text-center forget-text-font mt-3'>We will send you link to reset your password</p>
               <form onSubmit={handleSubmit}>
                <div className="row justify-content-center align-items-center my-3 text-white">
                    <div className="col-md-10 col-10">
                        <input
                          type="email"
                          value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
                          className="forget-form-control"
                          id="formGroupExampleInput"
                          required
                        />
                    </div>
                </div>
                <div className="row justify-content-center align-items-center my-3 text-white">
                    <div className="col-md-10 col-10">
                <Spin spinning={isLoading}>
                      <button
                            type="submit"
                            className="forget-submit-btn btn-primary "
                          >
                            Send Email

                        </button>
                        </Spin>
                    </div>
                </div>
                </form>
                <div className="mt-3 text-white">
                    <p className="text-center forget-text-font">
                    <Link
                  to={"/auth/login"}>
                  Back to Sign In!
                </Link>
                    </p>
                </div>
            </div>


            </div>


    </div>
  );
}
