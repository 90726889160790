import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout } from "../../redux/actions/auth";
import UserAvatar from "./user-avatar";
import ClinicalNav from "./nav/clinician-nav";
import PatientNav from "./nav/patient-nav";
import AdminNav from "./nav/admin-nav";
import UsersMangement from "./nav/usersmanagement";

import UserRoles from "../../utils/user-roles";

import { Spin } from "antd";
import NewClinicianNav from "./nav/NewClinicianNav";
import NewPatientNav from "./nav/NewPatientNav";
import DoctorNav from "./nav/doctor-nav";

function SideBar({ collapsed },props) {
  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    return () => {};
  }, [userDetails]);

  return (
    <div className='sticky-top w-100 h-100 d-flex flex-column justify-content-center justify-content-md-start'>
      {userDetails && (
        <>
          {/*<div className="flex justify-center">*/}
          {/*  <UserAvatar*/}
          {/*    name={`${userDetails.firstName} ${userDetails.lastName}`}*/}
          {/*    collapsed={collapsed}*/}
          {/*    logout={() => dispatch(logout(false))}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="sticky-top w-100">
            {userDetails.role === UserRoles.CLINICIAN && (
              // <ClinicalNav collapsed={collapsed} />
              <NewClinicianNav/>
            )}

            {userDetails.role === UserRoles.PATIENT && (
              // <PatientNav collapsed={collapsed} data={props}/>
              <NewPatientNav/>

            )}
            {userDetails.role === UserRoles.DOCTOR && (
              // <PatientNav collapsed={collapsed} data={props}/>
              <DoctorNav/>

            )}

            {(userDetails.role === UserRoles.ADMIN ||
              userDetails.role === UserRoles.SUPER_ADMIN )  && (
              <AdminNav collapsed={collapsed} />
            )}
            {/*(userDetails.role === UserRoles.ADMIN ||
              userDetails.role === UserRoles.SUPER_ADMIN && history.pathname === "/admin/userManagement") && (
              <UsersMangement collapsed={collapsed} />
            )*/}
          </div>
        </>
      )}
      {!userDetails && <div className='d-flex justify-content-center align-items-center h-100 w-100'><Spin /></div>}
    </div>
  );
}

export default SideBar;
