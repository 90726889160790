
import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";

am4core.useTheme(am4themes_animated);

function AidexTimeRange(props) {
  // // // // console.log("PROPS IN TIME RANGE", props);
        $(function() {
                $('g[style*="cursor: pointer"]').css('display', 'none');
            });
  let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";

    useEffect(() => {
        var chart = am4core.create("aidexTimeRange", am4charts.XYChart);

        chart.fontSize = 14;

        chart.data = props.dataPoints;

// Create axes

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "label";
// categoryAxis.title.text = "Local country offices";
        categoryAxis.renderer.grid.template.location = 1;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.title.text=props.themeType==="bg"?"[bold]Blood Glucose Range":"[bold]Inr Range"

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text=props.themeType==="bg"?"[bold]Frequency":"[bold]Inr"
        valueAxis.min = 1;
        categoryAxis.renderer.minGridDistance = 5;
// valueAxis.max = 20;
// valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 30;
// valueAxis.logarithmic = true;
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "label";
        series.tooltipText = "{valueY.value}";
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.dy = -6;
        series.columns.template.fill = am4core.color(color2);
// series.columnsContainer.zIndex = 100;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.hiddenState.properties.opacity = 1;
        series.tooltip.hiddenState.properties.visible = true;
// series.tooltip.adapter.add("x", function (x, target) {
// return (
// am4core.utils.spritePointToSvg(
// { x: chart.plotContainer.pixelX, y: 0 },
// chart.plotContainer
// ).x +
// chart.plotContainer.pixelWidth / 2
// );
// });

        var columnTemplate = series.columns.template;
        columnTemplate.width = 12;
        // columnTemplate.maxWidth = 6;
        columnTemplate.column.cornerRadiusTopRight = 8;
        columnTemplate.column.cornerRadiusTopLeft = 8;
        columnTemplate.strokeOpacity = 0;

        series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            dataField: "valueY",
// min: am4core.color("#0D92D2"),
// max: am4core.color("#AD3860"),
        });
        // chart.legend = new am4charts.Legend();
        var cursor = new am4charts.XYCursor();
        cursor.behavior = "panX";
        chart.cursor = cursor;
        cursor.lineX.disabled = true;

        chart.events.on("datavalidated", function () {
            categoryAxis.zoomToCategories(
                chart.data[0],
                chart.data[chart.data.length - 1],

                false,
                true
            );
        });

        series.mainContainer.mask = undefined;
    }, [props.dataPoints]);

    return <div id="aidexTimeRange" style={{ width: "100%", minHeight: "250px"}}></div>;
}
export default AidexTimeRange;
