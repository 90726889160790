import React, { useEffect, useState } from "react";

import {useDispatch, useSelector} from "react-redux";

import AidexChart from "./aidex-chart.js";
import CgmReport from"./cgm-report";
import AidexTimeRangeBg from "./time-range/AidexTimeRangeBg";
import AverageBg from "./AverageBgComp";
import BgStats from "../BloodGlucose/BgStats";
import BgRating from "../BloodGlucose/BgRating";

import Loading from "./loading";

//
import TimeInRangeReport from "./time-in-range-report";

import {fetchCgmData,fetchSortedCgmData, currentRecord,resetCgmReadings} from "../../../../redux/actions/aidex/cgm";
import * as _ from "lodash";
import moment from "moment";

import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";
const AidexOverview = () => {
  // document.title = Titles.reports;
  const { RangePicker } = DatePicker;

  const [filterText, setFilterText] = useState("Choose Data Range");

  // const [filter, setFilter] = useState("30days");

  const [filter, setFilter] = useState("24hours");

  const [tfTimeRange, setTfTimeRange] = useState([]);
  const [bgStats, setBgStats] = useState([]);

  const [avgBg, setAvgBg] = useState([]);

  const [showDate, setShowDate] = useState(false);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);

  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const [organizedData, setOrganizedData] = useState([]);
  const [insulin, setInsulin] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [diet, setDiet] = useState([]);
  const [medicine, setMedicine] = useState([]);


  const [calculatedValues, setCalculatedValues] = useState({});


  const calculated = useSelector((state) => {
    return (state.cgm.currentRecordCalculations)});
  const userData = useSelector((state) => state.user);
  const data = useSelector((state) => {
    return (state.cgm)});

  // const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);

  const cgmReadings = useSelector((state)=> state.cgm.cgmReadings);
  const sortedCgmReadings = useSelector((state)=> state.cgm.sortedCgmReadings);
  const myMin = (arr, prop) => _.minBy(arr, (o) => o[prop])[prop];
  const myMax = (arr, prop) => _.maxBy(arr, (o) => o[prop])[prop];

  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };


  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchSortedCgmData());

    if (filter !== "" || startDate !== "") {
      fetchReadings();
    }
    return function cleanup() {
    dispatch(resetCgmReadings());
  }
}, [filter, startDate, localStorage.getItem('AidexAuthToken')]);

  // useEffect(() => {
  //   // transformHistoricalReadings();
  // }, [data.cgmReadings]);

  const fetchReadings = async () => {


    let duration = {};
    // let findDaysRange =(date)=>{
    //   let start = moment(date).add(7, 'days');
    //   return new Date(start._d);
    // }
    if (filter) {
      let data_from = findStartDate(filter);
      let data_to = findEndDate();
      // let data_to = findDaysRange(data_from);

      duration.timeFrom = data_from;
      duration.timeTo = data_to;


    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      let lastTime = new Date(endDate);
lastTime.setHours(23);
lastTime.setMinutes(59);
lastTime.setSeconds(59);

      duration.timeTo = new Date (lastTime);
    }
    if(localStorage.getItem('AidexAuthToken') &&
     localStorage.getItem('AidexAuthToken')!=="" &&
      localStorage.getItem('AidexAuthToken')!== null &&
       localStorage.getItem('AidexAuthToken')!==undefined){
    const response = await  dispatch(fetchCgmData(duration));
    // // // console.log("repo",response);

    if (response && response.data){
      // // // console.log(response);
      if(response.data && response.data.length>0){

        setOrganizedData(response.data);

      }else {
        setOrganizedData(undefined);

      }
      setDiet(response.diet);
      setExercise(response.exercise);
      setInsulin(response.insulin);
      setMedicine(response.medicine);

      setCalculatedValues(response.calculation);
      // if(response.timeRange && response.timeRange.length>0){
        setTfTimeRange(response.timeRange.timeRange);

      // }
      let abg = response.calculation.hoursArr.map((hour, index)=>{
        let avgObj;
        let hourLength= hour.length;
        let gluco= 0;
        // // // // console.log("HOUR LENGTH", hour.length);
        for(let i = 0; i<hour.length; i++){
          gluco = gluco + hour[i].glucose;
        }
        let value = gluco/hourLength;
        avgObj = {
          date:index,
          value: value
        }
        // // // // console.log("AVERAGE OBJECT", avgObj);
        return avgObj;

      })
      setAvgBg(abg);

      //BG STATS
      setBgStats(response.BgStats);


    } else {
      // // // console.log(response);
      setOrganizedData(response);

    }
  }





    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };




  function handleMenuClick(e) {
    switch (e.item.props.value) {
      case "today":
        break;
      case "week":
        break;
      case "month":
        break;
      case "range":
        break;
      default:
        break;
    }
  }

  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {

      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {


      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {



      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {


      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "40days") {


      let start = moment().subtract(40, 'days');
      return new Date(start._d);

    } else if(filter === "24hours"){


      let start = moment().add(-24, 'hours');
      // // // // console.log("start._d", new Date(start._d));
      return new Date(start._d);

    }
    else if(filter === "17days"){
      let start = moment().subtract(17, 'days');
      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  // const transformTimeRange = () => {
  //   let labels = ["<3.9", "3.9-4.9", "5.0-8.0", "8.1-13.3", ">13.3"];
  //   let labelsMgdl = ["<70.24", "70.24-88.3", "90.0-144.1", "145.9-239.5", ">239.5"];
  //   const keys = Object.keys(data.timeRange[0]);
  //   const transformedData = keys.map(key => {
  //     return {
  //       label: unit.preferences && unit.preferences.unit === 0 ? labels[Number(key)] : labelsMgdl[Number(key)],
  //       value: data.timeRange[0][key].value
  //     }
  //   });
  //
  //   setTfTimeRange(transformedData);
  // };
    return (

        <div className="container-fluid h-100 p-0">
            {/*<Navbar user={userData}/>*/}
            <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">
              <div className="col-12">
                <div className="row  mr-sm-0 ">
                  <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
                    <div className="d-flex filter-btn p-2 align-items-center justify-content-lg-start filter-area justify-content-sm-center justify-content-center">

                      <div className="d-flex align-items-center p-2">
                      <div className=" text-voyger-purple">
                        <h4 className="chart-heading m-0">{
                          filter=== "30days"? "30 days":
                          filter=== "14days"? "14 days":
                          filter=== "7days"? "07 days":
                          filter=== "24hours"?filter : null}
                        </h4>
                      </div>
                      {filter==""?<span>
                      <span className="p-2">{toDate? toDate.toString(): 0} </span>
                        <span className=""> - </span>
                        <span className="p-2"> {fromDate? fromDate.toString(): 0}</span>
                      </span>:<span>
                      <span className="p-2">{fromDate? fromDate.toString(): 0} </span>
                        <span className="p-2"> - </span>
                        <span className="p-2"> { toDate? toDate: 0}</span>
                      </span>}

                      </div>
                        {/*<div className="row mw-100 m-auto justify-content-between align-items-center">
                          <h3 className="col-12 col-md font-weight-bold text-black">{filter}</h3>
                          <div className="col-12 col-md bg-light p-3"><span>{fromDate? fromDate.toString(): 0} - { toDate? toDate: 0}</span></div>
                        </div>*/}

                      <div className="d-flex justify-content-center flex-row mt-md-0 mt-2">

                          <button
                          className={
                            startDate === "" && filter === "24hours"
                              ? "bg-filter-btn mx-1 select-active"
                              : " bg-filter-btn mx-1"
                          }
                            onClick = {() => {
                                  setStartDate("");
                                  setEndDate("");
                                  setShowDate(false);
                                  setFilter("24hours");
                                  setFilterText("1day");
                                  setWeekFilter(true);
                                  setTodayFilter(false);
                                  setMonthFilter(false);
                                  setRangeFilter(false);
                                  if(filter !== "24hours"){setOrganizedData([])}

                                  }
                                }>
                                        1 day
                                        </button>
                          <button className={
                            startDate === "" && filter === "7days"
                            ? "bg-filter-btn mx-1 select-active"
                            : " bg-filter-btn mx-1"
                          }
                          onClick = {
                                          () => {
                                            if(filter !== "7days"){setOrganizedData([])}

                                            setStartDate("");
                                            setEndDate("");
                                            setShowDate(false);
                                            setFilter("7days");
                                            setFilterText("7days");
                                            setWeekFilter(false);
                                            setTodayFilter(false);
                                            setMonthFilter(false);
                                            setRangeFilter(false);
                                          }
                                        }
                          >7 days</button>
                          <button className={
                            startDate === "" && filter === "14days"
                            ? "bg-filter-btn mx-1 select-active"
                            : " bg-filter-btn mx-1"
                          }
                          onClick = {
                                          () => {
                                            if(filter !== "14days"){setOrganizedData([])}

                                            setStartDate("");
                                            setEndDate("");
                                            setShowDate(false);
                                            setFilter("14days");
                                            setFilterText("14days");
                                            setWeekFilter(false);
                                            setTodayFilter(false);
                                            setMonthFilter(true);
                                            setRangeFilter(false);
                                          }
                                        }

                          >14 days</button>
                          {/*}<button className=""
                          onClick = {
                                          () => {
                                            setStartDate("");
                                            setEndDate("");
                                            setShowDate(false);
                                            setFilter("90days");
                                            setFilterText("90Days");
                                            setWeekFilter(false);
                                            setTodayFilter(false);
                                            setMonthFilter(true);
                                            setRangeFilter(false);
                                          }
                                        }
                          >90 days</button>*/}
                          {/*<button type="button"

                          onClick = {
              () => {
                setFilter("");
                setFilterText("Range");
                setShowDate(true);
                setWeekFilter(false);
                setTodayFilter(false);
                setMonthFilter(false);
                setRangeFilter(true);
                setDropMenu(!dropMenu)
              }
            }>
              RANGE
            </button>*/}

            <div className={dropMenu==true?"dropdown-contenting d-block":"dropdown-contenting d-none"} >
                    {
              showDate && ( <RangePicker
                 defaultValue = {
                    startDate && [moment(startDate), moment(endDate)]
                  }
                                  onChange = {
                                    (val) => {
                                      setStartDate(val[1].format("YYYY-MM-DD").toString());
                                      setEndDate(val[0].format("YYYY-MM-DD").toString());
                                      setToDate(val[1].format("YYYY-MM-DD").toString());
                                      setFromDate(val[0].format("YYYY-MM-DD").toString());
                                      setDates(val);
                                    }
                                  }
                value={hackValue || value}
    // disabledDate={disabledDate}
    // onCalendarChange={val => setDates(val)}
    // onChange={val => setValue(val)}
    // onOpenChange={onOpenChange}
                  />
              )
            }
            </div>



</div>


                      <div className="col-md-2 col-12 overview-border d-md-flex justify-content-around align-items-center d-none">
                      {/*<i className="fas font-blue fa-print"></i>
                      <i className="fas font-blue fa-download"></i>
                      <i className="fas font-blue fa-envelope"></i>
                      <i className="fas font-blue fa-share"></i>*/}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row  mr-sm-0  mt-2 justify-content-center">
                  <div className="col-12" style={{ height: "500px" }}>
                    <div className={organizedData !== undefined || organizedData===null?"   bg-white border-radius-chart p-3 d-flex":"   bg-white border-radius-chart p-3 "} style={{ height: "100%" }}>
                    {/*<AidexChart data={organizedData} diet={diet} medicine={medicine} exercise={exercise} insulin={insulin} graph="cgm"/>*/}

                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
                    <CgmReport data={organizedData} id="overview" graph="cgm"/>


                    :
                    organizedData === undefined ?<h1 className="text-center pt-5">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>

                <div className="row  mr-sm-0  mt-2 justify-content-center">
                  <div className=" col-md-12" style={{ height: "700px" }}>
                    <div className={organizedData !== undefined || organizedData===null?"   bg-white border-radius-chart p-3 d-flex":"   bg-white border-radius-chart p-3 "} style={{ height: "100%" }}>
                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?

                    <CgmReport data={calculatedValues.hoursArr} graph="agp"/>


                    :
                    organizedData === undefined ?<h1 className="text-center">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>
                {/*organizedData && organizedData === "" || organizedData === null || organizedData === undefined || organizedData.length === 0?null:

                <div className="row  mr-sm-0  mt-2">
                  <div className="col-12  timeRange my-md-0 my-5" style={{ height: "300px" }} >
                    <div className="   bg-white border-radius-chart p-3 " style={{ height: "100%" }}>
                    <TimeInRangeReport chartId="overviewChart" data={calculatedValues}/>


                    </div>
                  </div>
                </div>  */}

                <div className="row mr-sm-0  mt-2">
                  <div className="col-12 col-lg-6" >
                    <div className="   bg-white border-radius-chart p-3 " style={{ height: "100%" }}>
                      <AidexTimeRangeBg themeType={"bg"} dataPoints = { tfTimeRange }/>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6" >
                    <div className=" bg-white h-100 border-radius-chart d-flex justify-content-lg-around justify-content-xl-around flex-column">
                    {organizedData && organizedData === "" || organizedData === null || organizedData === undefined || organizedData.length === 0?null:
                    <>
                    <BgRating
                         id={"chart1"}
                          margin={true}
                          height="60%"
                          width="75%"
                          themeType={"bg"}
                          timePercentage = {
                        calculatedValues.avgP
                      }
                                 glucosePercentage = {

                                       calculatedValues.hiP +
                                      calculatedValues.loP

                                 }
                                 timeDataPoints = {
                                   [{
                                     label: "In-Range",
                                     value: parseInt(calculatedValues.avgP),
                                   },
                                     {
                                       label: "Out-Range",
                                       value: 100 - parseInt(calculatedValues.avgP),
                                     },
                                   ]
                                 }
                                 glucoseDataPoints = {
                                   [{
                                     label: "High-events",
                                     value: parseInt(calculatedValues.hiP),
                                   },
                                     {
                                       label: "Low-events",
                                       value: parseInt(calculatedValues.loP),
                                     },
                                     {
                                       label: "In-Range",
                                       value: parseInt(calculatedValues.avgP),
                                     },
                                   ]
                                 }
                      />
                      <BgStats themeType={"bg"} {...bgStats} />
                      </>
                    }
                    </div>
                  </div>

                </div>

                <div className="row  mr-sm-0  mt-2 justify-content-center">
                <div className="col-12 mt-2 mt-md-0" >
                  <div className="   bg-white border-radius-chart p-3 " style={{ height: "400px" }}>
                    {/*<AverageBg dataPoints = { tfAvgBgData }/>*/}
                    <AverageBg themeType={"bg"} id={"aidexABg"} dataPoints = { avgBg }/>
                  </div>
                </div>

                </div>
              </div>

            {/*</main>*/}
            </div>
        </div>
    );
};

export default AidexOverview;
