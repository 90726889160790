import React,{useState, useEffect} from "react";
import {DatePicker} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {fetchCgmDataForHistory} from "../../../../redux/actions/aidex/cgm";
import HistoryChart  from "./history-chart";
import Loading from"./loading";

import * as _ from "lodash";
import moment from "moment";


const AidexHistory = () => {
    // const [dateFilter,setDateFilter]=useState();

    // const handleFilterDate=(date)=>{
    //     setDateFilter(date);
    // }
  const { RangePicker } = DatePicker;

  const userData = useSelector((state) => state.user);
  const [organizedData, setOrganizedData] = useState([]);

  let data = [];


  const [dateFilter,setDateFilter]=useState(false);
  const [timeFilter,setTimeFilter]=useState(false);
  const [eventFilter,setEventFilter]=useState(false);
  const [usageFilter,setUsageFilter]=useState(false);
  const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("7days");
  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [tfBgReadings, setTfBgReadings] = useState([]);
  const [tfAvgBgData, setTfAvgBgData] = useState([]);
  const [tfTimeRange, setTfTimeRange] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [firstWeekStartDate, setFirstWeekStartDate] = useState("");

  const [dropMenu,setDropMenu]=useState(false);

  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };


  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      fetchReadings();
    }
  }, [filter, startDate]);

  const fetchReadings = async () => {


    let duration = {};
    if (filter) {
      let data_from = findStartDate(filter);
      let data_to = findEndDate();

      duration.timeFrom = data_from;
      duration.timeTo = data_to;



    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      let lastTime = new Date(endDate);
lastTime.setHours(23);
lastTime.setMinutes(59);
lastTime.setSeconds(59);

      duration.timeTo = new Date (lastTime);
    }
    const response = await  dispatch(fetchCgmDataForHistory(duration));
    if (response && response.data){
      // // // // console.log("RES DATA", response.data);
      setOrganizedData(response.data);
    } else {
      // // // // console.log("ELSE RES", response);
      setOrganizedData(response);

    }




    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };




  function handleMenuClick(e) {
    switch (e.item.props.value) {
      case "today":
        break;
      case "week":
        break;
      case "month":
        break;
      case "range":
        break;
      default:
        break;
    }
  }

  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      setFirstWeekStartDate(new Date(start._d));

      return new Date(start._d);

    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');
      setFirstWeekStartDate(new Date(start._d));

      return new Date(start._d);


    } else if (filter === "14days") {


      let start = moment().subtract(14, 'days');
      setFirstWeekStartDate(new Date(start._d));
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      setFirstWeekStartDate(new Date(start._d));

      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');
      setFirstWeekStartDate(new Date(start._d));

      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');
      setFirstWeekStartDate(new Date(start._d));

      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      setFirstWeekStartDate(new Date(start._d));

      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }
useEffect(()=>{

},[organizedData])

    return (
        <div className="container-fluid h-100 p-0">
          <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">
            <div className="col-12">
              <div className="row  mr-sm-0 ">
                <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
                  <div className="d-flex filter-btn p-2 align-items-center justify-content-lg-start filter-area justify-content-sm-center justify-content-center">
                  <div className="d-flex align-items-center p-2">
                  <div className=" text-voyger-purple">
                    <h4 className="chart-heading m-0">{
                      filter=== "30days"? "30 days":
                      filter=== "14days"? "14 days":
                      filter=== "7days"? "07 days":
                      filter=== "24hours"?filter : null}
                    </h4>
                  </div>
                  {filter==""?<span>
                  <span className="p-2">{toDate? toDate.toString(): 0} </span>
                    <span className=""> - </span>
                    <span className="p-2"> {fromDate? fromDate.toString(): 0}</span>
                  </span>:<span>
                  <span className="p-2">{fromDate? fromDate.toString(): 0} </span>
                    <span className="p-2"> - </span>
                    <span className="p-2"> { toDate? toDate: 0}</span>
                  </span>}

                  </div>
                    {/*<div className="row mw-100 m-auto justify-content-between align-items-center">
                      <h3 className="col-12 col-md font-weight-bold text-black">{filter}</h3>
                      <div className="col-12 col-md bg-light p-3"><span>{fromDate? fromDate.toString(): 0} - { toDate? toDate: 0}</span></div>
                    </div>*/}

                  <div className="d-flex justify-content-center flex-row mt-md-0 mt-2">

                        <button className={
                          startDate === "" && filter === "7days"
                          ? "bg-filter-btn mx-1 select-active"
                          : " bg-filter-btn mx-1"
                        }
                          onClick = {() => {
                            if(filter !== "7days"){setOrganizedData([])}

                                setStartDate("");
                                setEndDate("");
                                setShowDate(false);
                                setFilter("7days");
                                setFilterText("Week");
                                setWeekFilter(true);
                                setTodayFilter(false);
                                setMonthFilter(false);
                                setRangeFilter(false);

                                }
                              }>
                                      7 days
                                      </button>
                        <button className={
                          startDate === "" && filter === "14days"
                          ? "bg-filter-btn mx-1 select-active"
                          : " bg-filter-btn mx-1"
                        }
                        onClick = {
                                        () => {
                                          if(filter !== "14days"){setOrganizedData([])}

                                          setStartDate("");
                                          setEndDate("");
                                          setShowDate(false);
                                          setFilter("14days");
                                          setFilterText("14Days");
                                          setWeekFilter(false);
                                          setTodayFilter(false);
                                          setMonthFilter(false);
                                          setRangeFilter(false);



                                        }
                                      }
                        >14 days</button>
                        <button className={
                          startDate === "" && filter === "30days"
                          ? "bg-filter-btn mx-1 select-active"
                          : " bg-filter-btn mx-1"
                        }
                        onClick = {
                                        () => {
                                          if(filter !== "30days"){setOrganizedData([])}

                                          setStartDate("");
                                          setEndDate("");
                                          setShowDate(false);
                                          setFilter("30days");
                                          setFilterText("Month");
                                          setWeekFilter(false);
                                          setTodayFilter(false);
                                          setMonthFilter(true);
                                          setRangeFilter(false);


                                        }
                                      }

                        >30 days</button>


          <div className={dropMenu==true?"dropdown-contenting d-block":"dropdown-contenting d-none"} >
                  {
            showDate && ( <RangePicker
               defaultValue = {
                  startDate && [moment(startDate), moment(endDate)]
                }
                                onChange = {
                                  (val) => {
                                    setStartDate(val[1].format("YYYY-MM-DD").toString());
                                    setEndDate(val[0].format("YYYY-MM-DD").toString());
                                    setToDate(val[1].format("YYYY-MM-DD").toString());
                                    setFromDate(val[0].format("YYYY-MM-DD").toString());
                                    setDates(val);
                                  }
                                }
              value={hackValue || value}
  // disabledDate={disabledDate}
  // onCalendarChange={val => setDates(val)}
  // onChange={val => setValue(val)}
  // onOpenChange={onOpenChange}
                />
            )
          }
          </div>



  </div>


                  <div className="col-md-2 col-12 overview-border d-md-flex justify-content-around align-items-center d-none">

                    </div>
                  </div>

                </div>
              </div>
              <div className="row  mr-sm-0  mt-2 justify-content-center">
              <div className=" col-md-12" style={{ height: "100%" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <div className='h-100 d-flex flex-column justify-content-md-between justify-content-center px-3 pt-2'>
                <div className="d-flex flex-column justify-content-between  mt-5 pt-2 mb-4" style={{minHeight:"590px"}}>
                {/*<HistoryChart chartId={"HistoryReport"+ index}  data={week}/>*/}

                {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
                organizedData.map((week, index)=> (
                  week.readings[0].length>0?
                  <div className="w-100" key={index+1}>
                  <HistoryChart chartId={"HistoryReport"+ index}  data={week}/>

                  </div>
                  :null
                ))

                :
                organizedData === undefined ?<h1 className="text-center pt-5">No data found.</h1>:
                <Loading/>

              }
              {/*  { organizedData === "" || organizedData === null?
                <Loading/>
                : organizedData === undefined ?<h1 className="text-center pt-5">No data found.</h1>:
                organizedData.map((week, index)=> (
                  <>
                    {<HistoryChart chartId={"HistoryReport"+ index} data={week}/>}
                  </>
                ))


              }*/}

                </div>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default AidexHistory;
