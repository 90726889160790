export const LOGIN_START = "LOGIN_START";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const RESEND_VERIFICATION_EMAIL_START =
  "RESEND_VERIFICATION_EMAIL_START";
export const RESEND_VERIFICATION_EMAIL_FAILED =
  "RESEND_VERIFICATION_EMAIL_FAILED";
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  "RESEND_VERIFICATION_EMAIL_SUCCESS";


export const SAFARI_CHECK = "SAFARI_CHECK";
