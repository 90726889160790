import * as actions from "../actionTypes/history";

const initialState = {
  vitalInfo: { isLoading: false, data: null },
  socialHistory: { isLoading: false, data: null },
  hospitalAdmissions: { isLoading: false, data: null },
  surgicalHistory: { isLoading: false, data: null },
  familyHistory: { isLoading: false, data: null },
  commonHistory: { isLoading: false, data: null },
  medicalCondition:{ isLoading: false, data: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_VITAL_INFO_START:
      return {
        ...state,
        vitalInfo: {
          ...state.vitalInfo,
          isLoading: true,
        },
      };
    case actions.FETCH_VITAL_INFO_FAILED:
      return {
        ...state,
        vitalInfo: {
          ...state.vitalInfo,
          isLoading: false,
        },
      };
    case actions.FETCH_VITAL_INFO_SUCCESS:
      return {
        ...state,
        vitalInfo: {
          data: action.payload.vitalInfo,
          isLoading: false,
        },
      };

    case actions.SAVE_VITAL_INFO_START:
      return {
        ...state,
        vitalInfo: {
          ...state.vitalInfo,
          isLoading: true,
        },
      };
    case actions.SAVE_VITAL_INFO_FAILED:
      return {
        ...state,
        vitalInfo: {
          ...state.vitalInfo,
          isLoading: false,
        },
      };
    case actions.SAVE_VITAL_INFO_SUCCESS:
      return {
        ...state,
        vitalInfo: {
          // ...state.vitalInfo,
          data: action.payload.vitalInfo,
          isLoading: false,
        },
      };
    case actions.FETCH_SOCIAL_HISTORY_START:
      return {
        ...state,
        socialHistory: {
          ...state.socialHistory,
          isLoading: true,
        },
      };
    case actions.FETCH_SOCIAL_HISTORY_FAILED:
      return {
        ...state,
        socialHistory: {
          ...state.socialHistory,
          isLoading: false,
        },
      };
    case actions.FETCH_SOCIAL_HISTORY_SUCCESS:
      return {
        ...state,
        socialHistory: {
          data: action.payload.socialHistory,
          isLoading: false,
        },
      };

    case actions.SAVE_SOCIAL_HISTORY_START:
      return {
        ...state,
        socialHistory: {
          ...state.socialHistory,
          isLoading: true,
        },
      };
    case actions.SAVE_SOCIAL_HISTORY_FAILED:
      return {
        ...state,
        socialHistory: {
          ...state.socialHistory,
          isLoading: false,
        },
      };
    case actions.SAVE_SOCIAL_HISTORY_SUCCESS:
      return {
        ...state,
        socialHistory: {
          data: action.payload.socialHistory,
          // ...state.socialHistory,
          isLoading: false,
        },
      };
    case actions.FETCH_HOSPITAL_ADMISSION_HISTORY_START:
      return {
        ...state,
        hospitalAdmissions: {
          ...state.hospitalAdmissions,
          isLoading: true,
        },
      };
    case actions.FETCH_HOSPITAL_ADMISSION_HISTORY_FAILED:
      return {
        ...state,
        hospitalAdmissions: {
          ...state.hospitalAdmissions,
          isLoading: false,
        },
      };
    case actions.FETCH_HOSPITAL_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        hospitalAdmissions: {
          data: action.payload.hospitalAdmissions,
          isLoading: false,
        },
      };

    case actions.SAVE_HOSPITAL_ADMISSION_HISTORY_START:
      return {
        ...state,
        hospitalAdmissions: {
          ...state.hospitalAdmissions,
          isLoading: true,
        },
      };
    case actions.SAVE_HOSPITAL_ADMISSION_HISTORY_FAILED:
      return {
        ...state,
        hospitalAdmissions: {
          ...state.hospitalAdmissions,
          isLoading: false,
        },
      };
    case actions.SAVE_HOSPITAL_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        hospitalAdmissions: {
          ...state.hospitalAdmissions,
          isLoading: false,
        },
      };
    case actions.FETCH_SURGICAL_HISTORY_START:
      return {
        ...state,
        surgicalHistory: {
          ...state.surgicalHistory,
          isLoading: true,
        },
      };
    case actions.FETCH_SURGICAL_HISTORY_FAILED:
      return {
        ...state,
        surgicalHistory: {
          ...state.surgicalHistory,
          isLoading: false,
        },
      };
    case actions.FETCH_SURGICAL_HISTORY_SUCCESS:
      return {
        ...state,
        surgicalHistory: {
          data: action.payload.surgicalHistory,
          isLoading: false,
        },
      };

    case actions.SAVE_SURGICAL_HISTORY_START:
      return {
        ...state,
        surgicalHistory: {
          ...state.surgicalHistory,
          isLoading: true,
        },
      };
    case actions.SAVE_SURGICAL_HISTORY_FAILED:
      return {
        ...state,
        surgicalHistory: {
          ...state.surgicalHistory,
          isLoading: false,
        },
      };
    case actions.SAVE_SURGICAL_HISTORY_SUCCESS:
      return {
        ...state,
        surgicalHistory: {
          ...state.surgicalHistory,
          isLoading: false,
        },
      };
    case actions.FETCH_FAMILY_HISTORY_SUCCESS:
      return {
        ...state,
        familyHistory: {
          data: action.payload.familyHistory,
          isLoading: false,
        },
      };
    case actions.FETCH_FAMILY_HISTORY_START:
      return {
        ...state,
        familyHistory: {
          ...state.familyHistory,
          isLoading: true,
        },
      };
    case actions.FETCH_FAMILY_HISTORY_FAILED:
      return {
        ...state,
        familyHistory: {
          ...state.familyHistory,
          isLoading: false,
        },
      };

//Common history
case actions.FETCH_COMMON_HISTORY_SUCCESS:
  return {
    ...state,
    commonHistory: {
      data: action.payload.commonHistory,
      isLoading: false,
    },
  };
case actions.FETCH_COMMON_HISTORY_START:
  return {
    ...state,
    commonHistory: {
      ...state.commonHistory,
      isLoading: true,
    },
  };
case actions.FETCH_COMMON_HISTORY_FAILED:
    return {
      ...state,
      commonHistory: {
        ...state.commonHistory,
        isLoading: false,
      },
    };
case actions.SAVE_COMMON_HISTORY_START:
  return {
    ...state,
    commonHistory: {
      ...state.commonHistory,
      isLoading: true,
    },
  };
case actions.SAVE_COMMON_HISTORY_FAILED:
  return {
    ...state,
    commonHistory: {
      ...state.commonHistory,
      isLoading: false,
    },
  };
case actions.SAVE_COMMON_HISTORY_SUCCESS:
  return {
    ...state,
    commonHistory: {
      data: action.payload.commonHistory,
      isLoading: false,
    },
  };
  case actions.FETCH_MEDICAL_CONDITION_START:
    return {
      ...state,
      commonHistory: {
        ...state.medicalCondition,
        isLoading: true,
      },
    };
  case actions.FETCH_MEDICAL_CONDITION_FAILED:
    return {
      ...state,
      medicalCondition: {
        ...state.medicalCondition,
        isLoading: false,
      },
    };
  case actions.FETCH_MEDICAL_CONDITION_SUCCESS:
    return {
      ...state,
      medicalCondition: {
        data: action.payload.medicalCondition.conditions,
        isLoading: false,
      },
    };
    default:
      return state;
  }
};
