import React, { useState, useEffect } from "react";
import { Modal, Spin, Collapse, Empty, Pagination } from "antd";
import { SuccessTag, WarningTag } from "../common/Tags";
import StatusLabels from "../patient-dashboard/MedicationStatusLabels";
import { makeSeconds } from "../../utils/encryption";
import moment from "moment";

const { Panel } = Collapse;

const Medication = ({
  medicationName,
  date,
  time,
  med,
  lastIndex = false,
  onView,
}) => {

  const onBtnClick= (med) => {
    onView(med)
  }
  return (
    <div className="container-fluid py-2 border-bottom border-light medication-font-size">
      <div className="row justify-content-center align-items-center">
        <div className="col-7">
          <h3 className="text-left text-capitalize font-weight-bold text-primary medication-name">
            {medicationName}
          </h3>
        </div>
        <div className="col-5">
          <div className="d-flex flex-column align-items-end">
            <span className="d-block mb-2 date">{date}</span>

            <button
              onClick={() => onBtnClick({ ...med, medicationName: medicationName })}
              className="px-2 py-1 bg-blue-400 date text-white rounded-sm view-btn"
              data-toggle="modal"
              data-target="#medicationModal"
              id="viewMedication"
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MedicationDetail = ({ med ,isModalVisible,cancelModal}) => {
  ////("med=", med);
  const [visible,setVisible]=useState(isModalVisible);
  if (!med) return null;
  let sorted;
  let upcomingMed = [];
  let passedMed = [];
  let finalMed = [];

 const closeModal=(condition)=>{
  cancelModal(condition)
 }
  const sortedMed = med.timeAndDose.sort(function (a, b) {
    return new Date(b.timeDate) - new Date(a.timeDate);
  });
  sorted = med.timeAndDose.sort(function compare(a, b) {
    var dateA = new Date(a.timeDate);
    var dateB = new Date(b.timeDate);
    return dateB - dateA;
  });
  sortedMed.map((m, i) => {
    if (new Date(m.timeDate) < makeSeconds(new Date())) {
      passedMed.push(m);
    }
    if (new Date(m.timeDate) > makeSeconds(new Date())) {
      upcomingMed.push(m);
    }
  });
  function reverseArr(input) {
    var ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  let upcoming = reverseArr(upcomingMed);

  upcoming.map((up, i) => {
    finalMed.push(up);
  });
  passedMed.map((pa) => {
    finalMed.push(pa);
  });
  let updated = finalMed.sort(function compare(a, b) {
    var dateA = new Date(a.timeDate);
    var dateB = new Date(b.timeDate);
    return dateB - dateA;
  });
  function filter_dates(event) {
    return moment(event.timeDate) < moment();
}


const currentDate = new Date();
const currentDateTime = currentDate.getTime();
const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
const last30DaysDateTime = last30DaysDate.getTime();


function filter_dates(updated) {

  return moment(updated.timeDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD');

}

var last30DaysList = updated && updated.filter(filter_dates);

// const last30DaysList = updated.filter(x => {
//   const elementDateTime = new Date(x.timeDate).getTime();
//   if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
//     return true;
//   }
//   return false
// }).sort((a, b) => {
//   return new Date(b.timeDate) - new Date(a.timeDate);
// });



  return (
    <div>
      <div
           className={`modal big_modal_dialog ${
            isModalVisible ? "d-block modal-fading" : " d-none"
          }`}
        id="medicationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog medication_modal_dialog" role="document">
          <div className="modal-content">
          <div className="modal-header text-center d-block">
              <p className="modal-title text-capitalize font-weight-bold d-inline-block" id="exampleModalLabel">
              <span className='h3'>{med.medicationName}</span>{med.strengthAmount &&` (${med.strengthAmount} ${med.unit && med.unit})`} {med.howManyTimesADay &&` ${med.howManyTimesADay} dose a day`}
              </p>
              <button
                 type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={()=>{closeModal(false)}}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-header">
              <h4 className="modal-title text-center font-weight-bold" id="exampleModalLabel">

              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={()=>{closeModal(false)}}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body medication_modal_body">
              {/* <div></div> */}

              <div
                className="accordion md-accordion"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <div className="card">
                  <div className="card-header" role="tab" id="headingOne1">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                    >
                      <h5 className="mb-0">
                        Schedule{" "}
                        <i className="fas fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <div
                    id="collapseOne1"
                    className="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body overflow-auto">
                      <div className="container my-3">
                        <div className="row justify-content-center text-primary font-weight-bold text-center">
                          <div className="col-2  ">Date</div>
                          <div className="col-2 ">Time</div>
                          <div className="col-1 text-center">Pills</div>
                          <div className="col-1">Type</div>
                          <div className="col-2">Taken Time</div>
                          <div className="col-2 text-left">Status</div>
                          <div className="col-2">Reason</div>
                        </div>
                      </div>

                      <div
                        className=""
                        style={{ maxHeight: "40vh" }}
                      >
                        {last30DaysList &&
                          last30DaysList.length > 0 &&
                          last30DaysList.map((item, index) => {
                            const name = [
                              "Application(s)","Ampule(s)","Applicatior(s)",
                              "Cartridge(s)",
                              "Container(s)",
                              "Cups(s)",
                              "Drop(s)",
                              "mL",
                              "Syringe(s)",
                              "Vial(s)",
                              "Pill(s)",
                              "Gram(s)",
                              "Packet(s)",
                              "Scoopfull(s)",
                              "Tablespoon(s)",
                              "Teaspoon(s)",
                              "Chew(s)",
                              "Bar(s)",
                              "Package(s)",
                              "Gum(s)",
                              "Chip(s)",
                              "Inch(es)",
                              "Stick(s)",
                              "Wafer(s)",
                              "Roll(s)",
                              "Autoinjector(s)",
                              "Carpuject(s)",
                              "cc",
                              "IU",
                              "Pen(s)",
                              "Unit(s)",
                              "Bag(s)",
                              "Bottle(s)",
                              "Enema(s)",
                              "Kit(s)",
                              "Pouch(es)",
                              "Tube(s)",
                              "Globule(s)",
                              "Implant(s)",
                              "Blister(s)",
                              "Puff(s)",
                              "Respule(s)",
                              "Stripe(s)",
                              "Insert(s)",
                              "Spray(s)",
                              "Lozenge(s)",
                              "Diskus",
                              "Patch(es)",
                              "Popsicle(s)",
                              "Suppositories",
                              "Troche(s)",
                              "Capsule",
                              "Tablet(s)",
                            ];
                            var localDate = moment(item.timeDate).utc();
                            var date = localDate.format("DD-MM-YYYY ");

                            return (
                              <div
                                className="container my-1 border-light border-bottom "
                                key={index}
                              >
                                <div className="row justify-content-center text-center ">
                                  <div className="col-2">{date}</div>
                                  <div className="col-2">{item.time}</div>
                                  <div className="col-1">
                                    {item.pillsQuantity}
                                  </div>
                                  <div className="col-1">
                                    {name[item.pillType]}
                                  </div>
                                  <div className="col-2 ">
                                    {" "}
                                    {item.status === 3 &&
                                    item.takenTime !== 0 &&
                                    item.takenTime !== "" &&
                                    item.takenTime !== undefined
                                      ? moment(item.takenTime).format("hh:mm a")
                                      : null}
                                  </div>
                                  <div className="col-2 text-left ">
                                    {new Date(item.timeDate) <
                                      makeSeconds(new Date()) &&
                                    item.status === 0 ? (
                                      <StatusLabels
                                        color="#FF7E0C"
                                        message="Missed"
                                      />
                                    ) : null}
                                    {new Date(item.timeDate) >
                                      makeSeconds(new Date()) &&
                                    item.status === 0 ? (
                                      <StatusLabels
                                        color=" #0d92d2"
                                        message="Upcoming"
                                      />
                                    ) : null}
                                    {item.status === 1 ? (
                                      <StatusLabels
                                        color="red"
                                        message="Removed"
                                      />
                                    ) : null}
                                    {item.status === 2 ? (
                                      <StatusLabels
                                        color="#800080"
                                        message="Re-scheduled"
                                      />
                                    ) : null}
                                    {item.status === 3 ? (
                                      <StatusLabels
                                        color="#00B573"
                                        message="Taken"
                                      />
                                    ) : null}
                                    {item.status === 4 ? (
                                      <StatusLabels
                                        color="#E7D906"
                                        message="Skipped"
                                      />
                                    ) : null}
                                    {item.status === 5 ? (
                                      <StatusLabels
                                        color="#FF7E0C"
                                        message="Missed"
                                      />
                                    ) : null}
                                  </div>
                                  <div className="col-2 ">
                                    {" "}
                                    {item.status === 2 || item.status === 4
                                      ? item.skipReason
                                      : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingThree3">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseThree3"
                      aria-expanded="false"
                      aria-controls="collapseThree3"
                    >
                      <h5 className="mb-0">
                        Condition{" "}
                        <i className="fas fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <div
                    id="collapseThree3"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree3"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      <div className="container">
                        <p>{med.condition}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function UpcomingMedications({
  medications = [],
  isLoading,
  pageNumber,
  settingPage,
}) {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedMed, setSelectedMed] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewModal, setViewModal] = useState(false);

  useEffect(() => {}, [selectedMed]);
  const pageSet = (number) => {
    settingPage(number);
  };

  const renderMedications = () => {
    return medications.map(({ date, time, medicationName, ...rest }, index) => (
      <Medication
        key={index}
        date={date}
        time={time}
        medicationName={medicationName}
        med={{ date, time, ...rest }}
        lastIndex={index == medications.length - 1 ? true : false}
        onView={onView}

      />
    ));
  };

  const onView = (med) => {
    setModalVisibility(true);
    setSelectedMed(med);
  };
  const handleCancel = (modalCondition) => {

    setModalVisibility(modalCondition);
    setSelectedMed(null);
  };
  const onChange = (page, pageSize) => {
    pageSet(page);
    setCurrentPage(page)
  };

  return (
    <div
      className="w-100 bg-white border-radius-chart  p-4 h-100"
      // style={{ maxHeight: "50vh" }}
    >
      <div className="w-100 h-100 ">
        <p className="m-0 text-voyger-purple font-weight-bold text-xl w-100 p-2 chart-heading">
          Medications
        </p>
        <Spin spinning={isLoading}>
          <div className="content "  >
            {medications.length > 0 ? (
              <> {renderMedications()}
              <MedicationDetail med={selectedMed}  isModalVisible={isModalVisible} cancelModal={handleCancel}/>
              </>

            ) : (
              <div className="d-flex m-auto h-100 w-100 justify-content-center align-items-center">
                <Empty />
              </div>
              // <div className="p-1">You don't have any scheduled medications</div>
            )}
          </div>
        </Spin>

        <div className='d-flex justify-content-end mt-5'>
        <Pagination size="small"  current={currentPage}
                  total={10 * pageNumber}
                  onChange={onChange}
                  showSizeChanger={false}
                  hideOnSinglePage={true}
                  />
        </div>
      </div>
    </div>
  );
}
