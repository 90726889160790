import { API_BASE_URL } from "../../../utils/urls";
import {
  FETCH_USER_START,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  ADD_DOCTOR_START,
  ADD_DOCTOR_FAILED,
  ADD_DOCTOR_SUCCESS
} from "../../actionTypes/admin";
import http from "../../../utils/http-service";
// import {decrypt} from "../../utils/encryption";
import {logout} from "../auth";


export const getUser = (role) => async(dispatch) => {

  // role = JSON.stringify(role);
  dispatch({type: FETCH_USER_START})
  try {

    const res = await http.get(`${API_BASE_URL}/admin/getUsers?role=${role}`);


    dispatch({type: FETCH_USER_SUCCESS, payload: res.data.data.users})
  } catch (error) {
    dispatch({type: FETCH_USER_FAILED});
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }

  }
}

const addDoctorStart = () => {
  return { type: ADD_DOCTOR_START };
};

const addDoctorFailed = () => {
  return { type: ADD_DOCTOR_FAILED };
};

const addDoctorSuccess = (payload) => {
  return { type: ADD_DOCTOR_SUCCESS, payload };
};

export const addDoctor = (data) => async (dispatch) => {
  try {
    dispatch(addDoctorStart());
    let addDoctorData=data;

    // addDoctorData=JSON.stringify(addDoctorData)
    // addDoctorData = encrypt(addDoctorData);
    const result = await http.post(`${API_BASE_URL}/admin/addDoctor`, {addDoctorData});
    ////(result);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      // localStorage.setItem("grxAuthToken", data.token);
      // data= decrypt(data)
      // data=JSON.parse(data)


      dispatch(addDoctorSuccess(data));
      // showToast(err.response.data.message,"error");
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(addDoctorFailed());
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    throw error;
  }
};



export const removeDoctor = (_id)=> async (dispatch) => {
  try{
    // let id = _id;
    // id = encrypt(id);
    return await http.delete(`${API_BASE_URL}/admin/removeDoctor?id=${_id}`)
  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}

export const removeUser = (_id)=> async (dispatch) => {
  try{
    // let id = _id;
    // id = encrypt(id);
    return await http.delete(`${API_BASE_URL}/admin/removeUser?id=${_id}`)
  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}
export const suspendUser = (_id)=> async (dispatch) => {
  try{
    // let id = _id;
    // id = encrypt(id);
    return await http.get(`${API_BASE_URL}/admin/suspendUser?id=${_id}`)
  } catch(error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}
