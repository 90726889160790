import React, { useState } from "react";
import { LogoComponent as Logo } from "../../assets";
import { Link, useHistory } from "react-router-dom";
import { default as Titles } from "../../utils/document-titles";
import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { register } from "../../redux/actions/auth";
import GlucoRxLogo from "../../assets/GlucoRxLogo.svg";
import voyagerLogo from "../../assets/voyagerLogo.svg";
import appStore from "../../assets/app-store.png";
import playStore from "../../assets/play-store.png";
import { ToastContainer } from "react-toastify";
import { error } from "jquery";
import countryList from './CountriesList';
import LoginForm from "./new";

export default function Register() {
  document.title = Titles.register;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhoneNo] = useState("");
  const [matchPassword,setMatchPassword]=useState( null)
  const [checked, setChecked] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [message, setMessage] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);


  ////("hallo",message);
  const dispatch = useDispatch();

  const handleColorPicker=()=>{
    ////('open');
    setMessage(current=>!current.message )
  }
  function focusFunction() {
    document.getElementById("myInput").style.background = "yellow";
  }
  // {
  //   ////("hallo");
  //   // document.getElementById("message").style.display = "block";
  //   document.getElementById("myInput").style.background = "red";
  // }

  // No focus = Changes the background color of input to red
  const blurFunction=()=> {
    ////("bye");
    // document.getElementById("message").style.display = "none";
    document.getElementById("myInput").style.background = "yellow";
  }

  const   handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMatchPassword(null)
  };


  const comparePassword = () => {
    if (password === confirmPassword) {
      setMatchPassword(true)

    } else {
      setMatchPassword(false)
    }
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault()
        if(checked && password===confirmPassword){
          let re;
          if(password != "" ) {
            if(password.length < 8 && password===confirmPassword) {
              ShowToast("Password must contain at least Eight characters!","error");

              return false;
            }
            if(password === firstName) {
              ShowToast("Password must be different from Username!","error");

              return false;
            }

            re = /[0-9]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one number (0-9)!","error");

              return false;
            }
            re = /[a-z]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one lowercase letter (a-z)!","error");

              return false;
            }
            re = /[A-Z]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one uppercase letter (A-Z)!","error");

              return false;
            }
            re=/[~!@#$%^&*_\-+=?]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one special character (#?!@$%^&*-_)!","error");


            }
            if (password !== confirmPassword){
              ShowToast("Password is not matched","error");
              return false;
            }
          }


          let dateOfBirth = new Date(dob);
          let postcode =postCode;
          let role ="patient";
          try {

            setIsLoading(true);
            e.preventDefault();
            let result = await dispatch(register({ firstName, lastName, gender,dateOfBirth,email,password,role,address,town,postcode,country,phone }));
            ////("Result",result);
            setIsLoading(false);
            ShowToast(result.data.message,"success");
            history.push("/auth/login");

          } catch (err) {
            ////(err.response)

            ShowToast(err.response.data.message,"error");
            setIsLoading(false);
          }


          return true;
        }
        else{
           if(password!==confirmPassword){
            ShowToast("password not matched","error")
          }
          else
          ShowToast("Terms and Condition Not checked","error")
        }
        };


  return (
    <div>
        <div className="">
          <div className="container-fluid bg-white registration-top-bar">
          <div className="row align-items-center py-2 px-2" style={{height:"7vh"}}>
              <img className="mr-auto registration-glucoRx-logo" src={GlucoRxLogo}  ></img>
              <Link to={"/auth/login"}>
                <span className="ml-auto mr-3 registration-create-account-text">
                  Login
                </span>
              </Link>
            </div>
          </div>
          <div className="container-fluid registration-background d-flex flex-column">
            <div className="row  justify-content-md-between  justify-content-center  ml-md-5 ml-0 ">
              <div className="col-md-4 col-11 mt-5 ">
                <div className="text-md-left text-center text-uppercase registration-account-heading1">
                  <p>CREATE VOYAGER ACCOUNT</p>
                </div>
                <div className="text-md-left text-center registration-account-heading2  mr-md-5 mr-0">
                  <p>
                    Create a GlucoRx Account here to use our apps and access
                    data software. It only takes a few minutes.
                  </p>
                </div>
                <div className="d-flex justify-content-md-start justify-content-center ml-md-3 ml-0">
                  <img src={voyagerLogo} className="registration-voyager-logo"></img>
                </div>
              </div>
              <div className="col-md-6 col-11 registration-account-field-box mr-md-5 mr-0 mt-5 registration">

                <form onSubmit={handleSubmit} >
                  <div className="container my-4 ">
                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">First Name</div>
                      <div className="col-md-4">
                        <input
                         value={firstName}
                         onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                          className="registration-form-control"

                          required
                        />
                      </div>
                      <div className="col-md-2 registration-input-labels ">Last Name</div>
                      <div className="col-md-4">
                        <input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                          type="text"
                          className="registration-form-control"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Gender</div>
                      <div className="col-md-4">
                        <div className="styled-select">
                          <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            placeholder="Select a Blood Group"
                            className="registration-form-control "
                          >
                            <option value="Select">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Female">Other</option>
                          </select>
                          <i className="fa fa-chevron-down"></i>
                        </div>
                      </div>
                      <div className="col-md-2 registration-input-labels ">DOB</div>
                      <div className="col-md-4">
                        <input
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          type="date"
                          className="registration-datePicker"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Email</div>
                      <div className="col-md-10">
                        <input
                          type="email"
                          value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
                          className="registration-form-control"

                          required
                        />
                      </div>
                    </div>

                    <div className={`row justify-content-center ${message?null:"align-items-center"}  mb-3 text-white`}>
                      <div className="col-md-2 registration-input-labels ">Password</div>
                      <div className="col-md-4">
                        <div className="pass-wrapper">
                        <input
                          type={passwordShown ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="registration-form-control "
                          title="Your password must be at least 10 characters long.&#13;&#10;Remember that UPPERCASE letters are different&#13;&#10;from lowercase letters (for example, A is treated as different from a).&#13;&#10;It must contain at least one character that is not a letter&#13;&#10;such as a digit or special character eg &."
                          onFocus={(e) => {
                            setMessage(!message);

                          }}
                          onBlur={(e) => {
                            setMessage(!message);

                          }}
                           required
                        />

                        {passwordShown==true?<i className="fa fa-eye" onClick={togglePasswordVisiblity}></i>
                         :<i className="fas fa-eye-slash" onClick={togglePasswordVisiblity}></i>}
                     </div>
                     <div id="message" className='font-size-small-1'>



                     {message?'Password must contain (0-9) , (A-Z) , (a-z) , (~!@#$%^&*_\-+=?) and have min length 10 characters.':null}
                          </div>

                      </div>
                      <div className="col-md-2 registration-input-labels ">Confirm Password</div>
                      <div className="col-md-4">
                      <div className="pass-wrapper">
                        <input
                         value={confirmPassword}

                         onChange={(e) => handleConfirmPasswordChange(e)}
                         onBlur={comparePassword}
                        //  onBlur={setMessage(!message)}
                          type={confirmPasswordShown ? "text" : "password"}
                          className={`registration-form-control ${password!==confirmPassword ? 'error-color-input' : null}`}

                          required
                        />
                        {passwordShown==true?<i className="fa fa-eye" onClick={toggleConfirmPasswordVisiblity}></i>
                         :<i className="fas fa-eye-slash" onClick={toggleConfirmPasswordVisiblity}></i>}

                      </div>
                      </div>

                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Phone No</div>
                      <div className="col-md-10">
                        <input
                        value={phone}
                        onChange={(e) => setPhoneNo(e.target.value)}
                          type="number"
                          className="registration-form-control"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Address</div>
                      <div className="col-md-10">
                        <input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                          type="text"
                          className="registration-form-control"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Town</div>
                      <div className="col-md-10">
                        <input
                         value={town}
                         onChange={(e) => setTown(e.target.value)}
                          type="text"
                          className="registration-form-control"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Postcode</div>
                      <div className="col-md-10">
                        <input
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                          type="text"
                          className="registration-form-control"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels ">Country</div>
                      <div className="col-md-10">
                      <div className="styled-select">
                          <select
                             value={country}
                             onChange={(e) => setCountry(e.target.value)}
                            placeholder="Select a Blood Group"
                            className="registration-form-control "
                          >
                            <option value="Select">Select Country</option>
                            {countryList.map((option, index) => (
      <option key={index} value={option}>{option}</option>
    ))}
                          </select>
                          <i className="fa fa-chevron-down"></i>
                        </div>

                      </div>
                    </div>

                    <div className="row justify-content-md-between align-items-center mt-2 text-white">
                      <div className="col-md-7">
                        <div className="form-check form-check-inline">
                          <input
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                            className=" my_checkbox"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1"
                          />
                          <label
                            className="form-check-label ml-2 registration-terms-condition"
                            htmlFor="inlineCheckbox1"
                          >
                            By checking this box, you agree to the terms of use
                            and <button type="button" className="text-danger" onClick={()=>{setPrivacyModal(true)}}>privacy policy</button>.
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                      <Spin spinning={isLoading}>
                          <button
                            type="Submit"
                            className="registration-btn btn-primary "
                          >
                            Register
                          </button>
                          </Spin>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-inline d-flex justify-content-md-start justify-content-center mt-auto mb-3 pt-2 ml-md-3 ml-lg-0 ">
              <img src={appStore} width="150px"></img>
              <img src={playStore} width="150px" className="ml-3"></img>
            </div>
          </div>
        </div>
        <div
            className={`modal big_modal_dialog ${
                privacyModal? "d-block modal-fading" : " d-none"
            }`}
            tabIndex="-1"
            data-backdrop="static"
            data-keyboard="false"

            role="dialog"

            aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addHospitalModalTitle">
                  Privacy Policy
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>setPrivacyModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

                <div className="container my-4 ">
                  <div className="row justify-content-center align-items-center mb-3 text-white">
                    <div className="col-12">
                      <iframe id="inlineFrameExample"
                          title="Inline Frame Example"
                          width="100%"
                          height="450px"
                          src="https://www.grxvoyager.com/privacy-policy/">
                      </iframe>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={()=>{setPrivacyModal(false)}}>Close</button>
                </div>

            </div>
          </div>
        </div>
      </div>
  );
}
