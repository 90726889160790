const moment = require("moment");

const createRequestObjectFromForm = (values) => ({
  valid: true,
  firstName: values.firstName,
  lastName: values.lastName,
  hospitalName: values.hospitalName,
  doctorName: values.doctorName,
  reason: values.reason,
  admissionDate: values.admissionDate,
  admissionSummary: values.admissionSummary,
  dischargeDate: values.dischargeDate,
  daysStay: values.daysStay,
  dischargeSummary: values.daysStay,
  diagnosis: values.diagnosis,
  medication: values.medicationChanged === "yes",
  tests: values.testsDone === "yes",
  images: values.imagesDone === "yes",
  procedures: values.proceduresDone === "yes",
  followUpVisit: values.followUpVisitNeeded === "yes",
  comments: values.otherNotes,
});

const createFormObjectFromRequest = (values) => ({
  firstName: values.firstName,
  lastName: values.lastName,
  hospitalName: values.hospitalName,
  doctorName: values.doctorName,
  reason: values.reason,
  admissionDate: moment.utc(new Date(values.admissionDate)),
  admissionSummary: values.admissionSummary,
  dischargeDate: moment.utc(new Date(values.dischargeDate)),
  daysStay: values.daysStay,
  dischargeSummary: values.daysStay,
  diagnosis: values.diagnosis,
  medicationChanged: values.medication ? "yes" : "no",
  testsDone: values.tests ? "yes" : "no",
  imagesDone: values.images ? "yes" : "no",
  proceduresDone: values.procedures ? "yes" : "no",
  followUpVisitNeeded: values.followUpVisit ? "yes" : "no",
  otherNotes: values.comments,
});

module.exports = {
  createFormObjectFromRequest,
  createRequestObjectFromForm,
};
