import React from 'react';
import StatusLabels from '../../../patient-dashboard/MedicationStatusLabels';
import {makeSeconds} from "../../../../utils/encryption";
import moment from "moment";

const MedicationModal = () => {
  const medication=[{name:"Ponston 25mg",date:"26-02-2021",timeDose:[{id: "604f47e057f60d3225dfcc6c",
  localId: "637562574",
  medicationName: "2 way foley all silicone catheter male open tip 14Ch-18Ch",
  pillType: "10",
  pillsQuantity: 1,
  schedulingId: "1615808452",
  skipReason: "",
  status: 0,
  takenTime: 0,
  timeDate: "2021-04-15T08:00:00.000Z"},
  {id: "604f47e057f60d3225dfcc6c",
  localId: "637562574",
  medicationName: "2 way  tip 14Ch-18Ch",
  pillType: "10",
  pillsQuantity: 1,
  schedulingId: "1615808452",
  skipReason: "",
  status: 0,
  takenTime: 0,
  timeDate: "2021-03-15T08:00:00.000Z"},
  {id: "604f47e057f60d3225dfcc6c",
  localId: "637562574",
  medicationName: "2 way  tip 14Ch-18Ch",
  pillType: "10",
  pillsQuantity: 1,
  schedulingId: "1615808452",
  skipReason: "",
  status: 0,
  takenTime: 0,
  timeDate: "2021-03-15T08:00:00.000Z"}]}
,{name:"Pill 25mg",date:"27-02-2021"},{name:"Capsule 25mg",date:"28-02-2021"},
{name:"Brufen 25mg",date:"01-03-2021"}
]
    return (
        <div>
            <div className="modal fade fading" id="medModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">Medication</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/* <Accordian Start> */}
     {medication.map((row,i)=>{
       return(
        <div id="accordion" key={i}>
       <div className="card mb-1">
    <div className="card-header" id={`heading${i}`}>
      <h5 className="mb-0">
        <button className="  collapsed w-100" data-toggle="collapse" data-target={`#collapse${i}`} aria-expanded="false" aria-controls="collapseTwo">
          <div className='d-flex justify-content-between'><span>{row.name}</span><span>{row.date}</span></div>
        </button>
      </h5>
    </div>
    <div id={`collapse${i}`} className="collapse" aria-labelledby={`heading${i}`} data-parent="#accordion">
      <div className="card-body" style={{maxHeight:"40vh",overflowY:"scroll"}}>
      <div className="container my-3">
                        <div className="row justify-content-center text-primary font-weight-bold text-center">
                          <div className="col-2  ">Date</div>
                          <div className="col-2 ">Time</div>
                          <div className="col-1 text-center">Pills</div>
                          <div className="col-1">Pills Type</div>
                          <div className="col-2">Taken Time</div>
                          <div className="col-2 text-left">Status</div>
                          <div className="col-2">Skip Reason</div>
                        </div>
                      </div>
        {row.timeDose && row.timeDose.length>0 && row.timeDose.map((item,i)=>{
             const name = [
              "Application(s)",
              "Ampule(s)",
              "Applicatior(s)",
              "Cartridge(s)",
              "Container(s)",
              "Cups(s)",
              "Drop(s)",
              "mL",
              "Syringe(s)",
              "Vial(s)",
              "Pill(s)",
              "Gram(s)",
              "Packet(s)",
              "Scoopfull(s)",
              "Tablespoon(s)",
              "Teaspoon(s)",
              "Chew(s)",
              "Bar(s)",
              "Package(s)",
              "Gum(s)",
              "Chip(s)",
              "Inch(es)",
              "Stick(s)",
              "Wafer(s)",
              "Roll(s)",
              "Autoinjector(s)",
              "Carpuject(s)",
              "cc",
              "IU",
              "Pen(s)",
              "Unit(s)",
              "Bag(s)",
              "Bottle(s)",
              "Enema(s)",
              "Kit(s)",
              "Pouch(es)",
              "Tube(s)",
              "Globule(s)",
              "Implant(s)",
              "Blister(s)",
              "Puff(s)",
              "Respule(s)",
              "Stripe(s)",
              "Insert(s)",
              "Spray(s)",
              "Lozenge(s)",
              "Diskus",
              "Patch(es)",
              "Popsicle(s)",
              "Suppositories",
              "Troche(s)",
              "Capsule",
              "Tablet(s)",
            ];
            var localDate = moment(item.timeDate).utc();
            var date = localDate.format("DD-MM-YYYY ");
          return(
            <div className="container my-1 border-light border-bottom ">
            <div className="row justify-content-center text-center ">
              <div className="col-2">{date}</div>
              <div className="col-2">{item.time}</div>
              <div className="col-1">
                {item.pillsQuantity}
              </div>
              <div className="col-1">
                {name[item.pillType]}
              </div>
              <div className="col-2 "> {item.status===3 && item.takenTime!==0 && item.takenTime!=="" &&item.takenTime
              !==undefined? moment(item.takenTime).format("hh:mm a"):null}</div>
              <div className="col-2 text-left ">
                {new Date(item.timeDate) <
                  makeSeconds(new Date()) &&
                item.status === 0 ? (
                  <StatusLabels
                    color="#FF7E0C"
                    message="Missed"
                  />
                ) : null}
                {new Date(item.timeDate) >
                  makeSeconds(new Date()) &&
                item.status === 0 ? (
                  <StatusLabels
                    color=" #0d92d2"
                    message="Upcoming"
                  />
                ) : null}
                {item.status === 1 ? (
                  <StatusLabels
                    color="red"
                    message="Removed"
                  />
                ) : null}
                {item.status === 2 ? (
                  <StatusLabels
                    color="#800080"
                    message="Re-scheduled"
                  />
                ) : null}
                {item.status === 3 ? (
                  <StatusLabels
                    color="#00B573"
                    message="Taken"
                  />
                ) : null}
                {item.status === 4 ? (
                  <StatusLabels
                    color="#E7D906"
                    message="Skipped"
                  />
                ) : null}
                {item.status === 5 ? (
                  <StatusLabels
                    color="#FF7E0C"
                    message="Missed"
                  />
                
                ) : null}
                
              </div>
              <div className="col-2 "> {item.status === 2 || item.status === 4? item.skipReason:null}</div>
            </div>
          </div>
          )
        })}

      </div>
    </div>
  </div>
        </div>
       );
     })}   
    
         {/* <Accordian End> */}
        
      </div>
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
        </div>
    );
};

export default MedicationModal;