import React from "react";

export const Device = ({ name, lastIndex = false }) => {
  return (
    <div
      className={`w-full p-3 flex items-center border rounded-lg border-gray-400 my-3`}
    >
      <div className="w-4/5 flex flex-col">
        <span className="text-gray-600 font-semibold">{name}</span>
      </div>
      {/* <div className="w-1/5 flex justify-end items-center">
        <span className="text-gray-600 font-semibold">{count}</span>
      </div> */}
    </div>
  );
};

export default function Devices({ devices = [] }) {
  const renderDevices = () => {
    return devices.map(({ name, count }, index) => (
      <Device name={name} count={count} key={index} />
    ));
  };

  return (
    <div
      className="my-3 md:my-0 md:mr-2 flex flex-col w-full h-full bg-white rounded-lg shadow-lg p-0 overflow-y-auto"
      style={{ maxHeight: "25rem" }}
    >
      <p className="m-0 rounded-t-lg font-bold text-md w-full p-2 px-4">
        Devices
      </p>

      <div className="flex flex-col justify-center items-center px-2">
        {devices.length > 0 ? renderDevices() : <div>No Devices</div>}
      </div>
    </div>
  );
}
