import React, { useEffect } from "react";
import { Divider, Form, Button, Spin, Select } from "antd";

import {
  fetchSocialHistory,
  saveSocialHistory,
} from "../../../redux/actions/history";
import { useDispatch, useSelector } from "react-redux";
import ShowToast from "../../../utils/show-toast";
import Smoking from "./smoking";
import Drinking from "./drinking";
import socialHistoryTransformer from "../../../utils/transformers/history/social-history-transformer";

const { Option } = Select;

export default function SocialHistory() {
  const [form] = Form.useForm();
  window.form = form;

  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(
    (state) => state.history.socialHistory
  );

  useEffect(() => {
    if (!data) return;

    const input = socialHistoryTransformer.createFormObjectFromRequest(data);
    form.setFieldsValue(input);
  }, [data]);

  useEffect(() => {
    dispatch(fetchSocialHistory());
    return () => {};
  }, []);

  const handleSubmit = async (values) => {
    const res = socialHistoryTransformer.createRequestObjectFromForm(values);

    await dispatch(saveSocialHistory(res));
    ShowToast("Success!", "success");
    await dispatch(fetchSocialHistory());
  };

  return (
    <Spin spinning={isLoading}>
      <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <div className="p-1 relative flex flex-col lg:w-2/3 mx-auto">
          <Form
            labelAlign={"left"}
            labelCol={{ span: 24 }}
            form={form}
            className="w-full"
            name="VitalInfoForm"
            onFinish={handleSubmit}
            hideRequiredMark={true}
          >
            <Smoking form={form} data={data} />
            <Drinking form={form} data={data} />
            {/* <Form.Item
              labelCol={{ span: 10 }}
              colon={false}
              wrapperCol={{ span: 5 }}
              label="Do you and your partners practice safe sex?"
              name="safeSex"
              className="flex justify-start"
            >
              <Select placeholder={"Select an option"}>
                <Option value="noSex">No Sex</Option>
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
                <Option value="notSure">Not Sure</Option>
              </Select>
            </Form.Item> */}
            <Divider />
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
