import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";

am4core.useTheme(am4themes_animated);

function Chart5(props) {
// // // console.log("ABG CHART DATA", props.dataPoints);

 let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";

  const convertTimeFormat = (time12h) => {
    // // // // console.log("time12h", typeof(time12h));
    // // // // console.log("time12h", time12h);


    let time12hFormat = time12h;
    // if(typeof(time12hFormat) !== "string"){
    //   time12hFormat=JSON.stringify(time12hFormat);
    // }
    const [time, modifier] = time12hFormat.split(' ');

    let [hours, minutes] = time.split(':');
     let form;
    if(time.search("AM")){
      form="AM"
    }
    else if(time.search("PM")){
      form="PM"

    }

    if (hours === '12'&& form==="AM" ) {
      hours = '00';
    }
    if(minutes!==undefined ||minutes!==null ||minutes!==""){
      minutes="00"
    }
    else{
        minutes=null
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  }
  useEffect(() => {
    let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];

    let chart = am4core.create(props.id, am4charts.XYChart);

    chart.fontSize =14;
    // // // // // console.log("DATAPOINTS :", props.dataPoints);
    let list = props.dataPoints.map(function(data,index) {
      if(typeof(time12hFormat) !== "string"){
        return {

            date:data.date , value:data.value

        };
      }else{
        return {

            date:convertTimeFormat(data.date) , value:data.value

        };
      }
    });
    // // // console.log("LIST", list);
    let customData=[];
    for(let i=0; i<24; i++){
      let newDate;
      let newBg;
      for(let j=0; j<list.length; j++){
        if(parseInt(list[j].date)===i){
        newDate = list[j].date;
        newBg= list[j].value;

        }
      }
      // // // // // console.log("NEW DATE :", newDate);
      // // // // // console.log("NEW BG :", newBg);

      let obj={
        //before V!2-23(Jira task)
        // date:newDate!==undefined?newDate:timeSlots[i],

        //After fixes
        date:timeSlots[i],

        value:newBg!== undefined?newBg.toFixed(1):null
      }
      customData.push(obj);
    }
    // // // console.log("CUSTOM", customData);
    chart.data = customData;
    // // // // // console.log("AVGBG", list);

// Create axes


    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.title.text = "[bold]Time  ";
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 10;
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text=props.themeType==="bg"?"[bold]Blood Glucose":"[bold]Inr"
    valueAxis.min = 1;
    valueAxis.renderer.minGridDistance = 30;
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";
    series.tooltipText = "{valueY.value}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.dy = -6;
    series.columns.template.fill = am4core.color(color2);
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.hiddenState.properties.opacity = 1;
    series.tooltip.hiddenState.properties.visible = true;
    let columnTemplate = series.columns.template;
    columnTemplate.width = 12;
    columnTemplate.column.cornerRadiusTopRight = 8;
    columnTemplate.column.cornerRadiusTopLeft = 8;
    columnTemplate.strokeOpacity = 0;
    series.heatRules.push({
      target: columnTemplate,
      property: "fill",
      dataField: "valueY",

    });
    let cursor = new am4charts.XYCursor();
    cursor.behavior = "panX";
    chart.cursor = cursor;
    cursor.lineX.disabled = true;

    chart.events.on("datavalidated", function () {
      categoryAxis.zoomToCategories(
          chart.data[0],
          chart.data[chart.data.length - 1],

          false,
          true
      );
    });

    series.mainContainer.mask = undefined;
  }, [props.dataPoints]);

  return <div id={props.id} style={{ width: "100%", minHeight: "250px" }}></div>;
}
export default Chart5;
