import React, { useState, useEffect } from "react";
import FamilyHistoryConditions from "../../../utils/config/history/family-history-conditions";
import { Form, Input, Button, Select, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { fetchMedicalConditions } from "../../../redux/actions/history";
import { AllergyConditions, reactionOptions } from "./util";
const { Option } = Select;

export const AllergyForm = (props) => {

  const [conditions , setConditions] = useState([])
  useEffect(()=>{
    // getAllMedicalConditions()
  },[])

  const fieldsList = () => {
    return (
      <Form.List name="allergyForm">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, "name"]}
                    fieldKey={[field.fieldKey, "name"]}
                    rules={[{ required: true, message: "Missing condition" }]}
                  >
                    <Select
                      placeholder="Select condition"
                      showSearch
                      options={(AllergyConditions).map(
                        (key) => ({
                          name: key,
                          value: key,
                        })
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "description"]}
                    fieldKey={[field.fieldKey, "description"]}
                    rules={[{ required: true, message: "Missing description" }]}
                    label="Description"
                    className="w-full flex justify-center"
                  >
                    <Input.TextArea style={{ height: "20px" }} />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "reaction"]}
                    fieldKey={[field.fieldKey, "reaction"]}
                    rules={[{ required: true, message: "Missing reaction" }]}
                    label="Reaction"
                    className="w-full flex justify-center"
                  >
                    <Select
                     placeholder="Select reaction"
                     showSearch
                     options={(reactionOptions).map(
                       (key) => ({
                         name: key,
                         value: key,
                       })
                     )}
                    >
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "duration"]}
                    fieldKey={[field.fieldKey, "duration"]}
                    rules={[{ required: true, message: "Missing duration" }]}
                    label="Duration"
                    className="w-full flex justify-center"
                  >
                    <Input type={"datetime"} />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <div className="flex justify-end w-full">
                    <Button
                      className="flex text-center justify-center"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{display: 'flex'}}
                    >

                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ margin: "0 8px", color: '#ff4d4f' }}
                      />
                      Remove
                    </Button>
                    </div>
                  ) : null}
                  <Divider/>
                </div>
              ))}
              {!props.getFieldValue("allergyForm")[0].id &&
              <Form.Item>
                <div className="flex flex-row w-full justify-center content-center ">
                  <Button
                    type="dashed"
                    className="flex text-center justify-center"
                    style={{ width: "100%", display: 'flex' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add field
                  </Button>
                </div>
              </Form.Item>
                  }
            </div>
          );
        }}
      </Form.List>
    );
  };
  return (
    <div>
      {fieldsList()}
    </div>
  );
};
