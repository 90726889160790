import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import VitalInfo from "./vital-info/index";
import SocialHistory from "./social-history";
import SocialHistoryNew from "./social-history/SocialHistory";
import HospitalAdmissionHistory from "./hospital-admissions";
import HospitalAdmissions from "./hospital-admissions/HospitalAdmissions";
import SurgicalHistory from "./surgical-history";
import SurgicalHistoryNew from "./surgical-history/SurgicalHistory";
import FamilyHistory from "./family-history";
import FamilyHistoryNew from "./family-history/FamilyHistory";
import DocumentTitles from "../../utils/document-titles";
import VitalInfoNew from "./vital-info/VitalInfo";
import CommmonDiesease from "./Common/CommmonDesease";
import ExportData from "../patient-dashboard/Export/ExportData";


const { TabPane } = Tabs;

export default function History() {
  document.title = DocumentTitles.history;
  const device = useSelector((state) =>(state.devices.devices));


  return (
    <div className="bg-white p-3 rounded-lg h-100">
      <div className="d-flex my-2 mx-3  p-2 pr-1 justify-content-end align-items-center  border-bottom">
       {device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?<ExportData />:null}

      </div>
      <Tabs type="card">
      <TabPane tab="Common" key="0">
          {/*<VitalInfo />*/}
          <CommmonDiesease/>
        </TabPane>
        <TabPane tab="Vital Info" key="1">
          {/*<VitalInfo />*/}
          <VitalInfoNew />
        </TabPane>
        <TabPane tab="Social History" key="2">
          {/*<SocialHistory />*/}
          <SocialHistoryNew />
        </TabPane>
        <TabPane tab="Hospital Admissions" key="3">
          {/*<HospitalAdmissionHistory />*/}
          <HospitalAdmissions/>
        </TabPane>
        <TabPane tab="Surgical History" key="4">
          {/*<SurgicalHistory />*/}
          <SurgicalHistoryNew />
        </TabPane>
        <TabPane tab="Family History" key="5">
          {/*<FamilyHistory />*/}
           <FamilyHistoryNew />
        </TabPane>
      </Tabs>
    </div>
  );
}
