
import React, { useEffect } from "react";
import * as _ from 'lodash';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function TimeInRange(props) {
// // // // console.log("PROPS :", props);

  let chartData = [
    {
    "type": "High",
    "value":props.data? _.round(props.data.hiP , 2).toFixed(2):0,
    "color": "#ad3860",
  },
  {
    "type": "In Range",
    "value":props.data?_.round(props.data.avgP, 2).toFixed(2):0,
    "color": "#3498db",

  },
  {
    "type": "low",
    "value": props.data?_.round(props.data.loP, 2).toFixed(2):0,
    "color": "#fff900b0",

  }];
  // _.round(props.avg.stdDeviation, 1).toFixed(1)
  useEffect(() => {


    let chart = am4core.create(props.chartId, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = chartData;

   // Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "value";
pieSeries.dataFields.category = "type";
// pieSeries.dataFields.hidden = "hidden";
pieSeries.slices.template.propertyFields.fill = "color";

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(40);

// Disable ticks and labels
// pieSeries.labels.template.disabled = true;
// pieSeries.ticks.template.disabled = true;

// // Disable tooltips
// pieSeries.slices.template.tooltipText = "";

// Add a legend
chart.legend = new am4charts.Legend();
chart.legend.position = document.documentElement.clientWidth>767 ?"right":"bottom";
chart.legend.maxWidth = 340;
chart.legend.fontSize=document.documentElement.clientWidth>767 ?"16px":"14px";

var marker = chart.legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
marker.strokeWidth = 5;
marker.strokeOpacity = 1;
marker.stroke = am4core.color("#ccc");

return function cleanup(){
      chart.dispose();
    };


}, [props.data, document.documentElement.clientWidth]);

  return (<div className="">

        <div id={props.chartId} style={{ width: "100%", minHeight: "200px" }}></div>
  </div>
  );
}
export default TimeInRange;
