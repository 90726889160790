import React from "react";
import { Device } from "./devices";

export default function ActiveDevices({ devices = [] }) {
  const renderDevices = () => {
    return devices.map(({ name, count }, index) => (
      <Device name={name} count={count} key={index} />
    ));
  };

  return (
    <div
      className="my-3 md:my-0  md:ml-2 flex flex-col w-full h-full bg-white rounded-lg shadow-lg p-0 overflow-y-auto"
      style={{ maxHeight: "25rem" }}
    >
      <p className="m-0 rounded-t-lg font-bold text-md w-full p-2 px-4">
        Devices not performing
      </p>

      <div className="flex flex-col justify-center items-center px-2">
        {devices.length > 0 ? (
          renderDevices()
        ) : (
          <div>All Devices performing</div>
        )}
      </div>
    </div>
  );
}
