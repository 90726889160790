import React from 'react';
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom'

const NewClinicianNav = () => {
    const location = useLocation();
    //("Location",location.pathname);
    return (
        <nav className="navbar sticky-top navbar-expand flex-lg-column  flex-row align-items-start py-2 px-md-0 px-2" style={{background:"#003650"}}>
        <div className="collapse navbar-collapse align-items-start mt-lg-5 mt-none w-100">
            <ul className="flex-lg-column   flex-row navbar-nav w-100 justify-content-between text-white">
                
                <li className="nav-item">
                    <NavLink to="/dashboard"  className={location.pathname==="/dashboard"?"is-active nav-link  px-2" : "nav-link  px-2"}> <i className="navbar-icon-box fas fa-home"></i> <span className="d-none d-lg-inline  ml-3">Home</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/doctor/appointment/add"  className={location.pathname==="/doctor/appointment/add"?"is-active nav-link  px-2" : "nav-link px-2 py-2 font-size-big"} ><i className="navbar-icon-box fas fa-plus-circle"></i> <span className="d-none d-lg-inline ml-3">Add Appointment</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/doctor/appointment/schedule" className={location.pathname==="/doctor/appointment/schedule"?"is-active nav-link  px-2" : "nav-link px-2 py-2 font-size-big"} ><i className="navbar-icon-box fas fa-calendar-alt"></i>  <span className="d-none d-lg-inline ml-3">Schedule</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/doctor/patient" className={location.pathname==="/doctor/patient/history"|| location.pathname==="/doctor/patient"?"is-active nav-link  px-2" : "nav-link px-2 py-2 font-size-big"} ><i className="navbar-icon-box far fa-user"></i> <span className="d-none d-lg-inline ml-3">Patients</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/report" className={location.pathname==="/report"?"is-active nav-link  px-2" : "nav-link px-2 py-2 font-size-big"} ><i className="navbar-icon-box fas fa-file-alt"></i><span className="d-none d-lg-inline ml-3">Reports</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/e-pharmacy" className={location.pathname==="/e-pharmacy"?"is-active nav-link  px-2" : "nav-link px-2 py-2 font-size-big"} ><i className="navbar-icon-box fas fa-laptop-medical"></i><span className="d-none d-lg-inline ml-3">E-pharmacy</span></NavLink>
                </li>
               
            </ul>
        </div>
    </nav>
    );
};

export default NewClinicianNav;