import React,{useState} from 'react';
import { Table } from 'antd';
import Tooltip from '../../../../utils/Tooltip';

const columns = [
  {
    title: 'Date',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Oxygen',
    dataIndex: 'spO2',
    key: 'spO2',
  },
  {
    title: 'Heart Rate',
    dataIndex: 'heartRate',
    key: 'heartRate',
  },
];

const POReading = ({dataSource}) => {
  const [search,setSearch]=useState("")

  let arr=dataSource&& dataSource.length > 0 && dataSource
          .filter((value)=>value.time.includes(search))


  return (
    <div className="row bg-white po-reading border-radius-chart shadow-lg p-4  mw-100 m-auto h-100">
      <p className="chart-heading font-weight-bold text-xl w-full text-left">SPO2 Readings
      <span className='ml-2'><Tooltip text="A log book of your spo2 and heart rate results starting with a most recent."/></span>
      </p>
      <div className="w-full mx-auto overflow-x-auto px-2">
      <input type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control  d-block d-lg-none  mt-2' ></input>
        <div className="position-relative">
        <input style={{width:"30%",top:"2%"}} type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control position-absolute d-none d-lg-block' ></input>
        <Table dataSource={arr} columns={columns} pagination={{width:"50%", defaultPageSize: 5,showQuickJumper:false,size:"small",showSizeChanger:false,position:["topRight"]}}/>
        </div>
      </div>
    </div>
   );
}

export default POReading;
