import React from "react";

const ButtonGridComp = () => {
  return (
    <div>
      <div className="d-flex  justify-content-md-start justify-content-sm-center justify-content-center">
        <button type="button" className="bg-filter-btn mx-1">
          TODAY
        </button>
        <button type="button" className="bg-filter-btn mx-1">
          WEEK
        </button>
        <button type="button" className="bg-filter-btn mx-1">
          MONTH
        </button>
        <button type="button" className="bg-filter-btn mx-1">
          RANGE
        </button>
      </div>
    </div>
  );
};

export default ButtonGridComp;
