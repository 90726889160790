import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as _ from 'lodash';
import $ from "jquery";
import Tooltip from "../../../../utils/Tooltip";

am4core.useTheme(am4themes_animated);

function Chart6c(props) {
//
let heading=props.themeType==="bg"?"text-voyger-purple":props.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
let color1=props.themeType==="bg"?"#0D92D2":props.themeType==="inr"?"#99D7A6":"#0D92D2";
let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";
let color3=props.themeType==="bg"?"#7C1237":props.themeType==="inr"?"#818FAE":"#7C1237";

let data= props.data>0 ?  _.round(props.data, 1).toFixed(1):0;

// if(props.data === undefined || props.data === null){
//   data=0
// }else{
//   data = _.round(props.data, 1).toFixed(1);
// }
  // let unit = props.unit === 0 ? "mmol": "mgdl/L";

  useEffect(() => {
    let chart = am4core.create("chartdiv6c", am4charts.PieChart);

    chart.paddingRight = 0;
    chart.paddingLeft = 0;
    chart.fontSize = 7;

    chart.data = [
      {
        country: "Average After Meal",
        litres: data + 0.00000000000000000001,
      },
    ];

    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;

    chart.innerRadius = am4core.percent(60);
    pieSeries.labels.template.disabled = true;

    // Set up fills
    pieSeries.slices.template.fillOpacity = 1;
    pieSeries.slices.template.fill = color1;

    const label = pieSeries.createChild(am4core.Label);
    label.text = `${data}%`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontWeight = "bold";
    label.fill = color1;

    label.fontSize = 11;
    var hs = pieSeries.slices.template.states.getKey("hover");
    hs.properties.scale = 1;
    hs.properties.fillOpacity = 0.5;
  }, [props]);

  return ( <div className="mt-1 md:mt-0 flex flex-col justify-center items-center">
        <p className={`font-semibold text-md text-center font-size-normal ${heading}`}>Average After-Meal
        <span className='ml-2'><Tooltip themeType={props.themeType}  text="Average result after eating your meal.To ensure accuracy remember to tag your results when performing a blood glucose test."/></span>
        </p>
        <div id="chartdiv6c" style={{ width: "60%", height: "60%" }}></div>
      </div>
);
}
export default Chart6c;
