import React, { useEffect } from "react";
import $ from "jquery";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Tooltip from "../../../../utils/Tooltip";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end
function Chart2(props) {
  ////(props);
//


  let heading=props.themeType==="bg"?"text-voyger-purple":props.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
  let color1=props.themeType==="bg"?"#0D92D2":props.themeType==="inr"?"#99D7A6":"#0D92D2";
  let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";
  let color3=props.themeType==="bg"?"#7C1237":props.themeType==="inr"?"#818FAE":"#7C1237";
  //("Chart1=",props)
  let chart;
  useEffect(() => {

    chart = am4core.create(`${props.id}TimeRange`, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.data = props.dataPoints;
    chart.radius = am4core.percent(90);
    chart.innerRadius = am4core.percent(60);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";

    series.slices.template.cornerRadius = 5;
    series.slices.template.innerCornerRadius = 3;
    series.slices.template.draggable = true;
    series.labels.template.disabled = true;

    series.slices.template.inert = true;
    series.alignLabels = false;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    const label = series.createChild(am4core.Label);
    label.text = `${props.percentage === null ? 0 : props.percentage} %`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 24;
    label.fontWeight = "bold";
    label.fill = color1;
    // chart.legend = new am4charts.Legend();
    // var hs = series.slices.template.states.getKey("hover");
    // hs.properties.scale = 1;
    // hs.properties.fillOpacity = 0.5;
    series.colors.list = [
      am4core.color(color2),
      am4core.color(color1),
      am4core.color(color3),
    ];

}, [props]);

  return (<div className={props.mr? "flex  flex-col justify-center items-center mr-1": "flex flex-col h-50 justify-center items-center"}>
        <p className={`font-semibold text-md text-center chart-heading mb-md-2 mb-3 ${heading}`}>
          Time In Range
          <span className='ml-2'>
          <Tooltip themeType={props.themeType}  text="The percentage of reading inside your goal range."/>
          </span>
          </p>
        <div id={`${props.id}TimeRange`} style={{ width: props.width, height: props.height }}></div>
  </div>
  );
}
export default Chart2;
