import React from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  FileOutlined,
  TeamOutlined,
  ApiOutlined,
  UserOutlined,
  MessageOutlined,
  MedicineBoxOutlined,
  AlertOutlined,
  MonitorOutlined,
  BranchesOutlined,
  DesktopOutlined,
  FileImageOutlined,
  FileSearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { EPharmSVG, DoctorSVG } from "../../../assets";

export default function AdminNav({ collapsed }) {
  return (
    <div className="admin-nav overflow-y-auto" style={{ maxHeight: "70vh" }}>
      <Menu mode="inline" defaultSelectedKeys={"1"}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link className="ml-1" to="/dashboard">
            Home
          </Link>
        </Menu.Item>
        {/*<Menu.Item key="2" icon={<TeamOutlined />}>
          <Link className="ml-1" to="/admin/users">
            Admin Users
          </Link>
        </Menu.Item>*/}
        <Menu.Item key="3" icon={<FileImageOutlined />}>
          <Link className="ml-1" to="/admin/media">
            Media
          </Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<FileTextOutlined />}>
          <Link className="ml-1" to="/admin/documents">
            Documents
          </Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<FileSearchOutlined />}>
          <Link className="ml-1" to="/admin/fileManagement">
            File Management
          </Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<ApiOutlined />}>
          <Link className="ml-1" to="/admin/apiManagement">
            API Management
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="7" icon={<UserOutlined />}>
          <Link className="ml-1" to="/admin/userManagement">
            User Management
          </Link>
        </Menu.Item>
        
        <Menu.Item
          key="8"
          icon={<DoctorSVG className="w-4 h-4 inline mr-2 fill-current" />}
        >
          <Link className="ml-1" to="/admin/hcp">
            <span className={!collapsed ? "" : "text-blue-100"}>
              HCP Management
            </span>
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="9" icon={<MessageOutlined />}>
          <Link className="ml-1" to="/admin/notifications">
            Notification Management
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="10" icon={<MedicineBoxOutlined />}>
          <Link className="ml-1" to="/admin/medicationManagement">
            Medication Management
          </Link>
        </Menu.Item>
        <Menu.Item
          key="11"
          icon={<EPharmSVG className="w-4 h-4 inline mr-2 fill-current" />}
        >
          <Link className="ml-1" to="/epharmacyOrders">
            <span className={!collapsed ? "" : "text-blue-100"}>
              E-pharmacy Orders
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="12" icon={<FileOutlined />}>
          <Link className="ml-1" to="/admin/vts">
            VTS
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="13" icon={<AlertOutlined />}>
          <Link className="ml-1" to="/admin/faultManagement">
            Fault Management
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="14" icon={<MonitorOutlined />}>
          <Link className="ml-1" to="/admin/logs">
            Logs
          </Link>
        </Menu.Item>{" "}
        <Menu.Item key="15" icon={<BranchesOutlined />}>
          <Link className="ml-1" to="/admin/appManagement">
            App Management
          </Link>
        </Menu.Item>
        <Menu.Item key="16" icon={<DesktopOutlined />}>
          <Link className="ml-1" to="/admin/software">
            Software
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}
