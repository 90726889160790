import React,{useState} from "react";
import { Tabs } from "antd";
import SharedWith from "./SharedWith";
import Pending from "./PendingInvites";

const { TabPane } = Tabs;
const ShareComp = () => {
  const [activeTab,setActiveTab]=useState("shared")
  return (
    <div className="bg-white">
      <div className="share-comp-bg d-flex justify-content-end align-items-end" style={{height:"200px"}}>
        <div className="mt-auto ml-auto ">
               <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a className={` d-block p-3 px-2 text-white mr-1 btn-share-category ${activeTab==="shared"?" text-decoration-underline":null}`} id={`shared-tab1`} data-toggle="tab" onClick={()=>{setActiveTab("shared")}} href={`#shared1`}  role="tab" aria-controls="shared" aria-selected="true">Shared</a>
                </li>
                <li className="nav-item">
                  <a className={`d-block p-3 px-2 text-white mr-1 btn-share-category ${activeTab==="pending"?" text-decoration-underline":null}`} id={`pending-tab1`} data-toggle="tab" onClick={()=>{setActiveTab("pending")}} href={`#pending1`} role="tab" aria-controls="pending" aria-selected="false">Pending</a>
                </li>
              </ul>
        </div>
      </div>
      <div className="tab-content my-2" id="myTabContent">
        <div className="tab-pane fade show active" id={`shared1`}  role="tabpanel" aria-labelledby="shared-tab">
        <SharedWith activeTab={activeTab}/>
        </div>
        <div className="tab-pane fade" id={`pending1`}  role="tabpanel" aria-labelledby="pending1-tab">
        <Pending activeTab={activeTab}/>
        </div>
      </div>

    </div>
  );
};

export default ShareComp;
