

import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import "./styles/tailwind/tailwind.generated.css";
import "./styles/tailwind/tailwind.css";
import "./styles/index.scss";
import "./assets/font-awesome/all.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import { Provider } from "react-redux";
// Internet Explorer 6-11
var isIE = /*@cc_on!@*/false || !!document.documentMode;

ReactDOM.render( <
    React.StrictMode >
    <
    Provider store = { store } >
    {isIE ? null :
    <App />}
    <
    /Provider> 
    < / React.StrictMode > ,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();