import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Spin,
  Modal,
  Table,
  Collapse,
  Row,
  Col,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import * as _ from 'lodash';
 import {
  saveFamilyHistoryData,
  fetchFamilyHistory,
} from "../../../redux/actions/history";
import { useDispatch, useSelector } from "react-redux";
import ShowToast from "../../../utils/show-toast";
import { FamilyHistoryForm } from "./family-history-form";
import { createRequestObjectFromForm } from "../../../utils/transformers/history/family-history-transformer";

const { Panel } = Collapse;
export default function SurgicalHistory() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [familyhistoryRecords, setFamilyhistoryRecords] = useState([]);
  const [isViewMode, setViewMode] = useState(false);

  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(
    (state) => state.history.familyHistory
  );

  useEffect(() => {
    dispatch(fetchFamilyHistory());
  }, []);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const res = createRequestObjectFromForm(values);

    Object.keys(res).map(key=>{
      res[key] = [...res[key],..._.difference(data.values[key],res[key])]
    })
    
    try {
      await dispatch(saveFamilyHistoryData(res));
      ShowToast("Success!", "success");
      setIsSubmitting(false);
      setVisible(false);
    } catch (error) {
      setIsSubmitting(false);
    }
    form.resetFields();
    await dispatch(fetchFamilyHistory());
  };

  const handleCancel = () => {
    setVisible(false);
    setViewMode(false);
    form.resetFields();
  };

  const genExtra = (conditionData) => {
    const formatData = { familyHistory: conditionData };
    return (
      <EditOutlined
        onClick={() => {
          setVisible(true);
          form.setFieldsValue(formatData);
        }}
      />
    );
  };
  return (
    <Spin spinning={isLoading}>
      <div className="w-full" style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <div className="p-1 relative flex flex-col mx-auto">
          <div className="mb-2 md:self-end">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setVisible(!visible)}
              className="w-auto rounded-lg"
            >
              <span className="flex justify-between items-center">
                <PlusOutlined className="text-xl md:text-2xl md:mr-2" />
                <span className="hidden md:inline-block">
                  Add Family History
                </span>
              </span>
            </Button>
          </div>
          <Row
            className="flex flex-1 w-full p-4 bg-gray-200"
            style={{ margin: "0" }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col className="gutter-row" span={6}>
              <p className="font-bold text-md mb-0">Description</p>
            </Col>
            <Col className="gutter-row" span={6}>
              <p className="font-bold text-md mb-0">Relation</p>
            </Col>
            <Col className="gutter-row" span={6}>
              <p className="font-bold text-md mb-0">Age</p>
            </Col>
          </Row>
          <div className="family-history">
          <Collapse defaultActiveKey={["0"]}>
            {data &&
              Object.keys(data.values).map((key, index) => {
                return (
                  <Panel
                    className="font-semibold"
                    header={key}
                    key={index}
                    extra={genExtra(data.values[key])}
                  >
                    {data.values[key].map((val, index) => {
                      return (
                        <Row
                          key={index}
                          style={{
                            borderBottom: "1px solid lightgray",
                            marginTop: "10px",
                          }}
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col className="gutter-row" span={6}>
                            <p>{val.description}</p>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <p>{val.relation}</p>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <p>{val.age}</p>
                          </Col>
                        </Row>
                      );
                    })}
                  </Panel>
                );
              })}
          </Collapse>
          </div>
        </div>
        <Modal
          style={{ top: 10 }}
          visible={visible}
          title="Add Family History"
          onCancel={handleCancel}
          footer={[]}
        >
          <div className=" overflow-y-auto pr-5" style={{ maxHeight: "75vh" }}>
            <Form
              labelAlign={"left"}
              labelCol={{ span: 12 }}
              form={form}
              className="w-full"
              name="FamilyHistoryForm"
              onFinish={handleSubmit}
              hideRequiredMark={true}
              initialValues={{
                familyHistory: [
                  { condition: "", description: "", relation: "", age: "" },
                ],
              }}
            >
              <FamilyHistoryForm {...form}/>
              {!isViewMode && (
                <Form.Item>
                  <Button
                    loading={isSubmitting}
                    className="flex text-center justify-center"
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        </Modal>
      </div>
    </Spin>
  );
}
