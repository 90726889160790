import { API_BASE_URL, fetchSpecificPatient,deleteSharedPatient} from "../../../utils/urls";
import {
    ACCEPTED_USERS_SUCCESS,ACCEPTED_USERS_START,ACCEPTED_USERS_FAILED
    ,PENDING_INVITES_SUCCESS,PENDING_INVITES_START,PENDING_INVITES_FAILED,
    DELETE_USERS_SUCCESS
} from "../../actionTypes/external-doctor/inviteList";
import http from "../../../utils/http-service";
import {decrypt,encrypt} from "../../../utils/encryption";
import {logout} from "../auth"

import ShowToast from "../../../utils/show-toast";
import { stringify } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { JSONParser } from "@amcharts/amcharts4/core";

export const acceptedUsers = (data) => async(dispatch) => {
    let {page,noOfRecord}=data
    page = stringify(page);
    page = encrypt(page);
    noOfRecord = stringify(noOfRecord);
    noOfRecord = encrypt(noOfRecord);

    try {
        let res;
        res = await http.get(`${API_BASE_URL}/doctor/e_acceptedInvites?page=${page}&records=${noOfRecord}`, {});

        let gift = res.data.data;
        gift = decrypt(gift);
        gift = JSON.parse(gift);

        dispatch({ type: ACCEPTED_USERS_SUCCESS, payLoad: gift });
        // ShowToast(res.data.message,"success");

        return res;
    } catch (error) {
      if(error.response.status===422 || error.response.status === 401){

        dispatch(logout(true));
        window.location.reload();

      }
        return error;
    }
};

export const searchSpecificPatient = (data) => async(dispatch) => {
    try {
        let res;
        res = await http.get(`${API_BASE_URL}${fetchSpecificPatient}?filter=${data}`, {});

        let gift = res.data.data;

        dispatch({ type: ACCEPTED_USERS_SUCCESS, payLoad: gift });
        // ShowToast(res.data.message,"success");

        return res;
    } catch (error) {
      if(error.response.status===422 || error.response.status === 401){

        dispatch(logout(true));
        window.location.reload();

      }
        ShowToast(error.response.data.message,"error")
        dispatch({type:ACCEPTED_USERS_FAILED,payLoad:[]})
        return error;
    }
};

export const deleteSharedPatientFromList = (id) => async(dispatch) => {

    try {
        let res;
        res = await http.post(`${API_BASE_URL}${"/doctor/removePatient"}`,{"id":id});
        let gift = res.data.data;
        dispatch({ type: DELETE_USERS_SUCCESS});
        ShowToast(res.data.message,"success")

        return res;
    } catch (error) {
      if(error.response.status===422 || error.response.status === 401){

        dispatch(logout(true));
        window.location.reload();

      }
        ShowToast(error.response.data.message,"error")
        return error;
    }
};

export const pendingInvites = () => async(dispatch) => {


    try {

        let res;
        res = await http.get(`${API_BASE_URL}/doctor/e_pendingInvites`);

        let gift = res.data.data;
        gift = decrypt(gift);
        gift = JSON.parse(gift);
        dispatch({ type: PENDING_INVITES_SUCCESS, payLoad: gift });
        // ShowToast(res.data.message,"success");

        return res;
    } catch (error) {
      if(error.response.status===422 || error.response.status === 401){

        dispatch(logout(true));
        window.location.reload();

      }
        return error;
    }
};
