import crypto from "crypto";
import moment from "moment";
// import * as Realm from 'realm-web';


// const app = new Realm.App({id: process.env.REACT_APP_REALM_APP_ID});

const algorithm = 'aes-256-gcm',
    GRX_grx = process.env.REACT_APP_GRX_grx,
    // do not use a global iv for production,
    // generate a new one for each encryption
    GRX_iv = process.env.REACT_APP_GRX_IV;

export  const encrypt = (text) => {

  const cipher = crypto.createCipheriv(algorithm, GRX_grx, GRX_iv)
  let encrypted = Buffer.concat([cipher.update(text, 'utf8'), cipher.final(), cipher.getAuthTag()]);
  return encrypted.toString('hex');
}

export  const decrypt = (encrypted) => {

  const decipher = crypto.createDecipheriv(algorithm, GRX_grx, GRX_iv)
  encrypted = Buffer.from(encrypted, 'hex');
  const content = encrypted.slice(0, encrypted.length - 16);
  const tag = encrypted.slice(encrypted.length - 16, encrypted.length);
  decipher.setAuthTag(tag);
  let dec = decipher.update(content)
  dec += decipher.final('utf8');
  return dec;
}
export const makeSeconds=(date)=>{
  var now = new Date(date);
  var time = now.getTime();
  var offset = now.getTimezoneOffset();
  offset = offset * 60000;

  return time - offset;


  // let time=((((((((((((new Date(date).getFullYear()-1)*12+(new Date(date).getMonth()-1))*30)+new Date(date).getDay()-1)*24)+(new Date(date).getHours()-1)))*60)+(new Date(date).getMinutes()-1)))*60));
  // return time;
}
