import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import $ from "jquery";
import {
  fetchAllImplantedDevices,
  saveImplantedDevices,
  updateImplantedDevice,
  deleteImplantedDevice,
} from "../../../redux/actions/emergency";
import updateDate from "../../../utils/updateDate";
import ShowToast from "../../../utils/show-toast";

class ImplantedDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      name: "",
      doctorName: "",
      reason: "",
      description: "",
      placementDate: "",
      bodyPart: "",
      alert: "",
      manufacturer: "",
      deviceId: "",
      event: "",
      view: false,
      validSubmit: false,
      editMode: false,
      deleteModal: false,
      id: "",
      devicesModal: false,
      toDelete: "",
    };
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    if (
      this.props.implantedDevices &&
      this.props.implantedDevices.data !== null
    ) {
      this.setState({ data: this.props.implantedDevices.data });
    } else {
      this.props.dispatch(fetchAllImplantedDevices());
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.implantedDevices && props.implantedDevices.data !== null) {
      let listOfImplantedDevices = props.implantedDevices.data;
      state.data = listOfImplantedDevices;
    }
  }

  updateImplantedDevicesDataStart = (index) => {
    if (this.state.data && this.state.data.length > 0) {
      let implantedDevicesData = this.state.data[index];

      this.setState({
        modal: true,
        id: implantedDevicesData.id,
        name: implantedDevicesData.name,
        doctorName: implantedDevicesData.doctorName,
        reason: implantedDevicesData.reason,
        description: implantedDevicesData.description,
        placementDate: implantedDevicesData.placementDate,
        bodyPart: implantedDevicesData.bodyPart,
        alert: implantedDevicesData.alert,
        manufacturer: implantedDevicesData.manufacturer,
        deviceId: implantedDevicesData.deviceId,
        event: implantedDevicesData.event,
      });
      updateDate(implantedDevicesData.placementDate, "placementDate", false);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let data = [
      {
        name: this.state.name,
        doctorName: this.state.doctorName,
        reason: this.state.reason,
        description: this.state.description,
        placementDate: new Date(this.state.placementDate),
        bodyPart: this.state.bodyPart,
        alert: this.state.alert,
        manufacturer: this.state.manufacturer,
        deviceId: this.state.deviceId,
        event: this.state.event,
      },
    ];
    await this.props.dispatch(saveImplantedDevices(data));
    this.setState({ view: true });
    ShowToast("Success!", "success");
    $("form").trigger("reset");
    this.setState({
      name: "",
      doctorName: "",
      reason: "",
      description: "",
      placementDate: "",
      bodyPart: "",
      alert: "",
      manufacturer: "",
      deviceId: "",
      event: "",
      view: false,
      validSubmit: false,
      devicesModal: false,
    });

    await this.props.dispatch(fetchAllImplantedDevices());
  };
  updateImplantedDeviceRecord = async (e) => {
    e.preventDefault();

    let data = {
      name: this.state.name,
      doctorName: this.state.doctorName,
      reason: this.state.reason,
      description: this.state.description,
      placementDate: new Date(this.state.placementDate),
      bodyPart: this.state.bodyPart,
      alert: this.state.alert,
      manufacturer: this.state.manufacturer,
      deviceId: this.state.deviceId,
      event: this.state.event,
    };
    await this.props.dispatch(updateImplantedDevice(data, this.state.id));
    this.setState({ view: true });
    ShowToast("Success!", "success");
    $("form").trigger("reset");
    this.setState({
      name: "",
      doctorName: "",
      reason: "",
      description: "",
      placementDate: "",
      bodyPart: "",
      alert: "",
      manufacturer: "",
      deviceId: "",
      event: "",
      view: false,
      validSubmit: false,
      devicesModal: false,
      editMode: false,
    });

    await this.props.dispatch(fetchAllImplantedDevices());
  };
  deleteImplantedDeviceRecord = async (id) => {
    await this.props.dispatch(deleteImplantedDevice(id));
    ShowToast("Success!", "success");
    this.setState({ deleteModal: false });
    await this.props.dispatch(fetchAllImplantedDevices());
  };
  addNewDevice = () => {
    this.setState({
      name: "",
      doctorName: "",
      reason: "",
      description: "",
      placementDate: "",
      bodyPart: "",
      alert: "",
      manufacturer: "",
      deviceId: "",
      event: "",
      view: false,
      validSubmit: false,
      devicesModal: true,
    });
  };
  toUpdateState = () => {
    $("form").trigger("reset");
    this.setState({
      name: "",
      doctorName: "",
      reason: "",
      description: "",
      placementDate: "",
      bodyPart: "",
      alert: "",
      manufacturer: "",
      deviceId: "",
      event: "",
      view: false,
      validSubmit: false,
      devicesModal: false,
      editMode: false,
    });
  };
  deleteModal = (id) => {
    this.setState({ deleteModal: true, toDelete: id });
    this.setState({ editMode: true });
    this.setState({ view: false });
  };
  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  };
  render() {
    const { data } = this.state;

    return (
      <>
        <div className="text-right">
          <button
            type="button"
            className="add-info-btn"
            data-toggle="modal"
            data-target="#implantedDeviceModal"
            onClick={this.addNewDevice}
          >
            <span className="d-flex justify-content-center align-items-center">
              <i className="fas fa-plus mr-2"></i>
              <span>Add Implanted Devices</span>
            </span>
          </button>
        </div>

        <div
          className={`modal big_modal_dialog ${
            this.state.devicesModal ? "d-block modal-fading" : " d-none"
          }`}
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {this.state.editMode
                    ? "Update Implanted Devices"
                    : "Add Implanted Devices"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.toUpdateState}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                autoComplete="off"
                onSubmit={
                  this.state.editMode
                    ? this.updateImplantedDeviceRecord
                    : this.handleSubmit
                }
              >
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6 col-10">
                        <div className="d-block">Device Name </div>
                        <div className="form-group">
                          <input
                            name="name"
                            placeholder="Name"
                            defaultValue={this.state.name}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-10">
                        <div className="d-block">Doctor Name </div>
                        <div className="form-group">
                          <input
                            name="doctorName"
                            placeholder="Doctor Name"
                            defaultValue={this.state.doctorName}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6 col-10">
                        <div className="d-block">Reason</div>
                        <div className="form-group">
                          <input
                            name="reason"
                            placeholder="Reason"
                            defaultValue={this.state.reason}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-10">
                        <div className="d-block">Description</div>
                        <div className="form-group">
                          <input
                            name="description"
                            placeholder="Description"
                            defaultValue={this.state.description}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-md-6 col-10">
                        <div className="d-block">Placement Date </div>
                        <div className="form-group">
                          <input
                            name="placementDate"
                            id="placementDate"
                            placeholder="Placement Date"
                            // defaultValue={this.state.placementDate}
                            onChange={this.handleChange}
                            // className="datepicker"
                            type="date"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-10">
                        <div className="d-block">Body part</div>
                        <div className="form-group">
                          <input
                            name="bodyPart"
                            placeholder="Body Part"
                            defaultValue={this.state.bodyPart}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6 col-10">
                        <div className="d-block">Alert</div>
                        <div className="form-group">
                          <input
                            name="alert"
                            placeholder="Alert"
                            defaultValue={this.state.alert}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-10">
                        <div className="d-block">Manufacturer</div>
                        <div className="form-group">
                          <input
                            name="manufacturer"
                            placeholder="Manufacturer"
                            defaultValue={this.state.manufacturer}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6 col-10">
                        <div className="d-block">Device Id</div>
                        <div className="form-group">
                          <input
                            name="deviceId"
                            placeholder="Device Id"
                            defaultValue={this.state.deviceId}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-10">
                        <div className="d-block">Event</div>
                        <div className="form-group">
                          <input
                            name="event"
                            placeholder="Event"
                            defaultValue={this.state.event}
                            onChange={this.handleChange}
                            className="form-control input-md msg"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {this.state.view == false ? (
                    <button
                      type="submit"
                      className="modal-save-btn"
                      data-toggle={this.state.editMode ? "modal" : "null"}
                      data-target={
                        this.state.editMode ? "#implantedDeviceModal" : "null"
                      }
                    >
                      {this.state.editMode ? "Update" : "Save"}
                    </button>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <div>table</div> */}

        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-success">
                <table className="table table-hover" id="task-table">
                  <thead>
                    <tr>
                      <th>Implanted Device</th>
                      <th>Date</th>
                      <th>Body part</th>
                      <th>Description</th>
                      <th>Manufacturer</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data !== null &&
                      data !== undefined &&
                      data.length > 0 &&
                      data.map((row, index) => (
                        <tr key={index + 0}>
                        {row.name !== "" && row.bodyPart !== "" &&
                         row.description!== "" && row.manufacturer !== "" ?
                         <>
                          <td>{row.name}</td>
                          <td>
                            {moment(row.placementDate).format("D MMM YYYY")}
                          </td>
                          <td>{row.bodyPart}</td>
                          <td>{row.description}</td>
                          <td>{row.manufacturer}</td>
                          <td>
                            <button
                              type="button"
                              className=" "
                              data-toggle="modal"
                              data-target="#implantedDeviceModal"
                              onClick={(event) => {
                                this.updateImplantedDevicesDataStart(
                                  `${index}`
                                );
                                this.setState({ editMode: true });
                                this.setState({ view: false });
                                this.setState({ devicesModal: true });
                              }}
                            >
                              <i className="fas fa-edit text-primary"></i>
                            </button>

                            <button
                              type="button"
                              className=" ml-4"
                              onClick={() => this.deleteModal(`${row.id}`)}
                            >
                              <i className="fas fa-trash-alt text-danger"></i>
                            </button>

                          </td>
                          </>
                          : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
                 <div
                              tabindex="-1"
                              id="myModal-delete"
                              className={`modal  ${
                                this.state.deleteModal
                                  ? "d-block fading"
                                  : " d-none"
                              }`}
                            >
                              <div className="modal-dialog modal-dialog-centered modal-confirm">
                                <div className="modal-content">
                                  <div className="modal-header flex-column">
                                    <div className="icon-box">
                                      <i className="fas fa-trash-alt text-danger"></i>
                                    </div>
                                    <h4 className="modal-heading w-100 text-black">
                                      Are you sure?
                                    </h4>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={this.closeDeleteModal}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <p>
                                      Do you really want to delete these
                                      records? This process cannot be undone.
                                    </p>
                                  </div>
                                  <div className="modal-footer justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={this.closeDeleteModal}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={(event) => {
                                        this.deleteImplantedDeviceRecord(
                                          this.state.toDelete
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    implantedDevices: state.emergency.implantedDevices,
  };
}

export default withRouter(connect(mapStateToProps)(ImplantedDevices));
