import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  fetchMedicalConditions,
  fetchFamilyHistory,
  saveFamilyHistoryData,
  updateFamilyHistory,
  deleteFamilyHistory,
} from "../../../redux/actions/history";
import ShowToast from "../../../utils/show-toast";
import $ from "jquery";

class FamilyHistoryNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conditions: [],
      family: [],
      familyHistory: [],
      _id: "",
      name: "",
      description: "",
      age: "",
      relation: "",
      editMode: false,
      deleteModal: false,
      modalClose: false,
      toDelete: "",
      familyModal: false,
    };
  }
  componentDidMount() {
    if (this.props.medicalCondition && this.props.medicalCondition.data !== null ){

      this.setState({conditions:this.props.medicalCondition.data})
    } else {

      this.props.dispatch(fetchMedicalConditions())
    }
    // const getAllMedicalConditions = async () => {
    //   const res = await fetchMedicalConditions();
    //
    //   this.setState({ conditions: res.data.data.conditions });
    //   return res;
    // };
    // getAllMedicalConditions();
    let family = [
      {
        name: null,
        description: "",
        age: "",
        relation: null,
      },
    ];
    this.setState({ family: family });
    if (this.props.familyHistory && this.props.familyHistory.data === null) {
      this.props.dispatch(fetchFamilyHistory());
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.familyHistory && props.familyHistory.data !== null) {
      let familyHistory = props.familyHistory.data;
      state.familyHistory = familyHistory;
    }
    if (props.medicalCondition && props.medicalCondition.data !== null) {
      let condition = props.medicalCondition.data;

      state.conditions = condition;
    }
  }

  handleChange = (event, i) => {
    this.setState({ [event.target.name]: event.target.value });
    let target = event.target.name;
    let family = this.state.family;
    if (target === "name") {
      family[i].name = event.target.value;
      this.setState({ family: family });
    } else if (target === "description") {
      family[i].description = event.target.value;
      this.setState({ family: family });
    } else if (target === "relation") {
      family[i].relation = event.target.value;
      this.setState({ family: family });
    } else if (target === "age") {
      family[i].age = event.target.value;
      this.setState({ family: family });
    }
  };
  addValue = (event) => {
    let family = this.state.family;

    let obj = {
      name: "",
      description: "",
      age: "",
      relation: "",
    };
    family.push(obj);
    this.setState({ family: family });
  };
  removeIndex = (index) => {
    let newData = this.state.family;
    newData.splice(index, 1);
    this.setState({ family: newData });
  };
  submitFamilyHistory = async (e) => {
    e.preventDefault();

    let data = this.state.family;

    await this.props.dispatch(saveFamilyHistoryData(data));

    this.setState({ modalClose: false });
    ShowToast("Success!", "success");
    $("form").trigger("reset");
    this.setState({
      family: [],
      familyHistory: [],
      _id: "",
      name: "",
      description: "",
      age: "",
      relation: "",
      editMode: false,
      deleteModal: false,
      modalClose: false,
      toDelete: "",
      familyModal: false,
    });

    await this.props.dispatch(fetchFamilyHistory());
  };
  updateFamilyData = async (e) => {
    e.preventDefault();
    await this.props.dispatch(
      updateFamilyHistory(
        {
          name: this.state.name,
          description: this.state.description,
          relation: this.state.relation,
          age: this.state.age,
        },
        this.state._id
      )
    );
    ShowToast("Success!", "success");
    $("form").trigger("reset");
    this.setState({
      family: [],
      familyHistory: [],
      _id: "",
      name: "",
      description: "",
      age: "",
      relation: "",
      editMode: false,
      deleteModal: false,
      modalClose: false,
      toDelete: "",
      familyModal: false,
    });
    this.setState({ modalClose: false });
    this.setState({ modal: false });
    await this.props.dispatch(fetchFamilyHistory());
  };

  startUpdateFamilyData = (id) => {
    this.setState({ _id: id });
    let famObj = this.state.familyHistory.filter((obj) => obj._id === id);

    this.setState({
      family: famObj,
      name: famObj.name,
      description: famObj.description,
      age: famObj.age,
      relation: famObj.relation,
    });
  };
  deleteFamilyRecord = async (_id) => {
    await this.props.dispatch(deleteFamilyHistory(_id));
    ShowToast("Success!", "success");
    this.closeDeleteModal();
    await this.props.dispatch(fetchFamilyHistory());
  };
  addNewFamilyHistory = () => {
    let family = [
      {
        name: "",
        description: "",
        age: "",
        relation: "",
      },
    ];
    this.setState({ family: family, editMode: false, familyModal: true });
  };
  resetData = () => {
    this.setState({ familyModal: false });
    this.props.dispatch(fetchFamilyHistory());
  };
  deleteModal = (id) => {
    this.setState({ deleteModal: true, toDelete: id });
  };
  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  };
  render() {
    const { conditions, family, familyHistory } = this.state;

    return (
      <>
        <div className="text-right">
          <button
            type="button"
            className="add-info-btn"
            data-toggle="modal"
            data-target="#exampleModalLong2"
            onClick={this.addNewFamilyHistory}
          >
            <span className="d-flex justify-content-center align-items-center">
              <i className="fas fa-plus mr-2"></i>
              <span>Add Family History</span>
            </span>
          </button>
        </div>

        <div
          className={`modal big_modal_dialog ${
            this.state.familyModal ? "d-block modal-fading" : " d-none"
          }`}
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {this.state.editMode
                    ? "Update Family History"
                    : "Add Family History"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.resetData}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={
                  this.state.editMode
                    ? this.updateFamilyData
                    : this.submitFamilyHistory
                }
              >
                <div className="modal-body">
                  <div className="container-fluid">
                    {family &&
                      family.length > 0 &&
                      family.map((member, i) => (
                        <div key={i}>
                          <div
                            className={
                              family.length > 1
                                ? "d-block mb-3 text-right"
                                : "d-none"
                            }
                          >
                            <div className="row justify-content-md-end justify-content-center">
                              <div className="col-md-3 col-10">
                                <button
                                  type="button"
                                  className="remove-btn"
                                  onClick={(section) => {
                                    this.removeIndex(i);
                                  }}
                                >
                                  remove
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center modal-grid-row-12">
                            <div className="col-md-12 col-10">
                              <h6 className=" ">Family History Item</h6>
                            </div>
                          </div>
                          <div className="row   justify-content-center modal-grid-row-12">
                            <div className="col-md-12 col-10">
                              {" "}
                              <div className="form-group">
                                <div className="styled-select">
                                  <select
                                    name="name"
                                    id={"name-" + i}
                                    placeholder="Select a Blood Group"
                                    className="form-control input-md msg"
                                    value={family[i].name}
                                    onChange={(event) =>
                                      this.handleChange(event, i)
                                    }
                                    required
                                  >
                                    <option value="">Select</option>

                                    {conditions &&
                                      conditions.length > 0 &&
                                      conditions.map((condition, index) => (
                                        <option
                                          value={condition.name}
                                          key={index}
                                        >
                                          {condition.name}
                                        </option>
                                      ))}
                                  </select>
                                  <i className="fa fa-chevron-down"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row justify-content-center mt-3">
                                <div className="col-md-11 col-10 ">
                                  <h6 className=" ">
                                    Family History Relation:
                                  </h6>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <div className="col-md-11 col-10 ">
                                  <div className="form-group">
                                    <div className="styled-select">
                                      <select
                                        name="relation"
                                        id={"relation-" + i}
                                        placeholder="Select a Blood Group"
                                        className="form-control input-md msg"
                                        value={family[i].relation}
                                        onChange={(event) =>
                                          this.handleChange(event, i)
                                        }
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                      </select>
                                      <i className="fa fa-chevron-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row justify-content-center mt-3">
                                <div className="col-md-11 col-10">
                                  <h6 className="  ">Description:</h6>
                                </div>
                              </div>
                              <div className="row   justify-content-center">
                                <div className="col-md-11 col-10 ">
                                  {" "}
                                  <div className="form-group">
                                    <div className="">
                                      <input
                                        name="description"
                                        placeholder="Description"
                                        className="form-control input-md msg"
                                        type="text"
                                        value={family[i].description}
                                        onChange={(event) =>
                                          this.handleChange(event, i)
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center mt-3 modal-grid-row-12">
                            <div className="col-md-12 col-10">
                              <h6 className=" ">Age:</h6>
                            </div>
                          </div>
                          <div className="row justify-content-center modal-grid-row-12">
                            <div className="col-md-12 col-10 ">
                              <div className="form-group">
                                <div className="">
                                  <input
                                    name="age"
                                    placeholder="Age"
                                    className="form-control input-md msg"
                                    type="text"
                                    value={family[i].age}
                                    onChange={(event) =>
                                      this.handleChange(event, i)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {this.state.editMode === false ? (
                      <div className="row  justify-content-center">
                        <div className="col-md-8 col-10">
                          <button
                            className="add-btn"
                            type="button "
                            onClick={this.addValue}
                          >
                            Add Fields
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="modal-save-btn">
                    <span>{this.state.editMode ? "Update" : "Save"}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-3 ">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-success">
                <table className="table table-hover" id="task-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Relation</th>
                      <th>Age</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {familyHistory &&
                      familyHistory.length > 0 &&
                      familyHistory.map((history, ind) => (
                        <tr>
                          <td>{history.name}</td>
                          <td>{history.description}</td>
                          <td>{history.relation}</td>
                          <td>{history.age}</td>

                          <td>
                            <button
                              type="button"
                              className=" "
                              data-toggle="modal"
                              data-target="#exampleModalLong2"
                              onClick={(event) => {
                                this.startUpdateFamilyData(`${history._id}`);
                                this.setState({ editMode: true });
                                this.setState({ view: false });
                                this.setState({ familyModal: true });
                              }}
                            >
                              <span className="">
                                <i className="fas   fa-edit text-primary"></i>
                              </span>
                            </button>

                            <button
                              type="button"
                              className=" ml-4"
                              onClick={() => this.deleteModal(`${history._id}`)}
                            >
                              <span className="">
                                <i className="fas fa-trash-alt text-danger "></i>
                              </span>
                            </button>

                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div
                              className={`modal  ${
                                this.state.deleteModal
                                  ? "d-block fading"
                                  : " d-none"
                              }`}
                            >
                              <div className="modal-dialog modal-dialog-centered modal-confirm">
                                <div className="modal-content">
                                  <div className="modal-header flex-column">
                                    <div className="icon-box">
                                      <i className="fas fa-trash-alt text-danger"></i>
                                    </div>
                                    <h4 className="modal-heading w-100 text-black">
                                      Are you sure?
                                    </h4>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={this.closeDeleteModal}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <p>
                                      Do you really want to delete these
                                      records? This process cannot be undone.
                                    </p>
                                  </div>
                                  <div className="modal-footer justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={this.closeDeleteModal}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={(event) => {
                                        this.deleteFamilyRecord(
                                          this.state.toDelete
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    familyHistory: state.history.familyHistory,
    medicalCondition: state.history.medicalCondition,

  };
}
export default withRouter(connect(mapStateToProps)(FamilyHistoryNew));
