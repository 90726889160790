import React, { useEffect,useState } from "react";
import * as _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_kelly);
am4core.useTheme(am4themes_animated);
// Themes end
function WheightScaleGauge(props) {
  const [weight,setWeight]=useState("kg")

  //(props);
  let chart;
  let reading = props.reading;
  // reading = parseInt(reading);
  //("reading",reading)

  useEffect(() => {
    chart = am4core.create(props.id, am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.innerRadius = -25;
    let axis = chart.xAxes.push(new am4charts.ValueAxis());
axis.min = props.heading==="Weight Lost" || props.heading==="Weight Gained"||props.heading==="Weight "?0 :0;
axis.max = props.heading==="Weight Lost" || props.heading==="Weight Gained"||props.heading==="Weight "?100 :200;
axis.strictMinMax = true;
axis.renderer.grid.template.stroke = new am4core.InterfaceColorSet().getFor("background");
axis.renderer.grid.template.strokeOpacity = 0.3;

let colorSet = new am4core.ColorSet();

let range0 = axis.axisRanges.create();
range0.value = 0;
range0.endValue = props.heading==="Weight Lost" || props.heading==="Weight Gained"||props.heading==="Weight "?100 :200;
range0.axisFill.fillOpacity = 1;
range0.axisFill.fill = colorSet.getIndex(9);
range0.axisFill.zIndex = - 1;


// let range1 = axis.axisRanges.create();
// range1.value = 80;
// range1.endValue = 110;
// range1.axisFill.fillOpacity = 1;
// range1.axisFill.fill = colorSet.getIndex(9);
// range1.axisFill.zIndex = -1;

// let range2 = axis.axisRanges.create();
// range2.value = 110;
// range2.endValue = 500;
// range2.axisFill.fillOpacity = 1;
// range2.axisFill.fill = colorSet.getIndex(9);
// range2.axisFill.zIndex = -1;

let hand = chart.hands.push(new am4charts.ClockHand());
//(reading);
// using chart.setTimeout method as the timeout will be disposed together with a chart
// chart.setTimeout(randomValue(80), 2000);
hand.showValue(props.reading, am4core.ease.cubicOut);
//
// function randomValue(num) {
//     hand.showValue(num, am4core.ease.cubicOut);
//     // chart.setTimeout(randomValue, 2000);
// }
    // chart.data = props.dataPoints;
    // chart.radius = am4core.percent(90);
    // chart.innerRadius = am4core.percent(60);
    // chart.startAngle = 180;
    // chart.endAngle = 360;
    //
    // let series = chart.series.push(new am4charts.PieSeries());
    // series.dataFields.value = "value";
    // series.dataFields.category = "label";
    //
    // series.slices.template.cornerRadius = 5;
    // series.slices.template.innerCornerRadius = 3;
    // series.slices.template.draggable = true;
    // series.labels.template.disabled = true;
    //
    // series.slices.template.inert = true;
    // series.alignLabels = false;
    //
    // series.hiddenState.properties.startAngle = 90;
    // series.hiddenState.properties.endAngle = 90;
    //
    // const label = series.createChild(am4core.Label);
    // label.text = `${props.percentage === null ? 0 : props.percentage} %`;
    // label.horizontalCenter = "middle";
    // label.verticalCenter = "middle";
    // label.fontSize = 24;
    // label.fontWeight = "bold";
    // label.fill = "#0D92D2";
    // // chart.legend = new am4charts.Legend();
    // // var hs = series.slices.template.states.getKey("hover");
    // // hs.properties.scale = 1;
    // // hs.properties.fillOpacity = 0.5;
    // series.colors.list = [
    //   am4core.color("#AD3860"),
    //   am4core.color("#0D92D2"),
    //   am4core.color("#7C1237"),
    // ];

}, []);

  return (<div className={props.mr? "flex flex-col justify-center items-center mr-3": "flex flex-col justify-center items-center"}>
        
        <p className="font-semibold text-center">{props.heading}</p>
          <p className="font-weight-bold">{props.unit===0?"Kg":"Lbs"}</p>
        <div id={props.id} style={{ width: "180px", height: "100%" , maxWidth:"100%"}}></div>
  </div>
  );
}
export default WheightScaleGauge;
