import * as actions from "../actionTypes/device";

const INITIAL_STATE = {
  devices: [],
  device: '',
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICES_START:
      return { ...state, isLoading: true };
    case actions.FETCH_DEVICES_FAILED:
      return { ...state, isLoading: false };
    case actions.FETCH_DEVICES_SUCCESS:
      //(state)
      return {
        ...state,
        isLoading: false,
        devices: action.payload,
      };
    case actions.FETCH_DEVICE_TYPE:
      return {
        ...state,
        isLoading: false,
        device: action.payload,
      }
    default:
      return{
        ...state
      }
  }
};
