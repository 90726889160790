import React from "react";

const Chart7 = (props) => {
 

  return (
    <div className="container-fluid">
      <div className="row justify-content-between text-center">
        <div className="col-3 ">
          <p className="bg-reading-chart-heads-color-purple font-bold"> Date</p>{" "}
        </div>
        <div className="col">
          <p className="bg-reading-chart-heads-color-purple font-bold"> Time</p>{" "}
        </div>{" "}

        <div className="col">
          <p className="bg-reading-chart-heads-color-purple font-bold"> Taken</p>{" "}
        </div>{" "}
        <div className="col">
          <p className="bg-reading-chart-heads-color-purple font-bold"> Bg Readings</p>{" "}
        </div>
      </div>
      {props.dataSource && props.dataSource.length > 0 && props.dataSource.map((row, index)=>(
      <div className="row justify-content-between text-center" key={index+1}>
        <div className="col-3">
          <p className="bg-reading-chart-heads-color-gray"> {row.time}</p>{" "}
        </div>
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.clock}</p>{" "}
        </div>{" "}
        {/*<div className="col">*/}
        {/*  <p className="bg-reading-chart-heads-color-gray"> String</p>{" "}*/}
        {/*</div>{" "}*/}
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.takenTime}</p>{" "}
        </div>{" "}
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.bgValue}</p>{" "}
        </div>
      </div>
      ))}
    </div>
  );
};

 export default Chart7;
