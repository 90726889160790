import React, { useEffect,useState } from 'react';
import ChatBox from './ChatBox';
import {useSelector} from "react-redux"
import NotesComp from './NotesComp';
import NotesModal from '../Modal/NotesModal';
import MedicationModal from '../../doctor-dashboard/PatientList/Modals/MedicationModal';

const ChatComp = (props) => {
    const p = useSelector((state) =>(state.doctor.bloodGlucose.user));
    const [patient,setPatient]=useState('')
 

   useEffect(()=>{
 setPatient(p)
   },[p])
 
    
   
    return (
        <div className="container-fluid">
            <div className='row bg-white rounded shadow-lg   p-2'>
                <div className='col-md-12 text-right'>
                <button  data-toggle="modal"
                            data-target="#medModal" className='bg-primary text-white rounded p-2 font-weight-bold mr-2' >Medication</button>
                    <button  data-toggle="modal"
                            data-target="#notesModal" className='bg-primary text-white rounded p-2 font-weight-bold' > +Add Notes</button></div>
                <NotesModal/>
                <MedicationModal/>
                <div className='col-md-12 mt-4 mb-2'>
                    <div className="container-fluid">
                <div className="row ">


                    <div className="col-md-3"><span className="text-secondary">Name:</span><br></br>
                    <span className="text-primary h6 text-capitalize">{`${patient && patient.firstName} ${patient && patient.lastName}`}</span></div>

                    <div className="col-md-3"><span className="text-secondary">Code:</span><br></br>
                    <span className="text-primary h6">{"23/30/79"}</span></div>

                    <div className="col-md-3"><span className="text-secondary">Status:</span><br></br>
                    <span className="text-primary h6">{"Member"}</span></div>

                    <div className="col-md-3"><span className="text-secondary">Call Time:</span><br></br>
                    <span className="text-primary h6">{"15:30:20"}</span></div>

                    </div>
                </div>
            </div>
            </div>

           <div className="mt-4">
               <ChatBox/>
           </div>
           
            
        </div>
    );
};

export default ChatComp;