import * as actions from "../actionTypes/index";

const INITIAL_STATE = {
  isLoading: false,
  vitalsData:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_VITALS_START:
      return { ...state, isLoading: true };
    case actions.FETCH_VITALS_FAILED:
      return { ...state, isLoading: false };
    case actions.FETCH_VITALS_SUCCESS:

      return {
        ...state,
        isLoading: false,
        vitalsData: action.payLoad,
      };

    default:
      return{
        ...state
      }
  }
};
