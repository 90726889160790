import React, { useState } from 'react';


const ProblemsComp = () => {
 const [title,setTitle]=useState("");

 const problems=[{
    "_id": "60583db569c8ce0fab722bdd",
    "description": "abcd",
    "desease": "Diabtese",
    "duration": "2021-03-11T00:00:00.000Z"
  },{
    "_id": "60583db569c8ce0fab722bdd",
    "description": "abcd",
    "desease": "Diabtese",
    "duration": "2021-03-11T00:00:00.000Z"
  },{
    "_id": "60583db569c8ce0fab722bdd",
    "description": "abcd",
    "desease": "AAA Screening",
    "duration": "2021-03-11T00:00:00.000Z"
  }]
 
    return (
        <div className=' shadow-lg bg-white rounded mt-3 pb-2'>
        <div className="d-flex justify-content-between">
            <div className="text-white font-weight-bold bg-primary font-size1 px-1">
                Problems
            </div>
        </div>
        <div className='container-fluid mt-4'>
         {/* <Accordian Start> */}
     {problems.map((row,i)=>{
       return(
        <div id="accordion">
       <div className="card mb-1">
    <div className="card-header" id={`heading${i}`}>
      <h5 className="mb-0">
        <button className="  collapsed w-100" data-toggle="collapse" data-target={`#collapse${i}`} aria-expanded="false" aria-controls="collapseTwo">
          <div className='d-flex justify-content-between'><span>{row.desease}</span><span>{row.duration}</span></div>
        </button>
      </h5>
    </div>
    <div id={`collapse${i}`} className="collapse" aria-labelledby={`heading${i}`} data-parent="#accordion">
      <div className="card-body" style={{maxHeight:"40vh",overflowY:"scroll"}}>
      <div className="container my-3">
                        
        </div>
        


      </div>
    </div>
  </div>
        </div>
       );
     })}   
    
         {/* <Accordian End> */}
        </div>
 
    </div>
    );
};

export default ProblemsComp;