import React, {useState,useEffect} from 'react';
import * as _ from 'lodash'
import {
  Form,
  Button,
  Spin,
  Modal,
  Table,
  Collapse,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import ShowToast from "../../../utils/show-toast";

import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { createRequestObjectFromForm } from '../../../utils/transformers/emergency/allergy-transformer';
import { AllergyForm } from './allergy-form';
import { saveAllergy, fetchAllergies, updateAllergy, deleteAllergy } from '../../../redux/actions/emergency';

const { Panel } = Collapse;

const Allergy = () => {

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allergyRecords, setAllergyRecords] = useState([]);
  const [isViewMode, setViewMode] = useState(false);

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(
    (state) => state.emergency.allergies
  );

  useEffect(() => {
    dispatch(fetchAllergies());
  }, []);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if(values.allergyForm[0].id){
        let data = {
          name: values.allergyForm[0].name,
          id: values.allergyForm[0].id,
          data:{
            ...values.allergyForm[0]
          }
        }
        await dispatch(updateAllergy(data))
      }
      else{
        const res = createRequestObjectFromForm(values);
        await dispatch(saveAllergy(res));
      }
      ShowToast("Success!", "success");
      setIsSubmitting(false);
      setVisible(false);
    } catch (error) {
      setIsSubmitting(false);
    }
    form.resetFields();
    await dispatch(fetchAllergies());
  };

  const handleCancel = () => {
    setVisible(false);
    setViewMode(false);
    form.resetFields();
  };

  const genExtra = (conditionData) => {
    const formatData = { allergyForm: [conditionData] };
    return (
      <EditOutlined
        onClick={() => {
          setVisible(true);
          form.setFieldsValue(formatData);
        }}
      />
    );
  };
  const deleteAction = (conditionData) => {
    const data = { ...conditionData };
    return (
      <DeleteOutlined
        onClick={() => {
          try {
            dispatch(deleteAllergy(data))
            ShowToast("Deleted!", "success");
            dispatch(fetchAllergies())
          } catch (error) {
            
          }
        }}
      />
    );
  };

  return ( 
    <Spin spinning={isLoading}>
      <div className="w-full" style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <div className="p-1 relative flex flex-col mx-auto">
          <div className="mb-2 md:self-end">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setVisible(!visible)}
              className="w-auto rounded-lg"
            >
              <span className="flex justify-between items-center">
                <PlusOutlined className="text-xl md:text-2xl md:mr-2" />
                <span className="hidden md:inline-block">
                  Add Allergy
                </span>
              </span>
            </Button>
          </div>
          <Row
            className="flex flex-1 w-full p-4 bg-gray-200"
            style={{ margin: "0" }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col className="gutter-row" span={6}>
              <p className="font-bold text-md mb-0">Description</p>
            </Col>
            <Col className="gutter-row" span={4}>
              <p className="font-bold text-md mb-0">Reaction</p>
            </Col>
            <Col className="gutter-row" span={4}>
              <p className="font-bold text-md mb-0">Duration</p>
            </Col>
            <Col className="gutter-row" span={4}>
              <p className="font-bold text-md mb-0">Edit</p>
            </Col>
            <Col className="gutter-row" span={4}>
              <p className="font-bold text-md mb-0">Delete</p>
            </Col>
          </Row>
          <div className="family-history">
          <Collapse defaultActiveKey={["0"]}>
            {data &&
              Object.keys(data.values).map((key, index) => {
                return (
                  <Panel
                    className="font-semibold"
                    header={key}
                    key={index}
                  >
                    {data.values[key].map((val, index) => {
                      return (
                        <Row
                          key={index}
                          style={{
                            borderBottom: "1px solid lightgray",
                            marginTop: "10px",
                          }}
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col className="gutter-row" span={6}>
                            <p>{val.description}</p>
                          </Col>
                          <Col className="gutter-row" span={4}>
                            <p>{val.reaction}</p>
                          </Col>
                          <Col className="gutter-row" span={4}>
                            <p>{val.duration}</p>
                          </Col>
                          <Col className="gutter-row" span={4}>
                            {genExtra(val)}
                          </Col>
                          <Col className="gutter-row" span={4}>
                            {deleteAction(val)}
                          </Col>
                        </Row>
                      );
                    })}
                  </Panel>
                );
              })}
          </Collapse>
          </div>
        </div>
        <Modal
          style={{ top: 10 }}
          visible={visible}
          title="Add Allergy"
          onCancel={handleCancel}
          footer={[]}
        >
          <div className=" overflow-y-auto pr-5" style={{ maxHeight: "75vh" }}>
            <Form
              labelAlign={"left"}
              labelCol={{ span: 12 }}
              form={form}
              className="w-full"
              name="allergyForm"
              onFinish={handleSubmit}
              hideRequiredMark={true}
              initialValues={{
                allergyForm: [
                  { name: "", description: "", reaction: "", duration: "", id: null },
                ],
              }}
            >
              <AllergyForm {...form}/>
              {!isViewMode && (
                <Form.Item>
                  <Button
                    loading={isSubmitting}
                    className="flex text-center justify-center"
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        </Modal>
      </div>
    </Spin>
   );
}
 
export default Allergy;