const moment = require("moment");

const createRequestObjectFromForm = (values) => ({
  valid: true,
  firstName: values.firstName,
  lastName: values.lastName,
  hospitalName: values.hospitalName,
  doctorName: values.doctorName,
  procedure: values.procedure,
  bodyPart: values.bodyPart,
  procedureDate: values.procedureDate,
  procedureTime: values.procedureTime,
  description: values.description,
  comments: values.otherNotes,
});

const createFormObjectFromRequest = (values) => ({
  firstName: values.firstName,
  lastName: values.lastName,
  hospitalName: values.hospitalName,
  doctorName: values.doctorName,
  otherNotes: values.comments,
  procedure: values.procedure,
  bodyPart: values.bodyPart,
  procedureDate: values.procedureDate
    ? moment.utc(new Date(values.procedureDate))
    : "",
  procedureTime: moment.utc(new Date(values.procedureTime)),
  description: values.description,
});

module.exports = {
  createFormObjectFromRequest,
  createRequestObjectFromForm,
};
