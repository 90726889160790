import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end
function Chart2(props) {
  let chart;
  useEffect(() => {

    chart = am4core.create("clinicalchartdiv2", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.data = props.dataPoints;
    chart.radius = am4core.percent(90);
    chart.innerRadius = am4core.percent(60);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";

    series.slices.template.cornerRadius = 5;
    series.slices.template.innerCornerRadius = 3;
    series.slices.template.draggable = true;
    series.labels.template.disabled = true;

    series.slices.template.inert = true;
    series.alignLabels = false;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    const label = series.createChild(am4core.Label);
    label.text = `${props.percentage === null ? 0 : props.percentage} %`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 24;
    label.fontWeight = "bold";
    label.fill = "#0D92D2";
    // chart.legend = new am4charts.Legend();
    // var hs = series.slices.template.states.getKey("hover");
    // hs.properties.scale = 1;
    // hs.properties.fillOpacity = 0.5;
    series.colors.list = [
      am4core.color("#AD3860"),
      am4core.color("#0D92D2"),
      am4core.color("#7C1237"),
    ];

}, []);

  return (<div className={props.mr? "flex flex-col justify-center items-center mr-3": "flex flex-col justify-center items-center"}>
        <p className="font-semibold text-md text-center chart-heading mb-5">Time In Range</p>
        <div id="clinicalchartdiv2" style={{ width: "200px", height: "200px" }}></div>
  </div>
  );
}
export default Chart2;
