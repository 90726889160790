import * as actions from "../../actionTypes";

const initialState = {
  isLoading: false,
  aidexAuthToken:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.AIDEX_LOGIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.AIDEX_LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.AIDEX_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.content.token,
      };
    case actions.AIDEX_USER_LOGGED_OUT:
      return {
        ...state,
        aidexAuthToken: null

      };

    default:
      return state;
  }
};
