import React, { useState, useEffect } from "react";
import FamilyHistoryConditions from "../../../utils/config/history/family-history-conditions";
import { Form, Input, Button, Select, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { fetchMedicalConditions } from "../../../redux/actions/history";
const { Option } = Select;

export const FamilyHistoryForm = ({getFieldValue}) => {

  const [conditions , setConditions] = useState([])
  useEffect(()=>{
    getAllMedicalConditions()
  },[])

  const getAllMedicalConditions = async() =>{
    const res = await fetchMedicalConditions();

    setConditions(res.data.data.conditions);
  }


  const fieldsList = () => {
    return (
      <Form.List name="familyHistory">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, "condition"]}
                    fieldKey={[field.fieldKey, "condition"]}
                    rules={[{ required: true, message: "Missing condition" }]}
                  >
                    <Select
                      placeholder="Select a condition"
                      showSearch
                      options={conditions.length>0?(conditions).map(
                        (key) => ({
                          name: key.name,
                          value: key.name,
                        })
                      ):null}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "description"]}
                    fieldKey={[field.fieldKey, "description"]}
                    rules={[{ required: true, message: "Missing description" }]}
                    label="Description"
                    className="w-full flex justify-center"
                  >
                    <Input.TextArea style={{ height: "20px" }} />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "relation"]}
                    fieldKey={[field.fieldKey, "relation"]}
                    rules={[{ required: true, message: "Missing relation" }]}
                    label="Family History Relation"
                    className="w-full flex justify-center"
                  >
                    <Select>
                      <Option value="father">Father</Option>
                      <Option value="mother">Mother</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "age"]}
                    fieldKey={[field.fieldKey, "age"]}
                    rules={[{ required: true, message: "Missing age" }]}
                    label="Age"
                    className="w-full flex justify-center"
                  >
                    <Input type={"number"} />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <div className="flex justify-end w-full">
                    <Button
                      className="flex text-center justify-center"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{display: 'flex'}}
                    >

                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ margin: "0 8px", color: '#ff4d4f' }}
                      />
                      Remove
                    </Button>
                    </div>
                  ) : null}
                  <Divider/>
                </div>
              ))}
              <Form.Item>
                <div className="flex flex-row w-full justify-center content-center ">
                  <Button
                    type="dashed"
                    className="flex text-center justify-center"
                    style={{ width: "100%", display: 'flex' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add field
                  </Button>
                </div>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    );
  };
  return (
    <div>
      {fieldsList()}
    </div>
  );
};
