import React, {useEffect, useState} from "react";
import { useDispatch} from "react-redux";

import AidexOverview from "./aidex-overview";
import AidexHistory from "./aidex-history";
import { Tabs } from "antd";
import AidexLogin from "../../../auth/aidex-login";
import { aidexLogin } from "../../../../redux/actions/aidex/aidexAuth";
import ShowToast from "../../../../utils/show-toast";
import AidexLogo from "../../../../assets/AidexLogo.svg";
import AidexLoginModal from "./aidex-login-modal";






function AidexComp(props) {
  const { TabPane } = Tabs;

  const [aidexToken, setAidexToken]=useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  useEffect(()=>{
    if(localStorage.getItem('AidexAuthToken') && aidexToken === false){
      setAidexToken(true);
      // // // // console.log("aidexToken", aidexToken);
    }
  },[aidexToken]);


  const handleSubmit = async (e) => {


    try {
      e.preventDefault();

      // setIsLoading(true);
      const result = await dispatch(aidexLogin({ email, password }));
      // // // // console.log("RESULT :", result);
      if(result.status=== 200){

        let ele = document.getElementsByTagName('body')[0];
        ele.classList.remove("modal-open");
        setAidexToken(true);
      // document.getElementsByClassName('close').click();

      }
      // setIsLoading(false);
    } catch (err) {

      ShowToast(err.response, "error");
      // setIsLoading(false);

      // if (err.response.data.message === "Please activate your account first!") {
      //
      //
      //   history.push("/auth/resendVerificationEmail");
      // }
    }
  };
  return (
    <div className="row mw-100 m-auto align-items-center justify-content-center h-100">
      <div className={aidexToken===true?"d-block col-12":"d-none col-12"}>
        <Tabs type="card">
          <TabPane tab="Overview" key="0">
            <AidexOverview/>
          </TabPane>
          <TabPane tab="History" key="1">
            <AidexHistory />
          </TabPane>
        </Tabs>
    </div>
    <div className={aidexToken===true?"d-none col-12":"d-block w-fit"}>
    <AidexLoginModal modal={aidexToken} onClick={setAidexToken}/>






    </div>
    </div>
  );
};


export default AidexComp;
