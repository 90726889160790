import React, { useState,useEffect } from 'react';
import HistoricalReadingsChart from '../../patient-dashboard/historical-readings';
import Chart from '../../patient-dashboard/micro-apps/BloodGlucose/Chart';
import Chart2 from '../Chart/chart2';
import BloodGlucoseChart from "../Chart/BloodGlucoseChart";
import Chart2b from '../../patient-dashboard/micro-apps/BloodGlucose/Chart2b';
import Chart3b from '../../patient-dashboard/micro-apps/BloodGlucose/Chart3b';
import Chart3c from '../../patient-dashboard/micro-apps/BloodGlucose/Chart3c';
import Chart3 from '../../patient-dashboard/micro-apps/BloodGlucose/Chart3';
import Chart4 from '../../patient-dashboard/micro-apps/BloodGlucose/Chart4';
import Chart5 from '../../patient-dashboard/micro-apps/BloodGlucose/Chart5';
import Chart6c from '../../patient-dashboard/micro-apps/BloodGlucose/Chart6c';
import Chart6b from '../../patient-dashboard/micro-apps/BloodGlucose/Chart6b';
import Chart6 from '../../patient-dashboard/micro-apps/BloodGlucose/Chart6';
import Chart7 from '../Chart/Chart7';
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";
import { useSelector ,useDispatch} from "react-redux";
import moment from "moment";
import _ from "lodash";
import BgRating from '../../patient-dashboard/micro-apps/BloodGlucose/BgRating';
import BgStats from '../../patient-dashboard/micro-apps/BloodGlucose/BgStats';
import TimeRangeBg from '../../patient-dashboard/micro-apps/BloodGlucose/TimeRangeBg';
import AverageBg from '../../patient-dashboard/micro-apps/BloodGlucose/AverageBg';
import HyposHyper from '../../patient-dashboard/micro-apps/BloodGlucose/HyposHyperBg';
import BpStats from '../../patient-dashboard/micro-apps/blood-pressure/bp-stats';
import BpReading from '../../patient-dashboard/micro-apps/blood-pressure/bp-readings';

const BloodGlucoseComp = (props) => {
  const { RangePicker } = DatePicker;
  const bg = useSelector((state) =>( state.doctor.bloodGlucose));
  const bp = useSelector((state) =>( state.doctor.bloodPressure));

  
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

 //BG Charts Hook
 const [tfTimeRange, setTfTimeRange] = useState([]);
 const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
 const [tfBgReadings, setTfBgReadings] = useState([]);
 const [tfAvgBgData, setTfAvgBgData] = useState([]);

 //BP Charts Hook
const [bpHistoricalReading,setBpHistoricalReading]=useState([])
const [tfBpReadings, setTfBpReadings] = useState([]);
 

const [days,setDays]=useState("")
const [defaultDays,setDefaultDays]=useState("30days")
const [dropMenu,setDropMenu]=useState(false);



 

      
//Handle Filters
const handleDaysFilter=(d)=>{
  setDays(d)
  setDefaultDays(d)
  props.handlingDays(d)
  props.handlingRange("","")
 setStartDate("")
  setEndDate("")
 }


//Historical reading
useEffect(() => {
  transformHistoricalReadings();
}, [bg.historicalReadings]);

const transformHistoricalReadings = () => {
  let transformedData = [];

  transformedData =  bg.historicalReadings && bg.historicalReadings.length > 0 && bg.historicalReadings
    .map((reading) => {
    
      return reading.data.map((item) => {
        if (
          new Date(startDate).getMonth() <= item.month &&
          new Date(endDate).getMonth() <= item.month
        ) {
          let date = new Date();
          date.setMonth(item.month - 1);
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
          return {
            date: date,
            reading: item.value["bloodGlucose"],
          };
        } else {
          let date = new Date();
          date.setMonth(item.month - 1);
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
          return {
            date: date,
            reading: item.value["bloodGlucose"],
          };
        }
      });
    })
    .flat();

  let myArray = _.sortBy( transformedData && transformedData.length> 0 && transformedData.flat(), function (dateObj) {
    return new Date(dateObj.date);
  });
  setTfHistoricalReadings(myArray);
};


// Bg reading
useEffect(()=>{
  transformBgReading();
},[bg])

const transformBgReading = () => {
  let tfBgReadings =bg.bgReadings && bg.bgReadings.map((item) => {
 
    var localDate = moment(item.time).utc()
    var date = localDate.format("DD-MM-YYYY ");
    var time = localDate.format("h:mm:ss A");
    item.takenTime=item.takenTime === 0 ? "Before meal" : item.takenTime === 1 ? "After Meal": item.takenTime === 2 ? "General": "QC";
    item.bgValue = item.value.bloodGlucose;
    item.bgValue = item.bgValue && item.bgValue.toFixed(1);
    item.time =date
    item.clock = time
    return item;
  });

  setTfBgReadings(tfBgReadings);
};

//Time Range
useEffect(() => {
  if (bg.timeRange) {
    transformTimeRange();
  }
}, [bg.timeRange]);
const transformTimeRange = () => {
  let labels = ["<3.9", "3.9-4.9", "5.0-8.0", "8.1-13.3", ">13.3"];
  let labelsMgdl = [
    "<70.24",
    "70.24-88.3",
    "90.0-144.1",
    "145.9-239.5",
    ">239.5",
  ];
  const keys = Object.keys(bg.timeRange[0]);
  const transformedData = keys.map((key) => {
    return {
      label:
        bg.preferences && bg.preferences.unit === 0
          ? labels[Number(key)]
          : labelsMgdl[Number(key)],
      value: bg.timeRange[0][key].value,
    };
  });

  setTfTimeRange(transformedData);
};

//AverageBg
useEffect(() => {
  transformAverageBgData();
}, [bg.averageBg]);
const transformAverageBgData = () => {
  let transformedData = [];
  transformedData =bg.averageBg && bg.averageBg.map((item) => {
    let m = moment().hour(item._id).format("hh A");
    return {
      date: m,
      value: item.avg,
    };
  });
  setTfAvgBgData(
    _.sortBy(transformedData, (dateObj) => new Date(dateObj.date))
  );
};

// Blood pressure Chart

// ---Historical reading---
useEffect(()=>{
  transformBpHistoricalReadings()
},[bp.historicalReadings])

const transformBpHistoricalReadings = () => {

  let transformedData = [];
  transformedData = bp.historicalReadings && bp.historicalReadings
    .map((reading) => {
    

      return reading.data.map((item) => {
        let date = new Date();
        date.setDate(reading._id);
        date.setHours(item.hour);
        date.setMinutes(item.minutes);
        return {
          date: date,
          reading: item.value["systolic"],
          reading2: item.value["diastolic"],
          reading3: item.value["heartRate"],
        };
      });
    })
    .flat();
  let myArray = _.sortBy(transformedData && transformedData.length> 0 && transformedData.flat(), function (dateObj) {
    return new Date(dateObj.date);
  });

  setBpHistoricalReading(myArray);
};

// ----Bp Reading----
useEffect(()=>{
  transformBpReading();
},[bp.bpReadings])

const transformBpReading = () => {
  
  let tfBpReadings = bp.bpReadings && bp.bpReadings.map((item) => {
    let localDate = moment(item.time).utc()
    let date = localDate.format("DD-MM-YYYY ");
    item.systolic = item.value.systolic;
    item.diastolic = item.value.diastolic;
    item.heartRate = item.value.heartRate;
    item.time = date
    return item;
  });
  setTfBpReadings(tfBpReadings);
};



//Handle Date Picker
const handleDateChange=(val)=>{
  if( val && val.length>0 ){
    setStartDate(
      val[1].format("YYYY-MM-DD").toString()
    );
    setEndDate(val[0].format("YYYY-MM-DD").toString());
    setToDate(val[1].format("YYYY-MM-DD").toString());
    setFromDate(val[0].format("YYYY-MM-DD").toString());
    props.handlingRange( val[0].format("YYYY-MM-DD").toString(),val[1].format("YYYY-MM-DD").toString())
    props.handlingDays("")
  }
}


    return (
        <div className=' shadow-lg bg-white rounded pb-2 overflow-auto' style={{maxHeight:"70vh"}}>
            <div className="d-flex justify-content-between">
                <div className="text-white font-weight-bold bg-primary font-size1 px-1">
                   {props.bg===true?"Blood Glucose":null} 
                   {props.cgm===true?"CGM":null} 
                   {props.inr===true?"INR":null} 
                   {props.bp===true?"Blood Pressure":null} 
                </div>

                <div className='row mr-3 pt-2'>
                    <button className={startDate==="" && days==="7days"?"bg-primary text-white rounded px-2 mr-2":" bg-light rounded px-2 mr-2"} onClick={()=>{handleDaysFilter("7days")}} >7 Days</button>
                    <button className={startDate==="" && days==="14days"?"bg-primary text-white rounded px-2 mr-2":" bg-light rounded px-2 mr-2"} onClick={()=>{handleDaysFilter("14days")}}>14 Days</button>
                    <button className={startDate==="" &&defaultDays==="30days"?"bg-primary text-white rounded px-2 mr-2":" bg-light rounded px-2 mr-2"} onClick={()=>{handleDaysFilter("30days")}}>30 Days</button>
                    <button className={startDate==="" && days==="90days"?"bg-primary text-white rounded px-2 mr-2":" bg-light rounded px-2 mr-2"} onClick={()=>{handleDaysFilter("90days")}}>90 Days</button>
                    <button className={startDate!==""?"bg-primary text-white rounded px-2 dropdown":"bg-light rounded px-2 dropdown"} onClick={()=>{setDropMenu(!dropMenu)}}><i className="far fa-edit"></i></button>
                    <div className={dropMenu?"dropdown-contenting d-block":"dropdown-contenting d-none"} >
                    <RangePicker
                          defaultValue={
                            startDate && [moment(startDate), moment(endDate)]
                          }
                          onChange={(val) => {
                            handleDateChange(val)}}
                        />
                    </div>
                </div>
            </div>
           
            <div className='mt-4'>{
                props.bg===true?
                <Spin spinning={props.loading}>
                <div className='container-fluid'>
                <div className="border-top border-bottom py-5 my-3" style={{height:"60vh"}}>
                  <Chart  data={tfHistoricalReadings} />
                  </div>

                <div className="row justify-content-around py-5 my-3 border-top border-bottom" >
                  
                    <BgRating
                    margin={true}
                    timePercentage={
                      bg.timeAndGlucose &&
                      parseInt(bg.timeAndGlucose.inRangePercentage)
                    }
                    glucosePercentage={
                      bg.timeAndGlucose &&
                      parseInt(
                        bg.timeAndGlucose.highEventsPercentage +
                          bg.timeAndGlucose.lowEventsPercentage
                      )
                    }
                    timeDataPoints={[
                      {
                        label: "In-Range",
                        value: parseInt(
                          bg.timeAndGlucose && bg.timeAndGlucose.inRangePercentage
                        ),
                      },
                      {
                        label: "Out-Range",
                        value:
                          100 -
                          parseInt(bg.timeAndGlucose && bg.timeAndGlucose.inRangePercentage),
                      },
                    ]}
                    glucoseDataPoints={[
                      {
                        label: "High-events",
                        value: parseInt(
                          bg.timeAndGlucose &&  bg.timeAndGlucose.highEventsPercentage
                        ),
                      },
                      {
                        label: "Low-events",
                        value: parseInt(
                          bg.timeAndGlucose && bg.timeAndGlucose.lowEventsPercentage
                        ),
                      },
                      {
                        label: "In-Range",
                        value: parseInt(
                          bg.timeAndGlucose && bg.timeAndGlucose.inRangePercentage
                        ),
                      },
                    ]}
                    />
                </div>

                <div className="row justify-content-around py-5 my-3 border-top border-bottom" style={{height:"50vh"}}>
    <div className="col-3">
        <div>
            <Chart3b unit={bg.preferences && bg.preferences.unit?bg.preferences.unit:0}
             lowest={bg.bgStats ? bg.bgStats.lowest:null} />
            <p className="text-center  mt-2">
                Lowest Bg <br />
                Reading
            </p>
        </div>
    </div>

    <div className="col-3">
        <div>
            <Chart3 unit={bg.preferences && bg.preferences.unit?bg.preferences.unit:0}
             avg={bg.bgStats ? bg.bgStats.average:null} />
            <p className="text-center mt-2">
                Average BG <br />
                Reading
            </p>
        </div>
    </div>
    <div className="col-3 bg-primary rounded">
        <Chart3c unit={bg.preferences && bg.preferences.unit?bg.preferences.unit:0}
             highest={bg.bgStats ? bg.bgStats.highest:null} />
            
        <p className="font-weight-bold text-center text-white text-high mt-2">High</p>
    </div>
            
</div>
             


                <div className="row justify-content-between py-5 my-3 border-top border-bottom" style={{height:"auto"}}>
                 <div className='col-12'>
                 <TimeRangeBg dataPoints={tfTimeRange}/>
                     </div>
                </div>

                <div className="row justify-content-between py-5 my-3 border-top border-bottom" style={{height:"auto"}}>
                 <div className='col-12'>
                  <AverageBg dataPoints={tfAvgBgData}/>
                     </div>
                </div>

                <div className="row justify-content-between py-5 my-3 border-top border-bottom" style={{height:"auto"}}>
                 <div className="col-12">
                  <HyposHyper 
                 unit={bg.preferences && bg.preferences.unit?bg.preferences.unit:0}
                {...bg.avgPreAndAfterMeal && bg.avgPreAndAfterMeal}
                {...bg.avgPreAndAfterMeal && bg.hyposAndHypers}
                />
                </div>
                </div>
                
                <div className="row justify-content-between py-5 my-3 border-top border-bottom" style={{height:"50vh",overflowY:"scroll"}}>
                 <div className='col-12'>
                   <Chart7 dataSource={tfBgReadings}/>
                 </div>    
                 </div>
                </div>
            
                </Spin>
                :null}
                {props.bp===true?
                <Spin spinning={props.loading}>
                <div>

              <HistoricalReadingsChart     
              color1="#667eea"
             color2="#7f9cf5"
             color3="#4c51bf"
             name = "Systolic"
             name2 = "Diastolic"
             name3 = "Heart Rate"
             dataPoints={bpHistoricalReading}/>
             <div className="mt-5">
               <BpStats  {...bp.bpStats && bp.bpStats}/>
               </div>
               <div className="mt-5">
               <BpReading  dataSource={tfBpReadings}/>
                 </div>
             </div>
             </Spin>
             :null
            }
           
            
             
            </div>
        </div>
    );
};

export default BloodGlucoseComp;