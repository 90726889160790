import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect,useHistory } from "react-router-dom";
import SharedLayout from "../../components/common/SharedLayout";
import {useLocation} from "react-router-dom"
import {saveShareToken, saveUserId} from "../../redux/actions/doctor";
import {logout} from "../../redux/actions/auth";

import { encrypt } from "../encryption";

class AuthenticatedRoute extends Component {
  constructor(props) {
    super(props);
    // console.log("Here before inMount", window.location.pathname);  // Properly placed console.log statement
    if((window.location.pathname === "/auth/verifyUpdateEmail" ||  window.location.pathname === "/auth/updateEmail") && !!localStorage.getItem("grxAuthToken") === false){
// console.log("HERE for test");

      let token = new URLSearchParams(window.location.search).get('token');
      if(token !== "" && token !== null){
        localStorage.setItem("pathname", window.location.pathname);

      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("pathname");
      localStorage.removeItem("token");

    }

    }
  }


  redirect=()=>{
    return(
      <Redirect to="/auth/login" />
    )

  }
  componentDidMount() {
    // console.log("Here inMount", window.location.pathname);

    // if (localStorage.getItem("grxAuthToken") === null) {
    //
    //   this.props.dispatch(logout());
    //
    // }
    if(!this.props.isAuthenticated || localStorage.getItem("grxAuthToken") === null){
      // console.log("Here", window.location.pathname);

      this.redirect()
    }
    if(localStorage.getItem("pathname")==="/auth/login"){
    localStorage.removeItem("pathname");
  }
    if(localStorage.getItem("pathname")===window.location.pathname){
    localStorage.removeItem("pathname");
  }
  }
  // componentWillReceiveProps(nextProps, nextContext) {
  //   if (localStorage.getItem("grxAuthToken") === null) {
  //
  //     this.props.dispatch(logout());
  //
  //   }
  // }
  render() {
//console.clear();

   const saveToken=()=>{

      var url = window.location;
      if(window.location.pathname !== "/auth/verifyUpdateEmail" &&  window.location.pathname !== "/auth/updateEmail" && window.location.pathname !== "/user/deleteAccount"){
      var access_token = new URLSearchParams(url.search).get('token');
      var token=access_token && access_token.slice(0,228)
      var id=access_token && access_token.slice(232, access_token && access_token.length)
      id && localStorage.setItem(encrypt("UserId"),encrypt(id.toString()))
      token && localStorage.setItem(encrypt("pendingInviteAcceptToken"),encrypt(token.toString()))

      this.props.dispatch(saveShareToken(token));
      this.props.dispatch(saveUserId(id));
    }
    if((window.location.pathname === "/auth/verifyUpdateEmail" ||  window.location.pathname === "/auth/updateEmail") && !!localStorage.getItem("grxAuthToken")){
      if((!!localStorage.getItem("token")) === false){

      let token = new URLSearchParams(url.search).get('token');
      if(token !== "" && token !== null){
        localStorage.setItem("pathname", window.location.pathname);

      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("pathname");
      localStorage.removeItem("token");

    }
    }else {

      localStorage.removeItem("token");
    }

    }
    if(window.location.pathname === "/user/deleteAccount" && !!localStorage.getItem("grxAuthToken") === false){


      let token = new URLSearchParams(url.search).get('token');
      if(token !== "" && token !== null){
        localStorage.setItem("pathname", window.location.pathname);

      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("pathname");
      localStorage.removeItem("token");

    }

    }

      return(
        <Redirect to="/auth/login" />
      )
     }

    const { isAuthenticated, component: Component, ...rest } = this.props;

    if (localStorage.getItem("grxAuthToken") === null || localStorage.getItem("grxAuthToken")==="") {
      this.props.dispatch(logout(false));

    }
    return (
      <Route
        {...rest}
        render={(props) =>

          isAuthenticated && !!localStorage.getItem("grxAuthToken")  && window.location.pathname !== "/auth/verifyUpdateEmail" &&  window.location.pathname !== "/auth/updateEmail"? (
            <SharedLayout>
              <Component {...props} />
            </SharedLayout>
          ) : isAuthenticated === false && !!localStorage.getItem("grxAuthToken") && window.location.pathname !== "/auth/verifyUpdateEmail" &&  window.location.pathname !== "/auth/updateEmail"?
          <SharedLayout>
            <Component {...props} />
          </SharedLayout>:  (isAuthenticated === true ||   isAuthenticated === false) && !!localStorage.getItem("grxAuthToken") && (window.location.pathname === "/auth/verifyUpdateEmail" ||  window.location.pathname === "/auth/updateEmail")?(<Component {...props} />):(
            saveToken()

          )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  const { token } = state.auth;
  return {
    isAuthenticated: !!token,

  };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
