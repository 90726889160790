import * as actions from "../actionTypes/medication";
import { API_BASE_URL, fetchMedicationURL } from "../../utils/urls";
import http from "../../utils/http-service";
import { loginSuccess } from "./auth";
import {decrypt, encrypt} from "../../utils/encryption"
import {logout} from "./auth";

const fetchMedicationStart = () => {
  return { type: actions.FETCH_MEDICATION_START };
};

const fetchMedicationFailed = () => {
  return { type: actions.FETCH_MEDICATION_FAILED };
};

const fetchMedicationSuccess = (payload) => {
  return { type: actions.FETCH_MEDICATION_SUCCESS, payload };
};

export const fetchMedication = (data) => async (dispatch) => {
  try {
    dispatch(fetchMedicationStart());

    const token = !!localStorage.getItem("grxAuthToken");
    if (!token) {
      dispatch(fetchMedicationFailed());
      return;
    }
     let {page,record}=data

     page=JSON.stringify(page)
    page=encrypt(page);
    record=JSON.stringify(record)
    record=encrypt(record);


    const result = await http.get(`${API_BASE_URL}${fetchMedicationURL}?page=${page}&records=${record}`, {});

    if (result.status === 200) {



      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

        dispatch(loginSuccess({ token: localStorage.getItem("grxAuthToken") }));

         dispatch(fetchMedicationSuccess({ medication: data }));

    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchMedicationFailed());
    return false;
  }
};
