import React, { useState,useEffect } from "react";
import { LogoComponent as Logo } from "../../assets";
import { default as Titles } from "../../utils/document-titles";
import { Spin, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { BackgroundImage } from "../../assets";
import { Link, useHistory } from "react-router-dom";
import { resetPassword } from "../../redux/actions/auth";
import GlucoRxLogo from "../../assets/glucorxwhite.svg";
import ShowToast from "../../utils/show-toast";
// import ShowToast from "../../utils/show-toast";

export default function Login() {
  useEffect(()=>{
    if(localStorage.getItem("grxAuthToken")) {
      localStorage.removeItem("grxAuthToken");

    }
  })
  document.title = Titles.forgotPassword;
  const token = new URLSearchParams(window.location.search).get("token");
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [matchPassword,setMatchPassword]=useState( null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const dispatch = useDispatch();

  const comparePassword = () => {
    if (password === cnfPassword) {
      setMatchPassword(true)

    } else {
      setMatchPassword(false)
    }
  }
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password===cnfPassword){

    let re;
          if(password != "" ) {
            if(password.length < 8 && password===cnfPassword) {
              ShowToast("Password must contain at least Eight characters!","error");

              return false;
            }

            re = /[0-9]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one number (0-9)!","error");

              return false;
            }
            re = /[a-z]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one lowercase letter (a-z)!","error");

              return false;
            }
            re = /[A-Z]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one uppercase letter (A-Z)!","error");

              return false;
            }
            re=/[~!@#$%^&*_\-+=?]/;
            if(!re.test(password)) {
              ShowToast("Password must contain at least one special character (#?!@$%^&*-_)!","error");

              return false;
            }
            if (password !== cnfPassword){
              ShowToast("Password is not matched","error");
              return false;
            }
          }
    try {
      setIsLoading(true);

      if (!token) {
        setIsLoading(false);
        return;
      }
      const result=await dispatch(resetPassword({ password,token }));

      history.push("/auth/login");

      ShowToast(result.data.message, "success");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
    return true;
  }
  else{
    ShowToast("Password is not matched", "error");
  }
  };

  return (
    <div className='container-fluid d-flex flex-column forget-background ' >

    <div className="row justify-center align-item-center my-auto" >
        <div className="col-md-7 col-11 forget-field-box align-items-center py-5">
           <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>
           <p className=' text-white text-center forget-text-font mt-3'>Type a new password </p>
           <form onSubmit={handleSubmit} >
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
                <div className="pass-wrapper">
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          value={password}
              onChange={(e) => setPassword(e.target.value)}
                          className="registration-form-control"
                          id="formGroupExampleInput"
                          required
                        />
                        {passwordShown==true?<i className="fa fa-eye" onClick={togglePasswordVisiblity}></i>
                         :<i className="fas fa-eye-slash" onClick={togglePasswordVisiblity}></i>}
                     </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
                <div className="pass-wrapper">
                    <input
                     type={confirmPasswordShown ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={cnfPassword}
                      onBlur={comparePassword}

          onChange={(e) => setCnfPassword(e.target.value)}
                      className={`forget-form-control ${password !==cnfPassword ? 'error-color-input' : null}`}
                      id="formGroupExampleInput"
                      required
                    />
                    {confirmPasswordShown==true?<i className="fa fa-eye" onClick={toggleConfirmPasswordVisiblity}></i>
                         :<i className="fas fa-eye-slash" onClick={toggleConfirmPasswordVisiblity}></i>}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
            <Spin spinning={isLoading}>
                  <button
                        type="submit"
                        className="forget-submit-btn btn-primary "
                      >
                       Reset Password
                    </button>
                    </Spin>
                </div>
            </div>
            </form>
            <div className="mt-3 text-white">
                <p className="text-center forget-text-font">
                <Link
              to={"/auth/login"}>
              Back to Sign In!
            </Link>
                </p>
            </div>
        </div>


        </div>


</div>
  );
}
