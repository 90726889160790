import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux"
import $ from "jquery"
import {
    fetchSurgicalHistory,
    saveSurgicalHistory,
    updateSurgicalHistory,
    deleteSurgicalHistory,
} from "../../../redux/actions/history";
// import updateDate from "../../../utils/updateDate";
import ShowToast from "../../../utils/show-toast";
import moment from "moment";
import updateDate from "../../../utils/updateDate";

class SurgicalHistoryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: "false",
            firstName: "",
            lastName: "",
            procedure: "",
            bodyPart: "",
            description: "",
            procedureDate: "",
            procedureTime: "",
            doctorName: "",
            hospitalName: "",
            comments: "",
            data: [],
            view: false,
            editMode: false,
            _id: '',
            deleteModal:false,
            surgicalModal:false,
            toDelete:""
        };
    }

    componentDidMount() {
        if (this.props.history.surgical && this.props.surgical.data.length > 0) {

        } else {

            this.props.dispatch(fetchSurgicalHistory())
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.surgical && props.surgical.data !== null) {

            let surgical = props.surgical.data;
            state.data = surgical;

        }
    }


    saveSurHistory = async (event) => {
        event.preventDefault();

        this.setState({modal: false});
        await this.props.dispatch(saveSurgicalHistory({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            procedure: this.state.procedure,
            bodyPart: this.state.bodyPart,
            description: this.state.description,
            procedureDate: this.state.procedureDate.toString(),
            procedureTime: this.state.procedureDate.toString(),
            doctorName: this.state.doctorName,
            hospitalName: this.state.hospitalName,
            comments: this.state.comments,
        }))
        ShowToast("Success!", "success");

        this.setState({modal: false})
        $('form').trigger("reset");

         this.toUpdateState()
         this.setState({
            modal: "false",
            firstName: "",
            lastName: "",
            procedure: "",
            bodyPart: "",
            description: "",
            procedureDate: "",
            procedureTime: "",
            doctorName: "",
            hospitalName: "",
            comments: "",
            editMode: false,
            view: false,
            surgicalModal:false
        })
        await this.props.dispatch(fetchSurgicalHistory());
    }

    toggle = () =>
        this.setState({
            modal: !this.state.modal,
        });
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    // updateTime = (props, tag, isLoading) => {
    //
    //
    //     if (isLoading && props === null) {
    //
    //     } else {
    //         let time = moment(props).format("hh:mm");
    //
    //         setTimeout(function () {
    //             document.getElementById(tag).value = time;
    //         }, 2000);
    //     }
    // }
    updateSurgicalDataStart = (index) => {
        if (this.state.data && this.state.data.length > 0) {
            let surgical = this.state.data[index];

            this.setState({
                modal: true,
                _id: surgical._id,
                firstName: surgical.firstName,
                lastName: surgical.lastName,
                procedure: surgical.procedure,
                bodyPart: surgical.bodyPart,
                description: surgical.description,
                procedureDate: new Date(surgical.procedureDate),
                procedureTime: new Date(surgical.procedureDate),
                doctorName: surgical.doctorName,
                hospitalName: surgical.hospitalName,
                comments: surgical.comments,
            })
            updateDate(surgical.procedureDate, "procedureDate", false);
            // this.updateTime(surgical.procedureDate, "procedureTime", false);
        }
    }
    updateFamilyRecord = async (event) => {
        event.preventDefault();


        this.setState({modal: false});
        await this.props.dispatch(updateSurgicalHistory({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            procedure: this.state.procedure,
            bodyPart: this.state.bodyPart,
            description: this.state.description,
            procedureDate: this.state.procedureDate.toString(),
            procedureTime: this.state.procedureDate.toString(),
            doctorName: this.state.doctorName,
            hospitalName: this.state.hospitalName,
            comments: this.state.comments,
        }, this.state._id))
        ShowToast("Success!", "success");
        await this.props.dispatch(fetchSurgicalHistory());
        $('form').trigger("reset");
        this.setState({

            firstName: null,
            lastName: null,
            procedure: null,
            bodyPart: null,
            description: null,
            procedureDate: null,
            procedureTime: null,
            doctorName: null,
            hospitalName: null,
            comments: null,
            editMode: false,
            view: false,
            surgicalModal:false
        })
    }
    addNewSurgicalHistory = () => {

        this.setState({
            modal: "false",
            firstName: "",
            lastName: "",
            procedure: "",
            bodyPart: "",
            description: "",
            procedureDate: "",
            procedureTime: "",
            doctorName: "",
            hospitalName: "",
            comments: "",
            editMode: false,
            view: false,
            surgicalModal:true
        })
        updateDate(this.props.procedureDate, "procedureDate", false);
        // this.updateTime(this.props.procedureDate, "procedureTime", false);
    }
    surgicalHistoryUpdate = async () => {
        this.setState({modal: false});
        await this.props.dispatch(updateSurgicalHistory({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            procedure: this.state.procedure,
            bodyPart: this.state.bodyPart,
            description: this.state.description,
            procedureDate: this.state.procedureDate.toString(),
            procedureTime: this.state.procedureDate.toString(),
            doctorName: this.state.doctorName,
            hospitalName: this.state.hospitalName,
            comments: this.state.comments,
        }, this.state._id))
        ShowToast("Success!", "success");
        this.setState({modal: false})
        await this.props.dispatch(fetchSurgicalHistory());
    }
    deleteSurgicalHistoryRecord = async (_id) => {

        await this.props.dispatch(deleteSurgicalHistory(_id))
        ShowToast("Success!", "success");
        this.setState({deleteModal:false});
        await this.props.dispatch(fetchSurgicalHistory());
    }

    toUpdateState = () => {

        this.setState({
            modal: "false",
            firstName: "",
            lastName: "",
            procedure: "",
            bodyPart: "",
            description: "",
            procedureDate: "",
            procedureTime: "",
            doctorName: "",
            hospitalName: "",
            comments: "",
            data: [],
            view: false,
            editMode: false,
            _id: '',
            deleteModal:false,
            surgicalModal:false
        });

    }
    deleteModal=(id)=>{
        this.setState({deleteModal:true,toDelete :id});
    }
    closeDeleteModal=()=>{
        this.setState({deleteModal:false});
    }

    render() {
        const {data} = this.state;


        return (
            <>
                <div className="text-right">
                    <button
                        type="button"
                        className="add-info-btn"
                        // data-toggle="modal"
                        // data-target="#surgicalModal"
                        onClick={this.addNewSurgicalHistory}
                    >
            <span className="">
              <i className="fas fa-plus mr-2"></i>
              <span className="p-2">Add Surgical History</span>
            </span>
                    </button>
                </div>

                <div
                    className={`modal big_modal_dialog ${
                        this.state.surgicalModal? "d-block modal-fading" : " d-none"
                    }`}
                    tabIndex="-1"
                    data-backdrop="static"
                    data-keyboard="false"
                    // id="surgicalModal"
                    role="dialog"
                    // aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">

                                    {this.state.editMode ? "Update Surgical History" : "Add Surgical History"}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.toUpdateState}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form autoComplete="off" onSubmit={this.state.editMode ? this.updateFamilyRecord : this.saveSurHistory}
                                  id="sur">
                                <div className="modal-body">
                                    <div className="container-fluid">

                                        {/* <div>Form Start</div> */}
                                        <div>

                                            {/* <div>Row Start</div> */}
                                            <div className='row justify-content-center align-items-center'>

                                                <div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>First Name</h6>
                                                    </div>
                                                    <div className="form-group">

                                                        <input
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.firstName}
                                                            onChange={this.handleChange}
                                                            required

                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Last Name</h6>
                                                    </div>
                                                    <div className="form-group">

                                                        <input
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.lastName}
                                                            onChange={this.handleChange}
                                                            required

                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>Row End</div> */}

                                            {/* <div>Row Start</div> */}
                                            <div className='row justify-content-center align-items-center'>

                                                <div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Hospital
                                                        Name</h6></div>
                                                    <div className="form-group">

                                                        <input
                                                            name="hospitalName"
                                                            placeholder="Hospital Name"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.hospitalName}
                                                            onChange={this.handleChange}
                                                            required


                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Attending Doctor
                                                        Name</h6></div>
                                                    <div className="form-group">

                                                        <input
                                                            name="doctorName"
                                                            placeholder="Attending Doctor Name"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.doctorName}
                                                            onChange={this.handleChange}
                                                            required
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>Row End</div> */}

                                            {/* <div>Row Start</div> */}
                                            <div className='row justify-content-center align-items-center'>

                                                <div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Description</h6>
                                                    </div>
                                                    <div className="form-group">

                                                        <input
                                                            name="description"
                                                            placeholder="Description"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.description}
                                                            onChange={this.handleChange}
                                                            required


                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Procedure
                                                        Name</h6></div>
                                                    <div className="form-group">

                                                        <input
                                                            name="procedure"
                                                            placeholder="Procedure name"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.procedure}
                                                            onChange={this.handleChange}
                                                            required
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>Row End</div> */}

                                            {/* <div>Row Start</div> */}
                                            <div className='row justify-content-center align-items-center'>

                                                <div className="col-12">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Procedure
                                                        Date</h6></div>
                                                    <div className="form-group">

                                                        <input
                                                            type="date"
                                                            id="procedureDate"
                                                            name="procedureDate"
                                                            className="datePicker"
                                                            defaultValue={this.state.procedureTime !== "" ?
                                                                moment(this.state.procedureTime).format("yyyy/mm/dd") : null}
                                                            onChange={this.handleChange}
                                                            required
                                                        />

                                                    </div>
                                                </div>

                                                {/*<div className="col-md-6 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Procedure
                                                        Time</h6></div>
                                                    <div className="form-group">

                                                        <input
                                                            type="time"
                                                            id="procedureTime"
                                                            name="procedureTime"
                                                            className="datePicker"
                                                            onChange={this.handleChange}
                                                            required
                                                            // defaultValue={this.state.procedureTime}
                                                        />

                                                    </div>
                                                </div>*/}
                                            </div>
                                            {/* <div>Row End</div> */}

                                            {/* <div>Row Start</div> */}
                                            <div className='row justify-content-center align-items-center'>

                                                <div className="col-md-12 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Body Part</h6>
                                                    </div>
                                                    <div className="form-group">

                                                        <input
                                                            name="bodyPart"
                                                            placeholder="Part of Body"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.bodyPart}
                                                            onChange={this.handleChange}
                                                            required

                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                            {/* <div>Row End</div> */}

                                            {/* <div>Row Start</div> */}
                                            <div className='row justify-content-center align-items-center'>

                                                <div className="col-md-12 col-10">
                                                    <div className='d-block mb-1 font-weight-bold'><h6>Other Notes</h6>
                                                    </div>
                                                    <div className="form-group">

                                                        <input
                                                            name="comments"
                                                            placeholder="Other Notes"
                                                            className="form-control input-md msg new-msg"
                                                            type="text"
                                                            defaultValue={this.state.comments}
                                                            onChange={this.handleChange}
                                                            required

                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                            {/* <div>Row End</div> */}

                                        </div>
                                        {/* <div>Form Ends</div> */}

                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {this.state.view === false ?
                                        <button
                                            type="submit"
                                            className="modal-save-btn"
                                            // data-toggle="modal"
                                            // data-target="#surgicalModal"
                                            // onClick={this.state.editMode? this.updateFamilyRecord: this.saveSurHistory}
                                        > {this.state.editMode ?
                                            "Update"
                                            : "Save"}
                                        </button>
                                        : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div>table</div> */}

                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-success">
                                <table className="table table-hover" id="task-table">
                                    <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Hospital Name</th>
                                        <th>Procedure Name</th>
                                        <th>Part of Body</th>
                                        <th>Procedure Date</th>
                                        <th>Action</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data!==undefined && data !== null && data.length > 0 && data.map((row, index) => (
                                      (row.firstName && row.hospitalName?
                                        <tr key={index + 0}>
                                            <td>{row.firstName}</td>
                                            <td>{row.hospitalName}</td>
                                            <td>{row.procedure}</td>
                                            <td>{row.bodyPart}</td>
                                            <td>{moment(row.procedureDate).format("D MMM YYYY")}</td>
                                            <td>
                                                {/*                <button*/}
                                                {/*                    type="button"*/}
                                                {/*                    className="add-info-btn"*/}
                                                {/*                    data-toggle="modal"*/}
                                                {/*                    data-target="#surgicalModal"*/}
                                                {/*                    onClick={(event)=>{this.updateSurgicalDataStart(`${index}`)*/}
                                                {/*                   this.setState({view:true})*/}
                                                {/*                  }}*/}
                                                {/*                >*/}
                                                {/*<span className="">*/}
                                                {/*  <i className="fas fa-plus mr-2"></i>*/}
                                                {/*  <span className="p-2">View</span>*/}
                                                {/*</span>*/}
                                                {/*                </button>*/}
                                                <button
                                                    type="button"
                                                    className=""

                                                    onClick={(event) => {
                                                        this.updateSurgicalDataStart(`${index}`)
                                                        this.setState({editMode: true})
                                                        this.setState({view: false})
                                                        this.setState({surgicalModal:true})
                                                    }}
                                                >
                              <span className="">
                              <i className="fas fa-edit text-primary"></i>
                              </span>
                                                </button>
                                                {/*<i className="far fa-edit" onClick={(event)=>{this.updateSurgicalData(`${index}`)}}>View</i>*/}
                                                {/*<span*/}
                                                {/*    onClick={this.updateSurgicalData(`${index}`)}*/}
                                                {/*>*/}
                                                {/*  View*/}
                                                {/*</span>*/}

                                                <button
                                                    type="button"
                                                    className="ml-4"

                                                    onClick={()=>this.deleteModal(`${row._id}`)}
                                                >
                              <span className="">
                              <i className="fas fa-trash-alt text-danger"></i>
                              </span>
                                                </button>

                                            </td>
                                        </tr>:null)))}
                                    </tbody>
                                </table>
                                <div   className={`modal  ${
                                                this.state.deleteModal? "d-block fading" : " d-none"
                                            }`} >
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                        <div className="modal-header flex-column">
                                            <div className="icon-box">
                                            <i className="fas fa-trash-alt text-danger"></i>
                                            </div>
                                            <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                            <button type="button" className="close" onClick={this.closeDeleteModal} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Do you really want to delete these records? This process cannot be undone.</p>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                            <button type="button" className="btn btn-secondary" onClick={this.closeDeleteModal}>Cancel</button>
                                            <button type="button" className="btn btn-danger"  onClick={(event) => {
                                        this.deleteSurgicalHistoryRecord(this.state.toDelete)
                                    }}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>table</div> */}
            </>
        );
    }
}

function mapStateToProps(state) {

    return {
        surgical: state.history.surgicalHistory,
    }
}

export default withRouter(connect(mapStateToProps)(SurgicalHistoryNew));
