import * as actions from "../actionTypes/medication";

const initialState = {
  medicationData: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_MEDICATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_MEDICATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.FETCH_MEDICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        medicationData: action.payload.medication,
      };
    default:
      return state;
  }
};
