import React from 'react';

const NotesComp = () => {
    return (
        <div className=' shadow-lg bg-white rounded mt-3 pb-2'>
        <div className="d-flex justify-content-between">
            <div className="text-white font-weight-bold bg-primary font-size1 px-1">
               Notes
            </div>
        </div>
        <div className='container-fluid mt-4'>
      
     
         <div className="row mt-1 align-items-center">
         <div className="col-2 text-black text-center font-weight-bold">
                 Reason
            </div>

             <div className="col-10">
             <div className="bg-light text-secondary font-weight-bold py-2 pl-2 rounded">Sinus Problem, Headche, Fever</div>
             </div>
             
         </div>
         <div className="row mt-1 align-items-center">
         <div className="col-2 text-black text-center font-weight-bold">
                 Evaluation
            </div>

             <div className="col-10">
             <div className="bg-light text-secondary font-weight-bold py-2 pl-2 rounded">Sinus Problem, Headche, Fever</div>
             </div>
             
         </div>
        
        </div>
       
    </div>
    );
};

export default NotesComp;