import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default function UsersAverageAgeChart(props) {
  let chart;
  useEffect(() => {
    // set chart container
    chart = am4core.create("UsersAverageAgeChartDiv", am4charts.XYChart);

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "averageAge";
    series.columns.template.fill = am4core.color("#2980b9");
    series.sequencedInterpolation = true;
    series.tooltipText = "{valueY}";
    series.defaultState.transitionDuration = 1000;
    series.name = "Average Age";

    chart.data = props.dataPoints;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.scrollbarX = new am4core.Scrollbar();

    // Add legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, chart);

  return (
    <div className="my-3 md:my-0  md:ml-2 w-full bg-white rounded-lg shadow-lg p-4 flex flex-col">
      <p className="font-bold text-xl">Age</p>
      <div
        style={{
          minHeight: "16.75rem",
          maxHeight: "16.75rem",
          //   minWidth: "18.75rem",
        }}
        className="w-full h-full"
        id={"UsersAverageAgeChartDiv"}
      ></div>
    </div>
  );
}
