import { Spin } from 'antd';
import React,{useState} from 'react';
import { useDispatch } from 'react-redux';
import { deleteSharedPatientFromList } from '../../../../redux/actions/external-doctor/inviteList';

const CancelModal = ({rowId,handleUpdating,tableUpdate}) => {
	const dispatch =useDispatch();
	const [loading, setLoading] = useState(false);

	const handleDeleteRecord=async()=>{
       setLoading(true)
	   dispatch(deleteSharedPatientFromList(rowId))
		handleUpdating(!tableUpdate)
		setLoading(false)
		document.getElementById("btn-close").click();
	}
    return (
        <div>
            <div id="cancelModal" className="modal fade fading">
	<div className="modal-dialog modal-dialog-centered modal-confirm">
		<div className="modal-content">
			<div className="modal-header flex-column">
				<div className="icon-box">
                <i className="fas fa-2x fa-times"></i>
				</div>
				<h4 className="modal-title w-100">Are you sure?</h4>
                <button type="button" id="btn-close" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div className="modal-body">
				<p>Do you really want to Cancel the record? This process cannot be undone.</p>
			</div>
			<div className="modal-footer justify-content-center">
				{/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button> */}
				<Spin spinning={loading}>
				<button type="button" className="btn btn-danger" onClick={handleDeleteRecord}>Ok</button>
				</Spin>

			</div>
		</div>
	</div>
</div>
        </div>
    );
};

export default CancelModal;
