import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getUser, suspendUser, removeUser, addDoctor, removeDoctor } from "../../../redux/actions/admin/admin";

import { useHistory } from "react-router-dom";
import {encrypt,decrypt} from "../../../utils/encryption";
import UserDetails from "./userstatchart.js";
import countryList from './CountriesList';

import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const UserManagement = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [users, setUsers] = useState([]);
    const [role, setRole] = useState("");
    const [patientFilter, setPatientFilter] = useState(false);
    const [adminFilter, setAdminFilter] = useState(false);
    const [doctorFilter, setDoctorFilter] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [susModal, setSusModal] = useState(false);
    const [toSuspend, setToSuspend] = useState("");
    const [addModal, setAddModal] = useState(false);
    const [instance, setInstance] = useState("");
    const [typedEmail, setTypedEmail] = useState("");




// Add Doctor
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState();
    const [email, setEmail] = useState("");
    const [docRole, setDocRole] = useState("");

    const [address, setAddress] = useState("");
    const [town, setTown] = useState("");
    const [postCode, setPostCode] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhoneNo] = useState("");


// CSV
    // const [json, setJson] = useState("");
    const [csv, setCsv] = useState("");

    useEffect(()=>{
      setRole("patient");
      setPatientFilter("true");
    },[])
    useEffect(() => {
        fetchUsers();
    }, [role]);



    const usersData = useSelector((state) => {
      // setJson(state.admin.users);

      return state.admin.users;
    });
    const safari = useSelector((state) => {
      // setJson(state.admin.users);

      return state.auth.safari;
    });

    // const json = useSelector((state) => {
    //   // setJson(state.admin.users);
    //
    //   return state.admin.users;
    // });
    // useEffect(() => {
    //     convert();
    // }, [json]);

     //////(userData)

    const fetchUsers=async ()=>{
      dispatch(getUser(role));

         // let data=row
         // data=JSON.stringify(data);
         // data=encrypt(data)
         // localStorage.setItem("rec",data)
         // let result= dispatch(savePatientData(row))
         //  history.push("/doctor/patient/history")

    }
    const toSuspendUser=async ()=>{
    await  dispatch(suspendUser(toSuspend));
    dispatch(getUser(role));
    setSusModal(false);
    }

    const toDeleteUser=async ()=>{
    await  dispatch(removeUser(toDelete));
    dispatch(getUser(role));
    setDelModal(false);
    }
    const toDeleteDoctor=async ()=>{
    await  dispatch(removeDoctor(toDelete));
    dispatch(getUser(role));
    setDelModal(false);
    }


   const toAddDoctor = async () => {
      // e.preventDefault()


            let dateOfBirth = new Date(dob);
            let postcode =postCode;
            let dRole =docRole;
            try {

              // setIsLoading(true);
              // e.preventDefault();
              let result = await dispatch(addDoctor({ firstName, lastName, gender,dateOfBirth,email:email.toLowerCase(),role:dRole,address,town,postcode,country,phone }));
              ////("Result",result);
              // setIsLoading(false);
              // ShowToast(result.data.message,"success");
              dispatch(getUser(role));


            } catch (err) {
              ////(err.response)

              // ShowToast(err.response.data.message,"error");
              // setIsLoading(false);
            }


            return true;


          };

          const convert = () => {
    // e.preventDefault();

    // "\uFEFF"+

    let dataNew =[];
    usersData.map((obj)=>{


      let object = {"firstName": obj.firstName,
       "lastName": obj.lastName, "gender": obj.gender, "dateOfBirth": obj.dateOfBirth,
       "email":obj.email.toLowerCase(), "role": obj.role, "city":obj.town,
       "country": obj.country, "phone": obj.phone, "address": obj.address.replace(/,/g, " ")}

      dataNew.push(object);

    })

    const parsedJson = dataNew;
    if (
      !Array.isArray(parsedJson) ||
      !parsedJson.every((p) => typeof p === "object" && p !== null)
    ) {
      return;
    }
    const heading = Object.keys(parsedJson[0]).join(",");
    const space = "\n"
    const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
    setCsv(`${heading}${space}${body}`);
    let csvData = heading + space + body;

    if(safari){
      download(csvData,"patients", 'text/csv;charset=utf-8;');

    }else{
    download("\uFEFF"+csvData,"patients", 'text/csv;charset=utf-8;');
    }
  };

  // Function to download data to a file
const download=( processedData, filename, type)=> {
  let data = processedData;

    var file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

    return (
        <div className="container-fluid patient-list">
            {/*<div className="container bg-white rounded my-5 p-3">*/}
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row p-2 px-3">
                <div className="col-12 col-md-2">
                <aside className="col-12 mx-lg-1 mx-0 py-lg-3 py-2 mb-lg-0 mb-2 fixed-top h-100" style={{ background:"rgb(0, 54, 80)",borderRadius:"1em" }}>
                <button type="button" className={ patientFilter? "is-active nav-link w-100  px-2 py-2 font-size-big select-active": " nav-link w-100 text-white px-2 py-2 font-size-big"}
                        onClick = {
                          () => {
                            setRole("patient");
                            setPatientFilter(true);
                            setAdminFilter(false);
                            setDoctorFilter(false);
                        }
                      }
                        >

                  Patients
                </button>
                <button type="button" className={ doctorFilter? "is-active nav-link w-100  px-2 py-2 font-size-big select-active": " nav-link w-100 text-white px-2 py-2 font-size-big"} onClick = {
                  () => {
                    setRole("doctor");
                    setPatientFilter(false);
                    setAdminFilter(false);
                    setDoctorFilter(true);
                  }
                } >
                  Doctors
                </button>
                <button type="button"

                className={ adminFilter? "is-active nav-link w-100  px-2 py-2 font-size-big select-active": " nav-link w-100 text-white px-2 py-2 font-size-big"} onClick = {
                  () => {
                    setRole("admin");
                    setPatientFilter(false);
                    setAdminFilter(true);
                    setDoctorFilter(false);
                  }
                }>
                  Admins
                </button>

                </aside>
                </div>
                    <div className="col-12 col-md-10">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12 col-md-6">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">User's Details</h1>
                            </div>
                            <div className="col-12 col-md-6 text-right">
                              {role === "doctor"?<button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Doctor</button>:null}
                            </div>
                            <div className="col-12">
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>DOB</th>

                                <th>Email</th>
                                <th>Joined At</th>
                                <th>Status</th>

                                <th>Country</th>
                                <th>City</th>
                                <th>Phone</th>


                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                              { usersData && usersData !== null && usersData.length > 0 && usersData.map((row, index)=>(
                                  <tr key={index + 0}>
                                    <td>{row.firstName}</td>
                                    <td>{row.lastName}</td>
                                    <td>{moment(row.dateOfBirth).format("D MMM YYYY")}</td>
                                    <td>{row.email}</td>
                                    <td>{moment(row.createdAt).format("D MMM YYYY")}</td>
                                    <td>{row.status}</td>
                                    <td>{row.country}</td>
                                    <td>{row.town}</td>
                                    <td>{row.phone}</td>
                                    <td>


                                      <button
                                          type="button"
                                          className=""
                                          data-toggle="modal"
                                          data-target="#addHospitalModal"
                                          onClick={(event)=>{
                                            this.updateAdmissionDataStart(`${index}`)
                                            this.setState({editMode:true})
                                            this.setState({view:false})
                                            this.setState({hospitalModal:true})
                                          }}
                                      >
                                        <span className="">
                                        <i className="fas fa-edit text-primary"></i>
                                        </span>
                                      </button>

                                      <button
                                          type="button"
                                          className=" ml-2"
                                          onClick={()=>{
                                            setToDelete(`${row.pId}`);
                                            setInstance(row)
                                            setDelModal(true);
                                        }}
                                      >
                                                          <span className="">
                                                          <i className="fas fa-trash-alt text-danger"></i>
                                                          </span>
                                      </button>
                                      {row.status !== "suspended" ?
                                      <button
                                          type="button"
                                          className=" ml-2"
                                          onClick={()=>{
                                            setToSuspend(`${row._id}`);
                                            setSusModal(true);
                                        }}
                                      >
                                                          <span className="">
                                                          <i className="fa fa-ban" aria-hidden="true"></i>

                                                          </span>
                                      </button>
                                      :null}
                                    </td>




                                  </tr>) )}

                              </tbody>
                            </table>
                            </div>

                            <div className="col-12 col-md-9">
                            <UserDetails/>
                            </div>
                            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                            <button className="btn btn-primary" onClick={()=>convert()}>Download CSV</button>
                            </div>
                            <div className={delModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-trash-alt text-danger"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                      <button type="button" className="close" onClick={()=>{setDelModal(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <p>Do you really want to delete this account? <strong>This will also delete all the devices and readings of this patient</strong>. Type "<strong>{instance.email}</strong>" to confirm deletion.</p>

                                      <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      id="title"
                                      onChange={(event)=>{setTypedEmail(event.target.value)}}
                                      required
                                    />
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      <button type="button" className="btn btn-secondary" onClick={()=>{setDelModal(false)}}>Cancel</button>
                                      {typedEmail === instance.email?<button type="button" className="btn btn-danger"  onClick={()=>{role==="doctor"?toDeleteDoctor():toDeleteUser()}}>Delete</button>:null}
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className={susModal? "modal d-block fading" : "modal d-none"}>
                                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                      <div className="modal-header flex-column">
                                        <div className="icon-box">
                                          <i className="fas fa-trash-alt text-danger"></i>
                                        </div>
                                        <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                        <button type="button" className="close" onClick={()=>{setSusModal(false)}} >&times;</button>
                                      </div>
                                      <div className="modal-body">
                                        <p>Do you really want to suspend this records? This process cannot be undone.</p>
                                      </div>
                                      <div className="modal-footer justify-content-center">
                                        <button type="button" className="btn btn-secondary" onClick={()=>{setSusModal(false)}}>Cancel</button>
                                        <button type="button" className="btn btn-danger"  onClick={()=>{toSuspendUser()}}>Suspend</button>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div
                                    className={`modal big_modal_dialog ${
                                        addModal? "d-block modal-fading" : " d-none"
                                    }`}
                                    tabIndex="-1"
                                    data-backdrop="static"
                                    data-keyboard="false"

                                    role="dialog"

                                    aria-hidden="true"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="addHospitalModalTitle">
                                          Add Doctor
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={()=>setAddModal(false)}
                                        >
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <form className="pl-3 pr-3">
                                        <div className="container my-4 ">
                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"> <p className="text-black">First Name</p></div>
                                            <div className="col-md-4">
                                              <input
                                               value={firstName}
                                               onChange={(e) => setFirstName(e.target.value)}
                                                type="text"
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Last Name</p></div>
                                            <div className="col-md-4">
                                              <input
                                              value={lastName}
                                              onChange={(e) => setLastName(e.target.value)}
                                                type="text"
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Gender</p></div>
                                            <div className="col-md-4">
                                              <div className="styled-select">
                                                <select
                                                  value={gender}
                                                  onChange={(e) => setGender(e.target.value)}
                                                  placeholder="Select a Blood Group"
                                                  className="registration-form-control "
                                                >
                                                  <option value="Select">Select</option>
                                                  <option value="Male">Male</option>
                                                  <option value="Female">Female</option>
                                                  <option value="Female">Other</option>
                                                </select>
                                                <i className="fa fa-chevron-down"></i>
                                              </div>
                                            </div>
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">DOB</p></div>
                                            <div className="col-md-4">
                                              <input
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                                type="date"
                                                className="registration-datePicker"

                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="row justify-content-center">
                                          <div className="col-md-2 registration-input-labels"><p className="text-black">Role</p></div>

                                            <div className="col-md-10 col-10 ">
                                              <div className="form-group">
                                                <div className="styled-select">
                                                  <select
                                                    name="relation"
                                                    placeholder="Select a Role"
                                                    className="form-control input-md msg"

                                                    onChange={(e) =>{

                                                      setDocRole(e.target.value);
                                                    }
                                                    }
                                                    required
                                                  >
                                                    <option value="">Select</option>
                                                    <option value="doctor">Internal</option>
                                                    <option value="teledoctor">External</option>


                                                  </select>
                                                  <i className="fa fa-chevron-down"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Email</p></div>
                                            <div className="col-md-10">
                                              <input
                                                type="email"
                                                value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Phone No</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={phone}
                                              onChange={(e) => setPhoneNo(e.target.value)}
                                                type="number"
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Address</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={address}
                                              onChange={(e) => setAddress(e.target.value)}
                                                type="text"
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"> <p className="text-black">Town</p></div>
                                            <div className="col-md-10">
                                              <input
                                               value={town}
                                               onChange={(e) => setTown(e.target.value)}
                                                type="text"
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Postcode</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={postCode}
                                              onChange={(e) => setPostCode(e.target.value)}
                                                type="text"
                                                className="registration-form-control"

                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels "><p className="text-black">Country</p></div>
                                            <div className="col-md-10">
                                            <div className="styled-select">
                                                <select
                                                   value={country}
                                                   onChange={(e) => setCountry(e.target.value)}
                                                  placeholder="Select a Blood Group"
                                                  className="registration-form-control "
                                                >
                                                  <option value="Select">Select Country</option>
                                                  {countryList.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                          ))}
                                                </select>
                                                <i className="fa fa-chevron-down"></i>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                        <button className="btn btn-primary" onClick={()=>{toAddDoctor()}}>Add Doctor</button>

                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                        </div>

                    </div>
                </div>
            {/*</div>*/}
        </div>
    );
};

export default UserManagement;
