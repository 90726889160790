import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pendingInvites } from "../../../redux/actions/external-doctor/inviteList";


const PendingInviteList = () => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.inviteList.pendingInviteList);
  const numberOfPages = useSelector((state) => state.inviteList.totalPages);
  const [page, setPage] = useState(1);

  //Api Call
  // const list = useSelector((state) => state.inviteList.pendingInviteList.users);

  useEffect(() => {
    handleSharedUser();
  }, [page]);
 
  //Handle Pagination
  const handleSharedUser = async () => {
    let noOfRecord = 6;
    const res = await dispatch(pendingInvites({ page, noOfRecord }));
  };

  //Handle Page Change
  const onChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <div>
      <div className="">
        <div className="container-fluid bg-white">
        <div class="w-50 mt-3 mt-md-0 d-flex justify-content-md-end justify-content-center mb-3">
              <Pagination
                   current={page}
                   total={numberOfPages && 10 * numberOfPages}
                   onChange={onChange}
                   showSizeChanger={false}
                   hideOnSinglePage={true}
                 />
              </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list && list.users &&
                list.users.length > 0 &&
                list.users.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.firstName}</td>
                      <td>{row.lastName}</td>
                      <td>{row.phone}</td>
                      <td>{row.email}</td>
                      <td>
                        <button
                          className="h5  text-danger w-25"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Remove User"
                        >
                          <i className=" fas fa-trash-alt"></i>
                        </button>
                        <button
                          className=" h5  w-25"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Cancel permission"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                        <button
                          className="text-primary h5  w-25"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit permission"
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button
                          className="text-success h5  w-25"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Approve permission"
                        >
                          <i className="fas fa-check"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PendingInviteList;
