import React from "react";
import contact from "../../assets/NavIcons/contact.svg";
import avatar from "../../assets/user.png";
import { NavLink } from "react-router-dom";
import { logout } from "../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";
import UserRoles from "../../utils/user-roles";
import UserProfileModal from "./UserProfileModal";
import Logo from "../../assets/NavIcons/grxVoyagerLogo.png";
import PatientHeader from "./nav/Header/PatientHeader";

const NewHeader = () => {
  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleCollapse = () => {

    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light nav-bg"
      style={{ backgroundColor: "#003650" }}

    >
      <div className="navbar-brand" style={{width:"15.5%",height:"100%"}}>
        <NavLink to="/">
        <img alt="logo" src={Logo} className="" style={{maxHeight:"57px"}}></img>
        </NavLink>

      </div>
      <button
        className="navbar-toggler text-white ml-auto" id="navbarBtn" type="button"
        data-target="#navbarNav" data-toggle="collapse"
        aria-controls="navbarNav" aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        {/* {userDetails && userDetails.role === UserRoles.PATIENT && <PatientMenu />} */}
        {userDetails && userDetails.role === UserRoles.PATIENT && (
         <PatientHeader/>
        )}
        <ul className="navbar-nav mr-auto text-white d-lg-none d-block">
        <li className="nav-item mr-lg-2">
              <div className=" top-nav-link">
                <NavLink
                  activeclassname="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/contact"
                  onClick={handleCollapse}
                >
                  <img
                    src={contact}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />
                  Contact Us
                </NavLink>
              </div>
            </li>
        <li className="nav-item mr-lg-2    ">
              <div className=" top-nav-link">
                <NavLink
                  activeclassname="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/contact"
                  onClick={handleCollapse}
                  data-toggle="modal" id="profile-btn" data-target="#userProfileModal"
                >
                  <i
                    className="fas fa-user fa-2x"
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  ></i>
                  Profile
                </NavLink>
              </div>
            </li>


                 <li className="nav-item mr-lg-2    ">
              <div className=" top-nav-link">
                <button
                  activeclassname="active"
                  className="pl-2 pr-2 d-flex align-items-center py-3 w-100 h-100"
                  onClick={() =>
                    dispatch(logout(false))
                  }

                >
                  <i
                    className="fas fa-sign-out-alt fa-2x"
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  ></i>
                  Logout
                </button>
              </div>
            </li>
        </ul>

        <div className="ml-auto d-lg-block d-none">
          <div className="dropdown">
            <a
              className="nav-link  d-flex justify-content-center align-items-center p-0"

              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="text-white mr-3">{`${
                userDetails && userDetails.firstName!==""?userDetails.firstName:""
              } ${userDetails && userDetails.lastName!==""?userDetails.lastName:""}`}</span>{" "}
              <img src={avatar} width="35" height="35" className="rounded-circle" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right pl-2 text-white"
              style={{ backgroundColor: "#003650" }}
              aria-labelledby="dropdownMenuButton"
            >
              <button onClick={() => dispatch(logout(false))}>
                <div className="">
                  <span className="mr-lg-2">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  Logout
                </div>
              </button>

              <button type="button" className='d-block' onClick={handleCollapse}  data-toggle="modal" id="profile-btn" data-target="#userProfileModal">
                <div className="">
                  <span className="mr-lg-2">
                    <i className="fas fa-user "></i>
                  </span>
                  Profile
                </div>
                </button>

               {/* {doc && doc!==""?
               <button type="button" className='d-block' onClick={()=>{handleBackToDoc()}} >
               <div className="">
                 <span className="mr-lg-2">
                 <i className="fas fa-user-md"></i>
                 </span>
                 Back to Doctor
               </div>
               </button>
               :null} */}


            </div>
          </div>
        </div>
      </div>
      <UserProfileModal/>
    </nav>
  );
};

export default NewHeader;
