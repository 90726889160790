import * as actions from "../actionTypes/emergency"
import {
  API_BASE_URL,
  saveAllergyUrl,
  fetchAllergyUrl,
  saveMedicationUrl,
  fetchMedicationUrl,
  saveImplantedDevicesUrl,
  fetchImplantedDevicesUrl,
  saveChronicConditionUrl,
  fetchChronicConditionUrl,
} from "../../utils/urls";
import http from "../../utils/http-service";
import {encrypt,decrypt} from "../../utils/encryption";
import {logout} from "./auth";

const saveAllergiesStart = () => {
  return { type: actions.SAVE_ALLERGIES_START };
};

const saveAllergiesFailed = () => {
  return { type: actions.SAVE_ALLERGIES_FAILED };
};

const saveAllergiesSuccess = (payload) => {
  return { type: actions.SAVE_ALLERGIES_SUCCESS, payload };
};


export const saveAllergy = (data) => async(dispatch) => {
  try {
    dispatch(saveAllergiesStart());

    let allergy=data
    allergy=JSON.stringify(allergy)
    allergy=encrypt(allergy)
    const result = await http.post(`${API_BASE_URL}${saveAllergyUrl}`, {"data": allergy});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);

      dispatch(saveAllergiesSuccess({ allergies: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveAllergiesFailed());
    throw error;
  }

}
export const updateAllergy = (data, _id) => async(dispatch) => {
  let allergyData=data;
  allergyData=JSON.stringify(allergyData);

  let record_id = _id;

  record_id = encrypt(record_id);

  allergyData=encrypt(allergyData);
  try {



  return http.put(`${API_BASE_URL}${saveAllergyUrl}?id=${record_id}`,{"data":allergyData})

}catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }

}

}
export const deleteAllergy = (params) => async(dispatch) => {


  params = encrypt(params);
try{
  return http.delete(`${API_BASE_URL}${saveAllergyUrl}?id=${params}`)
}catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }

}


}
const fetchAllergiesStart = () => {
  return { type: actions.FETCH_ALLERGIES_START };
};

const fetchAllergiesFailed = () => {
  return { type: actions.FETCH_ALLERGIES_FAILED };
};

const fetchAllergiesSuccess = (payload) => {
  return { type: actions.FETCH_ALLERGIES_SUCCESS, payload };
};

export const fetchAllergies = () => async (dispatch) => {

  try {
    dispatch(fetchAllergiesStart());
    let getAlleries = "allergies";
    getAlleries = encrypt(getAlleries);
    const result = await http.get(
        `${API_BASE_URL}${fetchAllergyUrl}${getAlleries}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data= JSON.parse(data);

      dispatch(fetchAllergiesSuccess({ allergies: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchAllergiesFailed());
    throw error;
  }
}



const saveChronicConditionStart = () => {
  return { type: actions.SAVE_CHRONIC_CONDITION_START };
};

const saveChronicConditionFailed = () => {
  return { type: actions.SAVE_CHRONIC_CONDITION_FAILED };
};

const saveChronicConditionSuccess = (payload) => {
  return { type: actions.SAVE_CHRONIC_CONDITION_SUCCESS, payload };
};


export const saveChronicCondition = (data) => async(dispatch) => {
  try {
    dispatch(saveChronicConditionStart());


    let chronic=data
    chronic=JSON.stringify(chronic)
    chronic=encrypt(chronic)

    const result = await http.post(`${API_BASE_URL}${saveChronicConditionUrl}`, {"data": chronic});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);
      dispatch(saveChronicConditionSuccess({ chronicCondition: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveChronicConditionFailed());
    throw error;
  }

}
export const updateChronicCondition = (data, id) => () => {
  data=JSON.stringify(data);
  data=encrypt(data);
  id=encrypt(id);

  return http.put(`${API_BASE_URL}${saveChronicConditionUrl}?id=${id}`,{"data":data})
}
export const deleteChronicCondition = (params) => () => {
  params = encrypt(params);
  return http.delete(`${API_BASE_URL}${saveChronicConditionUrl}?id=${params}`,{params})


}
const fetchChronicConditionStart = () => {
  return { type: actions.FETCH_CHRONIC_CONDITION_START };
};

const fetchChronicConditionFailed = () => {
  return { type: actions.FETCH_CHRONIC_CONDITION_FAILED };
};

const fetchChronicConditionSuccess = (payload) => {
  return { type: actions.FETCH_CHRONIC_CONDITION_SUCCESS, payload };
};

export const fetchChronicCondition = () => async (dispatch) => {

  try {
    dispatch(fetchChronicConditionStart());
    let chronic_conditions = "chronic_conditions";
    chronic_conditions = encrypt(chronic_conditions);
    const result = await http.get(
        `${API_BASE_URL}${fetchChronicConditionUrl}${chronic_conditions}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data= JSON.parse(data);

      dispatch(fetchChronicConditionSuccess({ chronicCondition: data}));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchChronicConditionFailed());
    throw error;
  }
}

const fetchMedicationStart = () => {
  return { type: actions.FETCH_MEDICATION_START };
};

const fetchMedicationFailed = () => {
  return { type: actions.FETCH_MEDICATION_FAILED };
};

const fetchMedicationSuccess = (payload) => {
  return { type: actions.FETCH_MEDICATION_SUCCESS, payload };
};
export const fetchAllMedications = () => async (dispatch) => {
  try {
    dispatch(fetchMedicationStart());
    let current_medications = "current_medications";
    current_medications = encrypt(current_medications);
    const result = await http.get(
        `${API_BASE_URL}${fetchMedicationUrl}${current_medications}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data = JSON.parse(data);

      dispatch(fetchMedicationSuccess({ medication: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchMedicationFailed());
    throw error;
  }
}



const saveMedicationStart = () => {
  return { type: actions.SAVE_MEDICATION_START };
};

const saveMedicationFailed = () => {
  return { type: actions.SAVE_MEDICATION_FAILED };
};

const saveMedicationSuccess = (payload) => {

  return { type: actions.SAVE_MEDICATION_SUCCESS, payload };
};

export const saveMedication = (data) => async(dispatch) => {
  try {
    dispatch(saveMedicationStart());

    let medications=data;
    medications=JSON.stringify(medications);

    medications=encrypt(medications);


    const result = await http.post(`${API_BASE_URL}${saveMedicationUrl}`, {"data":medications});


  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveMedicationFailed());
    throw error;
  }

}

export const updateMedication = (data, id) => async(dispatch) => {
  let med = {id,data};
  med = JSON.stringify(med);
  med = encrypt(med);

try{
  return http.put(`${API_BASE_URL}${saveMedicationUrl}`,{"data":med})
} catch (error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
}
}
export const deleteMedication = (params) => async(dispatch) => {


  params = encrypt(params);
  try{
  return http.delete(`${API_BASE_URL}${saveMedicationUrl}?id=${params}`)
}catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
}
}

const fetchImplantedDevicesStart = () => {
  return { type: actions.FETCH_IMPLANTED_DEVICES_START };
};

const fetchImplantedDevicesFailed = () => {
  return { type: actions.FETCH_IMPLANTED_DEVICES_FAILED };
};

const fetchImplantedDevicesSuccess = (payload) => {
  return { type: actions.FETCH_IMPLANTED_DEVICES_SUCCESS, payload };
};

export const fetchAllImplantedDevices = () => async (dispatch) => {
  try {
    dispatch(fetchImplantedDevicesStart());
    let implanted_devices = "implanted_devices";
    implanted_devices = encrypt(implanted_devices);
    const result = await http.get(
        `${API_BASE_URL}${fetchImplantedDevicesUrl}${implanted_devices}`,
        {}
    );

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data = JSON.parse(data);

      dispatch(fetchImplantedDevicesSuccess({ implantedDevices: data}));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(fetchImplantedDevicesFailed());
    throw error;
  }
}


const saveImplantedDevicesStart = () => {
  return { type: actions.SAVE_IMPLANTED_DEVICES_START };
};

const saveImplantedDevicesFailed = () => {
  return { type: actions.SAVE_IMPLANTED_DEVICES_FAILED };
};

const saveImplantedDevicesSuccess = (payload) => {
  return { type: actions.SAVE_IMPLANTED_DEVICES_SUCCESS, payload };
};


export const saveImplantedDevices = (data) => async(dispatch) => {
  try {
    dispatch(saveImplantedDevicesStart());
    let implantedDevice = data;
    implantedDevice=JSON.stringify(implantedDevice);

    implantedDevice=encrypt(implantedDevice);

    const result = await http.post(`${API_BASE_URL}${saveImplantedDevicesUrl}`, {"data": implantedDevice});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=decrypt(data);
      data=JSON.parse(data);
      dispatch(saveImplantedDevicesSuccess({ implantedDevices: data }));
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(saveImplantedDevicesFailed());
    throw error;
  }

}
export const updateImplantedDevice = (data, id) => async(dispatch) => {
  data=JSON.stringify(data);
  data=encrypt(data);
  id=encrypt(id);
try {
  return http.put(`${API_BASE_URL}${saveImplantedDevicesUrl}?id=${id}`,{"data": data})
} catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
}
}
export const deleteImplantedDevice = (params) => async(dispatch) => {
  params = encrypt(params)
  try {
  return http.delete(`${API_BASE_URL}${saveImplantedDevicesUrl}?id=${params}`,{params})
}catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
}
}
