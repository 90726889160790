import * as actions from "../../actionTypes/index";
const initialState = {
    isLoading: false,
    acceptedInviteList:[],
    pendingInviteList:[],
    totalPages:""

  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case actions.ACCEPTED_USERS_START:
        return {
          ...state,
          isLoading: true,
        };
      case actions.ACCEPTED_USERS_FAILED:
        return {
          ...state,
          isLoading: false,
          acceptedInviteList:action.payLoad

        };
      case actions.ACCEPTED_USERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          acceptedInviteList:action.payLoad

        };

        case actions.PENDING_INVITES_START:
            return {
              ...state,
              isLoading: true,
            };
          case actions.PENDING_INVITES_FAILED:
            return {
              ...state,
              isLoading: false,

            };
          case actions.PENDING_INVITES_SUCCESS:
            return {
              ...state,
              isLoading: false,
              pendingInviteList:action.payLoad

            };
          case actions.ACCEPTED_USERS_PAGES_START:
          return {
            ...state,
            isLoading: true,
          };
      case actions.ACCEPTED_USERS_PAGES_FAILED:
          return {
            ...state,
            isLoading: false,
            totalPages:action.payLoad

          };
      case actions.ACCEPTED_USERS_PAGES_SUCCESS:

          return {
            ...state,
            isLoading: false,
            totalPages:action.payLoad

          };

      default:
        return state;
    }
  };
