const updateDate = (props, tag, isLoading) => {


    if(isLoading && props === null) {

    } else {
        var date = new Date(props);
        var newdate = new Date(date);
        var dd = ("0" + newdate.getDate()).slice(-2);
        var mm = ("0" + (newdate.getMonth() + 1)).slice(-2);
        var y = newdate.getFullYear();
        let newformat = y + '-' + mm + '-' + dd;

        setTimeout(function () {
            document.getElementById(tag).value = newformat;
        }, 2000);
    }
}
export default updateDate;
