import React, { useEffect,useState } from "react";
import { Spin, DatePicker } from "antd";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import {fetchExportData} from "../../../redux/actions/export";
import { useSelector, useDispatch } from "react-redux";
import {encrypt,decrypt} from "../../../utils/encryption";
const WEIGHT = require("../../../assets/GRx Weight Scale-2.jpeg");
const BP = require("../../../assets/blood_pressure_meter.png");
const Aidex = require("../../../assets/AidexLogo.svg");

const TEMP = require("../../../assets/GRx Forehead Thermometer Pro.jpg");
const PO = require("../../../assets/GRx Fingertip Pulse Oximeter.png");
const Nexus= require("../../../assets/products/GlucoRxNexusMeter.png");


const PDF =  require("../../../assets/pdf-icon.svg");
const PDFWHITE =  require("../../../assets/pdf.png");


const ExportData = (sideBar) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const device = useSelector((state) =>(state.devices.devices));
  const {userDetails} = useSelector((state) =>(state.user));

  const { RangePicker } = DatePicker;
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [days, setDays] = useState("30days");
  const [bg, setBg] = useState(true);
  const [bp, setBp] = useState(false);
  const [weight, setWeight] = useState(false);
  const [temp,setTemp]=useState(false);
  const [spo2,setSpo2]=useState(false);
  const [aidexTab,setAidexTab]=useState(false);
  const [rangeDate, setRangeDate] = useState("");
  const [dateRange,setDateRange]=useState(false);
  const [csv, setCsv] = useState("");
const safari = useSelector((state) => {

      return state.auth.safari;
    });





   //Fetch Export Data
  //  useEffect(()=>{
  //   handleExportData()
  //   },[])

  const handleExportData=async()=>{
    let data={time:days,start:startDate,end:endDate,bg:bg,bp:bp,weight:weight,spo2:spo2,temp:temp, aidex:aidexTab}
    data=JSON.stringify(data)
            data=encrypt(data)
            localStorage.setItem("export",data)
            history.push({
                        pathname:"/export-data",

                      });
    // //("data=",data)
    // try {
    //   setIsLoading(true);
    //   let response= await dispatch( fetchExportData(data))
    //         setIsLoading(false);
    //         data=JSON.stringify(data)
    //         data=encrypt(data)
    //         localStorage.setItem("export",data)
    //         history.push({
    //           pathname:"/export-data",

    //         });
    //         //("printing")
    // } catch (error) {
    //     setIsLoading(false);

    // }

  }



  //Handle Filters
  const handleDaysFilter = (d) => {
    setDays(d);
    setStartDate("");
    setEndDate("");
  };
  //Handle Date Picker
  const handleDateChange = (val) => {
    if (val && val.length > 0) {
      setStartDate(val[1].format("YYYY-MM-DD").toString());
      setEndDate(val[0].format("YYYY-MM-DD").toString());
      setToDate(val[1].format("YYYY-MM-DD").toString());
      setFromDate(val[0].format("YYYY-MM-DD").toString());
      setDays("")
    }

  };

  const onStartDateChange=(e)=>{
    // // // // console.log("EVENT", e);

      const val=e.target.value

       setEndDate(moment(val).format("YYYY-MM-DD").toString());
       setFromDate(moment(val).format("YYYY-MM-DD").toString());
       setRangeDate(moment(val).format("YYYY-MM-DD").toString())


  }
  const onEndDateChange=(e)=>{

    // setFilter("");
      const val=e.target.value
    if(val){

      setStartDate(moment(val).format("YYYY-MM-DD").toString());
    setToDate(moment(val).format("YYYY-MM-DD").toString());
    }

  }
  const onClickBtnOk=()=>{

    if(startDate!==""){
      setDateRange(true)
    }
    else{

    }

  }


  // Converting for CSV
  const convert = async () => {
let data={time:days,start:startDate,end:endDate,bg:bg,bp:bp,weight:weight,spo2:spo2,temp:temp, aidex:aidexTab};
const disclaimer = " This document is intended for authorised use only. By accessing or using this file, the user acknowledges responsibility for safeguarding the contents from unauthorised distribution or access. The creator of this document cannot be held liable for any misuse or unintended sharing of the information contained herein. Please ensure proper handling and secure storage of this document."
    // "\uFEFF"+
    let csvString =
    "Disclaimer: " + disclaimer + "\n" +
        "User" + ";" + userDetails.email + ";" +"\n";

    const headings = {
        "Time": "",
        "BloodGlucose" : "",
    }

    let measurementArray = [];
    // setApiLoader(true);
    let result = await dispatch(fetchExportData(data));

    // // // console.log("MEASUREMENT DATA", result);
    setIsLoading(false);
    result = result.data.data;
    result = decrypt(result);
    // // // console.log("DATA NEW DECRYPTED", result);

    result=JSON.parse(result)
    // // // console.log("DATA NEW parse", result);
    const {bgReadings} = result.bloodGlucose;

    let resultCsv = bgReadings;
    // // // console.log("resultCsv", resultCsv);
    resultCsv.forEach((measurement, index) => {
      // // // console.log("bgReadings", measurement);
            measurementArray.push({
                "Time": new Date(measurement.time),
                "BloodGlucose" : measurement.value.bloodGlucose.toFixed(1)



            })

    });
    const fileName = "BloodGlucose_CSV";

    csvString += Object.keys(headings).join(";");
    csvString += "\n";
    csvString += measurementArray.map((j) => Object.values(j).join(";")).join("\n");
    csvString += "\n";


    setCsv(`${csvString}`);
    // let BOM = "\uFEFF";
    let csvData = csvString;
    // // // // // // console.log("csvType", typeof (csvData));
    //
    if (safari) {
      // let csvContent = csvData;
      // csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
        download(csvData, fileName, 'text/csv;charset=utf-8;');

    } else {
        download(csvData, fileName, 'text/csv;charset=utf-8;');
        // download("\uFEFF"+fileDat,"EngineerNEWFILE", 'text/csv;charset=utf-8;');

    }
  };

  // Function to download data to a file
const download=( processedData, filename, type)=> {
  let data = processedData;

    let file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}


  return (
    <div>
      <button
        type="button"
        className="d-flex"
        data-toggle="modal"
        data-target="#exportModal"
        id="pdfBtn"
        disabled={location.pathname==="/export-data"}
      >
      <img src={sideBar.sideBar=== true?PDFWHITE:PDF} className="pdf-img" alt="PDF Icon"/>
      {sideBar.sideBar=== true?<p className="ml-3 font-size-big">{device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?"Export Data":null}</p>:null}

        {/* <i className="fas fa-file-export"></i>*/}
      </button>

      <div
        className="modal fade"
        id="exportModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content pdf-back">
            <div className="modal-header">
              <h5 className="text-white" id="exampleModalLabel">
                Export
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="d-flex flex-row justify-content-center">
                  <button
                    className={
                      startDate === "" && days === "7days"
                        ? "selected text-white rounded2 px-2 mr-2"
                        : "not-selected rounded2 px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("7days");
                      setDropMenu(false);

                    }}
                  >
                    7 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "14days"
                        ? "selected text-white rounded2 px-2 mr-2"
                        : "not-selected rounded2 px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("14days");
                      setDropMenu(false);

                    }}
                  >
                    14 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "30days"
                        ? "selected text-white rounded2 px-2 mr-2"
                        : "not-selected rounded2 px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("30days");
                      setDropMenu(false);

                    }}
                  >
                    30 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "90days"
                        ? "selected text-white rounded2 px-2 mr-2"
                        : "not-selected rounded2 px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("90days");
                      setDropMenu(false);

                    }}
                  >
                    90 Days
                  </button>
                  <button
                    className={
                      dropMenu
                        ? "selected text-white rounded2 px-2 dropdown"
                        : "not-selected rounded2 px-2 dropdown"
                    }
                    onClick={() => {
                      setDropMenu(!dropMenu);
                      setDays("");
                    }}
                  >
                    Range
                  </button>
                </div>
                <div className="row text-center mt-3 justify-content-center">
                  <div className="col-12">

                  <div className="d-flex text-center mt-3 justify-content-center">

                        <div className={dropMenu ? " d-block mb-3" : " d-none"}>
                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                         <div className='d-flex flex-row mb-md-0 mb-2'>
                         <input type="date"
                        onChange={onStartDateChange}
                        ></input>
                        <input type="date"
                        disabled={rangeDate===""?true:false}
                        className="ml-1"
                        onChange={onEndDateChange}
                        min={moment(endDate).format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        ></input>
                           </div>
                    </div>

                  </div>
                    </div>

                  </div>
                </div>
                <div className='row justify-content-center align-items-center my-5'>

                     {device && device.Bg==true?

                       <div className="col-6 col-md-3 text-center"><button className={
                      bg === true
                      ? " btnImgColor-hover bg-primary text-white rounded px-3 ml-auto mr-auto py-3 mb-3"
                      : " btnImgColor rounded px-3 ml-auto mr-auto py-3 mb-3"
                    } onClick={()=>{
                        setBg(!bg)
                      }}>
                         <div className="example example-cover">
                          <img width="50px"  src={Nexus}/></div>
                      </button></div> :null}
                      {localStorage.getItem('AidexAuthToken') &&
                       localStorage.getItem('AidexAuthToken')!=="" &&
                        localStorage.getItem('AidexAuthToken')!== null &&
                         localStorage.getItem('AidexAuthToken')!==undefined?<div className="col-6 col-md-3 text-center"><button className={
                       aidexTab === true
                       ? " btnImgColor-hover bg-primary text-white rounded px-3 ml-auto mr-auto py-3 mb-3"
                       : " btnImgColor rounded px-3 ml-auto mr-auto py-3 mb-3"
                     } onClick={()=>{
                         setAidexTab(!aidexTab)
                       }}>
                          <div className="example example-cover">
 <img width="50px" style={{objectFit:"contain"}}  src={Aidex}/></div>
                       </button></div> :null}

                      {device && device.Bp==true?<div className="col-6 col-md-3 text-center"><button className={
                      bp === true
                        ? " btnImgColor-hover bg-primary text-white rounded px-3 ml-auto mr-auto py-3 mb-3"
                        : " btnImgColor rounded px-3 ml-auto mr-auto py-3 mb-3"
                    } onClick={()=>{
                        setBp(!bp)
                      }}>
                         <div className="example example-cover">
                            <img width="50px"  src={BP}/>
                         </div>
                      </button></div> :null}

                      {device && device.Weight==true?<div className="col-6 col-md-3 text-center"><button className={
                      weight === true
                        ? " btnImgColor-hover bg-primary text-white rounded px-3 ml-auto mr-auto py-3 mb-3"
                        : " btnImgColor rounded px-3 ml-auto mr-auto py-3 mb-3"
                    } onClick={()=>{
                        setWeight(!weight)
                      }}>
                         <div className="example example-cover">
                          <img width="50px"  src={WEIGHT}/>
                         </div>
                      </button></div> :null}

                      {device && device.Temp==true?<div className="col-6 col-md-3 text-center"><button className={
                      temp === true
                        ? " btnImgColor-hover bg-primary text-white rounded px-3 ml-auto mr-auto py-3 mb-3"
                        : " btnImgColor rounded px-3 ml-auto mr-auto py-3 mb-3"
                    } onClick={()=>{
                        setTemp(!temp)
                      }}>
                       <div className="example example-cover">
                        <img width="50px"  src={TEMP}/></div>
                      </button></div> :null}

                      {device && device.PO==true?<div className="col-6 col-md-3 text-center"><button className={
                      spo2 === true
                        ? " btnImgColor-hover bg-primary text-white rounded px-3 ml-auto mr-auto py-3 mb-3"
                        : " btnImgColor rounded px-3 ml-auto mr-auto py-3 mb-3"
                    } onClick={()=>{
                        setSpo2(!spo2)
                      }}>
                         <div className="example example-cover">
                          <img width="50px"  src={PO}/></div>
                      </button></div> :null}







                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div><Spin spinning={isLoading}>{
                spo2 === true || bp=== true || bg=== true ||temp === true
                || weight === true || aidexTab === true?
                <button type="button" className="btn export-it" onClick={handleExportData}>Export</button> : null}</Spin></div>
                <div><Spin spinning={isLoading}>{
                  bg === true?
                  <button className="btn btn-primary print-btn" onClick={()=>convert()}>Download CSV</button> : null}</Spin></div>


      </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportData;
