import React, {useEffect, useLayoutEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";
import Tooltip from "../../../../utils/Tooltip";

am4core.useTheme(am4themes_animated);

function Chart2b(props) {
//
  //("Chart2=",props)
  let heading=props.themeType==="bg"?"text-voyger-purple":props.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
  let color1=props.themeType==="bg"?"#0D92D2":props.themeType==="inr"?"#99D7A6":"#0D92D2";
  let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";
  let color3=props.themeType==="bg"?"#7C1237":props.themeType==="inr"?"#818FAE":"#7C1237";

  let chart;
  useEffect(() => {

    chart = am4core.create(`${props.id}glucoseEvents`, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;
    // this creates initial fade-in
    chart.data = props.dataPoints;

    chart.radius = am4core.percent(90);
    chart.innerRadius = am4core.percent(60);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";

    series.slices.template.cornerRadius = 5;
    series.slices.template.innerCornerRadius = 3;
    series.slices.template.draggable = false;
    series.labels.template.disabled = true;

    series.slices.template.inert = true;
    series.alignLabels = false;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    const label = series.createChild(am4core.Label);
      label.text = `${props.percentage === null ? 0 : props.percentage} %`;
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.fontWeight = "bold";
      label.fill = color1;
      label.fontSize = 24;
      var colorSet = new am4core.ColorSet();
      colorSet.list = [color3, color2, color1].map(
          function (color) {
            return new am4core.color(color);
          }
      );
      series.colors = colorSet;

      return () => {
        chart.dispose();
      };

  }, [props]);

  return (
      <div className={`mt-1 md:mt-0 flex ${props.mr?null:"h-50"} flex-col justify-center items-center mt-md-0 mt-4`}>
        <p className={`font-semibold text-md text-center chart-heading mb-md-2 mb-3 ${heading}`}>Glucose Events
        <span className='ml-1'><Tooltip themeType={props.themeType}  text="The percentage of reading outside your goal range."/></span>
        </p>
        <div id={`${props.id}glucoseEvents`} style={{ width: props.width, height: props.height }}></div>

      </div>);
}
export default Chart2b;
