exports.AllergyConditions = [
   "Medication",
  "Latex",
  "Nuts",
   "Iodine",
  "Food",
  "Other"
];

exports.reactionOptions = [
  "Anaphylaxis",
  "Cardiac Arrest",
  "Dizziness",
  "Fever",
  "Generalized Rash",
  "Heart Rate Decreased",
  "Heart Rate Increased",
  "Hives",
  "Itching",
  "Local Rash",
  "Other"
]