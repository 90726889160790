export const FETCH_ALLERGIES_START = "FETCH_ALLERGIES_START"
export const FETCH_ALLERGIES_FAILED = "FETCH_ALLERGIES_FAILED"
export const FETCH_ALLERGIES_SUCCESS = "FETCH_ALLERGIES_SUCCESS"
export const SAVE_ALLERGIES_START = "SAVE_ALLERGIES_START"
export const SAVE_ALLERGIES_FAILED = "SAVE_ALLERGIES_FAILED"
export const SAVE_ALLERGIES_SUCCESS = "SAVE_ALLERGIES_SUCCESS"
export const FETCH_MEDICATION_START = "FETCH_MEDICATION_START"
export const FETCH_MEDICATION_FAILED = "FETCH_MEDICATION_FAILED"
export const FETCH_MEDICATION_SUCCESS = "FETCH_MEDICATION_SUCCESS"
export const SAVE_MEDICATION_START = "SAVE_MEDICATION_START"
export const SAVE_MEDICATION_FAILED = "SAVE_MEDICATION_FAILED"
export const SAVE_MEDICATION_SUCCESS = "SAVE_MEDICATION_SUCCESS"
export const FETCH_IMPLANTED_DEVICES_START = "FETCH_IMPLANTED_DEVICES_START"
export const FETCH_IMPLANTED_DEVICES_FAILED = "FETCH_IMPLANTED_DEVICES_FAILED"
export const FETCH_IMPLANTED_DEVICES_SUCCESS = "FETCH_IMPLANTED_DEVICES_SUCCESS"
export const SAVE_IMPLANTED_DEVICES_START = "SAVE_IMPLANTED_DEVICES_START"
export const SAVE_IMPLANTED_DEVICES_FAILED = "SAVE_IMPLANTED_DEVICES_FAILED"
export const SAVE_IMPLANTED_DEVICES_SUCCESS = "SAVE_IMPLANTED_DEVICES_SUCCESS"
export const FETCH_CHRONIC_CONDITION_START = "FETCH_CHRONIC_CONDITION_START"
export const FETCH_CHRONIC_CONDITION_FAILED = "FETCH_CHRONIC_CONDITION_FAILED"
export const FETCH_CHRONIC_CONDITION_SUCCESS = "FETCH_CHRONIC_CONDITION_SUCCESS"
export const SAVE_CHRONIC_CONDITION_START = "SAVE_CHRONIC_CONDITION_START"
export const SAVE_CHRONIC_CONDITION_FAILED = "SAVE_CHRONIC_CONDITION_FAILED"
export const SAVE_CHRONIC_CONDITION_SUCCESS = "SAVE_CHRONIC_CONDITION_SUCCESS"