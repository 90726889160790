import React, {useEffect, useState} from "react";

import Chart6 from "./Chart6";
import Chart6b from "./Chart6b";
import Chart6c from "./Chart6c";

export default function HyposHyper (props) {

  const [hyper, setHyper]= useState();
  const [hypo, setHypo]= useState();
  const [total, setTotal]= useState();

  useEffect(() => {
   
    if (props.hyper && props.hyper.length > 0) {

      for (let count = 0; count< props.hyper.length ; count ++) {
  
        let datalength = props.hyper[count].data.length;
        setHyper(datalength);
      }
    }else {
      setHyper(0);
    }
    if (props.hypo && props.hypo.length > 0) {
      for (let countHypo = 0; countHypo< props.hyper.length ; countHypo ++) {
  
        let datalength = props.hyper[countHypo].data.length;
        setHypo(datalength);
        // // // // // console.log("HYPO",hypo);
      }
    } else {
      setHypo(0);
    }
  if(hyper === 0 && hypo === 0){
    setTotal(0);
  }else {
    let grandTotal =hyper+hypo;
    // // // // // console.log(grandTotal);
    setTotal(grandTotal);

  }
  }, [props.hyper,props.hypo, total]);
  // // // // // console.log(props);
  // // // // // console.log(props.hyper);
  // // // // // console.log(props.hypo);
  


  

  ////("Hyper/Hypos and total :",props,hyper, hypo, total);
  return (
    <div>
      <div className="container">
      
        <div className="row justify-content-between align-items-center ">
          <div className="col-12 col-sm-12">
            <Chart6 themeType={props.themeType} total={total} hyper={hyper} hypo={hypo}/>

          </div>
          <div className="col-12 col-sm-6 ">
            <Chart6b themeType={props.themeType} data={props.avgPreMeal} unit={props.unit}/>

          </div>
          <div className="col-12 col-sm-6 ">
            <Chart6c themeType={props.themeType} data={props.avgAfterMeal} unit={props.unit}/>

          </div>

        </div>
      </div>
    </div>
  );
};
