import React from "react";
import {
  UserOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Input, List, Avatar } from "antd";

const data = [
  {
    title: "USER1",
  },
  {
    title: "User 2",
  },
  {
    title: "User 3",
  },
  {
    title: "User 4",
  },
];
const { Search } = Input;

const Chat = () => {
  return (
    <div className="w-full bg-white rounded-lg shadow-lg py-1 flex justify-center flex-row">
      <div className="p-4 w-64 flex flex-col justify-center">
        <Search
          className="rounded-lg"
          placeholder="Search here..."

          enterButton
        />
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title={<a href="https://ant.design">{item.title}</a>}
                description="Chat desctiption"
              />
            </List.Item>
          )}
        />
      </div>
      <div className="flex flex-1 flex-start p-4 w-full h-full">
        <div className="flex flex-row w-full items-center">
          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          <p className="mb-0">Username</p>
          <div className='flex justify-end w-full'>

          <div className="mx-4">
            <VideoCameraOutlined />
          </div>
          <div className="mx-4">
            <PhoneOutlined />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
