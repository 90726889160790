import * as actions from "../../actionTypes/index";

const initialState = {
  isLoading: false,
  userShareToken:''
 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SHARE_DATA_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SHARE_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SHARE_DATA_SUCCESS:
      return {
        ...state,
        userShareToken:action.payLoad,
        isLoading: false,
      
      };
    
    default:
      return state;
  }
};
