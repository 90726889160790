import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default function CheckInsChart(props) {
  let chart;
  useEffect(() => {
    // set chart container
    chart = am4core.create("checkInChartDiv", am4charts.XYChart);

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    chart.yAxes.push(new am4charts.ValueAxis());

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "successful";
    series.dataFields.categoryX = "date";
    series.name = "Successful Check-Ins";
    series.tooltipText = "{name}: {valueY}";

    const series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "failed";
    series2.dataFields.categoryX = "date";
    series2.name = "Failed Check-Ins";
    series2.tooltipText = "{name}: {valueY}";

    chart.data = props.dataPoints;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, chart);

  return (
    <div className="my-3 md:my-0 md:mr-2 w-full bg-white rounded-lg shadow-lg p-4 flex justify-center items-center flex-col">
      <p className="font-bold text-xl">
        Successful Check In's Versus Failed Check In's
      </p>
      <div
        style={{
          minHeight: "16.75rem",
          maxHeight: "16.75rem",
        }}
        className="w-full h-full"
        id={"checkInChartDiv"}
      ></div>
    </div>
  );
}
