import React from "react";
import * as _ from 'lodash';

const Heart = require("../../../../assets/heart.png");


const POStats = ({
  lowest = { spO2: 0, heartRate: 0},
  highest = { spO2: 0, heartRate: 0},
  average = 0,

  averageHeartRate = 0
}) => {
  return (
    // <div className="my-3 md:my-0 md:mx-2 w-full bg-white rounded-lg shadow-lg p-4 ">
    //   <p className="font-bold text-xl">SPO2 Stats</p>
    //
    //   <div className="flex items-center md:justify-between md:my-8 h-full md:h-auto flex-col md:flex-row">
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Highest SPO2</div>
    //       <div className="bg-pink-400 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">97%</span>
    //         <span className=" font-bold">65</span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Lowest SPO2</div>
    //       <div className="bg-pink-300 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">92%</span>
    //         <span className=" font-bold">78</span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col">
    //       <div className="mb-2">Average</div>
    //       <div className="bg-pink-200 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">97%</span>
    //         <span className=" font-bold">62</span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col">
    //       <div className="mb-2">Average Heart Rate</div>
    //       <div className="bg-pink-100 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">78 pm</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="row mw-100 m-auto bg-white border-radius-chart h-100">
    <div className="col-11 m-auto">
    <p className="chart-heading font-weight-bold text-xl pt-2">Pulse Oximeter Stats</p>

    </div>
      <div className="col-11 m-auto p-3 text-center">
      <div className="row mw-100 m-auto">
        <div className="col-6">
          <h3 className="mb-2">Best Reading</h3>
          <div className="bp">
            <p className=" font-bold">{highest.spO2?_.round(highest.spO2, 1).toFixed(1):0} % Oxygen</p>
            {/*}// <p className=" font-bold">{highest} dia</p>*/}
            <p className="flex font-bold" style={{width: "fit-content",margin: "auto"}}>
              {highest.heartRate?_.round(highest.heartRate, 1).toFixed(1):0} BPM{" "}
              <img style={{ margin: "auto auto auto 15px", height: "15px" }} src={Heart} />
            </p>
          </div>
        </div>
        <div className="col-6">
          <h3 className="mb-2">Worst Reading</h3>
          <div className="bp">
            <p className="font-bold" >{lowest.spO2?_.round(lowest.spO2, 1).toFixed(1):0} % Oxygen</p>
          {/*// <p className=" font-bold">{lowest} dia</p>*/}
            <p className="flex font-bold" style={{width: "fit-content",margin: "auto"}}>
              {lowest.heartRate?_.round(lowest.heartRate, 1).toFixed(1):0} BPM{" "}
              <img style={{ margin: "auto auto auto 15px", height: "15px" }} src={Heart} />
            </p>
          </div>
        </div>
      </div>
      </div>
      <div className="col-11 m-auto p-3 text-center">
      <div className="row mw-100 m-auto">
        <div className="col-6">
        <div className="mb-2">Average</div>
        <div className="bp">
          {/*// <p className=" font-bold">120 sys</p>*/}
          <p className=" font-bold">{_.round(average, 1).toFixed(1)} % Oxygen</p>
          {/*<p className="flex font-bold" style={{width: "fit-content",margin: "auto"}}>*/}
          {/*  {average && average.heartRate !== null ? _.round(average.heartRate, 1).toFixed(1): 0  }{" "}*/}
          {/*  <img style={{ margin: "auto auto auto 15px", height: "15px" }} src={Heart} />*/}
          {/*</p>*/}
        </div>
        </div>
        <div className="col-6">
        <div className="mb-2">Average Heart Rate</div>
        <div className="bp">
          <span className="flex font-bold flex-row items-center">
            <img style={{ margin: "auto", height: "35px" }} src={Heart} />
          </span>
          <span className="m-auto font-bold">
            {_.round(averageHeartRate, 1).toFixed(1)} BPM{" "}
          </span>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default POStats;
