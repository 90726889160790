import React from "react";
import ClinicianDashboard from "../clinician-dashboard";
import PatientDashboard from "../patient-dashboard";
import AdminDashboard from "../admin-dashboard";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import UserRoles from "../../utils/user-roles";
import DocumentTitles from "../../utils/document-titles";
import DoctorDashboard from "../doctor-dashboard";

export default function Dashboard() {
  document.title = DocumentTitles.dashboard;
  const { isLoading, userDetails } = useSelector((state) => state.user);

  if (!userDetails) {
    return null;
  }

  return (
    <Spin spinning={isLoading} style={{height:"100%"}}>
      {userDetails.role === UserRoles.CLINICIAN && <ClinicianDashboard />}
      {userDetails.role === UserRoles.PATIENT && <PatientDashboard />}
      {userDetails.role === UserRoles.DOCTOR && <DoctorDashboard/>}
      {userDetails.role === UserRoles.ADMIN && <AdminDashboard />}
      {userDetails.role === UserRoles.SUPER_ADMIN && <AdminDashboard />}
    </Spin>
  );
}
