import React from 'react';
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom'

const DoctorNav = () => {
    const location = useLocation();
    //("Location",location.pathname);
    return (
        <nav className="navbar sticky-top navbar-expand flex-lg-column  flex-row align-items-start py-2 px-md-0 px-2" style={{background:"#003650"}}>
        <div className="collapse navbar-collapse align-items-start mt-md-5 mt-none w-100">
            <ul className="flex-lg-column   flex-row navbar-nav w-100 justify-content-between text-white">
                
                <li className="nav-item">
                <NavLink to="/dashboard"  className={location.pathname==="/dashboard"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "}> <i className="navbar-icon-box fas fa-home"></i> <span className="d-none d-lg-inline  ml-3">Home</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/add-patient"  className={location.pathname==="/add-patient"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><i className="navbar-icon-box fas fa-user-plus"></i> <span className="d-none d-lg-inline ml-3">Add patient</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/pending"  className={location.pathname==="/pending"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><i className="navbar-icon-box fas fa-clipboard-list"></i> <span className="d-none d-lg-inline ml-3">Pending Invites</span></NavLink>
                </li>
                
                
               
            </ul>
        </div>
    </nav>
    );
};

export default DoctorNav;