import { API_BASE_URL, fetchShareToken } from "../../../utils/urls";
import {SHARE_DATA_SUCCESS,SHARE_DATA_START,SHARE_DATA_FAILED} from "../../actionTypes/external-doctor/shareData";
import http from "../../../utils/http-service";
import {encrypt,decrypt} from "../../../utils/encryption";
import {logout} from "../auth";


export const fetchUserShareToken = (data) => async(dispatch) => {


try {
    let res=data;
    let id={"id": res}
    id=JSON.stringify(id);
    id=encrypt(id);

    res = await http.post(`${API_BASE_URL}${fetchShareToken}`,{
     "data":id
    });
    // // // // // // console.log("sHARE TOKEN", res.data.data);
    let gift = res.data.data;
    gift = decrypt(gift);
    gift = JSON.parse(gift);
    // // // // // // console.log("GIFT", gift);
    localStorage.setItem("grxAuthToken",gift.token)
    window.location.reload(true);
    dispatch({ type: SHARE_DATA_SUCCESS, payLoad: gift.token });


  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
    return error;
}
// catch (error) {
//   if(error.response.status===422 || error.response.status === 401){
//
//     dispatch(logout());
//     window.location.reload();
//
//   }
//     return error;
// }
};
