import React from "react";
import { Row, Col, Button, Collapse } from "antd";

const { Panel } = Collapse;

const Note = (index) => {
  return (
    <div className="w-full">
      <Collapse defaultActiveKey={["0"]}>
        <Panel
          className="font-semibold"
          header={
              <Row
                className="flex flex-1 w-full p-1 bg-gray-200"
                style={{ margin: "0" }}
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                <Col className="gutter-row" span={8}>
                  <p className="font-bold text-md mb-0">17 July, 2020 14:30</p>
                </Col>
                <Col className="gutter-row" span={8}>
                  <p className="font-bold text-md mb-0 ">Dr Arnold Jones </p>
                </Col>
                <Col className="gutter-row" span={8}>
                  <p className="font-bold text-md mb-0">Video Call</p>
                </Col>
              </Row>
            }
          key={index}
          // extra={genExtra(data.values[key])}
        >
          <Row
            style={{ margin: "0", padding: 0, marginTop: 10 }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col className="gutter-row" span={4}>
              <b>Reason</b>
            </Col>
            <Col className="gutter-row" span={20}>
              <div className="border border-gray-200 w-full p-2">
                Reason descrption
              </div>
            </Col>
          </Row>
          <Row
            style={{ margin: "0", padding: 0, marginTop: 5 }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col className="gutter-row" span={4}>
              <b>Evaluation</b>
            </Col>
            <Col className="gutter-row" span={20}>
              <div className="border border-gray-200 w-full p-2">
                Evaluation descrption
              </div>
            </Col>
          </Row>
          <div className="flex justify-end my-2 mr-4">
            <Button
              className="w-30 mr-2 my-1 flex text-center justify-center"
              style={{ display: "flex" }}
              type="primary"
              shape="round"
            >
              Button 1
            </Button>
            <Button
              className="w-30 my-1 flex text-center justify-center"
              style={{ display: "flex" }}
              type="primary"
              shape="round"
            >
              See Details
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

const Notes = () => {
  return (
    <div
      className="flex w-full bg-white rounded-lg shadow-lg p-4 flex-col overflow-y-auto "
      style={{ maxHeight: "350px" }}
    >
      <div className="flex w-full flex-start flex-col md:flex-row justify-between px-2">
        <p className="font-bold text-xl text-left">Notes</p>
      </div>
      <Note index={0} />
      <Note index={1} />
      <Note index={2} />
    </div>
  );
};

export default Notes;
