import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as _ from 'lodash';
import $ from "jquery";

am4core.useTheme(am4themes_animated);

function Chart3b(props) {
  // // // // console.log("3b Props", props);

let color1=props.themeType==="bg"?"#0D92D2":props.themeType==="inr"?"#99D7A6":"#0D92D2";
let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";

  ////(props)
  let low = _.round(props.lowest, 1).toFixed(1);
  let unit = props.unit === 0 ? "mmol": "mgdl/L";
  useEffect(() => {
    let chart = am4core.create("chartdiv3b", am4charts.PieChart);

    chart.paddingRight = 0;
    chart.paddingLeft = 0;
    chart.fontSize = 7;

    chart.data = [
      {
        label: "Lowest",
        value: _.round(props.lowest, 1).toFixed(1),
      },
    ];

    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "label";

    chart.innerRadius = am4core.percent(55);
    // Set up fills
    pieSeries.slices.template.fillOpacity = 1;
    pieSeries.slices.template.fill = color1;
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.tooltip.label.fontSize = 10;

    const label = pieSeries.createChild(am4core.Label);
    label.text = '\xa0\xa0'+low +"\n" +unit;;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontWeight = "bold";
    label.fill = color1;
    label.fontSize = 16;

    var hs = pieSeries.slices.template.states.getKey("hover");
    hs.properties.scale = 1;
    hs.properties.fillOpacity = 0.5;
  }, [props]);

  return (
      <div id="chartdiv3b" className="bg-stats-chart" style={{width:"100%"}}></div>
  );
}
export default Chart3b;
