import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { default as Titles } from "../../utils/document-titles";
import { login } from "../../redux/actions/auth";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import ShowToast from "../../utils/show-toast";
import { useSelector } from "react-redux";

import GlucoRxLogo from "../../assets/GlucoRxLogo.svg";
import voyagerLogo from "../../assets/voyagerLogo.svg";
import appStore from "../../assets/app-store.png";
import playStore from "../../assets/play-store.png";

// import ShowToast from "../../utils/show-toast";


const LoginNew = () => {
  // useEffect(()=>{
  // //   if(document.getElementById("loginForm")){
  // //   setTimeout(function(){
  // //     document.getElementById("loginForm").reset();


  // //   }, 2000);
  // //  }
  // },[])
  document.title = Titles.login;
  const state = useSelector((state) =>(state.doctor));
  //(state)
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      // setIsLoading(true);
      e.preventDefault();
      await dispatch(login({ email, password }));
      // setIsLoading(false);
    } catch (err) {


      // setIsLoading(false);

      ShowToast(err.response.data.message, "error")
      if (err.message === "Request failed with status code 403") {
        history.push("/auth/resendVerificationEmail");
      }
    }
  };
  return (
    <div>
      <div>
        <div className="login-section">
          <div className="container-fluid bg-white login-top-bar py-auto position-fixed" >
            <div className="row align-items-center py-2 px-2">
              <img className="mr-auto login-glucoRx-logo" src={GlucoRxLogo}  ></img>

              <Link
                  to={"/auth/register"}>
                <span className="ml-auto mr-3 login-create-account-text">
                  Create Account
                </span>
              </Link>
            </div>
          </div>
          <div className="container-fluid login-background d-flex flex-column">
            <div className="row  justify-content-md-between login-form justify-content-center  ml-md-3 ml-lg-0">
              <div className="col-md-3 col-11  mt-5">
                <div className=" d-flex justify-content-md-start justify-content-center  ml-md-3 ml-0">
                  <img src={voyagerLogo} className="login-voyager-logo" ></img>
                </div>
                <div className="text-md-left text-center text-uppercase login-account-heading1">
                  <p>Login to your <br></br>Voyager Account</p>
                </div>
                <div className="text-md-left text-center login-account-heading2 ">
                  <p>Sign in to manage your account.</p>
                </div>
              </div>
              <div className="col-md-3 col-11 login-account-field-box mr-md-5 mr-0 mt-5 ">
                <form id="loginForm" onSubmit={handleSubmit} autoComplete="off">
                  <div className="container mt-5">
                    <div className="row justify-content-center text-white">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="formGroupExampleInput " className="login-input-labels">Email</label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                            name="email"
                            className="login-form-control"
                            id="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="formGroupExampleInput " className="login-input-labels">Password</label>
                          <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="login-form-control"
                            id="password"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-8 mt-3">
                        {/*<Spin spinning={isLoading}>*/}
                          <button
                            type="submit"
                            className="login-btn btn-primary "
                          >
                            Login
                          </button>
                        {/*</Spin>*/}
                      </div>


                      <div className="col-md-8 mt-3">
                      <div className="text-center text-white  login-forget-password">
                      <Link to={"/auth/resendVerificationEmail"}>
                        <p>Resend verification Email</p>
                        </Link>
                      </div>
                      <div className="text-center text-white mt-2 mb-3  login-forget-password">
                      <Link to={"/auth/forgotPassword"}>
                        <p>Forgot your password?</p>
                        </Link>
                      </div>
                    </div>
                    </div>

                  </div>
                </form>
              </div>

            </div>
            <div className="row justify-content-md-end login-form justify-content-center  ml-md-3 ml-lg-0">
            <div className="col-md-3 col-11 login-account-field-box1 mr-md-5 mr-0 mt-5 ">
            <div className="row" style={{padding:"10%"}}>
              <div className="col-12">
                 <p className="text-white">New Mobile Apps Now Available, Please Download To Avoid Any Disruptions.</p>
              </div>
              <div className="col-6 pt-3">
              <a href="https://apps.apple.com/gb/app/glucorx-voyager/id1531079363" target="_blank">
              <img src={appStore} width="150px" className="m-auto"></img>

              </a>

              </div>
              <div className="col-6 pt-3">
              <a href="https://play.google.com/store/apps/details?id=uk.co.glucorx.voyager_android&gl=GB" target="_blank">
              <img src={playStore} width="150px" className="m-auto"></img>

              </a>

              </div>

            </div>
            </div>
            </div>
            <div className="row justify-content-md-start justify-content-center mt-auto mb-3 pt-2 ml-md-3 ml-lg-0 ">
            <a href="https://apps.apple.com/gb/app/glucorx-voyager/id1531079363" target="_blank">
            <img src={appStore} width="150px"></img>

            </a>
              <a href="https://play.google.com/store/apps/details?id=uk.co.glucorx.voyager_android&gl=GB" target="_blank">
              <img src={playStore} width="150px" className="ml-3"></img>

              </a>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginNew;
