import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import countryList from "../auth/CountriesList";
import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { register, resetEmail, deleteAccountReq } from "../../redux/actions/auth";
import { decrypt } from "../../utils/encryption";
import moment from "moment";
import { fetchUser, updateUser } from "../../redux/actions/user";
import EmailUpdate from "./EmailUpdateModal";
import DeleteModal from "./DeleteModal";


const UserProfileModal = () => {
  const data = useSelector((state) => state.user.userDetails);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [password, setPassword] = useState("");

  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhoneNo] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {

    setFirstName(
      data && data.firstName && data.firstName !== "" ? data.firstName : ""
    );
    setLastName(
      data && data.lastName && data.lastName !== "" ? data.lastName : ""
    );
    setGender(
      data && data.gender && data.gender !== "" ? data.gender.toLowerCase() : ""
    );
    setEmail(data && data.email && data.email !== "" ? data.email : "");
    setDob(
      data && data.dateOfBirth && data.dateOfBirth !== ""
        ? moment(data.dateOfBirth).format("YYYY-MM-DD")
        : ""
    );
    setTown(data && data.town && data.town !== "" ? data.town : "");
    setCountry(data && data.country && data.country !== "" ? data.country : "");
    setPostCode(
      data && data.postcode && data.postcode !== "" ? data.postcode : ""
    );
    setTown(data && data.town && data.town !== "" ? data.town : "");
    setAddress(data && data.address && data.address !== "" ? data.address : "");
    setPhoneNo(data && data.phone && data.phone !== "" ? data.phone : "");
  },[data]);

  const dispatch = useDispatch();


  const handleUserUpdate = async (e) => {
    const dta={
      firstName,
      lastName,
      gender,
      dateOfBirth,
      email,
      address,
      town,
      postcode,
      country,
      phone,
    }

    e.preventDefault();
    let dateOfBirth = new Date(dob);
    let postcode = postCode;
    try {
      setIsLoading(true);
      e.preventDefault();
      let result = await dispatch(
        updateUser({
          firstName,
          lastName,
          gender,
          dateOfBirth,
          address,
          town,
          postcode,
          phone,
        })
      );
      dispatch(fetchUser())
      ////("Result",result);
      setIsLoading(false);
      ShowToast(result.data.message, "success");
    } catch (err) {
      ////(err.response)

      ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  };

  const resetEmailRequest=async()=>{
    try {
      setIsLoading(true);
      if(email !== newEmail){


      let result = await dispatch(
        resetEmail()
      );


      setIsLoading(false);
      ShowToast(result.data.message, "success");
      }
    } catch (err) {
      ////(err.response)

      ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  }
  const handleMouseEnter=(e)=>{
    if(editMode === false){
      e.target.classList.toggle("float-me");
    }

  }
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const DelAccountRequest =async()=>{
    try {
      const res = await dispatch(deleteAccountReq());
      if(res.status === 200){
        setDeleteModal(false);
        const modals = document.getElementsByClassName("modal");
  for (const modal of modals) {
    modal.classList.toggle('d-none');
  }
      }
    } catch (e) {

    }

  }

  return (
    <div>
      <div className="text-center"></div>

      <div id="userProfileModal" className="modal fading">
        <div className="modal-dialog modal-login">
          <div className="modal-content">
            <div className="modal-header">
              <div className="avatar text-center">
                <i className="fas fa-4x fa-user text-white"></i>
              </div>
              <h4 className="modal-title">User Profile</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body overflow-auto" style={{ height: "50vh" }}>
              <form onSubmit={handleUserUpdate} >
                <div className=" ">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="text-left">First Name</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                        className="registration-form-control"


                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="text-left">Last Name</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        className="registration-form-control"


                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-6">
                      <label className="text-left">Gender</label>
                      <div className="styled-select">
                        <select
                          value={gender}
                          disabled={editMode ? false : true}
                          onChange={(e) => setGender(e.target.value)}
                          placeholder="Select a Blood Group"
                          className="registration-form-control "
                          selected={gender}
                        >
                          <option value="Select">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                        <i className="fa fa-chevron-down"></i>
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="text-left">Date of Birth</label>
                      <input
                        disabled={editMode ? false : true}
                       defaultValue={dob}
                        onChange={(e) => setDob(e.target.value)}
                        type="date"
                        className="registration-datePicker"


                      />
                    </div>
                  </div>





                  <div className="row">
                    <div className="form-group col-12 col-md-6">
                      <label className="text-left">Email</label>
                      <input
                        disabled= {true}
                        defaultValue={email}
                        type="email"
                        className="registration-form-control"
                        onChange={(e)=>{
                          setNewEmail(e.target.name);
                        }}

                      />
                      {/*editMode ? <button className="btn btn-primary p-2 mt-2 ml-auto" type="button" onClick={()=>{
                        resetEmailRequest();
                      }}>Save</button> : null*/}

                      {editMode ?<button type="button" className='d-block mt-1 ml-auto'  title="Update Email" onClick={()=>{resetEmailRequest();}}>
                        <div className="">
                          <span className="mr-lg-2">
                            <i className="fas fa-edit "></i>
                          </span>

                        </div>
                        </button>: null}

                    </div>
                    <div className="form-group col-12 col-md-6">
                      <label className="text-left">Phone Number</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={phone}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        type="number"
                        className="registration-form-control"


                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">
                      <label className="text-left">Address</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        className="registration-form-control"


                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">
                      <label className="text-left">Town</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={town}
                        onChange={(e) => setTown(e.target.value)}
                        type="text"
                        className="registration-form-control"


                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">
                      <label className="text-left">Post Code</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                        type="text"
                        className="registration-form-control"


                      />
                    </div>
                  </div>
                  {editMode?<div className="row ">
                    <div className="form-group col-md-12">
                      <button className="btn btn-primary" type="button" onClick={()=>setDeleteModal(true)}>Delete Account</button>
                    </div>
                  </div>:null}



                  <div
                    className={`row justify-content-end ${
                      editMode ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="col-md-4 ml-auto">
                      <Spin spinning={isLoading}>
                        <button
                          type="Submit"
                          className="registration-btn btn-primary "
                        >
                          Save
                        </button>
                      </Spin>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
               className={editMode?"text-primary":""}
                onClick={() => {
                  setEditMode(!editMode);
                }}
              >
                Edit Profile?
              </button>
            </div>
          </div>
        </div>
      </div>
      <EmailUpdate/>
      <DeleteModal deleteModal={deleteModal} closeDeleteModal={()=>{closeDeleteModal()}} DelAccountRequest={()=>{DelAccountRequest()}}/>

    </div>
  );
};

export default UserProfileModal;
