import React from 'react';

const Tooltip = (props) => {

  let border=props.themeType==="bg"?{borderColor:"#AD3860"}:props.themeType==="inr"?{borderColor:"#5C91AA"}:props.themeType==="bp"?{borderColor:"#271CA1"}:{borderColor:"#AD3860"};

  return (
        <span className="tooltipp">
          <i className="fas fa-info-circle " ></i>
          <span style={border} className={`tooltipptext`}>{props.text}</span>
        </span>
    );
};

export default Tooltip;
