import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

const City = ({ name, count }) => {
  return (
    <div
      className={`w-full p-1 flex  items-center justify-between rounded-lg  my-1`}
    >
      <div className=" flex flex-col">
        <span className="text-gray-600 font-semibold">{name}</span>
      </div>
      <div className="ml-2 flex-autoflex justify-end items-center">
        <span className="text-gray-600 font-semibold">{count}</span>
      </div>
    </div>
  );
};

export default function TotalUsersChart(props) {
  let chart;
  useEffect(() => {
    // set chart container
    chart = am4core.create("UserLocationChartDiv", am4maps.MapChart);

    chart.geodata = am4geodata_worldLow;

    chart.projection = new am4maps.projections.Miller();
    chart.zoomControl = new am4maps.ZoomControl();
    chart.zoomControl.slider.height = 100;

    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
    polygonSeries.include = ["GB"];

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "";
    polygonTemplate.fill = am4core.color("#74B266");

    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonTemplate,
      min: am4core.color("#ffffff"),
      max: am4core.color("#AAAA00"),
    });

    const hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#367B25");

    const imageSeries = chart.series.push(new am4maps.MapImageSeries());
    const imageSeriesTemplate = imageSeries.mapImages.template;
    const circle = imageSeriesTemplate.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#3498db");
    circle.stroke = am4core.color("#FFFFFF");
    circle.strokeWidth = 2;
    circle.nonScaling = true;
    circle.tooltipText = "{title}";

    imageSeriesTemplate.propertyFields.latitude = "latitude";
    imageSeriesTemplate.propertyFields.longitude = "longitude";

    imageSeries.data = props.dataPoints;

    return () => {
      chart.dispose();
    };
  }, chart);

  return (
    <div className="my-3 md:my-0 md:mr-2 md:w-2/4 bg-white rounded-lg shadow-lg p-4 flex flex-col">
      <p className="font-bold text-xl  w-full">User's Location</p>
      <div className="flex flex-col md:flex-row md:justify-between overflow-y-auto">
        <div
          style={{
            minHeight: "16.75rem",
          }}
          className="md:w-2/4 lg:w-3/4 h-full"
          id={"UserLocationChartDiv"}
        ></div>
        <div className="md:w-2/4 lg:w-1/4 md:ml-2 overflow-y-auto">
          {props.dataPoints.map((city, index) => (
            <City key={index} name={city.title} count={city.count} />
          ))}
        </div>
      </div>
    </div>
  );
}
