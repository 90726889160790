import { combineReducers } from "redux";

import auth from "./auth";
import aidexAuth from "./aidex/aidexAuth";
import cgm from "./aidex/cgm";


import user from "./user";
import dashboard from "./dashboard";
import history from "./history";
import emergency from "./emergency";
import medication from "./medication";
import bloodGlucose from "./micro-apps/blood-glucose";
import bloodPressure from "./micro-apps/blood-pressure";
import pulseOximeter from "./micro-apps/pulse-oximeter";
import weightScale from "./micro-apps/weight-scale";
import temperature from "./micro-apps/temperature";


import shareInvite from "./external-doctor/shareInvite";
import inviteList from "./external-doctor/inviteList";
import shareData from "./external-doctor/shareData"
import share from "./share/share";


import devices from "./device";
import doctor from "./doctor";
import exportData from "./exportData";

import admin from "./admin";



const rootReducer = combineReducers({
  auth,
  aidexAuth,
  cgm,
  user,
  dashboard,
  history,
  medication,
  emergency,
  bloodGlucose,
  bloodPressure,
  devices,
  exportData,
  pulseOximeter,
  weightScale,
  temperature,
  doctor,
  shareInvite,
  inviteList,
  shareData,

  share,
  admin,
});

export default (state, action) =>
  rootReducer(action.type === "USER_LOGGED_OUT" ? undefined : state, action);
