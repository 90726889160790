import { API_BASE_URL, cancelShareData,activeShareData,removeShareData, doctorDetails, acceptDocInvitation, sharedUsersList, patientPendingInvitesList } from "../../../utils/urls";
import {DOCTOR_INFO_SUCCESS,DOCTOR_INFO_START,DOCTOR_INFO_FAILED,ACCEPT_INVITE_SUCCESS,ACCEPT_INVITE_START,ACCEPT_INVITE_FAILED
    ,SHARED_USERS_SUCCESS,SHARED_USERS_START,SHARED_USERS_FAILED
    ,PENDING_INVITES_SUCCESS,PENDING_INVITES_START,PENDING_INVITES_FAILED,
    CANCEL_INVITES_SUCCESS,ACTIVE_INVITES_SUCCESS,REMOVE_INVITES_SUCCESS
} from "../../actionTypes/share/share";
import http from "../../../utils/http-service";
import {decrypt,encrypt} from "../../../utils/encryption";
import ShowToast from "../../../utils/show-toast";
import {logout} from "../auth";



export const doctorInfo = (t) => async(dispatch) => {
    const { token } = t;

    if(t.local_doctorId !== "" && t.local_doctorId!== null){


    let data={"token":token}

     data=JSON.stringify(data)

     data=encrypt(data)





try {
    let res;
    res = await http.post(`${API_BASE_URL}${doctorDetails}`,{
     data
    });

    let gift = decrypt(res.data.data.doctor);

    gift=JSON.parse(gift)


    dispatch({ type: DOCTOR_INFO_SUCCESS, payLoad: gift });


  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
    return error;
}

}
};


export const acceptInvitationUser = (_id) => async(dispatch) => {
    const { id } = _id;


    let data={"data":id}

     data=JSON.stringify(data)

     data=encrypt(data)


try {
    let res;
    res = await http.post(`${API_BASE_URL}/user/e_acceptViewDataRequest`,{
    data
    });

    // let gift = decrypt(res.data.data);
    // gift=JSON.parse(gift)
    // gift=JSON.stringify(gift)

    ShowToast(res.data.message,"success");
        //  history.push("/dashboard");
        // document.getElementById("btn-close").click();
    // dispatch({ type: ACCEPT_INVITE_SUCCESS, payLoad:"" });


  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }

    if(error.response.status===409){
    ShowToast(error.response.data.message,"success");
    }
    else{
    ShowToast(error.response.data.message,"error");
    }

    return error;
}
};


export const acceptInvitationDoctor = (t) => async(dispatch) => {
    const { token } = t;


    let data={"token":token}
     data=JSON.stringify(data)

     data=encrypt(data)


try {
    let res;
    res = await http.post(`${API_BASE_URL}${acceptDocInvitation}`,{
    data
    });

    // let gift = decrypt(res.data.data);
    // gift=JSON.parse(gift)
    // gift=JSON.stringify(gift)

    ShowToast(res.data.message,"success");
        //  history.push("/dashboard");
        // document.getElementById("btn-close").click();
    dispatch({ type: ACCEPT_INVITE_SUCCESS, payLoad:"" });


  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
    if(error.response.status===409){
    ShowToast(error.response.data.message,"success");
    }
    else{
    ShowToast(error.response.data.message,"error");
    }

    return error;
}
};

export const sharedUsers = () => async(dispatch) => {


try {
    let res;
    res = await http.get(`${API_BASE_URL}${sharedUsersList}`);

    let gift = decrypt(res.data.data);
    gift=JSON.parse(gift)


    dispatch({ type: SHARED_USERS_SUCCESS, payLoad: gift });


  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
    return error;
}
};

export const pendingInvites = () => async(dispatch) => {


    try {

        let res;
        res = await http.get(`${API_BASE_URL}${patientPendingInvitesList}`);


        let gift = decrypt(res.data.data);
        gift=JSON.parse(gift)

        dispatch({ type: PENDING_INVITES_SUCCESS, payLoad: gift });


      return res;
    } catch (error) {
      if(error.response.status===422 || error.response.status === 401){

        dispatch(logout(true));
        window.location.reload();

      }
        return error;
    }
    };


export const cancelPermission = (id) => async(dispatch) => {

    try {
        let res;
        res = await http.post(`${API_BASE_URL}${cancelShareData}`,{"id":id});
        dispatch({ type: CANCEL_INVITES_SUCCESS });
        ShowToast(res.data.message,'success')
      return res;
    } catch (error) {
      if(error.response.status===422 || error.response.status === 401){

        dispatch(logout(true));
        window.location.reload();

      }
        return error;
    }
    };

    export const activePermission = (id) => async(dispatch) => {

          try {
              let res;
              res = await http.post(`${API_BASE_URL}${activeShareData}`,{"id":id});
              dispatch({ type: ACTIVE_INVITES_SUCCESS });
              ShowToast(res.data.message,'success')

            return res;
          } catch (error) {
            if(error.response.status===422 || error.response.status === 401){

              dispatch(logout(true));
              window.location.reload();

            }
              return error;
          }
    };

    export const removePermission = (id) => async(dispatch) => {

          try {
              let res;
              res = await http.post(`${API_BASE_URL}${removeShareData}`,{"id":id});
              dispatch({ type: REMOVE_INVITES_SUCCESS });
              ShowToast(res.data.message,'success')

            return res;
          } catch (error) {
            if(error.response.status===422 || error.response.status === 401){

              dispatch(logout(true));
              window.location.reload();

            }
              return error;
          }
    };
