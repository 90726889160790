import React, {useEffect, useState} from "react";
import { useDispatch} from "react-redux";

import AidexOverview from "./aidex-overview";
import AidexHistory from "./aidex-history";
import AidexLogin from "../../../auth/aidex-login";
import { aidexLogin } from "../../../../redux/actions/aidex/aidexAuth";
import ShowToast from "../../../../utils/show-toast";
import AidexLogo from "../../../../assets/AidexLogo.svg";





function AidexLoginModal({modal,onClick}) {
// // // // console.log("ON CLICK", onClick);


  // const [aidexToken, setAidexToken]=useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();



  useEffect(() => {

    // FetchDocInfo();
    if (modal===false) {
      document.getElementById("aidex-login-modal").click();
    }
    // return(
    //   setModalClose(false)
    // )
  }, [modal]);


  const handleSubmit = async (e) => {


    try {
      e.preventDefault();

      // setIsLoading(true);
      const result = await dispatch(aidexLogin({ email, password }));
      // // // // console.log("RESULT :", result);
      if(result.status=== 200){

        let ele = document.getElementsByTagName('body')[0];
        ele.classList.remove("modal-open");
        // // // // console.log("ON CLICK", onClick);
        onClick(true);
        // document.getElementById("close-modal").click();

        // setModalClose(true)

      // document.getElementsByClassName('close').click();

      }
      // setIsLoading(false);
    } catch (err) {

      ShowToast(err.response, "error");
      // setIsLoading(false);

      // if (err.response.data.message === "Please activate your account first!") {
      //
      //
      //   history.push("/auth/resendVerificationEmail");
      // }
    }
  };
  return (
    <div className="row mw-100 m-auto align-items-center justify-content-center h-100">

    <div className="col-12">
      <button type="button" id="aidex-login-modal" class="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModal">
        Link Aidex
      </button>

      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style={{border:"none", borderRadius:"5px", background:"white"}} role="document">
          <div class="modal-content loginNew-fieldBox">
            <div class="modal-header">
              <h5 class="text-black" id="exampleModalLabel">Link Your Aidex Account</h5>
              <button type="button" id="close-modal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
            <div className="w-100 mt-5 mb-5 ">
              <form onSubmit={(e)=>handleSubmit(e)}>

                <div className="container ">

                  <div className="row justify-content-center text-white">
                  <div className="col-md-8">
                  <img src={AidexLogo} className="logo-gen m-auto" width="200px" alt="AidexLogo"></img>

                  </div>
                    <div className="col-md-8 mt-4">
                      <div className="form-group">

                        <input
                          type="text"
                          className="loginNew-form-control"
                          placeholder="Email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">

                        <input
                          type="password"
                          className="loginNew-form-control"
                          placeholder="Password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                    {/*<div className="text-right  text-white ">
                      <Link to="/forgetPassword"><p className="font-blue">Forgot your password?</p></Link>
                    </div>*/}

                    </div>
                    <div className="col-md-8 mt-2 ">
                      <button
                        type="submit"
                        className="login-btn btn-primary"

                      >
                        Login
                      </button>
                    </div>
                    {/*<div className="col-md-8 mt-2 ">


                        <Link to="/Registration" className="login-btn btn-primary ">
                        Signup
                         </Link>
                    </div>*/}

                  </div>
                </div>
              </form>
            </div>
            </div>
            {/*<div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Login</button>
            </div>*/}
          </div>
        </div>
      </div>




    </div>
    </div>
  );
};


export default AidexLoginModal;
