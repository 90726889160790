import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pendingInvites } from "../../../redux/actions/Share/share";
import PermissionModal from "./PermissionModal";

const Pending = ({activeTab}) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.share);

  const [loading,setLoading]=useState(false);
  const [modalType,setModalType]=useState("");
  const [rowId,setRowId]=useState("");
  const [render,setRender]=useState(true);
  const [isModalVisible,setIsModalVisible]=useState(false);
  const [obj, setObj]= useState({});
  // const [listData, setListData] = useState([])

  useEffect(() => {
    handleSharedUser();
  }, [render,activeTab]);

  const handleSharedUser = async () => {
    setLoading(true)
    const res = await dispatch(pendingInvites());

    // setListData(list.pendingInvites);
    setLoading(false)
  };
  const btnClick=(data)=>{
   setRender(data)
  }

  const permissionCancelBtn=(show)=>{
    // setCancelBtn(show)
  }
  const ModalVisibility=(show)=>{
    setIsModalVisible(show)
  }

  return (
    <div>
        <div className="row mw-100 m-auto bg-white shareTable">
         <table>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Action</th>

        </tr>
      </thead>

      <tbody>

           {list && list.pendingInvitesList &&
                list.pendingInvitesList.length > 0 &&
                list.pendingInvitesList.map((row, index) => {
                  return (

                    <tr key={index}>
                      <td>{row.firstName}</td>
                      <td>{row.lastName}</td>
                      <td>{row.email}</td>

                      <td>
                      <Spin spinning={loading}>
                        <button
                          className="h5  text-danger w-25"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Remove User"
                          data-toggle="modal"
                          data-target="#permissionModal"
                          onClick={()=>{
                            setIsModalVisible(true);
                            setRowId(row._id);
                            setModalType("delete");
                            setObj(row);
                          }}
                        >
                          <i className=" fas fa-trash-alt"></i>
                        </button>

                        <button
                          className="text-success h5  w-25"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Approve permission"
                          data-toggle="modal"
                          data-target="#permissionModal"
                          onClick={()=>{
                            setIsModalVisible(true);
                            setRowId(row._id);
                            setModalType("acceptPendingInvite");
                            setObj(row);
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </button>
                        </Spin>

                      </td>

                    </tr>

                  );

                })}

      </tbody>


         </table>
         <PermissionModal ModalVisibility={ModalVisibility} object={obj} isModalVisible={isModalVisible} type={modalType} id={rowId} permissionCancelBtn={permissionCancelBtn} btnClick={btnClick} render={render}/>
        </div>
    </div>
  );
};

export default Pending;
