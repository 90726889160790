import React, { useState } from "react";
import $ from "jquery";
import { withRouter } from 'react-router-dom';
import ReactHorizontalDatePicker from './Home/ReactHorizontalDatePicker';

const Appointment = ({
  description,
  date,
  time,
  location,
  roundedBorder = false,
  history
}) => {
  return (
    <div
      className={`w-full p-3 flex items-center border-b border-gray-500 ${
        roundedBorder ? "rounded-b-lg" : ""
      }`}
    >
      <div className="mr-1 md:mr-0 md:w-4/5 flex flex-col">
        <span className="text-blue-400 font-semibold">{description}</span>
        <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center">
          <div>
            <span className="mt-1 md:mt-0 text-gray-500">Date </span>
            <b>{date}</b>
          </div>
          <div>
            <span className="mt-1 md:mt-0 text-gray-500">Time </span>{" "}
            <b>{time}</b>
          </div>
          <div>
            <span className="mt-1 md:mt-0 text-gray-500">Location </span>{" "}
            <b>{location}</b>
          </div>
        </div>
      </div>
      <div className="ml-1 md:ml-0 md:w-1/5 flex justify-end items-center">
        <button onClick={()=>{history.push('/doctor/patient/record')}} className="px-2 py-1 bg-blue-400 text-white rounded-sm">
          View
        </button>
      </div>
    </div>
  );
};

function UpcomingAppointments({ appointments = [],history,settingDate}) {
  const [udate,setUDate]=useState("")
  const [filteredData,setFilteredData]=useState([])

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

  const onSelectedDay = (d) =>{
    setUDate(d)
    settingDate(d)
    // var day=d.getDate();
    // var monthNumber=d.getMonth()
    // var month=monthNames[d.getMonth()]
    // var year=d.getFullYear();
  
    // //(d);
    // //("day", day);
    // //("month", month);
    // //("monthNumber", monthNumber);
    // //("year", year);
   
   
};
// //(appointments)
// //("UDate",udate)
  
  
//   function handleChange(event) {
//     // Here, we invoke the callback with the new value
//       onChange(event.target.value);
// }
  
// let filtered;
// for (let i = 0; i<appointments.length; i++) {
//   let dateFil = new Date(appointments[i].date);
//   let cDay = dateFil.getDay();
//   let cMonth = dateFil.getMonth();
//   let cYear = dateFil.getFullYear();
//   if(cDy === day && cMonth === month && cYear === year) {
//     setFilteredData(appointments[i])
//   } 

// }
  const renderAppointments = () => {
    return appointments
    .map(({ description, date, time, location }, index) => (
      <Appointment
        key={index}
        date={date}
        location={location}
        time={time}
        description={description}
        history={history}
      />
    ));
  };

  return (
    <div className="my-3 md:my-0 md:mr-2 w-full bg-white rounded-t-lg shadow-lg p-0">
      <p className="m-0 rounded-t-lg font-bold text-xl bg-gray-100 text-center w-full px-1 py-2">
        Upcoming Appointments with Vendor Representatives
      </p>

 
  <ReactHorizontalDatePicker selectedDay={onSelectedDay} enableScroll={true} enableDays={180}/> 



      <div className="content rounded-b-lg">
        {appointments.length > 0 ? (
          renderAppointments()
        ) : (
          <div className="text-center mt-5">You don't have any scheduled appointments</div>
        )}
      </div>
    </div>
  );
}

export default withRouter(UpcomingAppointments)