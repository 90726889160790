import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ShowToast from "../../../utils/show-toast";
import {
  fetchAllMedications,
  saveMedication,
  updateMedication,
  deleteMedication,
} from "../../../redux/actions/emergency"
// import moment from "moment";
import $ from "jquery";
import updateDate from "../../../utils/updateDate";

class CurrentMedications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal:false,
      id:"",
      data:[],
      name:"",
      genericName:"",
      route:"",
      dose:"",
      unit:"",
      description:"",
      frequency:"",
      instructions:"",
      reason:"",
      status:"",
      event:"",
      reminder:null,
      doctorName:"",
      notes:"",
      dateOfPrescription:"",
      view: false,
      editMode: false,
      medicationModal:false,
      toDelete:"",
    };
  }


  componentDidMount() {

    if (this.props.medications && this.props.medications.data !== null ){

      this.setState({data:this.props.medications.data})
    } else {

      this.props.dispatch(fetchAllMedications())
    }
  }
  static getDerivedStateFromProps(props, state) {

    if(props.medications && props.medications.data!==null){

      let listOfMedications = props.medications.data;
      state.data= listOfMedications;
    }
  }

  updateMedicationsDataStart = (index) => {
    if (this.state.data && this.state.data.length>0){
      let medicationsData = this.state.data[index];

      this.setState({
        modal: true,
        id:medicationsData.id,
        name:medicationsData.name,
        genericName: medicationsData.genericName,
        route: medicationsData.route,
        dose: medicationsData.dose,
        unit: medicationsData.unit,
        description: medicationsData.description,
        frequency: medicationsData.frequency,
        instructions: medicationsData.instructions,
        reason: medicationsData.reason,
        status: medicationsData.status,
        event: medicationsData.event,
        reminder: medicationsData.reminder,
        doctorName: medicationsData.doctorName,
        notes: medicationsData.notes,
        dateOfPrescription: new Date(medicationsData.dateOfPrescription)
      })
      updateDate(medicationsData.dateOfPrescription, "dateOfPrescription", false);
    }
  }

  handleChange=(event)=>{
    this.setState({[event.target.name]:event.target.value});

  }


  handleSubmit= async(e)=>{
    e.preventDefault();
    let data =[{
      name:this.state.name,
      genericName: this.state.genericName,
      route: this.state.route,
      dose: this.state.dose,
      unit: this.state.unit,
      description: this.state.description,
      frequency: this.state.frequency,
      instructions: this.state.instructions,
      reason: this.state.reason,
      status: this.state.status,
      event: this.state.event,
      reminder: this.state.reminder,
      doctorName: this.state.doctorName,
      notes: this.state.notes,
      dateOfPrescription: new Date(this.state.dateOfPrescription)
    }]
    await this.props.dispatch(saveMedication(data));

    ShowToast("Success!", "success");
    $('form').trigger("reset");
    this.setState({
      modal:false,
      name:"",
      genericName:"",
      route:"",
      dose:"",
      unit:"",
      description:"",
      frequency:"",
      instructions:"",
      reason:"",
      status:"",
      event:"",
      reminder:null,
      doctorName:"",
      notes:"",
      dateOfPrescription:"",
      view: false,
      editMode:false,
      medicationModal:false,
    })


    await this.props.dispatch(fetchAllMedications());

  }
  updateMedicationRecord= async(event)=>{
    event.preventDefault();
    let data ={
      name:this.state.name,
      genericName: this.state.genericName,
      route: this.state.route,
      dose: this.state.dose,
      unit: this.state.unit,
      description: this.state.description,
      frequency: this.state.frequency,
      instructions: this.state.instructions,
      reason: this.state.reason,
      status: this.state.status,
      event: this.state.event,
      reminder: this.state.reminder,
      doctorName: this.state.doctorName,
      notes: this.state.notes,
      dateOfPrescription: new Date(this.state.dateOfPrescription)
    }
    await this.props.dispatch(updateMedication(data, this.state.id));

    ShowToast("Success!", "success");
    $('form').trigger("reset");
    this.setState({
      modal:false,
      name:"",
      genericName:"",
      route:"",
      dose:"",
      unit:"",
      description:"",
      frequency:"",
      instructions:"",
      reason:"",
      status:"",
      event:"",
      reminder:null,
      doctorName:"",
      notes:"",
      dateOfPrescription:"",
      view: false,
      editMode:false,
      medicationModal:false,
    })
    await this.props.dispatch(fetchAllMedications());
    this.setState({
      modal:false,
      name:"",
      genericName:"",
      route:"",
      dose:"",
      unit:"",
      description:"",
      frequency:"",
      instructions:"",
      reason:"",
      status:"",
      event:"",
      reminder:null,
      doctorName:"",
      notes:"",
      dateOfPrescription:"",
      view: false,
      editMode:false,
      medicationModal:false
    });

  }
  deleteMedicationRecord = async(id) => {

    await this.props.dispatch(deleteMedication(id))
    ShowToast("Success!", "success");
    this.setState({deleteModal:false})
    await this.props.dispatch(fetchAllMedications());
  }
  newCurrenMedication=()=>{

    this.setState({
      modal:false,
      name:"",
      genericName:"",
      route:"",
      dose:"",
      unit:"",
      description:"",
      frequency:"",
      instructions:"",
      reason:"",
      status:"",
      event:"",
      reminder:null,
      doctorName:"",
      notes:"",
      dateOfPrescription:"",
      view: false,
      editMode:false,
      medicationModal:true,

    });
  }
  toUpdateState=()=>{

    this.setState({
      modal:false,
      name:"",
      genericName:"",
      route:"",
      dose:"",
      unit:"",
      description:"",
      frequency:"",
      instructions:"",
      reason:"",
      status:"",
      event:"",
      reminder:null,
      doctorName:"",
      notes:"",
      dateOfPrescription:"",
      view: false,
      editMode:false,
      medicationModal:false
    });
  }
  deleteModal=(id)=>{
    this.setState({deleteModal:true,toDelete:id});
  }
  closeDeleteModal=()=>{
    this.setState({deleteModal:false});
  }
  render() {
    const {data} = this.state;

    return (
        <div>
          <>
            <div className="text-right">
              <button
                  type="button"
                  className="add-info-btn"
                  data-toggle="modal"
                  data-target="#CurrentMedModal"
                  onClick={this.newCurrenMedication}

              >
              <span className="d-flex justify-content-center align-items-center">
                <i className="fas fa-plus mr-2"></i>
                <span>Add Current Medications</span>
              </span>
              </button>
            </div>

            <div

              className={`modal big_modal_dialog ${
                this.state.medicationModal? "d-block modal-fading" : " d-none"
              }`}
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"

              role="dialog"

              aria-hidden="true"

            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      {this.state.editMode?"Update Current Medications":"Add Current Medications"}

                    </h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.toUpdateState}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form autoComplete="off" onSubmit={this.state.editMode?this.updateMedicationRecord : this.handleSubmit}>
                    <div className="modal-body">
                      <div className='container-fluid'>


                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Name</h6></div>
                            <div className="form-group">

                              <input
                                  name="name"
                                  placeholder="Name"
                                  defaultValue={this.state.name}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required
                              />

                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Generic Name</h6></div>
                            <div className="form-group">
                              <input
                                  name="genericName"
                                  placeholder="Generic Name"
                                  defaultValue={this.state.genericName}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />

                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Route</h6></div>
                            <div className="form-group">
                              <input
                                  name="route"
                                  placeholder="Route"
                                  defaultValue={this.state.route}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />


                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Dose</h6></div>
                            <div className="form-group">
                              <input
                                  name="dose"
                                  placeholder="Dose"
                                  defaultValue={this.state.dose}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />

                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Unit</h6></div>
                            <div className="form-group">
                              <input
                                  type="text"
                                  id="unit"
                                  defaultValue={this.state.unit}
                                  onChange={this.handleChange}
                                  placeholder="Unit"
                                  name="unit"
                                  className="form-control input-md msg"
                                  required

                              />


                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Description</h6></div>
                            <div className="form-group">
                              <input
                                  type="text"
                                  id="description"
                                  name="description"
                                  defaultValue={this.state.description}
                                  onChange={this.handleChange}
                                  placeholder="Description"
                                  className="form-control input-md msg"
                                  required

                              />

                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Frequency</h6></div>
                            <div className="form-group">

                              <input
                                  name="frequency"
                                  placeholder="Frequency"
                                  defaultValue={this.state.frequency}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />

                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Instructions</h6></div>
                            <div className="form-group">
                              <input
                                  name="instructions"
                                  placeholder="instructions"
                                  defaultValue={this.state.instructions}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />

                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Reason</h6></div>
                            <div className="form-group">
                              <input
                                  name="reason"
                                  placeholder="Reason"
                                  defaultValue={this.state.reason}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />


                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Status</h6></div>
                            <div className="form-group">
                              <div className="styled-select">
                                <select
                                    name="status"
                                    placeholder="Status"
                                    value={this.state.status}
                                    onChange={this.handleChange}
                                    className="form-control input-md msg"
                                    required
                                >
                                  <option value="Select">Select</option>
                                  <option value="current">current</option>
                                  <option value="forever">forever</option>
                                  <option value="discontinue">discontinue</option>

                                </select>
                                <i className="fa fa-chevron-down"></i>
                              </div>



                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Event</h6></div>
                            <div className="form-group">

                              <input
                                  name="event"
                                  placeholder="Event"
                                  defaultValue={this.state.event}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />


                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Reminder</h6></div>
                            <div className="form-group">
                              <div className="form-check form-check-inline">
                                <input
                                    className=""
                                    type="radio"
                                    id="reminder"


                                    checked = {this.state.reminder === true}
                                    onClick={()=> this.setState({reminder:true})}
                                    required

                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  Yes
                                </label>
                              </div>

                              <div className="form-check form-check-inline ml-5">
                                <input
                                    className=""
                                    type="radio"
                                    id="reminder"

                                    checked = {this.state.reminder === false}
                                    onClick={()=> this.setState({reminder:false})}
                                    required


                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  No
                                </label>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Doctor Name</h6></div>
                            <div className="form-group">

                              <input
                                  name="doctorName"
                                  placeholder="Doctor Name"
                                  defaultValue={this.state.doctorName}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />

                            </div>
                          </div>

                          <div className="col-md-6 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Date of Prescription</h6></div>
                            <div className="form-group">

                              <input
                                  name="dateOfPrescription"
                                  id="dateOfPrescription"
                                  placeholder=""
                                  defaultValue={this.state.dateOfPrescription}
                                  onChange={this.handleChange}
                                  // className="datepicker"
                                  type="date"
                                  required

                              />

                            </div>
                          </div>
                        </div>

                        <div className='row justify-content-center align-items-center'>

                          <div className="col-md-12 col-10">
                            <div className='d-block mb-1 font-weight-bold'><h6>Other Notes</h6></div>
                            <div className="form-group">

                              <input
                                  name="notes"
                                  placeholder="Notes"
                                  defaultValue={this.state.notes}
                                  onChange={this.handleChange}
                                  className="form-control input-md msg"
                                  type="text"
                                  required

                              />

                            </div>
                          </div>


                        </div>


                      </div>
                    </div>


                    <div className="modal-footer">
                      {this.state.view===false?
                          <button
                              type="submit"
                              className="modal-save-btn"
                          >
                            {this.state.editMode?
                                "Update":
                                "Save"}
                          </button>
                          :null}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="container-fluid mt-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-success">
                    <table className="table table-hover" id="task-table">
                      <thead>
                      <tr>
                        <th>Medication Name</th>
                        <th>Generic Name</th>
                        <th>Dosage</th>
                        <th> Frequency</th>
                        <th>Status</th>

                        <th>Action</th>

                      </tr>
                      </thead>
                      <tbody>
                      { data !== null && data.length > 0 && data.map((row, index)=>(
                          <tr key={index + 0}>
                            <td>{row.name}</td>
                            <td>{row.genericName}</td>
                            <td>{row.dose}</td>
                            <td>{row.frequency}</td>
                            <td>{row.status}</td>
                            <td>
                              <button
                                  type="button"
                                  className=""
                                  data-toggle="modal"
                                  data-target="#CurrentMedModal"
                                  onClick={(event)=>{this.updateMedicationsDataStart(`${index}`)
                                  ; this.setState({editMode:true})
                                    this.setState({view:false})
                                    this.setState({medicationModal:true})
                                  }}
                              >
                                <i className="fas fa-edit text-primary"></i>
                              </button>

                              <button
                                  type="button"
                                  className=" ml-4"
                                 onClick={()=>this.deleteModal(`${row.id}`)}
                              >
                                <i className="fas fa-trash-alt text-danger "></i>
                              </button>
                                                          </td>
                          </tr>) )}
                      </tbody>
                    </table>
                    <div tabindex="-1" id="myModal-delete" className={`modal  ${
                                                                this.state.deleteModal? "d-block fading" : " d-none"
                                                            }`} >
                                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                                    <div className="modal-content">
                                                        <div className="modal-header flex-column">
                                                            <div className="icon-box">
                                                            <i className="fas fa-trash-alt text-danger"></i>
                                                            </div>
                                                            <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                                            <button type="button" className="close" onClick={this.closeDeleteModal} >&times;</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>Do you really want to delete these records? This process cannot be undone.</p>
                                                        </div>
                                                        <div className="modal-footer justify-content-center">
                                                            <button type="button" className="btn btn-secondary" onClick={this.closeDeleteModal}>Cancel</button>
                                                            <button type="button" className="btn btn-danger"  onClick={(event)=>{this.deleteMedicationRecord(this.state.toDelete)}}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
    );
  }
}
function mapStateToProps (state){

  return {
    medications: state.emergency.medication,
  }
}
export default withRouter(connect (mapStateToProps)(CurrentMedications)) ;
