import React,{useState} from 'react';

const NotesModal = () => {
  const [activeTab,setActiveTab]=useState("evaluation")

  const notes=[{name:"urwah",date:"12-06-2020",reason:"Cgm normal",evaluation:"Cgm pressure is normal",note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into"}
,{name:"urwah",date:"12-07-2020",reason:"High Blood pressure ",evaluation:"Now Blood pressure is normal",note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into"}
,{name:"urwah",date:"12-08-2020",reason:"High Blood glucose",evaluation:"Now Glucose pressure is normal",note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into"}
]
//(activeTab)
    return (
        <div>
            <div className="modal fade fading" id="notesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">Notes</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className='container mb-2'>
          <div className='row justify-content-md-between justify-content-center'>
            <div className="col-lg-6 col-11 ">
            <form className=" mr-md-3 mr-0 d-flex h-100 flex-column justify-content-between">
            <div><h5 className="mb-3 font-weight-bold ">Add Notes</h5></div>
            <textarea className="form-control mb-2" placeholder="Reason..." id="exampleFormControlTextarea1" rows="1"></textarea>  
            <textarea className="form-control mb-2" placeholder="Evaluation..." id="exampleFormControlTextarea1" rows="2"></textarea>    
            <textarea className="form-control mb-2" placeholder="Notes..." id="exampleFormControlTextarea1" rows="3"></textarea>       
        <div className="text-right">
          <button type="submit" className="w-25 btn btn-primary "> Save</button>
        </div>
      </form>
            </div>
            <div className="col-lg-6 col-11 " style={{maxHeight:'50vh',overflowY:"scroll"}}>
            <div><h5 className="mb-3 font-weight-bold ">Previous Notes</h5></div>
              {notes.map((row,index)=>{
                return(
                  <div id="accordion">
  <div className="card mb-1">
    <div className="card-header" id={`heading${index}`}>
      <h5 className="mb-0">
        <button
          className="  collapsed w-100"
          data-toggle="collapse"
          data-target={`#collapse${index}`}
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          <div className="d-flex justify-content-between">
            <span>{row.reason}</span>
            <span>{row.date}</span>
          </div>
        </button>
      </h5>
    </div>
    <div
      id={`collapse${index}`}
      className="collapse"
      aria-labelledby={`heading${index}`}
      data-parent="#accordion"
    >
      <div className="" style={{ maxHeight: "40vh", overflowY: "scroll" }}>
        <div className="container-fluid my-2 ">

        <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item">
    <a className={`nav-link  ${activeTab==="evaluation"?"bg-light":null}`} id={`evaluation-tab${index}`} data-toggle="tab" onClick={()=>{setActiveTab("evaluation")}} href={`#evaluation${index}`}  role="tab" aria-controls="evaluation" aria-selected="true">Evaluation</a>
  </li>
  <li className="nav-item">
    <a className={`nav-link  ${activeTab==="note"?"bg-light":null}`} id={`note-tab${index}`} data-toggle="tab" onClick={()=>{setActiveTab("note")}} href={`#note${index}`} role="tab" aria-controls="note" aria-selected="false">Notes</a>
  </li>
 
</ul>
<div className="tab-content my-2" id="myTabContent">
  <div className="tab-pane fade show active" id={`evaluation${index}`}  role="tabpanel" aria-labelledby="evaluation-tab">{row.evaluation}</div>
  <div className="tab-pane fade" id={`note${index}`}  role="tabpanel" aria-labelledby="note-tab">{row.note}</div>

</div>

        </div>
      </div>
    </div>
  </div>
</div>

                   
                );
              })}
                
            </div>
            </div>
        </div>
      </div>
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
        </div>
    );
};

export default NotesModal;