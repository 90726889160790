import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Loading from "./loading";


// am4core.useTheme(am4themes_animated);
// Themes end
function CGM(props) {
  const [showGraph, setShowGraph]=useState(false);
  // let chart;
  // const location = useLocation();
// // // // console.log("props.",props);
  useEffect(() => {


    let chart = am4core.create("CGM", am4charts.XYChart);

// Add data
// chart.data = props.data;
// let insulineSeriesData = props.insulin;
// let exerciseSeriesData = props.exercise;
// let medicineSeriesData = props.medicine;
// let dietSeriesData = props.diet;
// // // // // console.log("insulineSeriesData :", insulineSeriesData);
// // // // // console.log("exerciseSeriesData :", exerciseSeriesData);
// // // // // console.log("medicineSeriesData :", medicineSeriesData);
// // // // // console.log("dietSeriesData :", dietSeriesData);


chart.data = props.data && props.data.length > 0 && props.data.map(record => {
// chart.data = customeData.map(record => {

  return {
    time: new Date(record.time),
    glucose: record.glucose
  }
});
let dataLength = chart.data.length - 1;


chart.dateFormatter.dateFormat = "dd-MM-yyyy";
  // Create axes
let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.startLocation = 0.5;
dateAxis.dateFormatter = new am4core.DateFormatter();
dateAxis.dateFormatter.dateFormat = "dd-YYYY";


// Create value axis
let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis2.renderer.opposite = true;
valueAxis2.syncWithAxis = valueAxis;

// Create series

let lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.dataFields.valueY = "glucose";
lineSeries.dataFields.dateX = "time";
lineSeries.strokeWidth = 0;
// lineSeries.fillOpacity = 0;
lineSeries.stroke="#3498db";
// lineSeries.sequencedInterpolation = false;

let bullet = lineSeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 3;
    bullet.fill = ("#ad3860");
    bullet.tooltipText = "Date: {dateX} \n Reading: {valueY} mmol/L";
// Insulin Series
// let insulineSeries = chart.series.push(new am4charts.LineSeries());
// insulineSeries.dataFields.valueY = "dosage";
// insulineSeries.dataFields.dateX = "time";
// insulineSeries.strokeWidth = 0;
// insulineSeries.fillOpacity = 0;
// insulineSeries.sequencedInterpolation = false;
// insulineSeries.data= insulineSeriesData;
// let insulineBullet = insulineSeries.bullets.push(new am4charts.Bullet());
// let insulinImage = insulineBullet.createChild(am4core.Image);
// insulinImage.href = insulinIcon;
// insulinImage.width = 30;
// insulinImage.height = 30;
// insulinImage.horizontalCenter = "top";
// insulinImage.verticalCenter = "middle";
// insulineBullet.tooltipText = "Date: {dateX} \n Dosage: {valueY} \n Insulin Name: {insulinName}";

// Diet series
// let dietSeries = chart.series.push(new am4charts.LineSeries());
// dietSeries.dataFields.valueY = "carbohydrate";
// dietSeries.dataFields.dateX = "time";
// dietSeries.strokeWidth = 0;
// dietSeries.fillOpacity = 0;
// dietSeries.sequencedInterpolation = false;
// dietSeries.data= dietSeriesData;
// dietSeries.yAxis = valueAxis2;
// let dietBullet = dietSeries.bullets.push(new am4charts.Bullet());
// let dietImage = dietBullet.createChild(am4core.Image);
// dietImage.href = dietIcon;
// dietImage.width = 30;
// dietImage.height = 30;
// dietImage.horizontalCenter = "middle";
// dietImage.verticalCenter = "middle";
// dietBullet.tooltipText = "Date: {dateX} \n carbohydrate: {carbohydrate} \n Food: {food}";



// Exercise Series
// let exerciseSeries = chart.series.push(new am4charts.LineSeries());
// exerciseSeries.dataFields.valueY = "duration";
// exerciseSeries.dataFields.dateX = "time";
// exerciseSeries.strokeWidth = 0;
// exerciseSeries.fillOpacity = 0;
// exerciseSeries.sequencedInterpolation = false;
// exerciseSeries.data= exerciseSeriesData;
// let exerciseBullet = exerciseSeries.bullets.push(new am4charts.Bullet());
// let exerciseImage = exerciseBullet.createChild(am4core.Image);
// exerciseImage.href = exerciseIcon;
// exerciseImage.width = 30;
// exerciseImage.height = 30;
// exerciseImage.horizontalCenter = "middle";
// exerciseImage.verticalCenter = "middle";
// exerciseBullet.tooltipText = "Date: {dateX} \n Duration: {valueY} \n Intensity: {Intensity}";



// Medicine Series
// let medicineSeries = chart.series.push(new am4charts.LineSeries());
// medicineSeries.dataFields.valueY = "dosage";
// medicineSeries.dataFields.dateX = "time";
// medicineSeries.strokeWidth = 0;
// medicineSeries.fillOpacity = 0;
// medicineSeries.sequencedInterpolation = false;
// medicineSeries.data= medicineSeriesData;
// let medicineBullet = medicineSeries.bullets.push(new am4charts.Bullet());
// let image = medicineBullet.createChild(am4core.Image);
// image.href = medicineIcon;
// image.width = 30;
// image.height = 30;
// image.horizontalCenter = "middle";
// image.verticalCenter = "middle";
// medicineBullet.tooltipText = "Date: {dateX} \n Dosage: {valueY} \n Medicine Name: {medicine_name}";


/* Create legend and enable default markers */
chart.legend = new am4charts.Legend();
// chart.legend.useDefaultMarker = true;

/* Remove square from marker template */
let marker = chart.legend.markers.template;
marker.disposeChildren();
marker.width = 40;
marker.height = 40;




chart.scrollbarX = new am4core.Scrollbar();
// chart.scrollbarX.parent = chart.bottomAxesContainer;
//
// chart.scrollbarX.thumb.minWidth = 5;
// chart.scrollbarX.startGrip.disabled = true;
// chart.scrollbarX.endGrip.disabled = true;


chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.startGrip.background.fill = am4core.color('#ad3860');
    chart.scrollbarX.endGrip.background.fill = am4core.color('#ad3860');
    chart.scrollbarX.thumb.background.fill = am4core.color('#ad3860');
    chart.scrollbarX.startGrip.width = 10;
    chart.scrollbarX.startGrip.height = 10;
    chart.scrollbarX.endGrip.width = 10;
    chart.scrollbarX.endGrip.height = 10;
    chart.scrollbarX.minHeight = 4;





// chart.scrollbarX.startGrip.width=10;
// chart.scrollbarX.endGrip.width=10;

// let range = valueAxis.axisRanges.create();
// range.value = 0;
// range.endValue = 4;
// range.axisFill.fill = am4core.color("#ff7dc561");
// range.axisFill.fillOpacity = 0.2;
// range.grid.strokeOpacity = 0;
//
// let range1 = valueAxis.axisRanges.create();
// range1.value = 12;
// range1.endValue = 30;
// range1.axisFill.fill = am4core.color("#ffff008c");
// range1.axisFill.fillOpacity = 0.2;
// range1.grid.strokeOpacity = 0;

chart.cursor = new am4charts.XYCursor();
// chart.cursor.behavior= 'panXY';

chart.events.on('ready', () => {
  setShowGraph(true);
});
return () => {
      chart.dispose();
    };


}, [props.data, props.diet, props.exercise, props.medicine, props.insulin]);

  return (<div className="row w-100 h-full m-auto">
  <div className={showGraph?"d-none":"col-12"}>                    <Loading/>
</div>
        <div id="CGM" className={showGraph?" col-12 h-full":"d-none col-12 h-full"}></div>
  </div>
  );
}
export default CGM;
