import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import Tooltip from "../../utils/Tooltip";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default function HistoricalReadingsChartBg({
  backgroundColor = "bg-white",
  dataPoints,
  color1 = "#3498db",

  name = "Readings",
  name2 = "Readings",
  name3 = "Readings",
  id,
  text,
  renderOn,
  themeType
}) {


  let chart;
  useEffect(() => {
    // set chart container
    chart = am4core.create(id, am4charts.XYChart);

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    // dateAxis.dateFormats.setKey("day", "MM-dd");
    dateAxis.dateFormats.setKey("day", "[font-size: 9px]dd-MM");
    dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy");

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled =false;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "reading";
    series.strokeWidth = 3;
    series.stroke = am4core.color(themeType==="bp"?"#271CA1":color1);
    series.fill = themeType==="bp"?"#271CA1":"#ad3860";
    // series.sequencedInterpolation = true;
    series.tooltipText = "{valueY}";
    // series.bullets.push(new am4charts.CircleBullet());
    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color(themeType==="bp"?"#271CA1":"#ad3860");
    bullet.circle.radius = 4;
    bullet.tooltipText = "{dateX}:  {valueY}";
    // series.fillOpacity = 0.3;
    series.defaultState.transitionDuration = 1000;
    series.name = name;

    if (dataPoints.length > 0 && dataPoints[0].reading2) {
      const series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.dateX = "date";
      series2.dataFields.valueY = "reading2";
      series2.strokeWidth = 3;
      series2.stroke = am4core.color("#EA7B85");
      series2.fill = "#EA7B85";
      series2.sequencedInterpolation = true;
      series2.tooltipText = "{valueY}";
      // series2.bullets.push(new am4charts.CircleBullet());
      var bullet = series2.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#EA7B85");
      bullet.circle.radius = 4;
      // series2.fillOpacity = 0.3;
      series2.defaultState.transitionDuration = 1000;
      series2.name = name2;
      // Create axes



    }

    if (dataPoints.length > 0 && dataPoints[0].reading3) {
    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.dateX = "date";
    series3.dataFields.valueY = "reading3";
    series3.strokeWidth = 3;
    series3.stroke = am4core.color("#E4BE7F");
    series3.fill = "#E4BE7F"
    series3.sequencedInterpolation = true;
    series3.tooltipText = "{valueY}";
    // series3.bullets.push(new am4charts.CircleBullet());
    var bullet = series3.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#E4BE7F");
    bullet.circle.radius = 4;
    // series3.fillOpacity = 0.3;
    series3.defaultState.transitionDuration = 1000;
    series3.name = name3;
    }
    chart.data = dataPoints;
        // Tooltip
        series.tooltip.label.textAlign = "middle";
        series.tooltip.pointerOrientation = "down";
        series.tooltip.dy = -5;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.scrollbarX = new am4core.Scrollbar();
    // add cursor new
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.startGrip.background.fill = am4core.color(themeType==="bp"?"#271CA1":"#ad3860");
    chart.scrollbarX.endGrip.background.fill = am4core.color(themeType==="bp"?"#271CA1":"#ad3860");
    chart.scrollbarX.thumb.background.fill = am4core.color(themeType==="bp"?"#271CA1":"#ad3860");
    chart.scrollbarX.startGrip.width = 10;
    chart.scrollbarX.startGrip.height = 10;
    chart.scrollbarX.endGrip.width = 10;
    chart.scrollbarX.endGrip.height = 10;
    chart.scrollbarX.minHeight = 4;

    // Add legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, chart);

  return (
  <div
    className={window.location.pathname!=="/dashboard" && window.location.pathname!=="/blood-glucose"?`bg-history border-radius-chart px-3 py-2`:"w-100 h-100"}
  >
    <p className="chart-heading font-weight-bold text-xl w-full text-left">
      {renderOn==="dashboardBloodGlucose"?"Blood Glucose":"Historical Reading"}
     <span className='ml-2'><Tooltip  text={text}/></span>
    </p>

      <div
        style={{ width: "100%", height: "100%" }}
        className={window.location.pathname!=="/dashboard"?"w-full h-full py-5":null}
        id={id}
      ></div>
  </div>
  );
}
