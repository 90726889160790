import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
const COLORS = ["#D8DFBA", "#7FD0A5"];

export default function RepresentativeComplianceChart(props) {
  useEffect(() => {
    let chart;

    // set chart container
    chart = am4core.create("complianceRateChartDiv", am4charts.PieChart);

    // set dataFields & data
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";
    series.slices.template.propertyFields.fill = "color";
    chart.data = props.dataPoints.map((data, index) => {
      data.color = am4core.color(COLORS[index % COLORS.length]);
      return data;
    });
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;

    chart.radius = 80;
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, );

  return (
    <div className="my-3 md:my-0 md:ml-2 w-full bg-white rounded-lg shadow-lg p-4 flex justify-center items-center flex-col">
      <p className="font-bold text-xl">Representative Compliance Rate</p>
      <div
        style={{
          minHeight: "16.75rem",
          maxHeight: "16.75rem",
        }}
        className="w-full h-full"
        id={"complianceRateChartDiv"}
      ></div>
    </div>
  );
}
