const createRequestObjectFromForm = (values) => ({
  smoking: {
    valid: values.smokingStatus === "yes",
    smokingTime: values.smokingTime,
    packsPerDay: values.packsPerDay,
    quitTime: values.smokingQuitTime,
    lastSmoked: values.lastSmoked,
    comments: values.smokingComments,
  },
  drinking: {
    valid: values.drinkingStatus === "yes",
    drinksPerDay: values.drinksPerDay,
    drinkSocially: values.drinkSocially === "yes",
    quitTime: values.drinkingQuitTime,
    lastDrink: values.lastDrink,
    comments: values.drinkingComments,
  },
  // sexDetails: values.safeSex,
});

const createFormObjectFromRequest = (values) => ({
  smokingStatus: values.smoking.valid
    ? "yes"
    : values.smoking.quitTime && values.smoking.lastSmoked
    ? "usedTo"
    : "never",
  smokingTime: values.smoking && values.smoking.smokingTime,
  packsPerDay: values.smoking && values.smoking.packsPerDay,
  smokingQuitTime: values.smoking && values.smoking.quitTime,
  lastSmoked: values.smoking && values.smoking.lastSmoked,
  smokingComments: values.smoking && values.smoking.comments,

  drinkingStatus: values.drinking.valid
    ? "yes"
    : values.drinking.quitTime && values.smoking.lastDrink
    ? "usedTo"
    : "never",
  drinksPerDay: values.drinking && values.drinking.drinksPerDay,
  drinkSocially: values.drinking && values.drinking.drinksPerDay ? "yes" : "no",
  drinkingQuitTime: values.drinking && values.drinking.quitTime,
  lastDrink: values.drinking && values.drinking.lastDrink,
  drinkingComments: values.drinking && values.drinking.comments,

  // safeSex: values.sexDetails,
});

module.exports = {
  createFormObjectFromRequest,
  createRequestObjectFromForm,
};
