import React from "react";
import * as _ from 'lodash';
import Tooltip from "../../../../utils/Tooltip";

const Heart = require("../../../../assets/heart.png");


const BpStats = ({
  lowest = { systolic: 0, heartRate: 0, diastolic: 0 },
  highest = { systolic: 0, heartRate: 0, diastolic: 0 },
  averageHeartRate = 0,
  average = { systolic: 0, diastolic: 0, heartRate: 0 },
}) => {
  return (
    <div className="row mw-100 m-auto bg-white border-radius-chart bg-stats">
    <div className="col-11 m-auto">
    <p className="chart-heading font-weight-bold text-xl pt-2">Blood Pressure Stats
    <span className='ml-2'><Tooltip text="Systolic blood pressure indicates how much pressure your blood is exerting against your artery wall when the heart beat.Diastolic blood pressure indicates how much pressure your blood is exerting against your artery while the heart resting between beat."/></span>
    </p>

    </div>
      <div className="col-11 m-auto p-3 text-center">
      <div className="row mw-100 m-auto">
        <div className="col-6">

          <div className="bp-box bp-1 m-auto">
          <div className="heading py-1 text-center">Highest BP Reading</div>
          <div className='d-flex flex-column align-items-center justify-content-center card-body '> <p className=" font-bold">{highest.systolic} sys</p>
            <p className=" font-bold">{highest.diastolic} dia</p>
            <p className="flex font-bold flex-row items-center">
              {highest.heartRate}{" "}
              <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
            </p></div>

          </div>
        </div>
        <div className="col-6">

          <div className="bp-box bp-2 m-auto">
           <div className="heading py-1 text-center">Lowest BP Reading</div>
          <div className='d-flex flex-column align-items-center justify-content-center card-body '>
            <p className=" font-bold">{lowest.systolic} sys</p>
            <p className=" font-bold">{lowest.diastolic} dia</p>
            <p className="flex font-bold flex-row items-center">
              {lowest.heartRate}{" "}
              <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
            </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="col-11 m-auto p-3 text-center">
      <div className="row mw-100 m-auto">
        <div className="col-6">

        <div className="bp-box bp-3 m-auto">
        <div className="heading py-1 text-center">Average</div>
        <div className='d-flex flex-column align-items-center justify-content-center card-body '>
          <p className=" font-bold"> {_.round(average.systolic, 1).toFixed(1)} sys</p>
          <p className=" font-bold">{_.round(average.diastolic, 1).toFixed(1)} dia</p>
          <p className="flex font-bold flex-row items-center">
          {_.round(average.heartRate, 1).toFixed(1)}{" "}
            <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
          </p>
          </div>
        </div>
        </div>
        <div className="col-6">

        <div className="bp-box bp-4 m-auto">
        <div className="heading py-1 text-center">Average Heart Rate</div>
        <div className='d-flex flex-column align-items-center justify-content-center card-body '>
          <span className="flex font-bold flex-row items-center">
            <img style={{ marginLeft: "5px", height: "35px" }} src={Heart} />
          </span>
          <div className="flex font-bold flex-row items-center">
            {_.round(averageHeartRate, 1).toFixed(1)} {" "}
          </div>
          <div className="flex font-bold flex-row items-center">
          pm
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
    // <div className="my-3 md:my-0 md:mx-2 w-full bg-white rounded-lg shadow-lg p-4 ">
    //   <p className="font-bold text-xl">Blood Pressure Stats</p>
    //
    //   <div className="flex items-center md:justify-between md:my-8 h-full md:h-auto flex-col md:flex-row">
    //     <div className="flex flex-col items-center justify-center">
    //       <div className="mb-2">Highest BP Reading</div>
    //       <div className="bg-indigo-500 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">{highest.systolic} sys</span>
    //         <span className=" font-bold">{highest.diastolic} dia</span>
    //         <span className="flex font-bold flex-row items-center">
    //           {highest.heartRate}{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Lowest BP Reading</div>
    //       <div className="bg-indigo-400 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">{lowest.systolic} sys</span>
    //         <span className=" font-bold">{lowest.diastolic} dia</span>
    //         <span className="flex font-bold flex-row items-center">
    //           {lowest.heartRate}{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Average</div>
    //       <div className="bg-indigo-300 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">120 sys</span>
    //         <span className=" font-bold">80 dia</span>
    //         <span className="flex font-bold flex-row items-center">
    //           90{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Average Heart Rate</div>
    //       <div className="bg-indigo-200 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className="flex font-bold flex-row items-center">
    //           {averageHeartRate} pm{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BpStats;
