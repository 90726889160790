import React,{useState} from 'react';

const AddAppointment = () => {
    const [name,setName]=useState("");
    const [patientId,setPatientid]=useState("");
    return (
        <div>
           <div className='container'>
               <div className="row justify-content-center mt-3">
                   <div className="col-8">
                       <form>
                       <div className='row justify-content-between justify-content-md-center mt-5'>
                           <div className="col-md-6">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Name</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Name"/>
                            </div>
                            </div>
                           <div className="col-md-6">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Patient Id</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Patient Id"/>
                            </div>
                           </div>
                       </div>

                       <div className='row justify-content-between justify-content-md-center mt-2'>
                           <div className="col-md-6">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Doctor</label>
                                <div className="styled-select">
                          <select
                            // value={gender}
                            // onChange={(e) => setGender(e.target.value)}
                            placeholder="Select a Blood Group"
                            className="registration-form-control "
                          >
                            <option value="Select">Select</option>
                            <option value="Male">Sufyan</option>
                            <option value="Female">Urwah</option>
                            <option value="Female">Tabish</option>
                          </select>
                          <i className="fa fa-chevron-down"></i>
                        </div>
                            </div>
                            </div>
                           <div className="col-md-3">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Email Id</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Email Id"/>
                            </div>
                           </div>
                           <div className="col-md-3">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Telephone</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Telephone Number"/>
                            </div>
                           </div>
                       </div>

                       <div className='row justify-content-between justify-content-md-center mt-3 schedule'>
                           <div className="col-md-6">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Schedule On</label>
                                <input type="date" className=" shedule-date-picker" id="exampleInputPassword1" placeholder="Name"/>
                            </div>
                            </div>
                           <div className="col-md-3">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">At</label>
                                <input type="time" className=" shedule-date-picker" id="exampleInputPassword1" placeholder="Name"/>
                            </div>
                           </div>
                           <div className="col-md-3">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">For</label>
                                <div className="styled-select">
                          <select
                            // value={gender}
                            // onChange={(e) => setGender(e.target.value)}
                            placeholder="Select a Blood Group"
                            className="registration-form-control "
                          >
                            <option value="Select">Select</option>
                            <option value="Male">15 min</option>
                            <option value="Female">30 min</option>
                            <option value="Female">45 min</option>
                          </select>
                          <i className="fa fa-chevron-down"></i>
                        </div>
                            </div>
                           </div>
                       </div>

                       <div className='row justify-content-between justify-content-md-center mt-5'>
                           <div className="col-md-12">
                           <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Reason of Appointment</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Name"/>
                            </div>
                            </div>
                           
                       </div>

                       <div className='row justify-content-end  mt-2'>
                       <div className="col-md-2">
                           <button type="submit" className="bg-secondary w-100 rounded text-center text-white py-3 px-3">Cancel</button>
                            </div>
                           <div className="col-md-2">
                           <button type="submit" className="bg-primary w-100 rounded text-center text-white py-3 px-3">Save</button>
                            </div>
                           
                       </div>


                       </form>
                   </div>
               </div>
           </div>
        </div>
    );
};

export default AddAppointment;