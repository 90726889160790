import React from 'react';

const Schedule = () => {
    return (
        <div>
            <div className="container-fluid bg-white p-4">
                <div className="row justify-content-between align-items-center font-weight-bold text-success">
                    <div className="col-md-3 ">
                     Today Report
                    </div>
                    <div className="col-md-3  text-right">
                     Next Patient
                    </div>
                </div>
                 
                <div className="row justify-content-between align-items-center font-weight-bold text-success mt-4">
                    <div className="col-md-2 d-flex justify-content-between">
                       
                     <div className="d-flex flex-column justify-content-center bg-danger text-white text-center  px-2">
                       <div className="font-size2">{10}</div>
                       <div className="font-size-small-1">patients</div>
                     </div>
                     <div className="d-flex flex-column justify-content-center bg-primary text-white text-center  px-2">
                       <div className="font-size2">{5}</div>
                       <div className="font-size-small-1">pending</div>
                     </div>
                     <div className="d-flex flex-column justify-content-center bg-success text-white text-center  px-2">
                       <div className="font-size2">{5}</div>
                       <div className="font-size-small-1">visit</div>
                     </div>
                    </div>
                    <div className="col-md-2  text-right">
                     <div className="bg-success rounded text-white text-center font-weight-bold ">
                       <span className="font-size1"> {"Jackson Albert" }</span> <br>
                         </br>
                         {"Id:123456789"}
                     </div>
                    </div>
                </div>

                <div className="row justify-content-between align-items-center font-weight-bold text-success mt-4">
                    
                    <div className="col-md-5 ">
                    <input type="email" className="form-patient-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Patient Name"></input>
                    </div>
                    <div className="col-md-5 ">
                    <input type="email" className="form-patient-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="patient Id"></input>
                    </div>
                    <div className="col-md-2  text-right">
                    <button type="submit" className="btn submit-btn">Search</button>
                    </div>
                   
                </div>

                <div className='row mt-4 justify-content-between'>
                    <div className='col-md-3 bg-light-gray py-2 text-black font-weight-bold'>Patient Name</div>
                    <div className='col-md-5 bg-light-gray py-2 text-black font-weight-bold'>Patient Address</div>
                    <div className='col-md-2 bg-light-gray py-2 text-black font-weight-bold'>Patient Code</div>
                    <div className='col-md-2 bg-light-gray py-2 text-black text-center font-weight-bold'>Status</div>
                </div>
                <div className='row mt-1 '>
                    <div className='col-md-3 bg-light py-2 text-black '>Jhon Smith</div>
                    <div className='col-md-5 bg-light py-2 text-black '>2 Apline Street, Carott, CF10 134</div>
                    <div className='col-md-2 bg-light py-2 text-black '>123456789</div>
                    <div className='col-md-2 bg-light py-2 text-black text-center'><input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/></div>
                </div>
                <div className='row mt-1 '>
                    <div className='col-md-3 bg-light py-2 text-black '>Jhon Smith</div>
                    <div className='col-md-5 bg-light py-2 text-black '>2 Apline Street, Carott, CF10 134</div>
                    <div className='col-md-2 bg-light py-2 text-black '>123456789</div>
                    <div className='col-md-2 bg-light py-2 text-black text-center'><input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/></div>
                </div> <div className='row mt-1 '>
                    <div className='col-md-3 bg-light py-2 text-black '>Jhon Smith</div>
                    <div className='col-md-5 bg-light py-2 text-black '>2 Apline Street, Carott, CF10 134</div>
                    <div className='col-md-2 bg-light py-2 text-black '>123456789</div>
                    <div className='col-md-2 bg-light py-2 text-black text-center'><input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/></div>
                </div>

            </div>
        </div>
    );
};

export default Schedule;