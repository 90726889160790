export const ACCEPTED_USERS_START="ACCEPTED_USERS_START";
export const ACCEPTED_USERS_FAILED="ACCEPTED_USERS_FAILED";
export const ACCEPTED_USERS_SUCCESS="ACCEPTED_USERS_SUCCESS";

export const ACCEPTED_USERS_PAGES_START="ACCEPTED_USERS_PAGES_START";
export const ACCEPTED_USERS_PAGES_SUCCESS="ACCEPTED_USERS_PAGES_SUCCESS";
export const ACCEPTED_USERS_PAGES_FAILED="ACCEPTED_USERS_PAGES_FAILED";

export const PENDING_INVITES_START="PENDING_INVITES_START";
export const PENDING_INVITES_FAILED="PENDING_INVITES_FAILED";
export const PENDING_INVITES_SUCCESS="PENDING_INVITES_SUCCESS";

export const DELETE_USERS_START="DELETE_USERS_START";
export const DELETE_USERS_FAILED="DELETE_USERS_FAILED";
export const DELETE_USERS_SUCCESS="DELETE_USERS_SUCCESS";