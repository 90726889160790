import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux"
import { fetchVitalInfo, saveVitalInfo } from "../../../redux/actions/history";
import ShowToast from "../../../utils/show-toast";

class VitalInfoNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHeight: false,
      showWeight: false,
      height:'',
      centimetersValue:'',
      feet:'',
      inch:'',
      // kgs:'',
      weight:'',
      kgValue:'',
      // lbs:'',
      lbsValue:'',
      oz:'',
      ozValue:'',
      pulse:'',
      temperatureUnit:"Celsius",
      temperatureValue:'',
      glucose:'',
      glucoseValue:'',
      upper:'',
      lower:'',
      heartRate:'',
      spo2Pulse:'',
      spo2:'',
      activity:'',
      bloodGroup:'',

    };
  }

  componentDidMount() {


    if (this.props.vitalInfo.data !== null){

    } else {

      this.props.dispatch(fetchVitalInfo());
    }

  }
  componentWillReceiveProps(nextProps, nextContext) {

    if(nextProps.vitalInfo.data !== null) {
      let info = nextProps.vitalInfo.data;
      this.setState({
        activity: info.activity.value,
        bloodGroup: info.bloodGroup,
        height:info.height.unit,
        centimetersValue: info.height.centimeters,
        feet:info.height.feet,
        inch:info.height.inch,
        weight:info.weight.unit,
        kgValue:info.weight.kgs,
        lbsValue:info.weight.lbs,
        ozValue:info.weight.oz,
        pulse:info.pulse.beats,
        temperatureUnit:info.temperature.unit,
        temperatureValue:info.temperature.value,
        glucose:info.glucose.unit,
        glucoseValue:info.glucose.value,
        upper:info.bloodPressure.upper,
        lower:info.bloodPressure.lower,
        heartRate:info.bloodPressure.heartRate,
        spo2Pulse:info.spo2.pulse,
        spo2:info.spo2.value,

      })
    }
  }
  handleKeypress (e) {
    const characterCode = e.key
    if (characterCode === 'Backspace') return

    const characterNumber = Number(characterCode)
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return
      } else if (characterNumber === 0) {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }
  }
  handleChange = (event) => {
    //////(event);
    //////(event.target.value);
  this.setState({
    [event.target.name]: event.target.value
    })
  }
  handleSubmitForm = async (event) => {
    event.preventDefault();

    await this.props.dispatch(saveVitalInfo({
        height: {
          unit: this.state.height,
          centimeters: this.state.height === "Centimeters"? this.state.centimetersValue: 0,
          feet: this.state.height === "Feet"? this.state.feet: 0,
          inch: this.state.height === "Feet"? this.state.inch: 0,
        },
        weight: {
          unit: this.state.weight,
          kgs: this.state.weight === "kgs"? this.state.kgValue: 0,
          lbs:  this.state.weight === "lbs"? this.state.lbsValue: 0,
          oz: this.state.weight === "lbs"? this.state.ozValue: 0,
        },
        pulse: {
          beats: this.state.pulse,
        },
        temperature: {
          unit: this.state.temperatureUnit,
          value: this.state.temperatureValue,
        },
        bloodPressure: {
          upper: this.state.upper,
          lower: this.state.lower,
          heartRate: this.state.heartRate,
        },
        glucose: {
          unit: this.state.glucose,
          value: this.state.glucoseValue
        },
        spo2: {
          pulse: this.state.spo2Pulse,
          value: this.state.spo2,
        },
        activity: {
          value: this.state.activity,
        },
        bloodGroup: this.state.bloodGroup,
    }));
    ShowToast("Success!", "success");
    await this.props.dispatch(fetchVitalInfo());
  }

  //
  render() {


    return (
      <div className="container my-5">
        <form autoComplete="off" onSubmit={this.handleSubmitForm}>
          <h1 className="text-center mb-4 mt-5 "></h1>
          <fieldset>
            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Blood Group</h4>
              </div>
            </div>
            <div className="row   justify-content-center">
              <div className="col-md-8 col-10">
                {" "}
                <div className="form-group">
                  <div className="styled-select">
                    <select
                      name="bloodGroup"
                      id="blood-group"
                      placeholder="Select a Blood Group"
                      className="form-control input-md msg"
                      onChange={this.handleChange}
                      value={this.state.bloodGroup}
                      required
                    >
                      <option value="">Select</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Height </h4>
              </div>
            </div>
            <div className="row  justify-content-center">
              <div className="col-md-1 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline">
                  <input
                    className=""
                    type="radio"
                    name="feet"
                    id="height"

                    onChange={()=> {this.setState({height:"Feet"})}}
                    checked={this.state.height === "Feet"}
                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox1"
                  >
                    Feet
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    name="centimeters"
                    id="height"
                    value="option2"
                    onChange={()=> {this.setState({height:"Centimeters"})}}
                    checked={this.state.height === "Centimeters"}
                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox2"
                  >
                    Centimeters
                  </label>
                </div>
              </div>

              {this.state.height=== "Feet" ? (
                <div className="col-md-4 col-10 px-md-3 px-0 ">
                  <div className="row px-2">
                    <div className="col-md-6 col-10 ">
                      {" "}
                      <div className="form-group">
                        <div className="input-group ">
                          <input
                            name="feet"
                            placeholder="Feet"
                            className="form-control input-md msg"
                            type="number"
                            onChange={this.handleChange}
                            defaultValue={this.state.feet}
                            onKeyDown={this.handleKeypress} min='0' step='1'
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-10 ">
                      {" "}
                      <div className="form-group">
                        <div className="input-group ">
                          <input
                            name="inch"
                            placeholder="Inches"
                            className="form-control input-md msg"
                            type="number"
                            onChange={this.handleChange}
                            defaultValue={this.state.inch}
                            onKeyDown={this.handleKeypress} min='0' step='1'
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-4 col-10 ">
                  {" "}
                  <div className="form-group">
                    <div className="input-group ">
                      <input
                        name="centimetersValue"
                        placeholder="Height"
                        className="form-control input-md msg"
                        type="number"
                        onChange={this.handleChange}
                        defaultValue={this.state.centimetersValue}
                        min='0' step="any"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Weight </h4>
              </div>
            </div>
            <div className="row  justify-content-center">
              <div className="col-md-1 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline">
                  <input
                    className=""
                    type="radio"
                    name="kgs"
                    id="weight"
                    value="option3"
                    // onChange={this.handleWeight}
                    // checked={!this.state.showWeight}
                    onChange={()=> {this.setState({weight:"kgs"})}}
                    checked={this.state.weight === "kgs"}
                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox1"
                  >
                    Kg
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-10 d-flex justify-content-start">
                <div className="form-check nl-5 form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    name="lbs"
                    id="weight"
                    value="option4"
                    // checked={this.state.showWeight}
                      // onChange={()=>{this.setState({exampleRadios4:"Fahrenheit"})}}
                    onChange={()=> {this.setState({weight:"lbs"})}}
                    checked={this.state.weight === "lbs"}
                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox2"
                  >
                    Lbs
                  </label>
                </div>
              </div>
              {this.state.weight === "lbs"? (
                <div className="col-md-4 col-10 px-md-3 px-0 ">
                  <div className="row px-2">
                    <div className="col-md-6 col-10 ">
                      {" "}
                      <div className="form-group">
                        <div className="input-group ">
                          <input
                            name="lbsValue"
                            placeholder="Lbs"
                            className="form-control input-md msg"
                            type="number"
                            onChange={this.handleChange}
                            defaultValue={this.state.lbsValue}
                            onKeyDown={this.handleKeypress} min='0' step='1'

                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-10 ">
                      {" "}
                      <div className="form-group">
                        <div className="input-group ">
                          <input
                            name="ozValue"
                            placeholder="Oz"
                            className="form-control input-md msg"
                            type="number"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeypress} min='0' step='1'
                            defaultValue={this.state.ozValue}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-4 col-10 ">
                  {" "}
                  <div className="form-group">
                    <div className="input-group ">
                      <input
                        name="kgValue"
                        placeholder="Weight"
                        className="form-control input-md msg"
                        type="number"
                        onChange={this.handleChange}
                        defaultValue={this.state.kgValue}
                        min='0' step="any"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Temperature </h4>
              </div>
            </div>
            <div className="row  justify-content-center">
              <div className="col-md-1 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline">
                  <input
                    className=""
                    type="radio"
                    name="temperatureUnit"
                    id="exampleRadios4"
                    value="option5"
                    onChange={()=>{this.setState({temperatureUnit:"Fahrenheit"})}}
                    checked={this.state.temperatureUnit === "Fahrenheit"}

                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox1"
                  >
                    °F
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-10 d-flex justify-content-start">
                <div className="form-check nl-5 form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    name="temperatureUnit"
                    id="exampleRadios4"
                    value="option6"
                    onChange={()=>{this.setState({temperatureUnit:"Celsius"})}}
                    checked={this.state.temperatureUnit === "Celsius"}

                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox2"
                  >
                    °C
                  </label>
                </div>
              </div>
              {this.state.temperatureUnit === "Celsius"?
              <div className="col-md-4 col-10 ">
                {" "}
                <div className="form-group">
                  <div className="input-group ">
                    <input
                      name="temperatureValue"
                      placeholder="Temperature"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.temperatureValue}
                      min='0' step="any"
                      required
                    />
                  </div>
                </div>
              </div>:
              <div className="col-md-4 col-10 ">
              {" "}
              <div className="form-group">
                <div className="input-group ">
                  <input
                    name="temperatureValue"
                    placeholder="Temperature"
                    className="form-control input-md msg"
                    type="number"
                    onChange={this.handleChange}
                    defaultValue={this.state.temperatureValue}
                    min='0' step='1'
                    onKeyDown={this.handleKeypress}
                    required
                  />
                </div>
              </div>
            </div>}
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Glucose </h4>
              </div>
            </div>
            <div className="row  justify-content-center">
              <div className="col-md-1 col-10 d-flex justify-content-start">
                <div className="form-check form-check-inline">
                  <input
                    className=""
                    type="radio"
                    name="mg/dl"
                    id="glucose"
                    // value="option7"
                    onChange={()=>{this.setState({glucose:"mg/dl"})}}
                    checked={this.state.glucose === "mg/dl"}


                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox1"
                  >
                    mg/dL
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-10 d-flex justify-content-start">
                <div className="form-check nl-5 form-check-inline ">
                  <input
                    className=""
                    type="radio"
                    name="mmol/L"
                    id="glucose"
                    // value="option8"
                    onChange={()=>{this.setState({glucose:"mmol/L"})}}
                    checked={this.state.glucose === "mmol/L"}

                  />
                  <label
                    className="form-check-label ml-md-3 ml-0"
                    htmlFor="inlineCheckbox2"
                  >
                    mmol/L
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-10 ">
                {" "}
                <div className="form-group">
                  <div className="input-group ">
                    <input
                      name="glucoseValue"
                      placeholder="Glucose"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.glucoseValue}

                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Blood Pressure </h4>
              </div>
            </div>
            <div className="row mt-2 justify-content-center">
              <div className="col-md-2 col-10 ">
                {" "}
                <div className="form-group">
                  <div className="">
                    <input
                      name="heartRate"
                      placeholder="Heart Rate"
                      className="form-control input-md msg"
                      onChange={this.handleChange}
                      defaultValue={this.state.heartRate}

                      type="number"
                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-10 ">
                {" "}
                <div className="form-group">
                  <div className="">
                    <input
                      name="upper"
                      placeholder="Systolic"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.upper}

                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-10 ">
                {" "}
                <div className="form-group">
                  <div className="">
                    <input
                      name="lower"
                      placeholder="Diastolic"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.lower}

                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">SpO2</h4>
              </div>
            </div>
            <div className="row mt-2 justify-content-center">
              <div className="col-md-4 col-10">
                {" "}
                <div className="form-group">
                  <div className="">
                    <input
                      name="spo2"
                      placeholder="SpO2"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.spo2}

                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-10">
                {" "}
                <div className="form-group">
                  <div className="">
                    <input
                      name="spo2Pulse"
                      placeholder="Pulse"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.spo2Pulse}

                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <h4 className=" vital-info-field-head">Activity</h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-10">
                <div className="form-group">
                  <div className="">
                    <input
                      name="activity"
                      placeholder="Steps"
                      className="form-control input-md msg"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={this.state.activity}
                     onKeyDown={this.handleKeypress} min='0' step='1'
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-8 mt-3 d-flex justify-content-end">
                <button type="submit" className="bg-filter-btn mx-1 add-info-btn">
                  Save
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state) {

  return {
    vitalInfo: state.history.vitalInfo,
  }
}

export default withRouter(connect(mapStateToProps)(VitalInfoNew));
