import React from "react";

import AidexChart from "./aidex-chart";
import AgpChart from "./agp-chart";

// import BloodGlucoseExpo from "../Charts/BloodGlucoseExpo";
import { useSelector} from "react-redux";


// import { useHistory } from "react-router-dom";
const CgmReport = (props) => {
  // let history = useHistory();
  // // // // console.log("PROPS IN REPORT", props);
  const cgmReadings = useSelector((state)=> state.cgm.cgmReadings);


  return (
    <div className='h-100 d-flex w-100 flex-column'>

    <div className="chart-heading mt-3 loginNew-HCP-panel">
      <span className="p-2 font-weight-bol chart-heading text-voyger-purple">
      {props.graph && props.graph==="cgm"?
        "CGM Readings":"AGP Readings"}
      </span>
    </div>



  <div className="d-flex flex-column justify-content-center px-3 mt-3 mb-4 h-100">
{props.graph && props.graph==="cgm"?
    <AidexChart data={props.data && props.data.length>0? props.data:cgmReadings} diet={props.diet} medicine={props.medicine} exercise={props.exercise} insulin={props.insulin}/>
    :
    <AgpChart data={props.data} callBackFunc={props.callBackFunc}/>
  }


  </div>
  </div>
  );
};

export default CgmReport;
