import http from "../../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl, updateUnit } from "../../../utils/urls";
import {
  FETCH_BG_START,
  FETCH_BG_FAILED,
  FETCH_BG_SUCCESS,
} from "../../actionTypes";
import {encrypt, decrypt} from "../../../utils/encryption";
import {logout} from "../auth";


export const fetchBgData = (data, unit) => async(dispatch) => {
  dispatch({
    type: FETCH_BG_START,
  });
try {

    let bg;
    let res;
    ////("Data in blood glucose action=",data)
    if(data.time){
      bg = data.time;

      bg = encrypt(bg);

      let type = 1;
      type = JSON.stringify(type);
      type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?time=${bg}`);
    } else if(data.start_date) {

      let bg_start = data.start_date;
      bg_start = encrypt(bg_start);
      let bg_end = data.end_date;
      bg_end = encrypt(bg_end);

      let type = 1;
      type = JSON.stringify(type);
      type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?start_date=${bg_start}&end_date=${bg_end}`,);
}
let gift = res.data.data;

gift = decrypt(gift);
gift = JSON.parse(gift);

  updateUnit(unit, res.data.data)
  dispatch({ type: FETCH_BG_SUCCESS, payLoad: gift });

  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
  dispatch({
    type: FETCH_BG_FAILED,
  });
}
};
