module.exports = {
  DIABETES_INSULIN_DEPENDANT: "Diabetes-Insulin dependent",
  HYPERTENSION: "Hypertension",
  CORONARY_ARTERY_DISEASE: "Coronary Artery Disease",
  KIDNEY_DISEASE: "Kidney Disease",
  ALCOHOLISM_SUBSTANCE_ABUSE: "Alcoholism/Substance Abuse",
  ALS: "ALS (Lou Gehrig's Disease)",
  ALZHEIMER: "Alzheimer's/ Dementia",
  ANEMIA: "Anemia/ Bleeding Problems",
  BREAST_CANCER: "Breast Cancer",
  DEPRESSION: "Depression/ Other Mental Illness",
  HEPATITIS: "Hepatitis/ Liver Disease",
  HIGH_CHOLESTEROL: "High Cholesterol",
  OSTEOPOROSIS: "Osteoporosis",
  SEIZURES: "Seizure Disorders",
  STROKE: "Stroke",
  THYROID_DISEASE: "Thyroid Disease",
  TUBERCULOSIS: "Tuberculosis",
  INTESTINAL_CANCER: "Intestinal Cancer",
  OVARIAN_CANCER: "Ovarian Cancer",
  LIVER_CANCER: "Liver Cancer",
  CANCER_OTHER: "Cancer other",
  OTHER: "Other",
};
