import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default function UsersGenderChart(props) {
  useEffect(() => {
    let chart;

    // set chart container
    chart = am4core.create("UsersGenderChartDiv", am4charts.PieChart);

    // set dataFields & data
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";
    series.slices.template.propertyFields.fill = "color";
    chart.data = props.dataPoints;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;
    chart.innerRadius = 60;

    chart.radius = 80;
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, );

  return (
    <div className="my-3 md:my-0  md:mx-2 w-full bg-white rounded-lg shadow-lg p-4 flex flex-col">
      <p className="font-bold text-xl">Gender</p>
      <div
        style={{
          minHeight: "16.75rem",
          maxHeight: "16.75rem",
          //   minWidth: "18.75rem",
        }}
        className="w-full h-full"
        id={"UsersGenderChartDiv"}
      ></div>
    </div>
  );
}
