import React from "react";
// import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import AidexLogo from "../../../../assets/AidexLogo.svg";

import LoadingIcon from "../../../../assets/loading.gif";

// import { useLocation } from 'react-router-dom';


const Loading = () => {
  // const location = useLocation();

  return (
    <div className= "col-12 col-md-4 m-auto text-center">
      <div className="row mw-100 m-auto h-100 loading border-radius-chart p-3">
        <div className="col-12">
        <img src={AidexLogo} className="w-50 aidex-logo m-auto " alt="AIDEX LOGO"/>

        </div>
        <div className="col-12">
          <img src={LoadingIcon} className="w-50 m-auto" alt="Loading Gif"/>
        </div>
        <div className="col-12">
        <p className="font-bold text-white">PLEASE WAIT DOWNLOADING DATA</p>

        </div>

      </div>
    </div>
  );
};

export default Loading;
