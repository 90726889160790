import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default function ActiveUsersChart(props) {
  let chart;
  useEffect(() => {
    // set chart container
    chart = am4core.create("ActiveUsersChartDiv", am4charts.XYChart);

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "count";
    series.strokeWidth = 3;
    series.stroke = am4core.color("#3498db");
    series.fill = "#fff";
    series.sequencedInterpolation = true;
    series.tooltipText = "{valueY}";
    series.bullets.push(new am4charts.CircleBullet());
    // series.fillOpacity = 0.3;
    series.defaultState.transitionDuration = 1000;
    series.name = "Active Users";

    chart.data = props.dataPoints;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.scrollbarX = new am4core.Scrollbar();

    // Add legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, chart);

  const totalCount = props.dataPoints.reduce(
    (sum, item) => sum + item.count,
    0
  );

  return (
    <div className="my-3 md:my-0  md:mx-2 w-full bg-white rounded-lg shadow-lg p-4 flex justify-center items-center flex-col">
      <p className="font-bold text-xl flex justify-between w-full">
        Active Users <span>{totalCount}</span>
      </p>
      <div
        style={{
          minHeight: "16.75rem",
          maxHeight: "16.75rem",
          // minWidth: "18.75rem",
        }}
        className="w-full h-full"
        id={"ActiveUsersChartDiv"}
      ></div>
    </div>
  );
}
