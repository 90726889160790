import { convertToLocalTime } from "../DateTime";

const moment = require("moment");

const numToEnglish = (num) => {
  switch (num) {
    case 1:
      return "once";
    case 2:
      return "twice";
    case 3:
      return "thrice";
    default:
      return `${num} times`;
  }
};

const createPreviewObjectFromRequest = (values) => {
  const date = moment.utc(new Date(values.startDate));
  return {
    date: date.format("LL"),
    time: date.format("hh:mm A"),
    medicationName: values.medicationName,
  };
};

const createDetailsObjectFromRequest = (values) => ({
  ...createPreviewObjectFromRequest(values),
  medicationName: values.medicationName,
  reminderFrequency: `${numToEnglish(values.howManyTimesADay)}`,
  timeAndDose: values.timeAndDose && values.timeAndDose.map((item) => ({
    ...item,
    time: convertToLocalTime(item.timeDate),
  })),
  condition: values.condition,
  startDate:
    moment.utc(new Date(values.startPillDate)).format("YYYY-MM-DD")
      ,
      howManyTimesADay:values.howManyTimesADay,
      strengthAmount:values.strengthAmount,
      unit:values.unit,
  isScheduled: values.schedule ? (values.schedule.valid ? "Yes" : "No") : "No",
  duration: values.schedule
    ? values.schedule.duration === -1
      ? "On-Going"
      : `${values.schedule.duration} days`
    : "",
});

export default {
  createDetailsObjectFromRequest,
  createPreviewObjectFromRequest,
};
