import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Spin, Pagination, Empty } from "antd";
import ViewModal from "./Modals/ViewModal";
import NotesModal from "./Modals/NotesModal";
import CancelModal from "./Modals/CancelModal";
import MedicationModal from "./Modals/MedicationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptedUsers,
  searchSpecificPatient,
} from "../../../redux/actions/external-doctor/inviteList";
import Table from "./Table";
const WEIGHT = require("../../../assets/Diagnostics/GRx Weight Scales.png");
const BP = require("../../../assets/Diagnostics/GRx Blood Pressure Monitor.png");
const TEMP = require("../../../assets/Diagnostics/GRx Forehead Thermometer Pro.png");
const PO = require("../../../assets/Diagnostics/GRx Fingertip Pulse Oximeter.png");
const Nexus = require("../../../assets/products/GlucoRxNexusMeter.png");

const PatientList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [page, setPage] = useState(1);
  const [patientSearch, setPatientSearch] = useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [tableUpdate, setTableUpdate] = useState(false);
  const [searchItem, setSearchItem] = useState(false);

  const list = useSelector(
    (state) => state.inviteList.acceptedInviteList
  );
  const numberOfPages = useSelector(
    (state) => state.inviteList.acceptedInviteList.totalPages
  );

  //Api Call
  useEffect(() => {
    handleSharedUser();
  }, [page,tableUpdate,clearSearch]);

  //Handle Pagination
  const handleSharedUser = async () => {
    let noOfRecord = 6;
    setLoading(true);
    const res = await dispatch(acceptedUsers({ page, noOfRecord }));

    setLoading(false);
  };

  //Handle page Change
  const onChange = (page, pageSize) => {
    setPage(page);
  };

  //Handle Patient Search
  const handleSpecificPatientSearch = async () => {
    setSearchItem(true)
    setLoading(true);
    await dispatch(searchSpecificPatient(patientSearch));
    setLoading(false);
  };

  //Handle Clear SearchField
  const handlerClearSearchField=()=>{
    setClearSearch(!clearSearch);
    setPatientSearch("");
    setSearchItem(false)
  }

  //Setting Row Id
  const settingId=(id)=>{
    setRowId(id)
  }

  //Update the table After row delete
  const handleUpdating=(update)=>{
    setTableUpdate(update)
  }

  return (

      <div className="container-fluid h-100 bootstrap snippets bootdey patients-list bg-white">
        <div className="row mb-4">
          <div className="col-lg-12 mb-2">
            <div className=" d-flex flex-md-row flex-column justify-content-center justify-content-md-between">
              <div className="w-100 ">
              <div className="input-group specific-patient-search mb-3">
                 <input
                  type="text"
                  value={patientSearch}
                  onChange={(e) => {
                    setPatientSearch(e.target.value);
                  }}
                  className="form-control clearable"
                  placeholder="Search by Patient's firstName, lastName, email, date of birth(YYYY-MM-DD), postcode."
                  aria-label="search"
                  aria-describedby="basic-addon2"

                />

                <div className="input-group-append">
                {patientSearch !== "" && searchItem? (
                 <i className="fas fa-times cancel" onClick={handlerClearSearchField}></i>

                ) : null}
                  <button
                  style={{zIndex:"0"}}
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSpecificPatientSearch}
                    disabled={patientSearch===null||patientSearch===""?true:false}
                  >
                   <span><i className="fas fa-search mr-2"></i></span> Search
                  </button>
                </div>
              </div>


            </div>
            <div className="w-100 mt-3 mt-md-0 d-flex justify-content-md-end justify-content-center ">
              <Pagination
                   current={page}
                   total={numberOfPages && 10 * numberOfPages}
                   onChange={onChange}
                   showSizeChanger={false}
                   hideOnSinglePage={true}
                 />
              </div>
            </div>
            <div className="no-header clearfix">
              <div className="main-box-body clearfix">
                <div className="d-flex justify-content-between w-100 mb-3 ">
                  <div>
                     <p className="font-weight-bold h-4">{patientSearch !== "" && searchItem?`Result for: ${patientSearch}`:null}</p>
                  </div>


                </div>
                <div className="table-responsive">
                  <table className="table table-bordered user-list">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-center">
                          <span>Name</span>
                        </th>
                        <th className="text-center">
                          <span>Age</span>
                        </th>
                        <th className="text-center">
                          <span>Dob</span>
                        </th>
                        <th className="text-center">
                          <span>Email</span>
                        </th>
                        <th className="text-center">
                          <span>Phone Number</span>
                        </th>
                        <th className="text-center">
                          <div
                            className="btnTooltip"
                            data-html="true"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Tooltip on top"
                          >
                            <img
                              style={{ maxWidth: "100%", height: "35px" }}
                              src={Nexus}
                            />
                          </div>
                        </th>
                        <th className="text-center">
                          <div className="">
                            <img
                              style={{ maxWidth: "100%", height: "35px" }}
                              src={BP}
                            />
                          </div>
                        </th>
                        <th className="text-center">
                          <div className="">
                            <img
                              style={{ maxWidth: "100%", height: "35px" }}
                              src={WEIGHT}
                            />
                          </div>
                        </th>
                        <th className="text-center">
                          <div className="">
                            <img
                              style={{ maxWidth: "100%", height: "35px" }}
                              src={TEMP}
                            />
                          </div>
                        </th>
                        <th className="text-center">
                          <div className="">
                            <img
                              style={{ maxWidth: "100%", height: "35px" }}
                              src={PO}
                            />
                          </div>
                        </th>

                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    {!loading
                      ? React.Children.toArray(
                          list && list.users &&
                            list.users.map((row, index) => {
                              return <Table row={row} index={index} settingId={settingId} patientSearch={patientSearch}/>;
                            })
                        )
                      : null}
                    <tbody></tbody>
                    <ViewModal />
                    <CancelModal rowId={rowId} tableUpdate={tableUpdate} handleUpdating={handleUpdating}/>
                    <NotesModal />
                    <MedicationModal />
                  </table>
                  <div className="d-flex justify-content-center">
                  {list&& list!==[]?null:<Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                              height: 60,
                              marginTop: "60px",
                            }}
                            description={
                              <span>
                                No Data Found .

                              </span>
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              minHeight: "20.75rem",
                              maxHeight: "20.75rem",
                              alignItems: "center",
                            }}
                          ></Empty>}
                  </div>
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spin spinning={loading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  );
};

export default PatientList;
