import React, { useEffect, useLayoutEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { DashboardImage as Dash } from "../../../../assets";
import { History as History } from "../../../../assets";
import { Emergency as Emergency } from "../../../../assets";
import { Glucose as Glucose } from "../../../../assets";
import { Bp as Bp } from "../../../../assets";
import Po from "../../../../assets/NavIcons/Po.svg";
import inr from "../../../../assets/NavIcons/inr.png";
import Weightscale from "../../../../assets/NavIcons/Weightscale.svg";
import temperature from "../../../../assets/NavIcons/temperature.svg";
import right from "../../../../assets/right-caret.svg";
import back from "../../../../assets/back-arrow.svg";
import aidexLogo from "../../../../assets/AidexLogo.svg";

import contact from "../../../../assets/NavIcons/contact.svg";
import { NavLink, Redirect } from "react-router-dom";

const PatientHeader = () => {
  const [doctor,setDoctor]=useState(false)
  const token= useSelector((state)=>{
    if(state.aidexAuth && state.aidexAuth.token)
    {return state.aidexAuth.token}
  });
  useEffect(()=>{

    return(
      setDoctor(false)
    )
  },[doctor])
  useEffect(()=>{
  },[localStorage.getItem('AidexAuthToken'), token])

    var doc=localStorage.getItem('doc')
    const handleBackToDoc=()=>{
      localStorage.setItem('grxAuthToken',doc)
      localStorage.removeItem("doc")

      window.location.replace('/dashboard');
      setDoctor(true)


    }

    const screen = window.innerWidth;
    const handleCollapse = () => {

      var nav = document.getElementById("navbarNav");
      var btn = document.getElementById("navbarBtn");
      nav.classList.remove("show");
      btn.classList.add("collapsed");
    };
    return (
        <ul className="navbar-nav mr-auto text-white">

            {doc && doc!==""?
            <li className="nav-item mr-lg-2 active">
              <div className=" top-nav-link d-flex justify-content-center">
                <button
                  className="pl-2 pr-2 d-flex py-3"
                  onClick={()=>{handleBackToDoc()}}
                ><img
                    src={back}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  Doctor
                </button>
              </div>
           </li>
            :null}

            <li className="nav-item mr-lg-2 active">
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  exact={true}
                  to="/dashboard"
                  onClick={handleCollapse}
                >
                  <img
                    src={Dash}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  Dashboard
                </NavLink>
              </div>
            </li>
            {localStorage.getItem('AidexAuthToken') && localStorage.getItem('AidexAuthToken')!== null && localStorage.getItem('AidexAuthToken') !== ""?
            <li className="nav-item mr-lg-2 active">
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  exact={true}
                  to="/aidex"
                  onClick={handleCollapse}
                >
                  <img
                    src={aidexLogo}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  GlucoRx Aidex
                </NavLink>
              </div>
            </li>:null }

            <li className="nav-item mr-lg-2">
              <div className=" top-nav-link">
                <NavLink className="pl-2 pr-2 d-flex py-3" to="/blood-glucose" onClick={handleCollapse}>
                  <img
                    src={Glucose}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  Blood Glucose
                </NavLink>
              </div>
            </li>
            <li className="nav-item mr-lg-2 ">
              {" "}
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/blood-pressure"
                  onClick={handleCollapse}
                >
                  <img
                    src={Bp}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />
                  Blood Pressure
                </NavLink>
              </div>
            </li>
            {/*<li className="nav-item mr-lg-2">
              <div className=" top-nav-link">
                <NavLink className="pl-2 pr-2 d-flex py-3" to="/inr" onClick={handleCollapse}>
                  <img
                    src={inr}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  INR
                </NavLink>
              </div>
            </li>*/}
             {/* //WeightScale*/}
            <li className="nav-item mr-lg-2">
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  onClick={handleCollapse}
                  to="/weight-scale"
                >
                  <img
                      src={Weightscale}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Weight Scale
                </NavLink>
              </div>
            </li>

            {/* {For Mobile Screen } */}

            <li className="nav-item mr-lg-2 d-lg-none d-block ">
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/pulse-oximeter"
                  onClick={handleCollapse}
                >
                  <img
                    src={Po}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />
                  Pulse Oximeter
                </NavLink>
              </div>
            </li>
            <li className="nav-item mr-lg-2 d-lg-none d-block ">
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/history"
                  onClick={handleCollapse}
                >
                  <img
                    src={History}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />
                  History
                </NavLink>
              </div>
            </li>
            <li className="nav-item mr-lg-2 d-lg-none d-block ">
              <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/emergency"
                  onClick={handleCollapse}
                >
                  <img
                    src={Emergency}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />
                  Emergency
                </NavLink>
              </div>
            </li>




            {/* {For Mobile Screen} */}

            {screen > 1930 ? (
              <li className="nav-item mr-lg-2 ">
                <div className=" top-nav-link">
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3"
                    to="/pulse-oximeter"
                    onClick={handleCollapse}
                  >
                    <img
                      src={Po}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Pulse Oximeter
                  </NavLink>
                </div>
              </li>
            ) : null}

                {screen > 1930 ? (
              <li className="nav-item mr-lg-2 ">
                <div className=" top-nav-link">
                <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3"
                  to="/temperature"
                  onClick={handleCollapse}
                >
                  <img
                      src={temperature}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Temperature
                </NavLink>
                </div>
              </li>
            ) : null}
            {screen > 1930 ? (
              <li className="nav-item mr-lg-2 ">
                <div className=" top-nav-link">
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3"
                    to="/history"
                    onClick={handleCollapse}
                  ><img
                    src={History}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  History
                  </NavLink>
                </div>
              </li>
            ) : null}
            {screen > 1930 ? (
              <li className="nav-item mr-lg-2 ">
                <div className=" top-nav-link">
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3"
                    to="/emergency"
                    onClick={handleCollapse}
                  >
                      <img
                    src={Emergency}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  Emergency
                  </NavLink>
                </div>
              </li>
            ) : null}
            {screen > 1930 ? (
              <li className="nav-item mr-lg-2  ">
                <div className=" top-nav-link">
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3"
                    to="/contact"
                    onClick={handleCollapse}
                  >
                    <img
                      src={contact}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Contact Us
                  </NavLink>
                </div>
              </li>
            ) : null}
            {screen <= 1930 ? (
              <li
                className="d-lg-flex d-none nav-item mr-lg-3 dropdown "
                style={{ width: "60px", backgroundColor: "none" }}
              >
                <button
                  className="d-flex justify-center align-items-center dropdown-toggler"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src={right} className=""></img>
                </button>

                <div
                  className="dropdown-menu text-white"
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{ backgroundColor: "#003650" }}
                >
                  <NavLink
                  activeClassName="active"
                  className="pl-2 pr-2 d-flex py-3 dropdown-option mt-2"
                  to="/temperature"
                  onClick={handleCollapse}
                >
                  <img
                      src={temperature}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Temperature
                </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3 dropdown-option mt-2"
                    to="/pulse-oximeter"
                    onClick={handleCollapse}
                  >
                    <img
                      src={Po}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Pulse Oximeter
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3 dropdown-option mt-2"
                    to="/history"
                    onClick={handleCollapse}
                  ><img
                    src={History}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  History

                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3 dropdown-option mt-2"
                    to="/emergency"
                    onClick={handleCollapse}
                  >
                      <img
                    src={Emergency}
                    style={{ maxWidth: "25px", marginRight: "4%" }}
                  />{" "}
                  Emergency
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="pl-2 pr-2 d-flex py-3 dropdown-option mt-2"
                    to="/contact"
                    onClick={handleCollapse}
                  >
                    <img
                      src={contact}
                      style={{ maxWidth: "25px", marginRight: "4%" }}
                    />
                    Contact Us
                  </NavLink>
                </div>
              </li>
            ) : null}

          </ul>
    );
};

export default PatientHeader;
