import React,{useState,useEffect,useRef, useLayoutEffect } from "react"
import { Spin } from "antd";
import ShowToast from "../../../utils/show-toast";
import { useDispatch } from "react-redux";
import {shareInvite} from "../../../redux/actions/external-doctor/shareInvite";
import patient from "../../../assets/addpatient.jpg";



const AddPatient = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {


    }, [phone]);

    const handleSubmit = async (e) => {

        setIsLoading(true);
        e.preventDefault();
        const res= await dispatch(shareInvite( {email,phone}));
        setIsLoading(false);
        //(res.response);
        if(res.response){
            if( res.response.data.status==="failure"){
                //(res);
                ShowToast(res.response.data.message, "error")
            }
            else{
                //(res);
                ShowToast(res.response.data.message, "success")
            }

        }
        else{
            if( res.data.status==="failure"){
                //(res);
                ShowToast(res.data.message, "error")
            }
            else{
                //(res);
                ShowToast(res.data.message, "success")
            }


        }

        // } catch (err) {
        //   setIsLoading(false);
        //   //("response=",err.response);
        //   ShowToast(err.response.data.message, "error")
        //   // if (err.message === "Request failed with status code 403") {
        //   //   history.push("/auth/resendVerificationEmail");
        //   // }
        // }
    };
    return (
        <div className='container-fluid'>
            {/*Start Table */}
            <div className='row justify-content-center'>
            <div className="col-12 text-center add-pat" style={{height:"40vh"}}>

            </div>
                <div className="col-lg-5 col-11">
                    <div className='text-center rounded  mb-5'>
                        {/* <i className="fas fa-user-plus fa-5x "></i>
                        <h1>Please enter email and phone number to send data share request.</h1>*/}
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className="form-group">
                            <input type="email" className="form-control" name="username" placeholder="Email"  value={email}
                                   onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                   name="email"
                                   required="required" />
                        </div>
                        <div className="form-group">
                            <input type="number" className="form-control" name="Phone" onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" value={phone} required="required"/>
                        </div>
                        <div className="form-group d-flex w-50 justify-content-center">
                            <Spin spinning={isLoading}>
                                <button type="submit" className=" btn btn-primary btn-lg btn-block login-btn"><i className="far fa-paper-plane"></i> Send</button>
                            </Spin>
                        </div>
                    </form>
                </div>
            </div>

            {/*End Table */}
        </div>
    );
};

export default AddPatient;
