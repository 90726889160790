export const FETCH_CGM_RECORD_START = "FETCH_CGM_RECORD_START"
export const FETCH_CGM_RECORD_SUCCESS = "FETCH_CGM_RECORD_SUCCESS"
export const  FETCH_CGM_RECORD_FAILED = "FETCH_CGM_RECORD_FAILED"
export const  RESET_CGM_RECORD = "RESET_CGM_RECORD"


export const FETCH_HISTORY_CGM_RECORD_START = "FETCH_HISTORY_CGM_RECORD_START"
export const FETCH_HISTORY_CGM_RECORD_SUCCESS = "FETCH_HISTORY_CGM_RECORD_SUCCESS"
export const  FETCH_HISTORY_CGM_RECORD_FAILED = "FETCH_HISTORY_CGM_RECORD_FAILED"


export const FETCH_SORTED_CGM_RECORD_START = "FETCH_SORTED_CGM_RECORD_START"
export const FETCH_SORTED_CGM_RECORD_SUCCESS = "FETCH_SORTED_CGM_RECORD_SUCCESS"
export const FETCH_SORTED_CGM_RECORD_FAILED = "FETCH_SORTED_CGM_RECORD_FAILED"

export const FETCH_EXPORT_CGM_RECORD_START = "FETCH_EXPORT_CGM_RECORD_START"
export const FETCH_EXPORT_CGM_RECORD_SUCCESS = "FETCH_EXPORT_CGM_RECORD_SUCCESS"
export const FETCH_EXPORT_CGM_RECORD_FAILED = "FETCH_EXPORT_CGM_RECORD_FAILED"


export const CGM_RECORD_CALCULATIONS = "CGM_RECORD_CALCULATIONS"

export const EXPORT_CGM_RECORD_CALCULATIONS = "EXPORT_CGM_RECORD_CALCULATIONS"
export const EXPORT_CGM_EVENTS = "EXPORT_CGM_EVENTS"
