import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default function GlucoseEventsChart(props) {
  useEffect(() => {
    let chart;

    chart = am4core.create("glucoseEventsChartDiv", am4charts.PieChart);
    // set dataFields & data
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";
    chart.data = props.dataPoints;
    // chart.radius = 50;

    chart.innerRadius = am4core.percent(60);
    series.labels.template.disabled = true;
    series.ticks.template.disabled = true;

    const label = series.createChild(am4core.Label);
    label.text = `${props.percentage} %`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 24;

    var colorSet = new am4core.ColorSet();
    colorSet.list = ["#3699db", "#63b3ed", "#2b6cb0"].map(
      function (color) {
        return new am4core.color(color);
      }
    );
    series.colors = colorSet;

    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <div className="mt-1 md:mt-0 flex flex-col justify-center items-center">
      <p className="font-semibold text-md text-center">Glucose Events</p>
      <div
        style={{
          minHeight: "12.75rem",
          maxHeight: "12.75rem",
        }}
        className="w-full h-full"
        id={"glucoseEventsChartDiv"}
      ></div>
    </div>
  );
}
