import React from 'react';

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      messageUsername: "input type",
      messagePassword: "input not typed"
    }
  }

  handleOnBlurUsername(event) {
    var username        = event.target.value;
    var messageUsername = "";

    if(!username) {
      messageUsername = "Username required"; 
    }

    this.setState({
      username: username,
      messageUsername: messageUsername
    });
  }

  render() {
    return (
      <div>
        <label>
          Username:
        </label>
        <input
          type="text"
          onBlur={this.handleOnBlurUsername.bind(this)}
        />
        {this.state.messageUsername}
        <br/>
        <label>
          Password:
        </label>
        <input
          type="password"
        />
        {this.state.messagePassword}
        <hr/>
        <button>
          Login
        </button>
      </div>
    );
  }
}