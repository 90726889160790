import * as actions from "../../actionTypes/history";

const initialState = {
  pulseStats: [],
  historicalReadings:[],
  readings:[],
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TEMP_START:
      return{...state,
      isLoading: true}
      break
    case actions.FETCH_TEMP_SUCCESS:
      return {
        ...state,
        ...action.payLoad,
        isLoading: false
      }
      break

    case actions.FETCH_TEMP_FAILED:
      return{
        ...state,
        isLoading: false
      }
      break

    default:
      return state;
  }
}
