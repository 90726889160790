import React from "react";
import { Select, Form } from "antd";
const { Option } = Select;

const BloodGroup = ({ value = null, setValue }) => {
  return (
    <div className=" flex items-center flex-col md:flex-row">
      <Form.Item
        value={value}
        name="bloodGroup"
        label="Blood Group"
        rules={[{ required: true, message: "please select a bloodGroup" }]}
        className="w-full md:w-4/6 flex justify-center"
      >
        <Select
          className=""
          placeholder="Select a Blood Group"
          onChange={setValue}
        >
          <Option value="A+">A+</Option>
          <Option value="A-">A-</Option>
          <Option value="B+">B+</Option>
          <Option value="B-">B-</Option>
          <Option value="O+">O+</Option>
          <Option value="O-">O-</Option>
          <Option value="AB+">AB+</Option>
          <Option value="AB-">AB-</Option>
        </Select>
      </Form.Item>
    </div>
  );
};

export default BloodGroup;
