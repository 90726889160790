import React, { Component } from "react";
import { connect } from "react-redux";
import {saveShareToken} from "../../redux/actions/doctor";

import { Route, Redirect } from "react-router-dom";
import { is } from "@amcharts/amcharts4/core";

class GuestRoute extends Component {
  redirect=()=>{
    return(
      <Redirect to={ localStorage.getItem("pathname")} />
    )

  }
  componentDidMount(){
   if(window.location.pathname==="/user/activate-account" && window.location.search!=="") {
    localStorage.removeItem("grxAuthToken")
   }
  //  if(this.props.isAuthenticated==false){
  //      this.redirect();
  //  }


  }

  render() {
//(this.props);
    const { isAuthenticated, component: Component, ...rest } = this.props;
    if (this.props.path === "/" && !isAuthenticated) {
      // var url = window.location;
      // var access_token = new URLSearchParams(url.search).get('token');
      // this.props.dispatch(saveShareToken(access_token));

      return <Redirect to="/auth/login" />;
    } else if(isAuthenticated && localStorage.getItem("url") !== "/dashboard" &&
        localStorage.getItem("url") !== "/auth/login" && localStorage.getItem("url") !== "/") {

    }

    //("Location=",window.location.pathname);
    //(!isAuthenticated)

    return (
      <div>
        <Route
          {...rest}
          render={(props) =>

            !isAuthenticated ? (
              <Component {...props} />
            )
            :localStorage.getItem("pathname") &&  localStorage.getItem("pathname") !== "/auth/login"?
            (<Redirect to={ localStorage.getItem("pathname")} />

          ):window.location.pathname === "/auth/updateEmail" ? (<Component {...props} />) :(
              <Redirect to="/dashboard" />
            )
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { token } = state.auth;
  return {
    isAuthenticated: !!token,
  };
}

export default connect(mapStateToProps)(GuestRoute);
