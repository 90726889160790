export const DefaultTitle = "GlucoRX";

export default {
  default: `${DefaultTitle} | Welcome`,
  login: `${DefaultTitle} | Login`,
  register: `${DefaultTitle} | Register`,
  devices: `${DefaultTitle} | Devices`,
  bloodGlucose: `${DefaultTitle} | Blood Glucose`,
  bloodPressure: `${DefaultTitle} | Blood Pressure`,
  pulseOximeter: `${DefaultTitle} | Pulse Oximeter`,
  weightScale: `${DefaultTitle} | Weight Scale`,
  temprature: `${DefaultTitle} | Temprature`,
  contactUs: `${DefaultTitle} | Contact Us`,
  pharmacy: `${DefaultTitle} | Pharmacy`,
  onlineDoctor: `${DefaultTitle} | Online Doctor`,
  shareData: `${DefaultTitle} | Share Data`,






  dashboard: `${DefaultTitle} | Dashboard`,
  history: `${DefaultTitle} | History`,
  emergency: `${DefaultTitle} | Emergency`,
  forgotPassword: `${DefaultTitle} | Forgot Password`,
  resendVerificationEmail: `${DefaultTitle}  | Resend Verification Email`,
};
