import * as actions from "../actionTypes/index";

const initialState = {
  userDetails: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.FETCH_USER_SUCCESS:

      return {
        ...state,
        isLoading: false,
        userDetails: action.payload.user,
      };

      case actions.UPDATE_USER_START:
        return {
          ...state,
          isLoading: true,
        };
      case actions.UPDATE_USER_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case actions.UPDATE_USER_SUCCESS:
        return {
          ...state,
          isLoading: false,

        };
    default:
      return state;
  }
};
