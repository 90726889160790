import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

export default function NonCompliantVendorChart(props) {
  let chart;
  useEffect(() => {
    // set chart container
    chart = am4core.create("NonCompliantVendorChartDiv", am4charts.XYChart);

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "compliantVendors";
    series.tooltipText = "{name}: {valueY}";
    series.sequencedInterpolation = true;
    series.fillOpacity = 0.3;
    series.tensionX = 0.8;
    series.defaultState.transitionDuration = 1000;
    series.name = "Compliant Vendors";

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.dateX = "date";
    series2.dataFields.valueY = "nonCompliantVendors";
    series2.tooltipText = "{name}: {valueY}";
    series2.sequencedInterpolation = true;
    series2.fillOpacity = 0.3;
    series2.tensionX = 0.8;
    series2.defaultState.transitionDuration = 1000;
    series2.name = "Non-Compliant Vendors";

    chart.data = props.dataPoints;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.scrollbarX = new am4core.Scrollbar();

    // Add legend
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, chart);

  return (
    <div className="my-3 md:my-0 md:ml-2 w-full bg-white rounded-lg shadow-lg p-4 flex justify-center items-center flex-col">
      <p className="font-bold text-xl">Non-Compliant Vendor Representative</p>
      <div
        style={{
          minHeight: "16.75rem",
          maxHeight: "16.75rem",
        }}
        className="w-full h-full"
        id={"NonCompliantVendorChartDiv"}
      ></div>
    </div>
  );
}
