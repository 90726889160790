import React,{useState} from 'react';
import CircleBadge from "./CircleBadge";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserShareToken } from "../../../redux/actions/external-doctor/shareData";
import { Spin } from 'antd';



const Table = ({row,index,settingId,patientSearch}) => {
    const dispatch = useDispatch();
    const docToken = useSelector((state) => state.auth.token);
    const [collapse, setCollapse] = useState(false);
    const [loading,setLoading]=useState(false);

    //Handling View btn
    const handleViewData=async(row)=>{
      setLoading(true)
      let res= await dispatch(fetchUserShareToken(row._id))
       localStorage.setItem("doc",docToken)
       setLoading(false)
    }

  let nameSearch=patientSearch!==""?row.firstName.toLowerCase().includes(patientSearch.toString().toLowerCase()):false
  let lastNameSearch=patientSearch!==""?row.lastName.toLowerCase().includes(patientSearch.toString().toLowerCase()):false
  let emailSearch=patientSearch!==""?row.email.toLowerCase().includes(patientSearch.toString().toLowerCase()):false

  return (
        <>

                <tbody key={index} style={{textAlign:'center',width:"100%",height:"100%"}}>
                            <tr   >
                        <td>
                         <button onClick={()=>{setCollapse(!collapse)}} type="button" data-toggle="collapse" data-target={`#collapseExample${index}`} aria-expanded="false" aria-controls="collapseExample"><i className={collapse?"fas fa-chevron-up":"fas fa-chevron-down"}></i></button>
                        </td>
                      
                        <td className={nameSearch ||lastNameSearch? "table-primary":""}>{`${row.firstName} ${row.lastName}`} </td>
                        <td>42</td>
                        <td className={ " text-center"}>
                          <span className="">22-03-1989</span>
                        </td>
                        <td className={emailSearch? "table-primary":""}>{row.email}</td>
                        <td>{row.phone}</td>
                        <td>
                          <CircleBadge color="bg-success" />
                        </td>
                        <td>
                          <CircleBadge color="bg-danger" />
                        </td>
                        <td>
                          <CircleBadge color="bg-warning" />
                        </td>
                        <td>
                          <CircleBadge color="bg-success" />
                        </td>

                        <td>
                          <CircleBadge color="bg-success" />
                        </td>

                        <td className='text-center'>
                        <Spin spinning={loading}>
                          <button
                            className=" text-primary "
                            style={{ width: "30px" }}
                            onClick={()=>{
                              handleViewData(row);

                            }}
                          >
                            <i className="fas fa-lg fa-eye"></i>
                          </button>
                          <button
                            className="text-warning ml-2 "
                            style={{ width: "30px" }}
                            data-toggle="modal"
                            data-target="#notesModal"
                          >
                            <i className="fas fa-lg fa-notes-medical"></i>
                          </button>
                          <button
                            className="text-success ml-2 "
                            style={{ width: "30px" }}
                            data-toggle="modal"
                            data-target="#medModal"
                          >
                            <i className="fas fa-lg fa-capsules"></i>
                          </button>
                          <button
                            className="text-danger ml-2 "
                            style={{ width: "30px" }}
                            data-toggle="modal"
                            data-target="#cancelModal"
                            onClick={()=>{settingId(row._id)}}
                          >
                            <i className="fas fa-lg fa-times"></i>
                          </button>
                          </Spin>
                        </td>


                      </tr>

                       <tr className="collapse" id={`collapseExample${index}`} >
                      <td></td>

                      <td colSpan="4">
                        {row.address}
                          </td>
                      <td colSpan="7">
                        <button className="border rounded px-3 py-2 mr-2" style={{width:"20%"}} >Ponston 25mg</button>
                        <button className="border rounded px-3 py-2 mr-2" style={{width:"20%"}}>Pill 25mg</button>
                        <button className="border rounded px-3 py-2 mr-2" style={{width:"20%"}}>Capsule 25mg</button>
                        <button className="border rounded px-3 py-2 mr-2" style={{width:"20%"}}>Brufen 25mg</button>
                    </td>
                    </tr>
                    </tbody>
        </>
    );
};

export default Table;
