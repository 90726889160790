import React from "react";

import WheightScaleGauge from "./weight-gauge"

const Heart = require("../../../../assets/heart.png");


const WsStats = (props) => {

  //(props);
  let length , wLost,heading;
  if(props.readings && props.readings.length>0){
     length = props.readings.length;
     wLost = props.readings[0].value.weight - props.readings[props.readings.length - 1].value.weight;
    //(wLost, props.readings[props.readings.length-1], length);
    if(wLost < 0) {
      wLost= (wLost * -1)
    }
    heading=props.readings[0].value.weight>props.readings[props.readings.length - 1].value.weight?"Weight Gained":props.readings[0].value.weight<props.readings[props.readings.length - 1].value.weight?"Weight Lost":"Weight"

  }
  // setRead(wLost)

  return (
    <div className="row mw-100 m-auto bg-white border-radius-chart">
    <div className="col-11 m-auto">
    <p className="chart-heading font-weight-bold text-xl pt-2">Weight Scale</p>

    </div>
      <div className="col-11 m-auto p-3 text-center">
      <div className="row mw-100 m-auto">
        <div className="col-6">
        <WheightScaleGauge id="best" reading={props.highest?props.highest.weight:0} heading="Highest Weight" unit={0}/>
        </div>
        <div className="col-6">
        <WheightScaleGauge id="worst" reading={props.lowest?props.lowest.weight:0} heading="Lowest Weight" unit={0}/>
        </div>
      </div>
      </div>
      <div className="col-11 m-auto p-3 text-center">
      <div className="row mw-100 m-auto">
        <div className="col-6">
        <WheightScaleGauge id="avgChart" reading={props.average?props.average:0} heading="Average Weight" unit={0}/>
        </div>
        <div className="col-6">
        {/*}<WheightScaleGauge id="weightLost" reading={props.weightLost?props.weightLost:0} heading="Weight Lost"/>*/}
        <WheightScaleGauge id="weightLost" reading={wLost} heading={heading} unit={0}/>

        </div>
      </div>
      </div>
    </div>
    // <div className="my-3 md:my-0 md:mx-2 w-full bg-white rounded-lg shadow-lg p-4 ">
    //   <p className="font-bold text-xl">Blood Pressure Stats</p>
    //
    //   <div className="flex items-center md:justify-between md:my-8 h-full md:h-auto flex-col md:flex-row">
    //     <div className="flex flex-col items-center justify-center">
    //       <div className="mb-2">Highest BP Reading</div>
    //       <div className="bg-indigo-500 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">{highest.systolic} sys</span>
    //         <span className=" font-bold">{highest.diastolic} dia</span>
    //         <span className="flex font-bold flex-row items-center">
    //           {highest.heartRate}{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Lowest BP Reading</div>
    //       <div className="bg-indigo-400 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">{lowest.systolic} sys</span>
    //         <span className=" font-bold">{lowest.diastolic} dia</span>
    //         <span className="flex font-bold flex-row items-center">
    //           {lowest.heartRate}{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Average</div>
    //       <div className="bg-indigo-300 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className=" font-bold">120 sys</span>
    //         <span className=" font-bold">80 dia</span>
    //         <span className="flex font-bold flex-row items-center">
    //           90{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <div className="mb-2">Average Heart Rate</div>
    //       <div className="bg-indigo-200 rounded-lg w-24 h-24 flex flex-col justify-center items-center">
    //         <span className="flex font-bold flex-row items-center">
    //           {averageHeartRate} pm{" "}
    //           <img style={{ marginLeft: "5px", height: "15px" }} src={Heart} />
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default WsStats;
