export const DOCTOR_INFO_START="DOCTOR_INFO_START";
export const DOCTOR_INFO_FAILED="DOCTOR_INFO_FAILED";
export const DOCTOR_INFO_SUCCESS="DOCTOR_INFO_SUCCESS";

export const ACCEPT_INVITE_START="ACCEPT_INVITE_START";
export const ACCEPT_INVITE_FAILED="ACCEPT_INVITE_FAILED";
export const ACCEPT_INVITE_SUCCESS="ACCEPT_INVITE_SUCCESS";

export const SHARED_USERS_START="SHARED_USERS_START";
export const SHARED_USERS_FAILED="SHARED_USERS_FAILED";
export const SHARED_USERS_SUCCESS="SHARED_USERS_SUCCESS";

export const PENDING_INVITES_START="PENDING_INVITES_START";
export const PENDING_INVITES_FAILED="PENDING_INVITES_FAILED";
export const PENDING_INVITES_SUCCESS="PENDING_INVITES_SUCCESS";

export const CANCEL_INVITES_START="CANCEL_INVITES_START";
export const CANCEL_INVITES_FAILED="CANCEL_INVITES_FAILED";
export const CANCEL_INVITES_SUCCESS="CANCEL_INVITES_SUCCESS";

export const ACTIVE_INVITES_START="ACTIVE_INVITES_START";
export const ACTIVE_INVITES_FAILED="ACTIVE_INVITES_FAILED";
export const ACTIVE_INVITES_SUCCESS="ACTIVE_INVITES_SUCCESS";

export const REMOVE_INVITES_START="REMOVE_INVITES_START";
export const REMOVE_INVITES_FAILED="REMOVE_INVITES_FAILED";
export const REMOVE_INVITES_SUCCESS="REMOVE_INVITES_SUCCESS";