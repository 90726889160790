import React, { Component } from "react";
import { connect } from "react-redux";
import {saveShareToken} from "../../redux/actions/doctor";

import { Route, Redirect } from "react-router-dom";
import { is } from "@amcharts/amcharts4/core";

class SimpleAlexa extends Component {
  redirect=()=>{
    return(
      <Redirect to={ localStorage.getItem("pathname")} />
    )

  }
  componentDidMount(){
   if(window.location.pathname==="/user/activate-account" && window.location.search!=="") {
    localStorage.removeItem("grxAuthToken")
   }
  //  if(this.props.isAuthenticated==false){
  //      this.redirect();
  //  }


  }

  render() {
//(this.props);
    const { isAuthenticated, component: Component, ...rest } = this.props;


    //("Location=",window.location.pathname);
    //(!isAuthenticated)

    return (
      <div>
        <Route
          {...rest}
          render={(props) =>
              <Component {...props} />
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { token } = state.auth;
  return {
    isAuthenticated: !!token,
  };
}

export default connect(mapStateToProps)(SimpleAlexa);
