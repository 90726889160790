import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import $ from "jquery"
import {
  fetchHospitalAdmissionHistory,
  saveHospitalAdmissionHistory,
  updateHospitalAdmission,
  deleteHospitalAdmission,
} from "../../../redux/actions/history";
import ShowToast from "../../../utils/show-toast";
import updateDate from "../../../utils/updateDate";
import moment from "moment";

class HospitalAdmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      _id:"",
      modal: false,
      hospitalName: "",
      firstName: "",
      lastName: "",
      doctorName: "",
      reason: "",
      admissionDate: "",
      admissionSummary: "",
      dischargeDate: "",
      daysStay: "",
      dischargeSummary: "",
      diagnosis: "",
      medication: false,
      tests: false,
      images: false,
      procedures: false,
      followUpVisit: false,
      comments: "",
      view: false,
      editMode:false,
      deleteModal:false,
      toDelete:"",
      hospitalModal:false
    };
  }

  componentDidMount() {
    //console.clear();

    if (this.props.hospitalAdmissions && this.props.hospitalAdmissions.data !== null ){

      this.setState({data:this.props.hospitalAdmissions.data})
    } else {

      this.props.dispatch(fetchHospitalAdmissionHistory())
    }
  }
  static getDerivedStateFromProps(props, state) {
    //console.clear();

    if(props.hospitalAdmissions && props.hospitalAdmissions.data!==null){

      let hospitalAdmissions = props.hospitalAdmissions.data;
      state.data=hospitalAdmissions;
    }
    //console.clear();
    return true;
  }
  updateAdmissionDataStart = (index) => {
    if (this.state.data && this.state.data.length>0){
      let hospitalAdmissions = this.state.data[index];

      this.setState({
        modal: true,
        _id: hospitalAdmissions._id,
        firstName: hospitalAdmissions.firstName,
        lastName: hospitalAdmissions.lastName,
        doctorName: hospitalAdmissions.doctorName,
        reason: hospitalAdmissions.reason,
        hospitalName: hospitalAdmissions.hospitalName,
        admissionDate: hospitalAdmissions.admissionDate,
        admissionSummary: hospitalAdmissions.admissionSummary,
        dischargeDate: hospitalAdmissions.dischargeDate,
        daysStay: hospitalAdmissions.daysStay,
        dischargeSummary: hospitalAdmissions.dischargeSummary,
        diagnosis: hospitalAdmissions.diagnosis,
        medication: hospitalAdmissions.medication,
        tests: hospitalAdmissions.tests,
        images: hospitalAdmissions.images,
        procedures: hospitalAdmissions.procedures,
        followUpVisit: hospitalAdmissions.followUpVisit,
        comments: hospitalAdmissions.comments,
      })
      updateDate(hospitalAdmissions.admissionDate, "admissionDate", false);
      updateDate(hospitalAdmissions.dischargeDate, "dischargeDate", false);
    }
  }
  handleChange=(event)=> {
    this.setState({
      [event.target.name]: event.target.value})

  }

  saveAdmissionHistory = async (event) => {
    event.preventDefault();

    this.setState({modal:false});
    await this.props.dispatch(saveHospitalAdmissionHistory({
      hospitalName: this.state.hospitalName,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      doctorName: this.state.doctorName,
      reason: this.state.reason,
      admissionDate: new Date(this.state.admissionDate),
      admissionSummary: this.state.admissionSummary,
      dischargeDate: new Date(this.state.dischargeDate),
      daysStay: this.state.daysStay,
      dischargeSummary: this.state.dischargeSummary,
      diagnosis: this.state.diagnosis,
      medication: this.state.medication,
      tests: this.state.tests,
      images: this.state.images,
      procedures: this.state.procedures,
      followUpVisit: this.state.followUpVisit,
      comments: this.state.comments,

    }))
    ShowToast("Success!", "success");
    $('form').trigger("reset");

    this.setState({modal:false});
    this.setState({
      modal: false,
      hospitalName: '',
      firstName: "",
      lastName: "",
      doctorName: "",
      reason: "",
      admissionDate: "",
      admissionSummary: "",
      dischargeDate: "",
      daysStay: 0,
      dischargeSummary: "",
      diagnosis: "",
      medication: false,
      tests: false,
      images: false,
      procedures: false,
      followUpVisit: false,
      comments: "",
      view: false,
      editMode:false,
      deleteModal:false,
      hospitalModal:false
    });
    await this.props.dispatch(fetchHospitalAdmissionHistory());
  }
  updateAdmissionHistoryRecord = async (event) => {
    event.preventDefault();
    await this.props.dispatch(updateHospitalAdmission({
      hospitalName: this.state.hospitalName,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      doctorName: this.state.doctorName,
      reason: this.state.reason,
      admissionDate: new Date(this.state.admissionDate),
      admissionSummary: this.state.admissionSummary,
      dischargeDate: new Date(this.state.dischargeDate),
      daysStay: this.state.daysStay,
      dischargeSummary: this.state.dischargeSummary,
      diagnosis: this.state.diagnosis,
      medication: this.state.medication,
      tests: this.state.tests,
      images: this.state.images,
      procedures: this.state.procedures,
      followUpVisit: this.state.followUpVisit,
      comments: this.state.comments,

    }, this.state._id))
    ShowToast("Success!", "success");
    this.setState({modal:false})
    $('form').trigger("reset");
    this.setState({
      modal: false,
      hospitalName: '',
      firstName: "",
      lastName: "",
      doctorName: "",
      reason: "",
      admissionDate: "",
      admissionSummary: "",
      dischargeDate: "",
      daysStay: 0,
      dischargeSummary: "",
      diagnosis: "",
      medication: false,
      tests: false,
      images: false,
      procedures: false,
      followUpVisit: false,
      comments: "",
      view: false,
      editMode:false,
      deleteModal:false,
      hospitalModal:false
    });
    await this.props.dispatch(fetchHospitalAdmissionHistory());
  }

  newHospitalAdmission=()=>{

    this.setState({
      modal: false,
      hospitalName: '',
      firstName: "",
      lastName: "",
      doctorName: "",
      reason: "",
      admissionDate: "",
      admissionSummary: "",
      dischargeDate: "",
      daysStay: 0,
      dischargeSummary: "",
      diagnosis: "",
      medication: false,
      tests: false,
      images: false,
      procedures: false,
      followUpVisit: false,
      comments: "",
      view: false,
      editMode:false,
      deleteModal:false,
      hospitalModal:true
    });
    updateDate(this.state.admissionDate, "admissionDate", false);
    updateDate(this.state.dischargeDate, "dischargeDate", false);
  }
  deleteHospitalAdmissionRecord = async(_id) => {

    await this.props.dispatch(deleteHospitalAdmission(_id))

    ShowToast("Success!", "success");
    this.setState({deleteModal:false});
    await this.props.dispatch(fetchHospitalAdmissionHistory());
  }
  toUpdateState = () => {
    this.setState({
      modal: false,
      hospitalName: '',
      firstName: "",
      lastName: "",
      doctorName: "",
      reason: "",
      admissionDate: "",
      admissionSummary: "",
      dischargeDate: "",
      daysStay: 0,
      dischargeSummary: "",
      diagnosis: "",
      medication: false,
      tests: false,
      images: false,
      procedures: false,
      followUpVisit: false,
      comments: "",
      view: false,
      editMode:false,
      deleteModal:false,
      hospitalModal:false
    });
  }
  deleteModal=(id)=>{
    this.setState({deleteModal:true,toDelete :id});
  }
  closeDeleteModal=()=>{
    this.setState({deleteModal:false});
  }
  render() {
    const {data} = this.state;

    return (
        <>
          <div className="text-right">
            <button
                type="button"
                className="add-info-btn"

                onClick={this.newHospitalAdmission}
            >
            <span className="d-flex justify-content-center align-items-center">
                <i className="fas fa-plus mr-2"></i>
                <span>Add hospital Admission</span>
              </span>
            </button>
          </div>

          <div
              className={`modal big_modal_dialog ${
                  this.state.hospitalModal? "d-block modal-fading" : " d-none"
              }`}
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"

              role="dialog"

              aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addHospitalModalTitle">
                    {this.state.editMode?"Update Hospital Admission History":"Add Hospital Admission History"}
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.toUpdateState}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={this.state.editMode? this.updateAdmissionHistoryRecord:this.saveAdmissionHistory}>
                  <div className="modal-body">


                    <div className="container-fluid">


                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>First Name </h6></div>
                          <div className="form-group">

                            <input
                                name="firstName"
                                placeholder="First Name"
                                className="form-control input-md msg"
                                type="text"

                                required
                                onChange={this.handleChange}
                                defaultValue={this.state.firstName}
                            />

                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Last Name</h6></div>
                          <div className="form-group">

                            <input
                                name="lastName"
                                placeholder="Last Name"
                                className="form-control input-md msg"
                                type="text"
                                onChange={this.handleChange}

                                required
                                defaultValue={this.state.lastName}

                            />

                          </div>
                        </div>
                      </div>



                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Hospital Name</h6></div>
                          <div className="form-group">

                            <input
                                name="hospitalName"
                                placeholder="Hospital name"
                                className="form-control input-md msg"
                                type="text"

                                required
                                onChange={this.handleChange}
                                defaultValue={this.state.hospitalName}
                            />

                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Doctor Name</h6></div>
                          <div className="form-group">

                            <input
                                name="doctorName"
                                placeholder="Attending Doctor Name"
                                className="form-control input-md msg"
                                type="text"
                                onChange={this.handleChange}

                                required
                                defaultValue={this.state.doctorName}

                            />

                          </div>
                        </div>
                      </div>

                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Date of Admission</h6></div>
                          <div className="form-group">

                            <input
                                type="date"
                                id="admissionDate"
                                name="admissionDate"
                                className="datePicker"
                                onChange={this.handleChange}
                                required

                            />

                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Discharge Date</h6></div>
                          <div className="form-group">

                            <input
                                type="date"
                                id="dischargeDate"
                                name="dischargeDate"
                                className="datePicker"
                                min={this.state.admissionDate}
                                required
                                onChange={this.handleChange}

                            />

                          </div>
                        </div>
                      </div>

                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Admission Summary</h6></div>
                          <div className="form-group">

                            <input
                                name="admissionSummary"
                                placeholder="Admission Summary"
                                className="form-control input-md msg"
                                type="text"
                                onChange={this.handleChange}
                                defaultValue={this.state.admissionSummary}

                                required

                            />

                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Discharge Summary</h6></div>
                          <div className="form-group">

                            <input
                                name="dischargeSummary"
                                placeholder="Discharge Summary"
                                className="form-control input-md msg"
                                type="text"
                                onChange={this.handleChange}
                                defaultValue={this.state.dischargeSummary}

                                required

                            />

                          </div>
                        </div>
                      </div>

                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Days Stay</h6></div>
                          <div className="form-group">

                            <input
                                name="daysStay"
                                placeholder="Days Stay"
                                className="form-control input-md msg"
                                type="number"
                                onChange={this.handleChange}
                                defaultValue={this.state.daysStay}

                                required

                            />

                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Final Diagnosis</h6></div>
                          <div className="form-group">

                            <input
                                name="diagnosis"
                                placeholder="Final Diagnosis"
                                className="form-control input-md msg"
                                type="text"
                                onChange={this.handleChange}
                                defaultValue={this.state.diagnosis}

                                required

                            />

                          </div>
                        </div>
                      </div>



                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Reason </h6></div>
                          <div className="form-group">

                            <input
                                name="reason"
                                placeholder="Reason"
                                className="form-control input-md msg"
                                type="text"
                                onChange={this.handleChange}
                                defaultValue={this.state.reason}

                                required

                            />

                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Other Notes</h6></div>
                          <div className="form-group">

                            <input
                                name="comments"
                                placeholder="Other Notes"
                                className="form-control input-md msg"
                                type="text"

                                required
                                onChange={this.handleChange}
                                defaultValue={this.state.comments}
                            />

                          </div>
                        </div>
                      </div>

                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Test/Labs Done:</h6></div>
                          <div className="row justify-content-center align-items-center mt-2 mb-2">
                            <div className="col-md-11 col-10 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input
                                    className=""
                                    type="radio"
                                    name="testsYes"
                                    id="tests"
                                    // value="option7"
                                    checked={this.state.tests === true}

                                    onClick={()=> this.setState({tests:true})}

                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  Yes
                                </label>
                              </div>

                              <div className="form-check form-check-inline ml-5">
                                <input
                                    className=""
                                    type="radio"
                                    name="testsNo"
                                    id="tests"
                                    // value="option7"
                                    checked={this.state.tests ===false}
                                    onClick={()=> this.setState({tests:false})}



                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Medication Added/Changed:</h6></div>
                          <div className="row justify-content-center align-items-center mt-2 mb-2">
                            <div className="col-md-11 col-10 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input
                                    className=""
                                    type="radio"
                                    name="medicationYes"
                                    id="medication"
                                    // value="option7"
                                    checked={this.state.medication === true}
                                    onClick={()=> this.setState({medication:true})}



                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  Yes
                                </label>
                              </div>

                              <div className="form-check form-check-inline ml-5">
                                <input
                                    className=""
                                    type="radio"
                                    name="medicationNo"
                                    id="medication"
                                    // value="option7"
                                    checked={this.state.medication === false}
                                    onClick={()=> this.setState({medication:false})}



                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row justify-content-center align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Procedure Done:</h6></div>
                          <div className="row justify-content-center align-items-center mt-2 mb-2">
                            <div className="col-md-11 col-10 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input
                                    className=""
                                    type="radio"
                                    name="proceduresYes"
                                    id="procedures"
                                    // value="option7"
                                    checked={this.state.procedures === true}
                                    onClick={()=> this.setState({procedures:true})}



                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  Yes
                                </label>
                              </div>

                              <div className="form-check form-check-inline ml-5">
                                <input
                                    className=""
                                    type="radio"
                                    name="proceduresNo"
                                    id="procedures"


                                    checked={this.state.procedures === false}
                                    onClick={()=> this.setState({procedures:false})}



                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Images:</h6></div>
                          <div className="row justify-content-center align-items-center mt-2 mb-2">
                            <div className="col-md-11 col-10 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input
                                    className=""
                                    type="radio"
                                    name="imagesYes"
                                    id="images"

                                    checked={this.state.images === true}
                                    onClick={()=> this.setState({images:true})}


                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"

                                >
                                  Yes
                                </label>
                              </div>

                              <div className="form-check form-check-inline ml-5">
                                <input
                                    className=""
                                    type="radio"
                                    name="imagesNo"
                                    id="images"

                                    checked={this.state.images === false}
                                    onClick={()=> this.setState({images:false})}

                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"

                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row justify-content-start align-items-center'>

                        <div className="col-md-6 col-10">
                          <div className='d-block mb-1 font-weight-bold'><h6>Do You need a Follow up visit:</h6></div>
                          <div className="row justify-content-center align-items-center mt-2 mb-2">
                            <div className="col-md-11 col-10 d-flex align-items-center">
                              <div className="form-check form-check-inline">
                                <input
                                    className=""
                                    type="radio"
                                    name="followUpVisitYes"
                                    id="followUpVisit"

                                    checked={this.state.followUpVisit === true}
                                    onClick={()=> this.setState({followUpVisit:true})}


                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  Yes
                                </label>
                              </div>

                              <div className="form-check form-check-inline ml-5">
                                <input
                                    className=""
                                    type="radio"
                                    name="followUpVisitNo"
                                    id="followUpVisit"

                                    checked={this.state.followUpVisit === false}
                                    onClick={()=> this.setState({followUpVisit:false})}


                                />
                                <label
                                    className="form-check-label ml-md-3 ml-0"
                                    htmlFor="inlineCheckbox1"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>


                  </div>



                  <div className="modal-footer">
                    {this.state.view==false?
                        <button
                            type="submit"
                            className="modal-save-btn"
                        >
                          {this.state.editMode? "Update"

                              : "Save"}
                        </button>
                        :null
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>



          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-success">
                  <table className="table table-hover" id="task-table">
                    <thead>
                    <tr>
                      <th>Hospital Name</th>
                      <th>Doctor Name</th>
                      <th>Reason</th>
                      <th>Admission Date</th>
                      <th>Discharge Date</th>


                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    { data !== null && data.length > 0 && data.map((row, index)=>(
                      (row.hospitalName && row.doctorName?
                        <tr key={index + 0}>
                          <td>{row.hospitalName}</td>
                          <td>{row.doctorName}</td>
                          <td>{row.reason}</td>
                          <td>{moment(row.admissionDate).format("D MMM YYYY")}</td>
                          <td>{moment(row.dischargeDate).format("D MMM YYYY")}</td>
                          <td>


                            <button
                                type="button"
                                className=""
                                data-toggle="modal"
                                data-target="#addHospitalModal"
                                onClick={(event)=>{
                                  this.updateAdmissionDataStart(`${index}`)
                                  this.setState({editMode:true})
                                  this.setState({view:false})
                                  this.setState({hospitalModal:true})
                                }}
                            >
                              <span className="">
                              <i className="fas fa-edit text-primary"></i>
                              </span>
                            </button>

                            <button
                                type="button"
                                className=" ml-4"
                                onClick={()=>this.deleteModal(`${row._id}`)}
                            >
                                                <span className="">
                                                <i className="fas fa-trash-alt text-danger"></i>
                                                </span>
                            </button>

                          </td>




                        </tr>:null)) )}

                    </tbody>
                  </table>
                  <div className={`modal  ${
                                this.state.deleteModal? "d-block fading" : " d-none"
                            }`} >
                              <div className="modal-dialog modal-dialog-centered modal-confirm">
                                <div className="modal-content">
                                  <div className="modal-header flex-column">
                                    <div className="icon-box">
                                      <i className="fas fa-trash-alt text-danger"></i>
                                    </div>
                                    <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                    <button type="button" className="close" onClick={this.closeDeleteModal} >&times;</button>
                                  </div>
                                  <div className="modal-body">
                                    <p>Do you really want to delete these records? This process cannot be undone.</p>
                                  </div>
                                  <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn btn-secondary" onClick={this.closeDeleteModal}>Cancel</button>
                                    <button type="button" className="btn btn-danger"  onClick={(event)=>{this.deleteHospitalAdmissionRecord(this.state.toDelete)}}>Delete</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                </div>
              </div>
            </div>

          </div>


        </>
    );
  }
}
function mapStateToProps(state){

  return {
    hospitalAdmissions: state.history.hospitalAdmissions,
  }
}

export default withRouter(connect (mapStateToProps)(HospitalAdmissions)) ;
