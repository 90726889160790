import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, Redirect } from "react-router-dom";
import { deleteAccountConfirm, logout } from "../../redux/actions/auth";
import ShowToast from "../../utils/show-toast";
// import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";
import LoadingEmail from "./loading-email";




const DeleteAccount = () => {
  // useEffect(()=>{
  //   if(localStorage.getItem("EostreAuthToken")) {
  //     localStorage.removeItem("EostreAuthToken");
  //
  //   }
  // });

  useEffect(()=>{
    const tokenUrl = new URLSearchParams(window.location.search).get("token");
    // const localToken = localStorage.getItem("grxAuthToken");
    // handleSubmit(tokenUrl);
    handleSubmit(tokenUrl);

  },{})
  const token = new URLSearchParams(window.location.search).get("token");
const history = useHistory();

const [isLoading, setIsLoading] = useState(false);

const dispatch = useDispatch();

const handleSubmit = async (tok) => {
  try {
    let token = tok;

    if(token === "" || token===null){
      token =localStorage.getItem('token');



    }
    let result = await dispatch(deleteAccountConfirm(token));
    if(result){
      dispatch(logout());
      history.push("/auth/login");
    }

  } catch (e) {

  }
};

    return (
        <div className='container-fluid d-flex flex-column forget-background ' >

        <div className="row justify-content-center align-item-center my-auto" >
            <div className="col-md-7 col-11 forget-field-box align-items-center py-5" >
               <div className='d-flex justify-content-center '></div>

               <LoadingEmail/>

            </div>


            </div>


    </div>
    );
};

export default DeleteAccount;
