import React, { useEffect, useState } from "react";
import { Vitals } from "../../patient-dashboard/vitals";
import Problems from "./problems";
import VideoChat from "./video-chat";
import { Tag } from "antd";
import Chat from "./chat";
import Notes from "./notes";
import Summary from "../../history";
import Allergy from "../../emergency/allergy";
import BloodGlucoseComp from "./BloodGlucoseComp";
import ProblemsComp from "./ProblemsComp";
import NotesComp from "./NotesComp";
import ChatComp from "./ChatComp";
import { useSelector ,useDispatch} from "react-redux";
import {encrypt,decrypt} from "../../../utils/encryption";
import { fetchDoctorBgData } from "../../../redux/actions/doctor";

const PatientHistory = (props) => {
  const dispatch = useDispatch();
  const bg = useSelector((state) =>(state.doctor.bloodGlucose));
  const [bloodGlucose, setBloodGlucose] = useState(true);
  const [pRec, setPRec] = useState([]);
  const [cgm, setCgm] = useState(false);
  const [inr, setInr] = useState(false);
  const [bloodPressure, setBloodPressure] = useState(false);
  const [days,setDays]=useState("30days")
  const [type,setType]=useState("1")
  const [startDate,setStartDate]=useState('');
  const [endDate,setEndDate]=useState('');
  const [loading,setIsLoading]=useState(false);




  const handleDate=(d)=>{
    setDays(d)}

    const handleRangeDate=(s,e)=>{
     setStartDate(s);
     setEndDate(e)
    }

  useEffect(()=>{
    fetchPatientHistory()},[days,type,startDate,endDate])

  const fetchPatientHistory=async()=>{
    let record =localStorage.getItem("rec");
    record=decrypt(record);
    record=JSON.parse(record)
    let data={type:type,id:record._id,pId:record.pId,time:days,start:startDate,end:endDate}


    try {


      setIsLoading(true);
      let response= await dispatch( fetchDoctorBgData(data))
            setIsLoading(false);

    } catch (error) {
        setIsLoading(false);

    }

  }




  return (
    <div>
      <div className="container-fluid bg-white p-4">
        <div className="text-center font-weight-bold">
        Information of {type==="1"?"Blood glucose":type==="2"?"Blood Pressure":null} is available from {days !=="" ||days.length!==0 ?`last ${days}.`:`${startDate} to ${endDate}.`}
        </div>

        <div className="d-flex mt-3  bg-white shadow-lg mx-n2  align-items-center justify-content-md-between filter-area justify-content-sm-center justify-content-center">
          <div>
            <button
              type="button"
              onClick={() => {

                setType("1")
                setBloodGlucose(true);
                setBloodPressure(false);
                setCgm(false);
                setInr(false);
              }}
              className={
                bloodGlucose === true
                  ? "font-size-small-1 cl-filter-btn mx-1 bg-primary text-white"
                  : "font-size-small-1 bg-white text-primary cl-filter-btn mx-1 "
              }
            >
              Blood Glucose
            </button>
            <button
              type="button"
              onClick={() => {
                setBloodGlucose(false);
                setBloodPressure(false);
                setCgm(true);
                setInr(false);
              }}
              className={
                cgm === true
                  ? "font-size-small-1 cl-filter-btn mx-1 bg-primary text-white"
                  : "font-size-small-1 bg-white text-primary cl-filter-btn mx-1 "
              }
            >
              CGM
            </button>
            <button
              type="button"
              onClick={() => {
                setBloodGlucose(false);
                setBloodPressure(false);
                setCgm(false);
                setInr(true);
              }}
              className={
                inr === true
                  ? "font-size-small-1 cl-filter-btn mx-1 bg-primary text-white"
                  : "font-size-small-1 bg-white text-primary cl-filter-btn mx-1 "
              }
            >
              INR
            </button>
            <button
              type="button"
              onClick={() => {
                setType("2");
                setBloodGlucose(false);
                setBloodPressure(true);
                setCgm(false);
                setInr(false);
              }}
              className={
                bloodPressure === true
                  ? "font-size-small-1 cl-filter-btn mx-1 bg-primary text-white"
                  : "font-size-small-1 bg-white text-primary cl-filter-btn mx-1 "
              }
            >
              Blood Pressure
            </button>
            <span className="form-check d-inline-block mx-1  text-primary">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Compilation
              </label>
            </span>
          </div>
          <div>
            <button className="btn btn-primary font-weight-bold mx-1">
              Smoker
            </button>

            {/* <button className="btn btn-danger font-weight-bold mx-1">
              Diabetic
            </button> */}

            <button className="btn btn-success font-weight-bold mx-1">
              Active
            </button>
          </div>
        </div>

        <div className="row mt-4 justify-content-between">
          <div className="col-md-6 col-12">
            <BloodGlucoseComp
              bg={bloodGlucose}
              bp={bloodPressure}
              inr={inr}
              cgm={cgm}
              handlingDays={handleDate}
              handlingRange={handleRangeDate}
              loading={loading}
            />
            <ProblemsComp />
            <NotesComp />
          </div>
          <div className="col-md-6">
            <ChatComp  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientHistory;
