import React from "react";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Carousel,WingBlank } from "antd";
import HCTMEter from "../../assets/products/HCTMeter.png"
import GRxBloodPressureMonitor from "../../assets/products/GRxBloodPressureMonitor.jpg"
import GRxFingertipPulseOximeter from "../../assets/products/GRxFingertipPulseOximeter.jpg"
import GRxForeheadThermometerProPackaging from "../../assets/products/GRxForeheadThermometerProPackaging.jpg"
import GRxWeightScale from "../../assets/products/GRxWeightScale.jpg"
import GlucoRxNexusMeter from "../../assets/products/GlucoRxNexusMeter.png"
import Grx from "../../assets/grx_diagnostic.png";
import GlucoRx from "../../assets/gluco_rx.jpg";

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: '#364d79',
};
const prods = [
  {name: "See GlucoRx Product Range", image: GlucoRx, description: "", link: "https://www.glucorx.co.uk/product-category/blood-glucose-meters/"},
  {name: "See Grx Diagnostics Product Range", image: Grx, description: "", link: "https://www.grxdiagnostics.co.uk/our-products/"},

]



export default function ProductAds({ products = [] }) {

  const renderProducts = () => {
    return prods.map(({ image, name, link }, index) =>
      // <div
      //     className="flex flex-col items-center justify-center rounded-lg border border-gray-300"
      // >
      //   <img src={image} className="m-auto object-contain h-56 rounded-t-lg" />
      //   <div className="flex p-1 justify-between w-full items-center rounded-b-lg p-1 bg-white border-t border-gray-500 text-2xl shadow-md">
          
      //   <a href={link} target="_blank" className="no-underline fill-current">
      //     <span className="dashboard-devices-name">{name}</span>{" "}
      //     </a>
      //   </div>
      // </div>
      <div className='d-flex flex-column  mt-2 ' key={index}>
        <div className='d-flex flex-column justify-content-center'>
          <img src={image} width="100%"></img>
          <a href={link} target="_blank" className="no-underline fill-current"><p className="text-white">{name}</p></a>
        </div>


      </div>
  
    );
  };

  return (
    // <div className="my-3 md:my-0 md:ml-2 bg-white rounded-lg shadow-lg p-4 w-64 items-center">
    <div className="d-flex flex-column justify-content-between h-100 w-100 pb-5">
      {/* <Carousel autoplay> */}
        {renderProducts()}
      {/* </Carousel> */}

    </div>
  );
}
