import React from 'react';
import { default as Titles } from "../../utils/document-titles";


const doctor = () => {
  document.title = Titles.onlineDoctor;
  
    return (
        <div className='online_doctor'>

        </div>
    );
};

export default doctor;
