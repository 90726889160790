import React from 'react';
import { default as Titles } from "../../utils/document-titles";


const pharmacy = () => {
  document.title = Titles.pharmacy;

    return (
        <div className="pharmacy">

        </div>
    );
};

export default pharmacy;
