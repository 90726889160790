import React from "react";
// import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import Logo from "../../assets/newglucorxwhite.svg";

import LoadingIcon from "../../assets/loading.gif";

// import { useLocation } from 'react-router-dom';


const Loading = () => {
  // const location = useLocation();

  return (
    <div className= "col-12 col-sm-6 col-md-4 m-auto text-center">
      <div className="row mw-100 m-auto h-100 loading">
        <div className="col-12">
        <img src={Logo} className="w-50 grx-logo" alt="AIDEX LOGO"/>

        </div>
        <div className="col-12">
          <img src={LoadingIcon} className="w-50 m-auto" alt="Loading Gif"/>
        </div>
        <div className="col-12">
        <p className="font-bold p-2">PLEASE WAIT CHECKING CLIENT ID</p>

        </div>

      </div>
    </div>
  );
};

export default Loading;
