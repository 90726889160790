export const FETCH_PATIENT_START = 'FETCH_PATIENT_START'
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS'
export const FETCH_PATIENT_FAILED = 'FETCH_PATIENT_FAILED'
export const SAVE_PATIENT_DATA = 'SAVE_PATIENT_DATA'
export const SAVE_SEARCH_INPUT = 'SAVE_SEARCH_INPUT'
export const FETCH_PATIENT_BG ='FETCH_PATIENT_BG'
export const FETCH_PATIENT_BP ='FETCH_PATIENT_BP'

//Save Share Token and Id

export const SAVE_DOCTOR_TOKEN ='SAVE_DOCTOR_TOKEN'
export const SAVE_USER_ID ='SAVE_USER_ID'