import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ExportData from "../../patient-dashboard/Export/ExportData";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import devices from "../../../assets/SideNav/devices.png";
import home from "../../../assets/SideNav/home.svg";
import doctor from "../../../assets/SideNav/doctor.svg";
import share from "../../../assets/SideNav/share.svg";
import pharmacy from "../../../assets/SideNav/pharmacy.svg";
import contactUs from "../../../assets/SideNav/contactUs.svg";
import store from "../../../assets/SideNav/store.svg";
import appStore from "../../../assets/app-store.png";
import playStore from "../../../assets/play-store.png";
import aidexLogo from "../../../assets/AidexLogo.svg";


const NewPatientNav = () => {
    const location = useLocation();
    const [grxStore,setGrxStore]=useState(false);
    const [diagnosticStore,setDiagnosticStore]=useState(false);
    const device = useSelector((state) =>(state.devices.devices));

    //("Location",location.pathname);
    var doc=localStorage.getItem('doc');
    const clickExport=()=> {

      if(location.pathname !== "/export-data"){
        document.getElementById('pdfBtn').click();

      }
    }
    return (
        <nav className="navbar sticky-top navbar-expand flex-lg-column  flex-row align-items-start py-2 px-md-0 px-2" style={{background:"#003650"}}>
        <div className="collapse navbar-collapse align-items-start mt-lg-5 mt-none w-100">
            {doc && doc !==""?
             <ul className="flex-lg-column   flex-row navbar-nav w-100 justify-content-between text-white">
             <li className="nav-item">
                 <NavLink to="/dashboard"  className={location.pathname==="/dashboard"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "}> <img src={home} className="d-inline-block" style={{ maxWidth: "25px" }}></img> <span className="d-none d-lg-inline  ml-3">Home</span></NavLink>
             </li>
             <li className="nav-item mt-2">
                 <NavLink to="/devices"  className={location.pathname==="/devices"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><img src={devices} className="d-inline-block" style={{ maxWidth: "25px" }}></img> <span className="d-none d-lg-inline ml-3">Devices</span></NavLink>
             </li>
             </ul>:
             <ul className="flex-lg-column   flex-row navbar-nav w-100 justify-content-between text-white">
             <li className="nav-item mt-2">
                 <NavLink to="/dashboard"  className={location.pathname==="/dashboard"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "}> <img src={home} className="d-inline-block" style={{ maxWidth: "25px" }}></img> <span className="d-none d-lg-inline  ml-3">Home</span></NavLink>
             </li>
             <li className="d-flex mt-2 pt-2">
             {device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?<ExportData sideBar={true}/>:null}



             {/*<button className="m-5" onClick={()=>clickExport()}>Pdf</button>*/}

             </li>
             <li className="nav-item mt-2">
                 <NavLink to="/devices"  className={location.pathname==="/devices"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><img src={devices} className="d-inline-block" style={{ maxWidth: "25px" }}></img> <span className="d-none d-lg-inline ml-3">Devices</span></NavLink>
             </li>
             <li className="nav-item mt-2">
                 <NavLink to="/share" className={location.pathname==="/share"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><img src={share} className="d-inline-block" style={{ maxWidth: "25px" }}></img> <span className="d-none d-lg-inline ml-3">Share</span></NavLink>
             </li>
             <li className="nav-item mt-2">
                 <NavLink to="/online_doctor" className={location.pathname==="/online_doctor"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><img src={doctor} className="d-inline-block" style={{ maxWidth: "25px" }}></img> <span className="d-none d-lg-inline ml-3">Online Doctor</span></NavLink>
             </li>
             <li className="nav-item mt-2">
                <a href="https://glucorxpharmacy.co.uk/" target="_blank"  className={ "nav-link  px-2 py-2 font-size-big "} ><img src={pharmacy} className="d-inline-block" style={{ maxWidth: "25px" }}></img><span className="d-none d-lg-inline ml-3">Pharmacy</span></a>
             </li>
             <li className="nav-item mt-2">
                 <NavLink to="/contact" className={location.pathname==="/contact"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><img src={contactUs} className="d-inline-block" style={{ maxWidth: "25px" }}></img><span className="d-none d-lg-inline ml-3">Contact Us</span></NavLink>
             </li>
             <li className="nav-item mt-2">
                 <NavLink to="/aidex" className={location.pathname==="/aidex"?"is-active nav-link  px-2 py-2 font-size-big " : "nav-link  px-2 py-2 font-size-big "} ><img src={aidexLogo} className="d-inline-block" style={{ maxWidth: "25px" }}></img><span className="d-none d-lg-inline ml-3">GlucoRx Aidex</span></NavLink>
             </li>

             <li className="nav-item mt-2">
                 <a href="https://www.glucorx.co.uk/product-category/blood-glucose-meters/" target="_blank"  className={ "nav-link  px-2 py-2 font-size-big "} ><img src={store} className="d-inline-block" style={{ maxWidth: "25px" }}></img><span className="d-none d-lg-inline ml-3">GlucoRx Store</span></a>
             </li>
             <li className="nav-item mt-2">
             <a href="https://www.grxdiagnostics.co.uk/our-products/" target="_blank" className={ "nav-link  px-2 py-2 font-size-big "} ><img src={store} className="d-inline-block" style={{ maxWidth: "25px" }}></img><span className="d-none d-lg-inline ml-3">GRX Diagnostics</span></a>
             </li>
             <li className="nav-item mt-2">
                 <a href="https://apps.apple.com/gb/app/glucorx-voyager/id1531079363"  className="nav-link  px-2 py-2 font-size-big" target="_blank">
                     <img src={appStore} width="150px"></img>

                 </a>
             </li>
             <li className="nav-item mt-2">
                 <a href="https://play.google.com/store/apps/details?id=uk.co.glucorx.voyager_android&gl=GB"  className="nav-link  px-2 py-2 font-size-big" target="_blank">
                 <img src={playStore} width="150px"></img>
              </a>
             </li>


         </ul>
            }

        </div>
    </nav>
    );
};

export default NewPatientNav;
