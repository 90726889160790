import React from "react";

 

const ExportBpReading = ({dataSource}) => {
  //("DataSource",dataSource)


  return (
    <div className="my-4 md:mr-2 w-full bg-white justify-evenly items-center po-reading rounded-lg p-4 flex flex-col">
    <div className="container-fluid">
      <div className="row justify-content-between text-center">
        <div className="col-3 ">
          <p className="text-voyger-blue font-bold"> Date</p>{" "}
        </div>
        <div className="col">
          <p className="text-voyger-blue font-bold"> Systolic</p>{" "}
        </div>{" "}

 

        <div className="col">
          <p className="text-voyger-blue font-bold"> Diastolic</p>{" "}
        </div>{" "}
        <div className="col">
          <p className="text-voyger-blue font-bold"> Heart Rate</p>{" "}
        </div>
      </div>
      {dataSource && dataSource.length > 0 && dataSource.map((row, index)=>(
      <div className="row justify-content-between text-center" key={index+1}>
        <div className="col-3">
          <p className="bg-reading-chart-heads-color-gray"> {row.time}</p>{" "}
        </div>
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.systolic}</p>{" "}
        </div>{" "}
        {/*<div className="col">*/}
        {/*  <p className="bg-reading-chart-heads-color-gray"> String</p>{" "}*/}
        {/*</div>{" "}*/}
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.diastolic}</p>{" "}
        </div>{" "}
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.heartRate}</p>{" "}
        </div>
      </div>
      ))}
    </div>
    </div>
  );
};

 

 export default ExportBpReading;