import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { register, resetEmail } from "../../redux/actions/auth";


const DeleteModal = ({deleteModal, closeDeleteModal, DelAccountRequest}) => {
  const data = useSelector((state) => state.user.userDetails);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {

  },[]);


  const dispatch = useDispatch();




  const resetEmailRequest=async(e)=>{
    e.preventDefault();

  }

  return (
<div
                  tabIndex="-1"
                  id="myModal-delete"
                  className={`modal  ${
                    deleteModal ? "d-block fading" : " d-none"
                  }`}
                >
                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box">
                          <i className="fas fa-trash-alt text-danger"></i>
                        </div>
                        <h4 className="modal-heading w-100 text-black">
                          Are you sure?
                        </h4>
                        <button
                          type="button"
                          className="close"
                          onClick={closeDeleteModal}
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Do you really want to delete this account? This
                          process cannot be undone.
                        </p>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeDeleteModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={DelAccountRequest}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
)}
export default DeleteModal;
