import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Allergy from "./Allergies/Allergies"
import CurrentMedications from "./CurrentMedication/CurrentMedications";
import ChronicConditions from "./ChronicConditions/ChronicConditions";
import ImplantedDevices from "./ImplantedDevices/ImplantedDevices";
import { Tabs } from "antd";
import DocumentTitles from "../../utils/document-titles";
import MedicationComp from "./CurrentMedication/MedicationComp";
import ExportData from "../patient-dashboard/Export/ExportData";



const { TabPane } = Tabs;

export default function Emergency() {
  document.title = DocumentTitles.emergency;
  const device = useSelector((state) =>(state.devices.devices));


  return (
    <div className="bg-white p-3 rounded-lg">
      <div className="d-flex my-2 mx-3  p-2 pr-1 justify-content-end align-items-center  border-bottom">
       {device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?<ExportData />:null}

      </div>
      <Tabs type="card">
      <TabPane tab="Allergies" key="1">
           <Allergy/>
          </TabPane>
          <TabPane tab="Current Medication" key="2">
            <MedicationComp/>
          </TabPane>
          <TabPane tab="Implanted Devices" key="3">
            <ImplantedDevices />
          </TabPane>
          <TabPane tab="Chronic Conditions" key="4">
            <ChronicConditions />
          </TabPane>
      </Tabs>
    </div>
  );
}
