import React from "react";
// import HistoricalReadings from "../../historical-readings";
import Tooltip from "../../../../utils/Tooltip";
import HistoricalReadingsChartBg from "../../historical-readingsBg";
// import HistoricalReadingsChart from "./Chart";
import HistoricChart from "./Chart";


export default function  HistoricalReading ({dataPoints, margin,id,renderOn ,themeType}){
  ////("Data",dataPoints)


  return (
    // <div className='h-100 d-flex justify-content-between flex-column'>

    <div className='h-100'>

      <div className="">
        <div className={themeType==="bg"?"chart-heading text-voyger-purple":themeType==="inr"?"text-voyger-inr-blue chart-heading font-bold":null}>

         {renderOn==="dashboardBloodGlucose"?"Blood Glucose":"Historical Readings"}
        <span className='ml-2'><Tooltip themeType={themeType} text="Line graph showing reading over selected date"/></span>
        </div>
      </div>

      {/*<div  style={renderOn==="dashboard"?{height:"60vh"}:renderOn==="blood-glucose"?{height:"50vh"}:null}>*/}
      {/*  <Chart data={dataPoints} id={id}/>*/}
      {/*</div>*/}
      {/*  <div className="d-flex justify-content-center my-3 position-relative">*/}
        {/*<HistoricalReadings dataPoints={dataPoints}/>*/}

        <div  style={{height:"60vh"}}>
      <HistoricalReadingsChartBg
      margin={false}
      id={id}
      dataPoints={dataPoints}
      themeType={themeType}
      renderOn={renderOn}/>
      </div>

    </div>
  );
};

// export default HistoricalReading;
