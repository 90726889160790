import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMedication } from "../../../redux/actions/medication";
import medicineTransformer from "../../../utils/transformers/medicine-transformer";
import Medications from "../../patient-dashboard/medications";

const MedicationComp = () => {
    const dispatch = useDispatch();
    const { medicationData, isLoading: isMedicationLoading } = useSelector(
        (state) => state.medication
      );
    const [page,setPage]=useState("1");
    const [medications, setMedications] = useState([]);

    let record=4;
  useEffect(()=>{
    dispatch(fetchMedication({page,record}));
  },[page])

  useEffect(() => {
    if (!medicationData) {
      return;
    }

    setMedications(
      medicationData && medicationData.medications
      .map((med) =>
        medicineTransformer.createDetailsObjectFromRequest(med)
      )
    );
  }, [medicationData,page]);

  const handlePageNumber=(page)=>{

      setPage(page)
  }

    return (
        <div>
             <Medications
            medications={medications}
            isLoading={isMedicationLoading}
            pageNumber={medicationData && medicationData.totalPage}
            settingPage={handlePageNumber}
          />
        </div>
    );
};

export default MedicationComp;
