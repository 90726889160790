import React from 'react';

const CircleBadge = (props) => {
    return (
        <div>
              <span className={`rounded-circle ml-3 ${props.color}`} style={{height: '15px', width: '15px', backgroundColor: '#bbb', borderRadius: '50%', display: 'inline-block'}} />
        </div>
    );
};

export default CircleBadge;