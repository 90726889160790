import * as actions from "../actionTypes/doctor";

const INITIAL_STATE = {
  patientsList: [],
  patient: '',
  patientData:[],
  isLoading: false,
  search:"",
  bloodGlucose:[],
  bloodPressure:[],
  shareToken:"",
  userId:""

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_PATIENT_START:
      return { ...state, isLoading: true };
    case actions.FETCH_PATIENT_FAILED:
      return { ...state, isLoading: false };

    case actions.SAVE_DOCTOR_TOKEN:
      //(action.payload);
        return {
          ...state,
          isLoading:false,
          shareToken:action.payload
        };
        case actions.SAVE_USER_ID:

            return {
              ...state,
              isLoading:false,
              userId:action.payload
            };
    case actions.FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patientsList: action.payload,
      };
    case actions.SAVE_PATIENT_DATA:
        return {
            ...state,
            isLoading:false,
            patientData:action.payload
        };
    case actions.FETCH_PATIENT_BG:

        return {
          ...state,
          isLoading:false,
          bloodGlucose:action.payLoad
        };
    case actions.FETCH_PATIENT_BP:

        return {
          ...state,
          isLoading:false,
          bloodPressure:action.payLoad
        };
    case actions.SAVE_SEARCH_INPUT:
    return {
    ...state,
    isLoading:false,
    search:action.payload
    }

    default:
      return{
        ...state
      }
  }
};
