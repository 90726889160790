import React from 'react';
import { Table } from 'antd';



const columns = [
  {
    title: 'Date',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Temperature',
    dataIndex: 'temperature',
    key: 'temperature',
  },
  
];

const ExportTempReading = ({dataSource}) => {
  ////(dataSource);
  return (
    <div className="my-4 md:mr-2 w-full bg-white justify-evenly items-center po-reading rounded-lg  p-4 flex flex-col">
    <div className="container-fluid">
      <div className="row justify-content-between text-center">
        <div className="col-3 ">
          <p className="text-voyger-blue font-bold"> Date</p>{" "}
        </div>
        <div className="col">
          <p className="text-voyger-blue font-bold"> Temperature</p>{" "}
        </div>{" "}

 

     
      </div>
      {dataSource && dataSource.length > 0 && dataSource.map((row, index)=>(
      <div className="row justify-content-between text-center" key={index+1}>
        <div className="col-3">
          <p className="bg-reading-chart-heads-color-gray"> {row.time}</p>{" "}
        </div>
        <div className="col">
          <p className="bg-reading-chart-heads-color-gray"> {row.temperature}</p>{" "}
        </div>{" "}
       
      </div>
      ))}
    </div>
    </div>
   );
}

export default ExportTempReading;
