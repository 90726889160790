import React from "react";
import Chart2 from "./Chart2";
import Chart2b from "./Chart2b";

export default function BgRating ({
    glucoseDataPoints,
    glucosePercentage = 0,
    timeDataPoints,
    timePercentage = 0,
    margin,
    id,
    height,
    width,
    renderOn,
    themeType
}) {
  ////( glucoseDataPoints)
  ////( timeDataPoints)
  ////( margin)
  // // // // console.log("BG RATING glucoseDataPoints", glucoseDataPoints);
  // // // // console.log("BG RATING glucosePercentage", glucosePercentage);
  // // // // console.log("BG RATING timeDataPoints", timeDataPoints);
  // // // // console.log("BG RATING timePercentage", timePercentage);



  return (


      <div className={renderOn==="dashboard"?"d-flex h-100 flex-column justify-content-between ":"d-flex flex-sm-row flex-column justify-content-center "}>
        <Chart2 width={width} themeType={themeType} mr={margin} height={height} percentage={timePercentage && timePercentage.toFixed(1)} id={id} dataPoints={timeDataPoints}/>
        <Chart2b width={width} themeType={themeType} mr={margin} height={height} percentage={glucosePercentage && glucosePercentage.toFixed(1)}
                 dataPoints={glucoseDataPoints} id={id}/>
      </div>

  );
};

// export default BgRating;
