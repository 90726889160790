import React,{useState,useEffect} from 'react';
import {useSelector,useDispatch} from "react-redux";
import DeviceCard from "../common/device-card";
import { default as Titles } from "../../utils/document-titles";
import website from "../../assets/website-icon.svg";
import {getDevices} from "../../redux/actions/device";

const Devices = () => {
  document.title = Titles.devices;

  const dispatch=useDispatch();
  const devices = useSelector((state) =>(state.devices.devices.devices));


  useEffect(()=>{
    getDevices();
  },[])

  return (
    <div className='h-100'>
             <div className="bg-white h-100">
         <div className="flex flex-col md:flex-row md:justify-between w-full flex-grow-1 h-100" >
           <div
               className="mr-md-2 mr-none bg-white rounded-lg shadow-lg p-4 flex flex-col overflow-auto"
               style={{minHeight: "91vh",minWidth:"50vw"}}
           >
             <p className="font-bold text-xl w-full text-left" style={{color:"#21608A"}}>My Devices</p>
             <div className='row justify-content-around '>
             {devices &&
             devices.devices.map((device, index) => {
               return <DeviceCard {...device} key={index+1}/>;

             })}
             </div>

           </div>
           <div className=" mt-md-0 mt-3  w-full bg-white rounded-lg shadow-lg p-4 flex flex-col  px-2" style={{color:"#21608A"}}>
             <p className="font-bold text-xl w-full text-left">
               Contact our support team through:
             </p>
             <div className="mt-3">
               {/* <p className="mt-2 d-flex flex-row"><span> </span><span>info@glucorx.co.uk Orders: orders@glucorx.co.uk</span></p> */}
               <div className='d-flex flex-rowmt-2'>
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><i className="fas fa-envelope font-size1 "></i></strong></div>
                 <div><strong>E-Mail:</strong> info@glucorx.co.uk Orders: orders@glucorx.co.uk</div>
               </div>


               {/* <p className="mt-4">
                 <strong><i className="fas fa-headset font-size1 "></i>Support hours:</strong> Contact us between 8am – 5pm, Monday to Friday
                 (with exception to bank holidays)
               </p> */}
               <div className='d-flex flex-row mt-4'>
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><i className="fas fa-headset font-size1 "></i></strong></div>
                 <div><strong>Support hours:</strong> Contact us between 8am – 5pm, Monday to Friday
                 (with exception to bank holidays)</div>
               </div>


               {/* <p className="mt-4">
                 <strong><i className="fas fa-comments font-size1 "></i>Live Chat:</strong> If you would like to instant message one of our support
                 staff, please use the Live Chat feature in the bottom-right corner
                 of your screen. We will respond as quickly as possible.
               </p> */}
               <div className='d-flex flex-row mt-4'>
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><i className="fas fa-comments font-size1 "></i></strong></div>
                 <div><strong>Live Chat:</strong> If you would like to instant message one of our support
                 staff, please use the Live Chat feature in the bottom-right corner
                 of your screen. We will respond as quickly as possible.</div>
               </div>



               {/* <p className="mt-4">
                 <strong><i className="fas fa-phone-alt font-size1 "></i>Phone:</strong> 01483 755133 / 0800 007 5892 (freephone) to talk directly
                 to a member of our support team.
               </p> */}

               <div className='d-flex flex-row mt-4'>
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><i className="fas fa-phone-alt font-size1 "></i></strong></div>
                 <div><strong>Phone:</strong> 01483 755133 / 0800 007 5892 (freephone) to talk directly
                 to a member of our support team.</div>
               </div>



               {/* <p className="mt-4"><strong><i className="font-size1 fas fa-fax "></i>Fax:</strong> 01483 770195</p> */}
               <div className='d-flex flex-row mt-4'>
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><i className="font-size1 fas fa-fax "></i></strong></div>
                 <div><strong>Fax:</strong> 01483 770195</div>
               </div>



                <div className='d-flex flex-row mt-4'>
                  <a className="d-inline-flex" href="https://www.glucorx.co.uk/">
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><img src={website} className="font-size1  d-inline-block"></img></strong></div>
                 <div className=''><strong>Website:</strong> Contact us on www.glucorx.co.uk for diabetes focused
                 products & information.</div>
                 </a>
               </div>




                <div className='d-flex flex-row mt-4'>
                <a className="d-inline-flex" href="https://www.glucorxketo.co.uk/">
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}}><img src={website} className="font-size1  d-inline-block"></img></strong></div>
                 <div className=''><strong>Visit:</strong> www.glucorxketo.co.uk for Ketosis diet focused products &
                 information.</div>
                 </a>
               </div>




               <div className='d-flex flex-row mt-4'>
                 <div className="d-flex mx-2 text-center"><strong style={{width:"1.5rem"}} ><i className=" font-size1 fas fa-map-marker-alt "></i></strong></div>
                 <div className=''><strong>Address:</strong> GlucoRx Ltd, Unit 1C Henley Business Park, Pirbright
                 Road, Guildford, Surrey, GU3 2DX, UK</div>
               </div>


             </div>
           </div>
         </div>
       </div>

    </div>
  );
};

export default Devices;
