import React, { useState, useEffect } from "react";
import Chart from "../../patient-dashboard/micro-apps/BloodGlucose/Chart";
import Chart3b from "../../patient-dashboard/micro-apps/BloodGlucose/Chart3b";
import Chart3c from "../../patient-dashboard/micro-apps/BloodGlucose/Chart3c";
import Chart3 from "../../patient-dashboard/micro-apps/BloodGlucose/Chart3";
import Chart7 from "../../clinician-dashboard/Chart/Chart7";
import { Spin, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchExportData } from "../../../redux/actions/export";
import {fetchCgmData, fetchCgmDataForHistory} from "../../../redux/actions/aidex/cgm";

import logo from "../../../assets/grx-logo.svg";
import AidexLogo from "../../../assets/AidexLogo.svg";

import moment from "moment";
import _ from "lodash";
import BgRating from "../../patient-dashboard/micro-apps/BloodGlucose/BgRating";
import TimeRangeBg from "../../patient-dashboard/micro-apps/BloodGlucose/TimeRangeBg";
import AidexTimeRangeBg from "../../patient-dashboard/micro-apps/aidex/time-range/AidexTimeRangeBg";
import HistoryChart from "../../patient-dashboard/micro-apps/aidex/history-chart";
import AverageBg from "../../patient-dashboard/micro-apps/BloodGlucose/AverageBg";
import HyposHyper from "../../patient-dashboard/micro-apps/BloodGlucose/HyposHyperBg";
import HistoricalReadings from "../../patient-dashboard/historical-readings";
import BpStats from "../../patient-dashboard/micro-apps/blood-pressure/bp-stats";
import BpReading from "../../patient-dashboard/micro-apps/blood-pressure/bp-readings";
import { decrypt } from "../../../utils/encryption";
import WsStats from "../micro-apps/weight-scale/weight-stats";
import WsReading from "../micro-apps/weight-scale/weight-readings";
import POStats from "../micro-apps/pulse-oximeter/po-stats";
import TempStats from "../micro-apps/temperature/temp-stats";
import TempReading from "../micro-apps/temperature/temp-readings";
import POReading from "../micro-apps/pulse-oximeter/po-readings";
import ExportPoReading from "./ExportPoReading";
import ExportWeightReading from "./ExportWeightReading";
import ExportBpReading from "./ExportBpReading";
import ExportTempReading from "./ExportTempReading";
import HistoricalReadingsChartBg from "../historical-readingsBg";

import CgmReport from"../../patient-dashboard/micro-apps/aidex/cgm-report";
import BgStatsAidex from "../../patient-dashboard/micro-apps/aidex/bg-stats/BgStatsAidex";
import Loading from "../../patient-dashboard/micro-apps/aidex/loading"


const ExportComp = (props) => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const data = useSelector((state) => state);
  const bg = useSelector((state) => state.exportData.exportData.bloodGlucose);
  const device = useSelector((state) => state.devices.device);

  // console.log("bg :", bg);
  const bp = useSelector((state) => state.exportData.exportData.bloodPressure);
  const weight = useSelector(
    (state) => state.exportData.exportData.weightScale
  );
  const po = useSelector((state) => state.exportData.exportData.spo2);
  const temp = useSelector((state) => state.exportData.exportData.thermometer);
  const user = useSelector((state) => state.user.userDetails);

  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  //BG Charts Hook
  const [tfTimeRange, setTfTimeRange] = useState([]);
  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [tfBgReadings, setTfBgReadings] = useState([]);
  const [tfAvgBgData, setTfAvgBgData] = useState([]);

  //BP Charts Hook
  const [bpHistoricalReading, setBpHistoricalReading] = useState([]);
  const [tfBpReadings, setTfBpReadings] = useState([]);

  //Weight scale Hooks
  const [weightHistoricalReadings, setTfWeightHistoricalReadings] = useState(
    []
  );
  const [tfWsReadings, settfWsReadings] = useState([]);

  //Po Hooks
  const [poHistoricalReadings, setTfPoHistoricalReadings] = useState([]);
  const [tfPoReadings, settfPoReadings] = useState([]);

  //Temp Hooks
  const [tempHistoricalReadings, setTfTempHistoricalReadings] = useState([]);
  const [tfTempReadings, setTempfWsReadings] = useState([]);

  const [days, setDays] = useState("");
  const [defaultDays, setDefaultDays] = useState("30days");
  const [dropMenu, setDropMenu] = useState(false);
  const [loading, setIsLoading] = useState(false);

  // Aidex Hooks
  const [organizedData, setOrganizedData] = useState([]);
  const [aidexData, setAidexData] = useState(false);
  const [csv, setCsv] = useState("");
  const [insulin, setInsulin] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [diet, setDiet] = useState([]);
  const [medicine, setMedicine] = useState([]);
  const [calculatedValues, setCalculatedValues] = useState({});
  const [bgStats, setBgStats] = useState([]);
  const [tfTimeRangeForAidex, setTfTimeRangeForAidex] = useState([]);
  const [avgBg, setAvgBg] = useState([]);
  const [csvDownloadButton, setCsvDownloadButton] = useState(true);
  const [organizedHistoryData, setOrganizedHistoryData] = useState([]);



  const safari = useSelector((state) => {

      return state.auth.safari;
    });

  useEffect(() => {
    handleExportData();
  }, []);
  const handleExportData = async () => {
    let data = localStorage.getItem("export");
    data = decrypt(data);
    data = JSON.parse(data);
    if(data.aidex === true){
      setAidexData(true);
    }else{
      setAidexData(false);
    }

    //("data=",data)
    try {
      setIsLoading(true);
      let response = await dispatch(fetchExportData(data));
      setIsLoading(false);
      //("printing")
    } catch (error) {
      setIsLoading(false);
    }
    let aidexCheck = data.aidex;
    if (aidexCheck && data.time !== "" && data.time!== null && data.time !== undefined){
      await fetchAidexReadings(data.time);
    }else if(aidexCheck && data.time === "" && data.start!== null && data.start !== undefined && data.end!== null && data.end !== undefined){
      let durationForAidex = {
        startDate: data.start,
        endDate: data.end
      }
      await fetchAidexReadings(durationForAidex);

    }
  };

  useEffect(() => {}, [bg, bp]);

  //Historical reading
  useEffect(() => {
    transformHistoricalReadings();
  }, [bg && bg.historicalReadings]);

  // const transformHistoricalReadings = () => {
  //   let transformedData = [];
  //
  //   transformedData =
  //     bg &&
  //     bg.historicalReadings &&
  //     bg.historicalReadings.length > 0 &&
  //     bg.historicalReadings
  //       .map((reading) => {
  //         return reading.data.map((item) => {
  //           if (
  //             new Date(startDate).getMonth() <= item.month &&
  //             new Date(endDate).getMonth() <= item.month
  //           ) {
  //             let date = new Date();
  //             date.setMonth(item.month - 1);
  //             date.setDate(reading._id);
  //             date.setHours(item.hour);
  //             date.setMinutes(item.minutes);
  //             return {
  //               date: date,
  //               reading: item.value["bloodGlucose"],
  //             };
  //           } else {
  //             let date = new Date();
  //             date.setMonth(item.month - 1);
  //             date.setDate(reading._id);
  //             date.setHours(item.hour);
  //             date.setMinutes(item.minutes);
  //             return {
  //               date: date,
  //               reading: item.value["bloodGlucose"],
  //             };
  //           }
  //         });
  //       })
  //       .flat();
  //
  //   let myArray = _.sortBy(
  //     transformedData && transformedData.length > 0 && transformedData.flat(),
  //     function (dateObj) {
  //       return new Date(dateObj.date);
  //     }
  //   );
  //   setTfHistoricalReadings(myArray);
  // };


// new version
  const transformHistoricalReadings = () => {
      let transformedData = [];
      if (bg && bg.historicalReadings && bg.historicalReadings.length > 0) {
          transformedData = bg.historicalReadings
              .map((reading) => {
                // console.log("BG :", bg);
                  return reading.data.map((item) => {
                      const date = new Date(item.year, item.month - 1, reading._id, item.hour, item.minutes);
                      // console.log("DATE :", date);
                      return {
                          date: date,
                          reading: item.value["bloodGlucose"],
                          time: item.tmie,
                      };
                  });
              })
              .flat();
      } else {
      }

      if (Array.isArray(transformedData)) {
          let myArray = _.sortBy(transformedData, function (dateObj) {
              return new Date(dateObj.date);
          });

          setTfHistoricalReadings(myArray);
      }
  };


  // Bg reading
  useEffect(() => {
    transformBgReading();
  }, [bg]);

  const transformBgReading = () => {
    let tfBgReadings =
      bg &&
      bg.bgReadings &&
      bg.bgReadings.map((item) => {
        var localDate = moment(item.time).utc();
        var date = localDate.format("DD-MM-YYYY ");
        var time = localDate.format("h:mm:ss A");
        item.takenTime =
          item.takenTime === 0
            ? "Before meal"
            : item.takenTime === 1
            ? "After Meal"
            : item.takenTime === 2
            ? "General"
            : "QC";
        item.bgValue = item.value.bloodGlucose;
        item.bgValue = item.bgValue && item.bgValue.toFixed(1);
        item.time = date;
        item.clock = time;
        return item;
      });

    setTfBgReadings(tfBgReadings);
  };

  //Time Range
  useEffect(() => {
    if (bg && bg.timeRange) {
      transformTimeRange();
    }
  }, [bg && bg.timeRange]);
  const transformTimeRange = () => {
    let labels = ["<3.9", "3.9-4.9", "5.0-8.0", "8.1-13.3", ">13.3"];
    let labelsMgdl = [
      "<70.24",
      "70.24-88.3",
      "90.0-144.1",
      "145.9-239.5",
      ">239.5",
    ];
    const keys = Object.keys(bg && bg.timeRange[0]);
    const transformedData = keys.map((key) => {
      return {
        label:
          device && device.preferences && device.preferences.unit === 0
            ? labels[Number(key)]
            : labelsMgdl[Number(key)],
        value: bg.timeRange[0][key].value,
      };
    });

    setTfTimeRange(transformedData);
  };

  //AverageBg
  useEffect(() => {
    transformAverageBgData();
  }, [bg && bg.averageBg]);
  const transformAverageBgData = () => {
    let transformedData = [];
    transformedData =
      bg &&
      bg.averageBg &&
      bg.averageBg.map((item) => {
        let m = moment().hour(item._id).format("hh A");
        let hour = item.data[0].time;
        hour = new Date(hour);
        hour = moment(hour).hour();
        return {
          date: hour,
          value: item.avg,
          hour: m
        };
      });
    setTfAvgBgData(
      _.sortBy(transformedData, (dateObj) => new Date(dateObj.date))
    );
  };

  // Blood pressure Chart

  // ---Historical reading---
  useEffect(() => {
    transformBpHistoricalReadings();
  }, [bp && bp.historicalReadings]);

  const transformBpHistoricalReadings = () => {
    let transformedData = [];
    transformedData =
      bp &&
      bp.historicalReadings &&
      bp.historicalReadings
        .map((reading) => {
          return reading.data.map((item) => {
            let date = new Date();
            date.setDate(reading._id);
            date.setHours(item.hour);
            date.setMinutes(item.minutes);
            return {
              date: date,
              reading: item.value["systolic"],
              reading2: item.value["diastolic"],
              reading3: item.value["heartRate"],
            };
          });
        })
        .flat();
    let myArray = _.sortBy(
      transformedData && transformedData.length > 0 && transformedData.flat(),
      function (dateObj) {
        return new Date(dateObj.date);
      }
    );

    setBpHistoricalReading(myArray);
  };

  // ----Bp Reading----
  useEffect(() => {
    transformBpReading();
  }, [bp && bp.bpReadings]);

  const transformBpReading = () => {
    let tfBpReadings =
      bp &&
      bp.bpReadings &&
      bp.bpReadings.map((item) => {
        let localDate = moment(item.time).utc();
        let date = localDate.format("DD-MM-YYYY ");
        item.systolic = item.value.systolic;
        item.diastolic = item.value.diastolic;
        item.heartRate = item.value.heartRate;
        item.time = date;
        return item;
      });
    setTfBpReadings(tfBpReadings);
  };

  // ---Weight Scale----
  useEffect(() => {
    transformWeightHistoricalReadings();
  }, [weight && weight.historicalReadings]);

  const transformWeightHistoricalReadings = () => {
    //("This is Called");
    let transformedData = [];
    transformedData =
      weight &&
      weight.historicalReadings
        .map((reading) => {
          //("ITEM :",reading);

          return reading.data.map((item) => {
            //(item);
            let date = new Date();
            date.setDate(reading._id);
            date.setHours(item.hour);
            date.setMinutes(item.minutes);
            return {
              date: date,
              reading: item.value["weight"],
              // reading2: item.value["heartRate"],
              // reading3: item.value["heartRate"],
            };
          });
        })
        .flat();
    let myArray = _.sortBy(
      transformedData && transformedData.length > 0 && transformedData.flat(),
      function (dateObj) {
        return new Date(dateObj.date);
      }
    );

    setTfWeightHistoricalReadings(myArray);
  };

  useEffect(() => {
    transformWsReading();
  }, [weight && weight.readings]);

  const transformWsReading = () => {
    //(data);
    let tfWsReadings =
      weight &&
      weight.readings.map((item, index) => {
        //(item);
        let reading = { time: 0, weight: 0, gained: 0, lost: 0 };
        reading.weight = _.round(item.value.weight, 1).toFixed(1);
        if (index === 0) {
          reading.gained = null;
          reading.lost = null;
        } else {
          if (
            weight &&
            weight.readings[index].value.weight > weight &&
            weight.readings[index - 1].value.weight
          ) {
            reading.gained = _.round(
              weight &&
                weight.readings[index].value.weight - weight &&
                weight.readings[index - 1].value.weight,
              1
            ).toFixed(1);
            reading.lost = 0;
          } else if (
            weight &&
            weight.readings[index].value.weight < weight &&
            weight.readings[index - 1].value.weight
          ) {
            reading.lost = _.round(
              weight &&
                weight.readings[index - 1].value.weight - weight &&
                weight.readings[index].value.weight,
              1
            ).toFixed(1);
            reading.gained = 0;
          }
        }

        reading.time = moment(item.time).format("MMMM-DD-YYYY");
        return reading;
      });

    settfWsReadings(tfWsReadings);
  };

  //Pulse-oximeter
  useEffect(() => {
    transformPOHistoricalReadings();
  }, [po && po.historicalReadings]);
  const transformPOHistoricalReadings = () => {
    //////("This is Called");
    let transformedData = [];
    transformedData =
      po &&
      po.historicalReadings
        .map((reading) => {
          //////("ITEM :",reading);

          return reading.data.map((item) => {
            let date = new Date();
            date.setDate(reading._id);
            date.setHours(item.hour);
            date.setMinutes(item.minutes);
            return {
              date: date,
              reading: item.value["oxygen"],
              // reading2: item.value["diastolic"],
              reading2: item.value["heartRate"],
            };
          });
        })
        .flat();
    let myArray = _.sortBy(
      transformedData && transformedData.length > 0 && transformedData.flat(),
      function (dateObj) {
        return new Date(dateObj.date);
      }
    );

    setTfPoHistoricalReadings(myArray);
  };
  useEffect(()=>{
    transformPoReading()
  },[po && po.readings])
   const transformPoReading = () => {
     //////(data);
     let tfPoReadings = po && po.readings.sort((a, b) => new Date(b.time) - new Date(a.time)).map((item) => {
       //////(item);
       let data= {time:0, oxygen: 0, heartRate:0};
       //////(data);

       data.oxygen = item.value.oxygen;
       data.heartRate = item.value.heartRate;
       data.spO2=item.value.spO2;
       // item.heartRate = item.value.heartRate;
       data.time = moment(item.time).format("MMMM-DD-YYYY");
       return data;
     });
     settfPoReadings(tfPoReadings);
   };

  //Temprature
  useEffect(() => {
    transformTempHistoricalReadings();
  }, [temp && temp.historicalReadings]);

  const transformTempHistoricalReadings = () => {
    ////("This is Called");
    let transformedData = [];
    transformedData =
      temp &&
      temp.historicalReadings
        .map((reading) => {
          ////("ITEM :",reading);

          return reading.data.map((item) => {
            ////(item);
            let date = new Date();
            date.setDate(reading._id);
            date.setHours(item.hour);
            date.setMinutes(item.minutes);
            return {
              date: date,
              reading: item.value["temperature"],
            };
          });
        })
        .flat();
    let myArray = _.sortBy(
      transformedData && transformedData.length > 0 && transformedData.flat(),
      function (dateObj) {
        return new Date(dateObj.date);
      }
    );

    setTfTempHistoricalReadings(myArray);
  };
  useEffect(() => {
    transformTempReading();
  }, [temp && temp.readings]);
  const transformTempReading = () => {
    let tfWsReadings =
      temp &&
      temp.readings.map((item, index) => {
        let reading = { time: 0, temperature: 0 };
        reading.temperature = _.round(
          item && item.value.temperature,
          1
        ).toFixed(1);

        reading.time = moment(item.time).format("MMMM-DD-YYYY");
        return reading;
      });

    setTempfWsReadings(tfWsReadings);
  };

  var localDate = moment(user && user.dateOfBirth).utc();
  var age = localDate.format("DD-MM-YYYY ");
  let localStorageData = localStorage.getItem("export");
  localStorageData = decrypt(localStorageData);
  localStorageData = JSON.parse(localStorageData);
  let startTime, endTime, lastTime;
  let date = new Date();
  if (localStorageData && localStorageData.time !== "") {
    endTime = new Date();
    endTime.setHours(23);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    if (localStorageData.time) {
      if (localStorageData.time === "90days") {
        date.setDate(date.getDate() - 90);
      } else if (localStorageData.time === "30days") {
        date.setDate(date.getDate() - 30);
      } else if (localStorageData.time === "14days") {
        date.setDate(date.getDate() - 14);
      } else if (localStorageData.time === "7days") {
        date.setDate(date.getDate() - 7);
      } else if (localStorageData.time === "today") {
      }
    }
  } else if (localStorageData.start && localStorageData.end) {
    startTime = new Date(localStorageData.start);

    lastTime = new Date(localStorageData.end);
  }


  // Aidex API and making data to call API.
  const fetchAidexReadings = async (timeDuration) => {
// // // // // console.log("TIME DURATION:", timeDuration);

    let duration = {};
    // let findDaysRange =(date)=>{
    //   let start = moment(date).add(7, 'days');
    //   return new Date(start._d);
    // }
    if (typeof(timeDuration) ==="string") {
      let data_from = findStartDate(timeDuration);
      let data_to = findEndDate();
      // let data_to = findDaysRange(data_from);

      duration.timeFrom = data_from;
      duration.timeTo = data_to;


    }
    if (typeof(timeDuration)==="object" && timeDuration.startDate) {
      // // // // // console.log("Making DURATION for range");
      duration.timeFrom = new Date(timeDuration.startDate);
      let lastTime = new Date(timeDuration.endDate);
lastTime.setHours(23);
lastTime.setMinutes(59);
lastTime.setSeconds(59);

      duration.timeTo = new Date (lastTime);
    }
    // // // // // console.log("DURATION Before CGM API CALL :", duration);
    const response = await  dispatch(fetchCgmData(duration));

    if (response && response.data){
      // // // // // console.log(response);
      setOrganizedData(response.data);
      setDiet(response.diet);
      setExercise(response.exercise);
      setInsulin(response.insulin);
      setMedicine(response.medicine);

      setCalculatedValues(response.calculation);
      setTfTimeRangeForAidex(response.timeRange.timeRange);
      let abg = response.calculation.hoursArr.map((hour, index)=>{
        let avgObj;
        let hourLength= hour.length;
        let gluco= 0;
        // // // // // console.log("HOUR LENGTH", hour.length);
        for(let i = 0; i<hour.length; i++){
          gluco = gluco + hour[i].glucose;
        }
        let value = gluco/hourLength;
        avgObj = {
          date:index,
          value: value
        }
        // // // // // console.log("AVERAGE OBJECT", avgObj);
        return avgObj;

      })
      setAvgBg(abg);

      //BG STATS
      setBgStats(response.BgStats);


    } else {
      setOrganizedData(response);

    }

    const historyResponse = await  dispatch(fetchCgmDataForHistory(duration));


if (historyResponse && historyResponse.data){
  // // // // // console.log("RES DATA", historyResponse.data);
  setOrganizedHistoryData(historyResponse.data);
} else {
  // // // // // console.log("ELSE RES", historyResponse);
  setOrganizedHistoryData(historyResponse);

}




  //   if (rangFilter === false){
  //   let start = findStartDate(timeDuration);
  //
  //   setFromDate(moment(start).format('D MMM YYYY').toString());
  //   setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  // } else if (rangFilter === true) {
  //
  // }

  };



  useEffect(() => {
    // // // // // console.log("organized", organizedData);
    // // // // // console.log("CSV DOWNLOAD", csvDownloadButton);
  },[organizedData, bgStats.lowest,csvDownloadButton]);












  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {

      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {


      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {



      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {


      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "40days") {


      let start = moment().subtract(40, 'days');
      return new Date(start._d);

    } else if(filter === "24hours"){


      let start = moment().add(-24, 'hours');
      return new Date(start._d);

    }
    else if(filter === "17days"){
      let start = moment().subtract(17, 'days');
      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }


  // Converting for CSV
  const convert = () => {
      // e.preventDefault();

      // "\uFEFF"+

      let dataNew =[];
      organizedData && organizedData.length>0 && organizedData.map((obj)=>{


        let object = {"deviceSn": obj.deviceSn,
         "time": obj.time, "glucose": obj.glucose}

        dataNew.push(object);

      })

      const parsedJson = dataNew;
      if (
        !Array.isArray(parsedJson) ||
        !parsedJson.every((p) => typeof p === "object" && p !== null)
      ) {
        return;
      }
      const heading = Object.keys(parsedJson[0]).join(",");
      const space = "\n"
      const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
      setCsv(`${heading}${space}${body}`);
      let csvData = heading + space + body;

      if(safari){
        download(csvData,"GlucoRx Aidex CGM Record", 'text/csv;charset=utf-8;');

      }else{
      download("\uFEFF"+csvData,"GlucoRx Aidex CGM Record", 'text/csv;charset=utf-8;');
      }
    };

    // Function to download data to a file
  const download=( processedData, filename, type)=> {
    let data = processedData;

      var file = new Blob([data], {type: type});
      if (window.navigator.msSaveOrOpenBlob) // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename);
      else { // Others
          var a = document.createElement("a"),
                  url = URL.createObjectURL(file);
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
          }, 0);
      }
  }


  return (
    <div className="m-auto printing-screen">
      {" "}
      <Spin spinning={loading}>
        <div className=" shadow-lg bg-white rounded pb-2">
          <div className="mt-4">
            <div className="">
              <div className="d-flex justify-content-center ">
                <img src={logo} alt="Logo" width="30%" className="mt-4"></img>
              </div>
              <div className="d-flex justify-content-between font-weight-bold px-5 my-3">
                <div className="ml-5">
                  <div>
                    Name: {user && user.firstName}
                    {user && user.lastName}
                  </div>
                  <div>Email: {user && user.email ? user.email : "N/A"}</div>
                  <div>
                    Address: {user && user.address ? user.address : "N/A"}
                  </div>
                </div>

                <div className="mr-5">
                  <div>
                    Age:{" "}
                    {user && user.dateOfBirth
                      ? moment().diff(user.dateOfBirth, "years")
                      : "N/A"}
                  </div>
                  <div>D.O.B: {user && user.dateOfBirth ? age : "N/A"}</div>
                  <div>Phone: {user && user.phone ? user.phone : "N/A"}</div>
                </div>
              </div>
            </div>
            <div className="text-center font-weight-bold mt-2 mb-4">
              {localStorageData.time !== "" ? (
                <div>
                  <span>From: {moment(date).utc().format("DD-MM-YYYY ")}</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>To: {moment(endTime).utc().format("DD-MM-YYYY ")}</span>
                </div>
              ) : (
                <div>
                  <span>
                    From: {moment(startTime).utc().format("DD-MM-YYYY ")}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    To: {moment(lastTime).utc().format("DD-MM-YYYY ")}
                  </span>
                </div>
              )}
            </div>



            {bg ? (
              <div className="container-fluid">
                <p className="font-size3 text-center">Blood Glucose</p>
                <div className="exportChart mx-auto historic-chart-width  my-3" style={{ height: "500px" }}>
                <div className={"chart-heading font-bold my-3"}>
                  <p>Historical Readings</p>
                </div>
                  <HistoricalReadingsChartBg id="exportHistoricChart" margin={false} dataPoints={tfHistoricalReadings}/>

                </div>

                <div className=" row justify-content-around  my-3 "  style={{ height: "40vh" }}>
                  <div className="exportChart ">
                    <BgRating
                      margin={true}
                      timePercentage={
                        bg &&
                        bg.timeAndGlucose &&
                        parseInt(bg.timeAndGlucose.inRangePercentage)
                      }
                      glucosePercentage={
                        bg &&
                        bg.timeAndGlucose &&
                        parseInt(
                          bg.timeAndGlucose.highEventsPercentage +
                            bg.timeAndGlucose.lowEventsPercentage
                        )
                      }
                      timeDataPoints={[
                        {
                          label: "In-Range",
                          value: parseInt(
                            bg &&
                              bg.timeAndGlucose &&
                              bg.timeAndGlucose.inRangePercentage
                          ),
                        },
                        {
                          label: "Out-Range",
                          value:
                            100 -
                            parseInt(
                              bg &&
                                bg.timeAndGlucose &&
                                bg.timeAndGlucose.inRangePercentage
                            ),
                        },
                      ]}
                      glucoseDataPoints={[
                        {
                          label: "High-events",
                          value: parseInt(
                            bg &&
                              bg.timeAndGlucose &&
                              bg.timeAndGlucose.highEventsPercentage
                          ),
                        },
                        {
                          label: "Low-events",
                          value: parseInt(
                            bg &&
                              bg.timeAndGlucose &&
                              bg.timeAndGlucose.lowEventsPercentage
                          ),
                        },
                        {
                          label: "In-Range",
                          value: parseInt(
                            bg &&
                              bg.timeAndGlucose &&
                              bg.timeAndGlucose.inRangePercentage
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>

                <div
                  className="exportChart row justify-content-around  my-5 "
                  style={{ height: "30vh" }}
                >
                  <div className="col-3 justify-content-center d-flex align-items-center">
                    <div className="">
                      <Chart3b
                        unit={
                          device && device.preferences && device.preferences.unit
                            ? device.preferences.unit
                            : 0
                        }
                        lowest={bg && bg.bgStats ? bg.bgStats.lowest : null}
                      />
                      <p className="text-center  mt-2">
                        Lowest Bg <br />
                        Reading
                      </p>
                    </div>
                  </div>

                  <div className="col-3 d-flex justify-content-center align-items-center ">
                    <div className="">
                      <Chart3
                        unit={
                          device && device.preferences && device.preferences.unit
                            ? device.preferences.unit
                            : 0
                        }
                        avg={bg && bg.bgStats ? bg.bgStats.average : null}
                      />
                      <p className="text-center mt-2">
                        Average BG <br />
                        Reading
                      </p>
                    </div>
                  </div>
                  <div className="col-3 d-flex justify-content-center align-items-center bg-primary rounded ">
                    <div className="">
                      <Chart3c
                        unit={
                          device && device.preferences && device.preferences.unit
                            ? device.preferences.unit
                            : 0
                        }
                        highest={bg && bg.bgStats ? bg.bgStats.highest : null}
                      />
                      <p className="text-center text-white mt-2">High</p>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-between  my-3 "
                  style={{ height: "auto" }}
                >
                  <div
                    className="mt-5 col-12 exportChart "
                    style={{ height: "400px" }}
                  >
                    <TimeRangeBg themeType={"bg"} id={"vBgExpo"} dataPoints={tfTimeRange} />
                  </div>
                </div>

                <div
                  className="row justify-content-between  my-3 "
                  style={{ height: "auto" }}
                >
                  <div
                    className=" mt-5 col-12 exportChart "
                    style={{ height: "400px" }}
                  >
                    <AverageBg themeType={"bg"} id={"vBg"} dataPoints={tfAvgBgData} />
                  </div>
                </div>

                <div
                  className="row justify-content-between  my-3 "
                  style={{ height: "auto" }}
                >
                  <div className="col-12 exportChart  my-3">
                    <HyposHyper
                      unit={
                        device && device.preferences && device.preferences.unit
                          ? device.preferences.unit
                          : 0
                      }
                      {...(bg &&
                        bg.avgPreAndAfterMeal &&
                        bg.avgPreAndAfterMeal)}
                      {...(bg && bg.avgPreAndAfterMeal && bg.hyposAndHypers)}
                    />
                  </div>
                </div>

                <div className="row justify-content-between  my-3 ">
                  <div className="col-12 exportChart ">
                    <Chart7 dataSource={tfBgReadings} />
                  </div>
                </div>
              </div>
            ) : null}


            {aidexData ? (
              <div className="container-fluid">
                <img src={AidexLogo} className="m-auto aidex-logo" alt="aidex logo"/>

                <div className="row  mr-sm-0  mt-3 justify-content-center">
                <button className="btn btn-primary print-btn" disabled={csvDownloadButton} onClick={()=>convert()}>Download CSV</button>

                  <div className="col-12" style={{ height: "500px" }}>
                    <div className={organizedData !== undefined || organizedData===null?"   bg-white border-radius-chart p-3 d-flex":"   bg-white border-radius-chart p-3 "} style={{ height: "100%" }}>
                    {/*<AidexChart data={organizedData} diet={diet} medicine={medicine} exercise={exercise} insulin={insulin} graph="cgm"/>*/}

                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
                    <CgmReport data={organizedData} id="overview" graph="cgm"/>


                    :
                    organizedData === undefined ?<h1 className="text-center pt-5">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                  <div className="col-12 mt-2" >
                    <div className=" bg-white h-100 border-radius-chart d-flex justify-content-lg-around justify-content-xl-around flex-column">
                    {organizedData && organizedData === "" || organizedData === null || organizedData === undefined || organizedData.length === 0?null:
                    <>
                    <BgRating
                         id={"chart1"}
                          margin={true}
                          height="60%"
                          width="75%"
                          themeType={"bg"}
                          timePercentage = {
                        calculatedValues.avgP
                      }
                                 glucosePercentage = {

                                       calculatedValues.hiP +
                                      calculatedValues.loP

                                 }
                                 timeDataPoints = {
                                   [{
                                     label: "In-Range",
                                     value: parseInt(calculatedValues.avgP),
                                   },
                                     {
                                       label: "Out-Range",
                                       value: 100 - parseInt(calculatedValues.avgP),
                                     },
                                   ]
                                 }
                                 glucoseDataPoints = {
                                   [{
                                     label: "High-events",
                                     value: parseInt(calculatedValues.hiP),
                                   },
                                     {
                                       label: "Low-events",
                                       value: parseInt(calculatedValues.loP),
                                     },
                                     {
                                       label: "In-Range",
                                       value: parseInt(calculatedValues.avgP),
                                     },
                                   ]
                                 }
                      />
                      <BgStatsAidex themeType={"bg"}  {...bgStats} />
                      </>
                    }
                    </div>
                  </div>
                </div>

                <div className="row  mr-sm-0  mt-2 justify-content-center">
                  <div className=" col-md-12" style={{ height: "700px" }}>
                    <div className={organizedData !== undefined || organizedData===null?"   bg-white border-radius-chart p-3 d-flex":"   bg-white border-radius-chart p-3 "} style={{ height: "100%" }}>
                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?

                    <CgmReport data={calculatedValues.hoursArr} graph="agp" callBackFunc={setCsvDownloadButton}/>


                    :
                    organizedData === undefined ?<h1 className="text-center">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>
                <div className="row mr-sm-0  mt-3">
                  <div className="col-12 mb-2" >
                    <div className="   bg-white border-radius-chart p-3 " style={{ height: "400px" }}>
                      <AidexTimeRangeBg themeType={"bg"} dataPoints = { tfTimeRangeForAidex }/>
                    </div>
                  </div>
                  <div className="col-12 mt-2 mt-md-0" >
                    <div className="   bg-white border-radius-chart p-3 " style={{ height: "400px" }}>
                      {/*<AverageBg dataPoints = { tfAvgBgData }/>*/}
                      <AverageBg themeType={"bg"} id={"aidexABg"} dataPoints = { avgBg }/>
                    </div>
                  </div>
                </div>
                <div className="row  mr-sm-0  mt-2 justify-content-center">
                <div className=" col-12 mb-2">
                  <div className="bg-white border-radius-chart p-3 ">
                  <h1 className="p-2 font-weight-bol chart-heading text-voyger-purple mb-3">GlucoRx Aidex History</h1>


                  {organizedHistoryData && organizedHistoryData !== "" && organizedHistoryData !== null && organizedHistoryData !== undefined && organizedHistoryData.length !== 0?
                  organizedHistoryData.map((week, index)=> (
                    week.readings[0].length>0?
                    <div className="w-100" key={index+1}>
                    <HistoryChart chartId={"HistoryReport"+ index}  data={week}/>

                    </div>
                    :null
                  ))

                  :
                  organizedHistoryData === undefined ?<h1 className="text-center pt-5">No data found.</h1>:
                  <Loading/>

                }
                {/*  { organizedData === "" || organizedData === null?
                  <Loading/>
                  : organizedData === undefined ?<h1 className="text-center pt-5">No data found.</h1>:
                  organizedData.map((week, index)=> (
                    <>
                      {<HistoryChart chartId={"HistoryReport"+ index} data={week}/>}
                    </>
                  ))


                }*/}


                  </div>
                </div>
                </div>







              </div>
            ) : null}


            {bp ? (
              <div className="my-5 ">
                <p className="font-size3 text-center">Blood Pressure</p>
                <div className="exportChart" style={{ height: "500px" }}>
                  <HistoricalReadings
                    color1="#667eea"
                    color2="#7f9cf5"
                    color3="#4c51bf"
                    name="Systolic"
                    name2="Diastolic"
                    name3="Heart Rate"
                    id={"bloodpressure"}
                    dataPoints={bpHistoricalReading}
                  />
                </div>

                <div className="mt-5 exportChart">
                  <BpStats {...(bp.bpStats && bp.bpStats)} />
                </div>

                <div className="mt-5 exportChart">
                  <ExportBpReading dataSource={tfBpReadings} />
                </div>
              </div>
            ) : null}

            {weight ? (
              <div className="my-5 ">
                <p className="font-size3 text-center">Weight Scale</p>
                <div className="exportChart" style={{ height: "500px" }}>
                  <HistoricalReadings
                    color1="#667eea"
                    color2="#7f9cf5"
                    color3="#4c51bf"
                    name="Weight"
                    // name2 = "Diastolic"
                    // name2 = "Heart Rate"
                    dataPoints={weightHistoricalReadings}
                    id={"weight"}
                  />
                </div>

                <div className="mt-5 exportChart">
                  <WsStats
                    {...(weight.weightStats && weight.weightStats)}
                    readings={weight && weight.readings}
                  />
                </div>

                <div className="mt-5 exportChart">
                  <ExportWeightReading dataSource={tfWsReadings} />
                </div>
              </div>
            ) : null}

            {po ? (
              <div className="my-5 ">
                <p className="font-size3 text-center">Pulse Oximeter</p>
                <div className="exportChart" style={{ height: "500px" }}>
                  <HistoricalReadings
                    color1="#667eea"
                    color2="#7f9cf5"
                    color3="#4c51bf"
                    name="Oxygen"
                    name2="HeartRate"
                    dataPoints={poHistoricalReadings}
                    id={"po"}
                  />
                </div>

                <div className="mt-5 exportChart">
                  <POStats {...(po.pulseStats && po.pulseStats)} />
                </div>
                <div className="mt-5 exportChart">
                 <ExportPoReading dataSource={tfPoReadings}/>
                </div>
              </div>
            ) : null}

            {temp ? (
              <div className="my-5 ">
                <p className="font-size3 text-center">Temperature</p>
                <div className="exportChart" style={{ height: "500px" }}>
                  <HistoricalReadings
                    color1="#667eea"
                    color2="#7f9cf5"
                    color3="#4c51bf"
                    name="Temperature"
                    id={"tempHistoricalReading"}
                    dataPoints={tempHistoricalReadings}
                  />
                </div>

                <div className="mt-5 mb-3 exportChart">
                  <TempStats
                    {...(temp.pulseStats && temp.pulseStats)}
                    tempLost={temp.readings && temp.readings0}
                  />
                </div>

                <div className="mt-5 exportChart">
                  <ExportTempReading dataSource={tfTempReadings} />
                </div>
              </div>
            ) : null}
          </div>
          <div className="text-center font-weight-bold mt-2 mb-4 mt-4">
            <h4><b>Disclaimer:</b></h4>
            <p>This document is intended for authorised use only.<br /> By accessing or using this file, the user acknowledges responsibility for safeguarding the contents from unauthorised distribution or access.<br /> The creator of this document cannot be held liable for any misuse or unintended sharing of the information contained herein.<br /> Please ensure proper handling and secure storage of this document.</p>
          </div>
        </div>

      </Spin>
    </div>
  );
};

export default ExportComp;
