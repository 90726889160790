import React from "react";
import Tooltip from "../../../../utils/Tooltip";
import Chart3 from "./Chart3";
import Chart3b from "./Chart3b";
import Chart3c from "./Chart3c";

const BgStats = (props) => {
  let heading=props.themeType==="bg"?"text-voyger-purple":props.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
  let color1=props.themeType==="bg"?"#0D92D2":props.themeType==="inr"?"#99D7A6":"#0D92D2";
  // let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";
// // // // console.log("BG STATS", props);
  return (

      <div className="container mt-4">
        <p className={`chart-heading font-weight-bold ${heading}`}>{props.themeType==="bg"? "Bg Stats":"Inr Stats"}
            <span className='ml-2'><Tooltip themeType={props.themeType} text="Your lowest reading ,Your Average reading and the Highest reading recorded in your selected date range."/></span>
            </p>
      <div className="row justify-content-md-around  font-weight-bold ">
      <div className="col-md-3 col-12 mt-md-0 mt-5 pb-3  justify-content-center d-flex align-items-center">
            <div>
                  <Chart3b unit={props.unit} id={props.id} lowest={props.lowest} themeType={props.themeType}/>
                  <p className={`text-center ${color1}`}>
                    Lowest Bg

                  </p>
                </div>
            </div>
            <div className="col-md-3 col-12 mt-md-0 mt-2 pb-3  justify-content-center d-flex align-items-center">
             <div>
                    <Chart3 unit={props.unit} id={props.id} avg={props.average} themeType={props.themeType} />
                    <p className={`text-center ${color1}`}>
                      Average BG

                    </p>
                  </div>
             </div>
             <div className={`col-md-3 col-12 mt-md-0 mt-2  pb-3 justify-content-center d-flex align-items-center text-white ${props.themeType==="bg"?"bg-voyger-purple":"bg-voyger-inr-blue"}`}>
             <div>
             <Chart3c unit={props.unit} id={props.id} highest={props.highest} themeType={props.themeType}/>

                    <p className="text-center ">
                      High <br />

                    </p>
                  </div>
            </div>
      </div>
    </div>
  );
};

export default BgStats;
