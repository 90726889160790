import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { default as Titles } from "../../utils/document-titles";
import { login, checkClientId, logout } from "../../redux/actions/auth";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import ShowToast from "../../utils/show-toast";
import { useSelector } from "react-redux";

import GlucoRxLogo from "../../assets/GlucoRxLogo.svg";
import GlucoRxLogoWhite from "../../assets/newglucorxwhite.svg";

import Cross from "../../assets/cross.png";
import Tick from "../../assets/tick.png";


import voyagerLogo from "../../assets/voyagerLogo.svg";
import appStore from "../../assets/app-store.png";
import playStore from "../../assets/play-store.png";
import {encrypt,decrypt} from "../../utils/encryption";
import Loading from "../common/Loading"

// import ShowToast from "../../utils/show-toast";


const AlexaIntegrationComp = () => {

  document.title = Titles.login;
  const {token} = useSelector((state)=> state.auth);
// // // console.log("TOKEN", token);
  //(state)
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [respo, setRespo] = useState();


  const [stateUri, setStateUri] = useState("");
  // const [accessToken, setAccessToken] = useState("");
  const [message, setMessage] = useState("");


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [grantType, setGrantType] = useState("");
  const [clientId, setClientId] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const dispatch = useDispatch();


  useEffect(()=>{
// // console.log("TOEken", token);
setIsAuthenticated(!!token);
const query = new URLSearchParams(window.location.search);
const grant_type = query.get("grant_type");
setGrantType(grant_type);
const client_id = query.get("client_id");
setClientId(client_id);
const redirect_uri = query.get("redirect_uri");
const state = query.get("state");

setRedirectUrl(redirect_uri);

checkingClientId(client_id, redirect_uri, state);


},[token])

  const handleSubmit = async (e) => {
    try {
      // setIsLoading(true);
      e.preventDefault();
      await dispatch(login({ email, password }));
      // setIsLoading(false);
    } catch (err) {


      // setIsLoading(false);

      // ShowToast(err.response.data.message, "error")

    }
  };
  const checkingClientId=async(cId, redirect_uri, stateurl)=>{
// // console.log("CID", cId);

try {
  setIsLoading(true);
  // e.preventDefault();
  let res = await dispatch(checkClientId(cId));
  setIsLoading(false);

  let code = res.data.data;
  // accessToken = decrypt(accessToken);
  // accessToken = JSON.parse(accessToken);
  if(res.status === 200){
    let redUri = `${redirect_uri}#state=${stateurl}&code=${code}&response_type=code`;
    // // console.log("Access", redUri);
    localStorage.removeItem("grxAuthToken");
    window.open(redUri,"_self");
  }
  setMessage(res.data.message);
  setRespo(res.status);
} catch (err) {
setRespo(err.response.status);
setMessage(err.response.data.message);

  setIsLoading(false);
}
  }
  // // console.log("isAuthenticated", isAuthenticated);
  return (
    <section className="row mw-100 m-auto alexa">
      <div className="col-12 alexa-header p-2 d-flex align-items-center">
        <img className="mr-auto login-glucoRx-logo" src={GlucoRxLogo}></img>
        {localStorage.getItem("grxAuthToken") && localStorage.getItem("grxAuthToken") !== null && localStorage.getItem("grxAuthToken") !== ""?null:
        <Link
                  to={"/auth/register"}>
                <span className="ml-auto mr-3 login-create-account-text">
                  Create Account
                </span>
              </Link>
              }
      </div>
      <div className="col-12 alexa-content login-section">
      {localStorage.getItem("grxAuthToken") && localStorage.getItem("grxAuthToken") !== null && localStorage.getItem("grxAuthToken") !== ""?
      <div className="row  login-form justify-content-center h-100 w-100 m-auto pt-3">
                {message === "" || message === null?


      <Loading/>



                :null
              }
              {respo === 404 || respo=== 400?
                <div className= "col-12 col-sm-6 col-md-4 m-auto text-center">
                  <div className="row mw-100 m-auto h-100 loading">
                    <div className="col-12">
                    <img src={GlucoRxLogoWhite} className="w-50 grx-logo" alt="AIDEX LOGO"/>

                    </div>
                    <div className="col-12">
                      <img src={Cross} className="w-50 m-auto" alt="Loading Gif"/>
                    </div>
                    <div className="col-12">
                    <p className="font-bold p-2">{message}</p>

                    </div>

                  </div>
                </div>
                :respo === 200?<div className= "col-12 col-sm-6 col-md-4 m-auto text-center">
                  <div className="row mw-100 m-auto h-100 loading">
                    <div className="col-12">
                    <img src={GlucoRxLogoWhite} className="w-50 grx-logo" alt="AIDEX LOGO"/>

                    </div>
                    <div className="col-12">
                      <img src={Tick} className="w-50 m-auto" alt="Loading Gif"/>
                    </div>
                    <div className="col-12">
                    <p className="font-bold p-2">{message}</p>

                    </div>

                  </div>
                </div>:null}
                </div>
      :
      <div className="row  justify-content-md-between login-form justify-content-center mw-100 m-auto">
        <div className="col-11 col-md-4 col-lg-3  mt-5">
          <div className=" d-flex justify-content-md-start justify-content-center  ml-md-3 ml-0">
            <img src={voyagerLogo} className="login-voyager-logo" ></img>
          </div>
          <div className="text-md-left text-center text-uppercase login-account-heading1">
            <p>Link Your <br></br>Voyager Account <br></br> With Alexa</p>
          </div>
        </div>
        <div className="col-11 col-md-4 col-lg-3 login-account-field-box mr-md-5 mr-0 mt-5 ">
          <form id="loginForm" className="row justify-content-center text-white p-3" onSubmit={handleSubmit} autoComplete="off">


                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput " className="login-input-labels">Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      name="email"
                      className="login-form-control"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput " className="login-input-labels">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="login-form-control"
                      id="password"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-8 mt-3">

                    <button
                      type="submit"
                      className="login-btn btn-primary "
                    >
                      Login
                    </button>

                </div>


                <div className="col-md-8 mt-3">
                <div className="text-center text-white  login-forget-password">
                <Link to={"/auth/resendVerificationEmail"}>
                  <p>Resend verification Email</p>
                  </Link>
                </div>
                <div className="text-center text-white mt-2 mb-3  login-forget-password">
                <Link to={"/auth/forgotPassword"}>
                  <p>Forgot your password?</p>
                  </Link>
                </div>
              </div>

          </form>
        </div>

      </div>
      }
      </div>

    </section>

  );
};

export default AlexaIntegrationComp;
