import React,{useState} from 'react';
import { Table } from 'antd';
import Tooltip from '../../../../utils/Tooltip';



const columns = [
  {
    title: 'Date',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Gained',
    dataIndex: 'gained',
    key: 'gained',
  },
  {
    title: 'Lost',
    dataIndex: 'lost',
    key: 'lost',
  },
];

const WsReading = ({dataSource}) => {
  const [search,setSearch]=useState("")

  let arr=dataSource&& dataSource.length > 0 && dataSource
          .filter((value)=>value.time.includes(search))


  return (
    <div className="my-2 md:mr-2 border-radius-chart w-full bg-white justify-evenly items-center bp-readings shadow-lg pl-4 pr-4 pb-4 flex flex-col">
      <p className="chart-heading font-weight-bold text-xl w-full text-left pt-2  mb-3">Weight Scale Readings
      <span className='ml-2'><Tooltip text="A log book of your results starting with a most recent."/></span>
      </p>
      <div className="w-full mx-auto overflow-x-auto px-2">
      <input type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control  d-block d-lg-none  mt-2' ></input>
        <div className="position-relative">
        <input style={{width:"30%",top:"2%"}} type="text" placeholder="Search by date" value={search} onChange={(e)=>{setSearch(e.target.value)}} className='form-control position-absolute d-none d-lg-block' ></input>
        <Table dataSource={arr} columns={columns} pagination={{width:"50%", defaultPageSize: 5,showQuickJumper:false,size:"small",showSizeChanger:false,position:["bottomRight"]}}/>
        </div>
      </div>
    </div>
   );
}

export default WsReading;
