import { API_BASE_URL } from "../../utils/urls";

import { SAVE_DOCTOR_TOKEN,FETCH_PATIENT_START, FETCH_PATIENT_FAILED, FETCH_PATIENT_SUCCESS,SAVE_PATIENT_DATA,SAVE_SEARCH_INPUT,FETCH_PATIENT_BG,FETCH_PATIENT_BP,SAVE_USER_ID} from "../actionTypes/doctor";
import http from "../../utils/http-service";
import {decrypt,encrypt} from "../../utils/encryption";
import { withRouter } from "react-router-dom";
import {logout} from "./auth";


export const getPatient = (data) => async(dispatch) => {

  dispatch({type: FETCH_PATIENT_START})
  try {
    //   data=JSON.stringify(data);

    dispatch({type:SAVE_SEARCH_INPUT, payload: data});

      data=encrypt(data);

    const res = await http.get(`${API_BASE_URL}/doctor/e_searchPatients?filter=${data}`);
    let patient=res.data.data
    patient=decrypt(patient);
    patient=JSON.parse(patient);



    dispatch({type: FETCH_PATIENT_SUCCESS, payload: patient});

    // const data = patient;

    // let patient = data.patient.filter((patient)=> patient.type === 1)

  } catch (error) {
    if(error.response.status === 422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    if (error.response.data.status === "failure" &&
     error.response.data.message === "Token Expires or invalid token")
     {

       dispatch();
     }
    dispatch({type: FETCH_PATIENT_FAILED})

  }
}

export  const savePatientData=(data)=>(dispatch)=>{

 dispatch({type: SAVE_PATIENT_DATA, payload: data})

}

export  const saveSearchInput=(data)=>(dispatch)=>{

 dispatch({type: SAVE_SEARCH_INPUT, payload: data})

}



export const fetchDoctorBgData = (data) => async(dispatch) => {

try {

   let type=encrypt(data.type)
   let id=encrypt(data.id)
   let pId=encrypt(data.pId)
   let time=encrypt(data.time)
   let start=encrypt(data.start)
   let end=encrypt(data.end)
    let res;

    if(data.time!==""){
      res = await http.get(`${API_BASE_URL}/doctor/e_getPatientsData/${type}?id=${id}&pId=${pId}&time=${time}`);
    }
    else{
      res = await http.get(`${API_BASE_URL}/doctor/e_getPatientsData/${type}?id=${id}&pId=${pId}&start_date=${start}&end_date=${end}`);
    }

let gift = res.data.data;

  gift=decrypt(gift)
  gift=JSON.parse(gift)


  if(data.type==="1"){
    dispatch({ type: FETCH_PATIENT_BG, payLoad: gift });
  }
  else if (data.type==="2"){
    dispatch({ type: FETCH_PATIENT_BP, payLoad: gift });
  }
  // dispatch({ type: FETCH_PATIENT_BG, payLoad: gift });

  return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
  // dispatch({
  //   type: FETCH_BG_FAILED,
  // });
}
};


export const saveShareToken = (data) => (dispatch) => {
  //(data);
  dispatch({type:SAVE_DOCTOR_TOKEN,payload:data})

}

export const saveUserId = (data) => (dispatch) => {
  //(data);

  dispatch({type:SAVE_USER_ID,payload:data})

}
