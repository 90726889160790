import { API_BASE_URL } from "../../../utils/urls";
import {SEND_INVITE_SUCCESS,SEND_INVITE_START,SEND_INVITE_FAILED} from "../../actionTypes/external-doctor/shareInvite";
import http from "../../../utils/http-service";
import {logout} from "../auth";

import ShowToast from "../../../utils/show-toast";

export const shareInvite = (data) => async(dispatch) => {
    const { email,phone } = data;

try {
    let res;
    res = await http.post(`${API_BASE_URL}/doctor/sendDataShareInvite`,{
     "email":email,"phone":phone
    });
    let gift = res.data.data;
    ShowToast(res.data.message,"success");
    dispatch({ type: SEND_INVITE_SUCCESS, payLoad: gift });
    return res;
} catch (error) {
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
    ShowToast(error.response.data.message,"error")
    return error;
}
};
