import * as actions from "../actionTypes/export";

const INITIAL_STATE = {
 
  isLoading: false,
  exportData:[]

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_EXPORT_START:
      return { ...state, isLoading: true };
    case actions.FETCH_EXPORT_FAILED:
      return { ...state, isLoading: false };
    case actions.SAVE_EXPORT_SUCCESS:
        //("In reducer",action)
      return {
        ...state,
        isLoading: false,
        exportData: action.payLoad,
      };

    default:
      return{
        ...state
      }
  }
};
