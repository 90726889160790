import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {sharedUsers } from "../../../redux/actions/Share/share";
import PermissionModal from "./PermissionModal";

const SharedWith = ({activeTab}) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.share.sharedUsersList);
  const [cancelBtn, setCancelBtn] = useState(false);
  const [doctor, setDoctor] = useState(false);
  const [family, setFamily] = useState(false);
  const [isModalVisible,setIsModalVisible]=useState(false)
  const [doctorAccordian, setDoctorAccordian] = useState(false);
  const [familyAccordian, setFamilyAccordian] = useState(false);
  const [loading,setLoading] =useState(false);
  const [modalType,setModalType]=useState("");
  const [rowId,setRowId]=useState("");
  const [render,setRender]=useState(true);
  const [obj,setObj]=useState(true);



  useEffect(() => {
    handleSharedUser();
    return ()=>{
      setCancelBtn(false)
    }
  }, [cancelBtn,render,activeTab]);
  const handleSharedUser = async () => {
    setLoading(true)
    const res = await dispatch(sharedUsers());
    setLoading(false)
  };


  const permissionCancelBtn=(show)=>{
    setCancelBtn(show)
  }
  const ModalVisibility=(show)=>{
    setIsModalVisible(show)

  }
  const btnClick=(data)=>{
    setRender(data)
   }

  return (
    <div>

      <div className="accordion bg-white " id="accordionExample">
        <div className="">
          <div className="" id="headingOne">
            <h2 className="mb-0">
              <button
                className={`py-3 ${doctorAccordian===true ?"accordian-btn-active":"accordian-btn"} d-flex justify-content-between  align-items-center`}
                onClick={() => {
                  setDoctor(!doctor);
                  setDoctorAccordian(!doctorAccordian)
                  setFamilyAccordian(false)
                }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <span>
                Doctors
                </span>
                {doctor ? (
                  <i className="fas fa-chevron-left dropdown-toggler"></i>
                ) : (
                  <i className="fas fa-chevron-right dropdown-toggler"></i>
                )}
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className="collapse "
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div className="card-body bg-white">
              {/* <div>Doctor Share Table</div> */}
              <div className="shareTable">
                <table className="">
                  <thead>
                    <tr>
                      <th>FirstName</th>
                      <th>LastName</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {list &&
                      list.length > 0 &&
                      list.map((row, index) => {

                        return (row.role==="teledoctor"?
                          <tr key={index}>
                            <td>{row.firstName}</td>
                            <td>{row.lastName}</td>
                            <td>{row.email}</td>
                            <td>
                            <Spin spinning={loading}>
                              <button
                                className="h5  text-danger w-25"
                                // data-toggle="tooltip"
                                // data-placement="bottom"
                                title="Remove User"
                                // onClick={()=>{handleRemove(row._id)}}
                                data-toggle="modal"
                                data-target="#permissionModal"
                                onClick={()=>{setIsModalVisible(true);setRowId(row._id);setModalType("delete")}}
                              >
                                <i className=" fas fa-trash-alt"></i>
                              </button>
                              {row.permissionStatus==="active"?

                              <button
                                className={"text-warning h5  w-25"}
                                // data-toggle="tooltip"
                                // data-placement="bottom"
                                title="Cancel permission"
                                // onClick={()=>{handleCancel(row._id)}}
                                data-toggle="modal"
                                data-target="#permissionModal"
                                onClick={()=>{setIsModalVisible(true);setRowId(row._id);setModalType("cancel")}}

                              >
                                <i className="fas fa-times"></i>
                              </button>

                             :null}

                              <button
                                className="text-success h5  w-25"
                                // data-toggle="tooltip"
                                // data-placement="bottom"
                                title="Approve permission"
                                // onClick={()=>{handleActive(row._id)}}
                                data-toggle="modal"
                                data-target="#permissionModal"
                                onClick={()=>{setIsModalVisible(true);setRowId(row._id);setModalType("accept")}}
                              >
                                <i className="fas fa-check"></i>
                              </button>
                              </Spin>
                            </td>
                          </tr>:null

                    );
                  })}
                  </tbody>

                </table>
                <PermissionModal ModalVisibility={ModalVisibility} isModalVisible={isModalVisible} type={modalType} id={rowId} permissionCancelBtn={permissionCancelBtn} btnClick={btnClick} render={render}/>
              </div>
              {/* <div>Doctor Share Table</div> */}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="" id="headingTwo">
            <h2 className="mb-0">
              <button
               className={`py-3 ${familyAccordian===true ?"accordian-btn-active":"accordian-btn"} d-flex justify-content-between  align-items-center`}
                onClick={() => {
                  setFamily(!family);
                  setDoctorAccordian(false)
                  setFamilyAccordian(!familyAccordian)
                }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              ><span>
                Family
                </span>
                {family ? (
                  <i className="fas fa-chevron-left dropdown-toggler"></i>
                ) : (
                  <i className="fas fa-chevron-right dropdown-toggler"></i>
                )}
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div className="card-body bg-white">
              {/* <div>Doctor Share Table</div> */}
              <div className="shareTable">
                <table className="">
                  <thead>
                    <tr>
                      <th>FirstName</th>
                      <th>LastName</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {list &&
                      list.length > 0 &&
                      list.map((row, index) => {

                        return (


                 row.role!=="teledoctor"?
                          <tr key={index}>

                            <td>{row.firstName}</td>
                            <td>{row.lastName}</td>
                            <td>{row.email}</td>
                            <td>
                              <button
                                className="h5  text-danger w-25"
                                // data-toggle="tooltip"
                                // data-placement="bottom"
                                title="Remove User"
                                onClick={()=>{
                                  setIsModalVisible(true);
                                  setRowId(row._id);
                                  setModalType("deleteUser");
                                  setObj(row);
                                }}
                              >
                                <i className=" fas fa-trash-alt"></i>
                              </button>
                              {row.permissionStatus && row.permissionStatus === "active"?

                              <button
                                className=" h5  w-25"
                                // data-toggle="tooltip"
                                // data-placement="bottom"
                                title="Cancel permission"
                                onClick={()=>{
                                  setIsModalVisible(true);
                                  setRowId(row._id);
                                  setModalType("inactivePermission");
                                  setObj(row);
                                }}


                              >
                                <i className="fas fa-times"></i>
                              </button>
                              :null}
                              {row.permissionStatus && row.permissionStatus!=="active"?
                              <button
                                className="text-success h5  w-25"
                                // data-toggle="tooltip"
                                // data-placement="bottom"
                                title="Approve permission"
                                onClick={()=>{
                                  setIsModalVisible(true);
                                  setRowId(row._id);
                                  setModalType("activePermission");
                                  setObj(row);
                                }}
                              >
                                <i className="fas fa-check"></i>
                              </button>
                              :null}
                            </td>
                          </tr>:null


                    );
                  })}
                   </tbody>
                </table>
                <PermissionModal ModalVisibility={ModalVisibility} object={obj} isModalVisible={isModalVisible} type={modalType} id={rowId} permissionCancelBtn={permissionCancelBtn} btnClick={btnClick} render={render}/>
              </div>
              {/* <div>Doctor Share Table</div> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedWith;
