const createRequestObjectFromForm = (values) => {
  return {
    height: {
      feet: values.feet,
      inch: values.inch,
    },
    weight: {
      lbs: values.lbs,
      oz: values.oz,
    },
    pulse: {
      beats: values.beats,
    },
    temperature: {
      value: values.temperatureValue,
    },
    bloodPressure: {
      upper: values.upper,
      lower: values.lower,
    },
    glucose: {
      value: values.glucoseValue,
    },
    spo2: {
      value: values.spO2value,
    },
    activity: {
      value: values.activityValue,
    },
    bloodGroup: values.bloodGroup,
  };
};

const createFormObjectFromRequest = (values) => ({
  feet: values.height ? values.height.feet : undefined,
  inch: values.height ? values.height.inch : undefined,
  lbs: values.weight ? values.weight.lbs : undefined,
  oz: values.weight ? values.weight.oz : undefined,
  beats: values.pulse ? values.pulse.beats : undefined,
  temperatureValue: values.temperature ? values.temperature.value : undefined,
  upper: values.bloodPressure ? values.bloodPressure.upper : undefined,
  lower: values.bloodPressure ? values.bloodPressure.lower : undefined,
  glucoseValue: values.glucose ? values.glucose.value : undefined,
  spO2value: values.spo2 ? values.spo2.value : undefined,
  activityValue: values.activity ? values.activity.value : undefined,
  bloodGroup: values.bloodGroup,
  dates: {
    heightUpdateTime: values.height
      ? new Date(values.height.time).toUTCString("en-GB")
      : undefined,
    weightUpdateTime: values.weight
      ? new Date(values.weight.time).toUTCString("en-GB")
      : undefined,
    temperatureUpdateTime: values.temperature
      ? new Date(values.temperature.time).toUTCString("en-GB")
      : undefined,
    bloodPressureUpdateTime: values.bloodPressure
      ? new Date(values.bloodPressure.time).toUTCString("en-GB")
      : undefined,
    glucoseUpdateTime: values.glucose
      ? new Date(values.glucose.time).toUTCString("en-GB")
      : undefined,
    activityUpdateTime: values.activity
      ? new Date(values.activity.time).toUTCString("en-GB")
      : undefined,
    spO2UpdateTime: values.spo2
      ? new Date(values.spo2.time).toUTCString("en-GB")
      : undefined,
    pulseUpdateTime: values.pulse
      ? new Date(values.pulse.time).toUTCString("en-GB")
      : undefined,
  },
});

module.exports = {
  createFormObjectFromRequest,
  createRequestObjectFromForm,
};
