import * as actions from "../actionTypes/emergency";

const initialState = {
  allergies: { isLoading: false, data: null },
  medication:{isLoading: false, data: null},
  implantedDevices:{isLoading: false, data: null},
  chronicCondition:{isLoading: false, data: null},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ALLERGIES_SUCCESS:
      return {
        ...state,
        allergies: {
          data: action.payload.allergies,
          isLoading: false,
        },
      };
    case actions.FETCH_ALLERGIES_START:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          isLoading: true,
        },
      };
  case actions.FETCH_ALLERGIES_FAILED:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          isLoading: false,
        },
      };
  case actions.SAVE_ALLERGIES_START:
    return {
      ...state,
      allergies: {
        ...state.allergies,
        isLoading: true,
      },
    };
  case actions.SAVE_ALLERGIES_FAILED:
    return {
      ...state,
      allergies: {
        ...state.allergies,
        isLoading: false,
      },
    };
  case actions.SAVE_ALLERGIES_SUCCESS:
    return {
      ...state,
      allergies: {
        data: action.payload.allergies,
        isLoading: false,
      },
    };
  case actions.FETCH_MEDICATION_SUCCESS:
    return {
      ...state,
      medication: {
        data: action.payload.medication,
        isLoading: false,
      },
    };
  case actions.FETCH_MEDICATION_START:
    return {
      ...state,
      medication: {
        ...state.medication,
        isLoading: true,
      },
    };
  case actions.FETCH_MEDICATION_FAILED:
      return {
        ...state,
        medication: {
          ...state.medication,
          isLoading: false,
        },
      };
  case actions.SAVE_MEDICATION_START:
    return {
      ...state,
      medication: {
        ...state.medication,
        isLoading: true,
      },
    };
  case actions.SAVE_MEDICATION_FAILED:
    return {
      ...state,
      medication: {
        ...state.medication,
        isLoading: false,
      },
    };
  case actions.SAVE_MEDICATION_SUCCESS:
    return {
      ...state,
      medication: {
        data: action.payload.medication,
        isLoading: false,
      },
    };
  case actions.FETCH_IMPLANTED_DEVICES_SUCCESS:
    return {
      ...state,
      implantedDevices: {
        data: action.payload.implantedDevices,
        isLoading: false,
      },
    };
  case actions.FETCH_IMPLANTED_DEVICES_START:
    return {
      ...state,
      implantedDevices: {
        ...state.implantedDevices,
        isLoading: true,
      },
    };
  case actions.FETCH_IMPLANTED_DEVICES_FAILED:
      return {
        ...state,
        implantedDevices: {
          ...state.implantedDevices,
          isLoading: false,
        },
      };
  case actions.SAVE_IMPLANTED_DEVICES_START:
    return {
      ...state,
      implantedDevices: {
        ...state.implantedDevices,
        isLoading: true,
      },
    };
  case actions.SAVE_IMPLANTED_DEVICES_FAILED:
    return {
      ...state,
      implantedDevices: {
        ...state.implantedDevices,
        isLoading: false,
      },
    };
  case actions.SAVE_IMPLANTED_DEVICES_SUCCESS:
    return {
      ...state,
      implantedDevices: {
        data: action.payload.implantedDevices,
        isLoading: false,
      },
    };
   case actions.FETCH_CHRONIC_CONDITION_SUCCESS:
    return {
      ...state,
      chronicCondition: {
        data: action.payload.chronicCondition,
        isLoading: false,
      },
    };
  case actions.FETCH_CHRONIC_CONDITION_START:
    return {
      ...state,
      chronicCondition: {
        ...state.chronicCondition,
        isLoading: true,
      },
    };
  case actions.FETCH_CHRONIC_CONDITION_FAILED:
      return {
        ...state,
        chronicCondition: {
          ...state.chronicCondition,
          isLoading: false,
        },
      };
  case actions.SAVE_CHRONIC_CONDITION_START:
    return {
      ...state,
      chronicCondition: {
        ...state.chronicCondition,
        isLoading: true,
      },
    };
  case actions.SAVE_CHRONIC_CONDITION_FAILED:
    return {
      ...state,
      chronicCondition: {
        ...state.chronicCondition,
        isLoading: false,
      },
    };
  case actions.SAVE_CHRONIC_CONDITION_SUCCESS:
    return {
      ...state,
      chronicCondition: {
        data: action.payload.chronicCondition,
        isLoading: false,
      },
    };
    default:
      return state;
    }
}