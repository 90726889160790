import React, { useState } from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  AlertOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
  PieChartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { EPharmSVG } from "../../../assets";
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

import { useLocation } from 'react-router-dom';

const { SubMenu } = Menu;

export default function ClinicianNav({ collapsed }) {
  const [appointment,setAppointment]=useState(false)
  const [menu,setMenu]=useState(false)
  const location = useLocation();
   ////("Location",location.pathname);
   

  return (
    <div className="clinical-nav w-100">
      <Menu mode="inline"  className="w-100">
        <Menu.Item key="1" icon={<i className={location.pathname==="/dashboard"||location.pathname==="/doctor/patient/record"?"fas fa-house-user w-4 h-4 inline mr-2 text-white":"fas fa-house-user w-4 h-4 inline mr-2 fill-current"}></i>} title="Dashboard">
          <NavLink className={location.pathname==="/dashboard"||location.pathname==="/doctor/patient/record"?"text-white ml-1" : "text-primary ml-1"} to="/dashboard">
          <span className={!collapsed ? "" : "text-blue-100 "}>
              Dashboard
            </span>
          </NavLink>
        </Menu.Item>
    
        <SubMenu
          key="appointments"
          icon={<i className={location.pathname==="/doctor/appointment/schedule"||location.pathname==="/doctor/appointment/add"?"fas fa-calendar-check w-4 h-4 inline mr-2 text-white":"fas fa-calendar-check w-4 h-4 inline mr-2 text-primary"}></i>}
          title={"Appointments"}
          className={location.pathname==="/doctor/appointment/schedule"||location.pathname==="/doctor/appointment/add"?"text-white":"text-primary"}
         
         
         
         
          // className={location.pathname==="/doctor/appointment/schedule"?"text-white" : "text-primary"}
        >
       
      
        
          <Menu.Item key="2">
            <NavLink to="/doctor/appointment/add">
            <span className={location.pathname==="/doctor/appointment/add"?"flex items-center text-white":"flex items-center"}>
              <PlusCircleOutlined />
              Add Appointment
            </span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <NavLink to="/doctor/appointment/schedule">
            <span className={location.pathname==="/doctor/appointment/schedule"?"flex items-center text-white":"flex items-center"}>
              <ScheduleOutlined />
              Schedule
            </span>
            </NavLink>
          </Menu.Item>
         
        </SubMenu>
        <Menu.Item key="4" icon={<i className={location.pathname==="/doctor/patient"||location.pathname==="/doctor/patient/record"||location.pathname==="/doctor/patient/history"?"fas fa-hospital-user w-4 h-4 inline mr-2 text-white":"fas fa-hospital-user w-4 h-4 inline mr-2 fill-current"}></i>}>
          <NavLink className={location.pathname==="/doctor/patient"||location.pathname==="/doctor/patient/record"||location.pathname==="/doctor/patient/record"||location.pathname==="/doctor/patient/history"?"text-white ml-1" : "text-primary ml-1"} to="/doctor/patient">
          <span className={!collapsed ? "" : "text-blue-100"}>
            Patients
            </span>
          </NavLink>
        </Menu.Item>

        <Menu.Item
          key="5"
          icon={<i className="fas fa-file-alt w-4 h-4 inline mr-2 fill-current"></i> }
        >
          <NavLink className="ml-1" to="/doctor/patient">
            <span className={!collapsed ? "" : "text-blue-100"}>
            Reports
            </span>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<EPharmSVG className="w-4 h-4 inline mr-2 fill-current" />}
        >
          <NavLink className="ml-1" to="/doctor/patient">
            <span className={!collapsed ? "" : "text-blue-100"}>
              E-pharmacy
            </span>
          </NavLink>
        </Menu.Item>
      </Menu> 
      
    </div>

  );
}
