import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import { Menu, Dropdown, Button, message, Tooltip, Spin, Empty } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { DatePicker } from "antd";
import { fetchVitals } from "../../redux/actions/patient-dashboard";

import * as _ from "lodash";

import Chart from "./micro-apps/BloodGlucose/Chart";
import HistoricalReadingsChart from "./historical-readingsBg";

const { RangePicker } = DatePicker;

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);
export const Vitals = (props) => {
  const dispatch = useDispatch();
  const vital = useSelector((state) => state.dashboard.vitalsData.historicalReadings);
  const [dataRange, setDataRange] = useState();
  const [dataFilter, setDataFilter] = useState(1);
  const [showDate, setShowDate] = useState(false);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [resData, setResData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("30days");
  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [legendNames, setLegendNames] = useState(["Reading1", "Reading2", "Reading3"]);
  const [dropMenu, setDropMenu] = useState(false);
  const [rangeBtn, setRangeBtn] = useState(false);
  const [rangeDate, setRangeDate] = useState("");
  const [dateRange,setDateRange]=useState(false);

  useEffect(() => {
    if (filter !== "" || startDate !== "") {
      getVitals();
    }
    return () => {                                                               // second, we return an anonymous clean up function
      setDateRange(false)
    };
  }, [filter, dateRange, dataFilter]);
  useEffect(() => {
    transformHistoricalReadings();
    if(dataFilter===2){
      transformBpHistoricalReadings()
    }
    else if (dataFilter===4){
      transformHistoricalPOReadings()
    }
    else if(dataFilter===5){
      transformHistoricalWeightReadings()
    }
    else if(dataFilter===3){
      transformHistoricalTempReadings()
    }

  }, [vital && vital]);

  useEffect(() => {
    // if(resData && resData.historicalReadings){
    //   tfData();
    // }
  }, [resData]);

  const getVitals = async () => {
    setIsLoading(true);
    let data = {};
    if (dataFilter) {
      data.type = dataFilter;
    }
    if (filter) {
      data.time = filter;
    }
    if (startDate) {
      data.start_date = endDate;
      data.end_date = startDate;
    }
    const res = await dispatch(fetchVitals(data));

    setIsLoading(false);
  };

  //Bg
  const transformHistoricalReadings = () => {
    let transformedData = [];
    //("vital && vital.historical=",vital && vital.historical)
    transformedData = vital && vital

        .map((reading) => {

          return reading.data.map((item) => {
            if(new Date(startDate).getMonth()<=item.month && new Date(endDate).getMonth()<=item.month){
              let date = new Date();

              date.setMonth(item.month-1);
              date.setDate(reading._id);
              date.setHours(item.hour);
              date.setMinutes(item.minutes);
              date.setYear(item.year);

                return {
                  date: date,
                  reading: item.value["bloodGlucose"],
                };

            }
            else {
              let date = new Date();

              date.setMonth(item.month-1);
              date.setDate(reading._id);
              date.setHours(item.hour);
              date.setMinutes(item.minutes);
              return {
                date: date,
                reading: item.value["bloodGlucose"],
              };
            }


          });
        })
        .flat();

    let myArray = _.sortBy(transformedData && transformedData.flat(), function(dateObj) {

      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray);
  };

  //End Bg
  //Bp
  const transformBpHistoricalReadings = () => {
    ////("This is Called");
    let transformedData = [];
    transformedData =vital && vital
      .map((reading) => {
        ////("ITEM :",reading);

        return reading.data.map((item) => {
          let date = new Date();
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
           return {
              date: date,

              reading: item.value["systolic"],
              reading2: item.value["diastolic"],
              reading3: item.value["heartRate"],
            };

        });
      })
      .flat();
    let myArray = _.sortBy(transformedData && transformedData.flat(), function (dateObj) {
      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray);
  };
  //End Bp
  //start po

  const transformHistoricalPOReadings = () => {
    //////("This is Called");
    let transformedData = [];
    transformedData = vital && vital
      .map((reading) => {
        //////("ITEM :",reading);

        return reading.data.map((item) => {
          let date = new Date();
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
          return {
            date: date,
            reading: item.value["oxygen"],
            reading2: item.value["heartRate"],
          };
        });
      })
      .flat();
    let myArray = _.sortBy( transformedData && transformedData.flat(), function (dateObj) {
      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray);
  };
  //End po

  //Weightscale

  const transformHistoricalWeightReadings = () => {
    //("This is Called");
    let transformedData = [];
    transformedData = vital && vital
      .map((reading) => {
        //("ITEM :",reading);

        return reading.data.map((item) => {
          //(item);
          let date = new Date();
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
          return {
            date: date,
            reading: item.value["weight"],
            // reading2: item.value["heartRate"],
            // reading3: item.value["heartRate"],
          };
        });
      })
      .flat();
    let myArray = _.sortBy(transformedData && transformedData.flat(), function (dateObj) {
      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray)
  };

  //End Weight Scale

  //Temp
  const transformHistoricalTempReadings = () => {
    ////("This is Called");
    let transformedData = [];
    transformedData = vital && vital
      .map((reading) => {
        ////("ITEM :",reading);

        return reading.data.map((item) => {
          ////(item);
          let date = new Date();
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
          return {
            date: date,
            reading: item.value["temperature"],

          };
        });
      })
      .flat();
    let myArray = _.sortBy(transformedData && transformedData.flat(), function (dateObj) {
      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray)
  };
  //End Temp


  const onStartDateChange=(e)=>{
    const val=e.target.value
    setStartDate(moment(val).format("YYYY-MM-DD").toString());
    setRangeDate(moment(val).format("YYYY-MM-DD").toString())
}
const onEndDateChange=(e)=>{
    const val=e.target.value
  if(val){
    setEndDate(moment(val).format("YYYY-MM-DD").toString());
    setEndDate(moment(val).format("YYYY-MM-DD").toString());

  }
}
const onClickBtnOk=()=>{

  if(startDate!==""){
    setDateRange(true)
    setDropMenu(false)
  }
  else{

  }
}


  return (
    <div className="mr-2 w-100 bg-white p-3 d-flex justify-content-between align-item-center flex-column">
      <div className="d-flex mb-3 w-100 flex-start flex-column flex-md-row justify-content-between px-2 ">
        <p className="font-weight-bold text-voyger-purple text-left chart-heading">Vitals</p>
      </div>
      <Spin className="w-100" spinning={isLoading}>
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3 w-md-50 w-100">

          <div className='d-flex flex column justify-content-center justify-content-md-center mb-2 mb-md-0 w-100 w-md-50'>
          <div className="d-flex flex-row justify-content-lg-start justify-content-center mb-lg-0 mb-2 w-100 w-md-100">
            <button
              className={`   mr-1 ${
                filter === "today" ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setStartDate("");
                setDropMenu(false);
                setDateRange(false)
                setEndDate("");
                setShowDate(false);
                setFilter("today");
              }}
            >
              Today
            </button>
            <button
              className={`   mr-1 ${
                filter === "7days" ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setStartDate("");
                setDropMenu(false)
                setDateRange(false);
                setEndDate("");
                setShowDate(false);
                setFilter("7days");
              }}
            >
              Week
            </button>
            <button
              className={`   mr-1 ${
                filter === "30days" ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setStartDate("");
                setDropMenu(false)
                setDateRange(false)
                setEndDate("");
                setShowDate(false);
                setFilter("30days");
              }}
            >
              Month
            </button>
            <button
              className={`   mr-1 ${
                startDate ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setFilter("");
                setShowDate(true);
                setDropMenu(true)
              }}
            >
              Range
            </button>

          </div>
          </div>

          <div className="d-flex flex-row justify-content-lg-start justify-content-center mb-lg-0 mb-2 w-100 w-md-50">

            <button
              className={` mr-1 ${
                dataFilter === 5 ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setDataFilter(5);
              }}
            >
              Weight
            </button>

            <button
              className={` mr-1 ${
                dataFilter === 2 ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setDataFilter(2);
              }}
            >
              BP
            </button>
            <button
              className={` mr-1 ${
                dataFilter === 1? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setDataFilter(1);
              }}
            >
              BG
            </button>
            <button
              className={` mr-1 ${
                dataFilter === 3 ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setDataFilter(3);
              }}
            >
              Temp
            </button>
            <button
              className={` mr-1 ${
                dataFilter === 4 ? "dashboard-vital-filter-active  py-2 px-1" : "py-2 px-1  dashboard-vital-filter"
              }`}
              onClick={() => {
                setDataFilter(4);
              }}
            >
              Pulse
            </button>

          </div>

        </div>
        <div className="d-flex text-center mt-3 justify-content-center">

                  <div className={dropMenu ? " d-block mb-3" : " d-none"}>
              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                   <div className='d-flex flex-row mb-md-0 mb-2'>
                   <input type="date"
                  onChange={onStartDateChange}
                  ></input>
                  <input type="date"
                  disabled={rangeDate===""?true:false}
                  className="ml-1"
                  onChange={onEndDateChange}
                  min={moment(startDate).format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  ></input>
                     </div>
                  <button className="btn bg-light" onClick={onClickBtnOk}>Go</button>
              </div>
              </div>
            </div>

        {vital && vital.length > 0 ? (
          <div
            style={{
              height:"50vh"
            }}
            className="d-flex w-100"
            id={"VitalsChartDiv"}
          >

           <HistoricalReadingsChart
           color1="#667eea"
           color2="#7f9cf5"
           color3="#4c51bf"
           name = {dataFilter===2?"Systolic":dataFilter===4?"Oxygen":dataFilter===5?"Weight":dataFilter===3?"Temperature":null}
           name2 = {dataFilter===2?"Diastolic":dataFilter===4?"HeartRate":null}
           name3 = "Heart Rate"
           id={`vitalHistoricChart${dataFilter}`}
           dataPoints={tfHistoricalReadings}
           renderOn={"vitals"}
         />
        {/* }  */}

          </div>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
              marginTop: "60px",
            }}
            description={
              <span>
                No Data Found for <span className="font-weight-bold">{filter==="30days"?"30 days filter":filter==="7days"?"7 days filter":filter==="today"?"Today filter":"Selected Range"}</span> .

              </span>
            }
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "20.75rem",
              maxHeight: "20.75rem",
              alignItems: "center",
            }}
          ></Empty>
        )}
      </Spin>
    </div>
  );
};
