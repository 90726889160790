import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import  {connect} from "react-redux"
import $ from "jquery";
import {fetchAllergies, saveAllergy,updateAllergy, deleteAllergy} from "../../../redux/actions/emergency";
import ShowToast from "../../../utils/show-toast";
import moment from "moment";
import updateDate from "../../../utils/updateDate";



class Allergy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id:"",
      name:"",
      description:"",
      reaction:"",
      duration:"",
      editMode: false,
      allergies:[],
      allergiesData:[],
      deleteModal:false,
      toDelete:"",
      allergyModal:false
    };

  }
  componentDidMount() {

    let allergy = [{
      name:"",
      description:"",
      reaction:"",
      duration:"",
    }]
    this.setState({allergies:allergy});
    if (this.props.allergies && this.props.allergies.data === null ){
      this.props.dispatch (fetchAllergies())
    }
  }
  static getDerivedStateFromProps(props, state) {

    if(props.allergies && props.allergies.data!==null){

      let allergies = props.allergies.data;

      state.allergiesData = allergies;
    }
  }

  handleChange=(event , i)=> {

    this.setState({[event.target.name]: event.target.value})
    let target = event.target.name;
    let allergies = this.state.allergies;
    if(target === "name"){

      allergies[i].name = event.target.value;
      this.setState({allergies: allergies});

    } else if (target === "description"){
      allergies[i].description = event.target.value;
      this.setState({allergies: allergies});
    } else if (target === "reaction"){
      allergies[i].reaction = event.target.value;
      this.setState({allergies: allergies});
    } else if (target === "duration"){
      allergies[i].duration = new Date(event.target.value);
      this.setState({allergies: allergies});
    }

  }
  addValue=(event) =>{
    event.preventDefault();
    let allergies = this.state.allergies;

    let obj = {
      name:"", description:"", reaction:"", duration:""
    }
    allergies.push(obj);
    this.setState({allergies:allergies});
  }
  removeIndex=(index)=>{

    let newData = this.state.allergies;


    newData.splice(index, 1);


    this.setState({allergies: newData});

  }
  submitAllergy = async (e) => {
    e.preventDefault();

    let data = this.state.allergies;
    await this.props.dispatch(saveAllergy(data));
    ShowToast("Success!", "success");

    this.setState( {
      _id:"",
      name:"",
      description:"",
      reaction:"",
      duration:"",
      editMode: false,
      allergies:[],
      allergiesData:[],
      deleteModal:false,
      toDelete:"",
      allergyModal:false,
    });
    $('form').trigger("reset");
    await this.props.dispatch(fetchAllergies());
  }
  updateAllergyData = async (e) => {
    e.preventDefault();

    await this.props.dispatch(updateAllergy({
      name: this.state.name,
      description: this.state.description,
      reaction: this.state.reaction,
      duration: new Date(this.state.duration),
    }, this.state._id));
    ShowToast("Success!", "success");
    $('form').trigger("reset");
    this.setState( {
      _id:"",
      name:"",
      description:"",
      reaction:"",
      duration:"",
      editMode: false,
      allergies:[],
      allergiesData:[],
      deleteModal:false,
      toDelete:"",
      allergyModal:false,
    });
    await this.props.dispatch(fetchAllergies());
  }

  startUpdateAllergyData = (event, id, tag) => {

    let inst;
    for (let index = 0; index < this.state.allergiesData.length; index++) {
       if(id===this.state.allergiesData[index]._id){
         inst=this.state.allergiesData[index]

       }

    }
    this.setState({name:inst.name,description:inst.description,reaction:inst.reaction,duration:inst.duration})
    let aller=[{name:inst.name,description:inst.description,reaction:inst.reaction,duration:inst.duration}];

    let allergyObj = this.state.allergiesData.filter(obj=> obj._id === id);

    this.setState({allergies: aller,
      _id:id

    });

    updateDate(allergyObj[0].duration, "duration", false)
  }
  deleteAllergyRecord = async(_id) => {
    await this.props.dispatch(deleteAllergy(_id))
    ShowToast("Success!", "success");
    this.setState({deleteModal:false});
    await this.props.dispatch(fetchAllergies());
  }
  addNewAllergy=()=>{

    let allergies =[{
     name:"", description:"", reaction:"", duration:"",
   }]
   this.setState({allergies:allergies,editMode:false,allergyModal:true});
   updateDate("","duration",false)
  }
  resetData=()=>{
    if(this.state.allergyModal){
      this.setState({allergyModal:false})
    }

    this.props.dispatch(fetchAllergies());

   }
   deleteModal=(id)=>{
    this.setState({deleteModal:true,toDelete:id});
  }
  closeDeleteModal=()=>{
    this.setState({deleteModal:false});
  }
  render() {
    const { allergies, allergiesData } = this.state;
    return (
      <>
        <div className="text-right">
          <button
            type="button"
            className="add-info-btn"
            data-toggle="modal"
            data-target="#allegiesModal"
            onClick={this.addNewAllergy}
          >
            <span className="d-flex justify-content-center align-items-center">
              <i className="fas fa-plus mr-2"></i>
              <span>Add Allergy</span>
            </span>
          </button>
        </div>

        <div
        className={`modal big_modal_dialog ${
          this.state.allergyModal? "d-block modal-fading" : " d-none"
        }`}
        tabIndex="-1"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-hidden="true"

        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="allegiesModalTitle">
                  {this.state.editMode?"Update Allergy":
                  "Add Allergy"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.resetData}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.state.editMode === true ?this.updateAllergyData: this.submitAllergy}>
              <div className="modal-body">
                <div className="container-fluid">
                  {allergies && allergies.length > 0 && allergies.map((section, index) => (
                    <>
                      <div className="container-fluid">
                      <div className={index > 0 ? "d-block mb-3 text-right" : "d-none"}>
                          <div className='row justify-content-end'>
                            <div className="col-md-3">
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={(section) => {
                                this.removeIndex(section);
                              }}
                            >
                              Remove
                            </button>
                            </div>
                          </div>

                        </div>

                  <div className="row   justify-content-center modal-grid-row-12">
                        <div className="col-md-12 col-10">
                          {" "}
                          <div className='d-block'>Name</div>
                          <div className="form-group">
                            <div className="styled-select">
                              <select
                                name="name"
                                id="name"
                                value={section.name}
                                onChange={(event)=>this.handleChange(event,index)}
                                placeholder="Select a Blood Group"
                                className="form-control input-md msg"
                                required
                              >
                                <option value="">Select</option>
                                <option value="Medication">Medication</option>
                                <option value="Latex">Latex</option>
                                <option value="Nuts">Nuts</option>
                                <option value="Iodine">Iodine</option>
                                <option value="Food">Food</option>
                                <option value="Other">Other</option>
                              </select>
                              <i className="fa fa-chevron-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                  <div className="row">
                    <div className="col-md-6">

                      <div className="row justify-content-center">
                        <div className="col-md-11 col-10 ">
                        <div className='d-block'>Description</div>
                          <div className="form-group">
                            <div className="">
                              <input
                                name="description"
                                placeholder="Description"
                                value={section.description}
                                onChange={(event)=>this.handleChange(event,index)}
                                className="form-control input-md msg"
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">

                      <div className="row justify-content-center">
                          <div className="col-md-11 col-10 ">
                              {" "}
                              <div className='d-block'>Reaction</div>
                              <div className="form-group">
                                <div className="styled-select">
                                  <select
                                    name="reaction"
                                    id="reaction"
                                    value={section.reaction}
                                onChange={(event)=>this.handleChange(event,index)}
                                    placeholder="Select a Blood Group"
                                    className="form-control input-md msg"
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="Anaphylaxis">
                                      Anaphylaxis
                                    </option>
                                    <option value="Cardiac Arrest">
                                      Cardiac Arrest
                                    </option>
                                    <option value="Dizziness">Dizziness</option>
                                    <option value="Fever">Fever</option>
                                    <option value="Generalized Rash">
                                      Generalized Rash
                                    </option>
                                    <option value="Heart Rate Decreased">
                                      Heart Rate Decreased
                                    </option>
                                    <option value="Heart Rate Increased">
                                      Heart Rate Increased
                                    </option>
                                    <option value="Hives">Hives</option>
                                    <option value="Itching">Itching</option>
                                    <option value="Local Rash">
                                      Local Rash
                                    </option>
                                    <option value="Other">Other</option>
                                  </select>
                                  <i className="fa fa-chevron-down"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>


                      <div className="row justify-content-center modal-grid-row-12">
                        <div className="col-md-12 col-10 ">
                        <div className='d-block'>Duration</div>
                          <div className="form-group">
                            <div className="">
                              <input
                                name="duration"
                                id="duration"
                                onChange={(event)=>this.handleChange(event,index)}
                                // className="datepicker"
                                type="date"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
                    </>
                  ))}
                </div>
                {this.state.editMode === false?<div className="row justify-content-center">
                  <div className="col-md-8">
                  <button
                  className="add-btn"
                  type="button "
                  onClick={this.addValue}
                >
                    Add Fields
                  </button>
                  </div>
                </div>
                :null}
              </div>

                <div className="modal-footer">
                  <button
                      type="submit"
                      className="modal-save-btn"
                  >
                    {this.state.editMode === true ? "Update":"Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div className="container-fluid mt-3 ">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-success">
                <table className="table table-hover" id="task-table">
                  <thead>
                    <tr>
                    <th>Name</th>
                      <th>Description</th>
                      <th>Reaction</th>
                      <th>Duration</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>
                  { allergiesData && allergiesData.length> 0 && allergiesData.map((allergy, index)=>(
                    <tr>
                    {allergy.name && allergy.description && allergy.reaction ?
                      <>
                       <td>{allergy.name}</td>
                      <td>{allergy.description}</td>
                      <td>{allergy.reaction}</td>
                      <td>{moment(new Date(allergy.duration)).format(" DD-MM-YYYY")}</td>



                      <td>
                        <button
                            type="button"
                            className=""
                            data-toggle="modal"
                            data-target="#allegiesModal"
                            onClick={(event)=>{this.startUpdateAllergyData(event,`${allergy._id}`, `duration${index}`)
                              this.setState({editMode:true})
                              this.setState({allergyModal:true})
                              }}
                        >
                          <i className="fas fa-edit text-primary"></i>
                        </button>

                        <button
                            type="button"
                            className=" ml-4"
                            onClick={()=>this.deleteModal(`${allergy._id}`)}

                        >
                              <span className="">

                                <i className="fas fa-trash-alt text-danger"></i>
                              </span>
                        </button>
                      </td>
                      </>
                      : null}

                    </tr>


                  ))}


                  </tbody>
                </table>
                <div tabindex="-1" id="myModal-delete" className={`modal  ${
                                                                this.state.deleteModal? "d-block fading" : " d-none"
                                                            }`} >
                                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                                    <div className="modal-content">
                                                        <div className="modal-header flex-column">
                                                            <div className="icon-box">
                                                            <i className="fas fa-trash-alt text-danger"></i>
                                                            </div>
                                                            <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                                            <button type="button" className="close" onClick={this.closeDeleteModal} >&times;</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>Do you really want to delete these records? This process cannot be undone.</p>
                                                        </div>
                                                        <div className="modal-footer justify-content-center">
                                                            <button type="button" className="btn btn-secondary" onClick={this.closeDeleteModal}>Cancel</button>
                                                            <button type="button" className="btn btn-danger"    onClick={(event)=>{this.deleteAllergyRecord(this.state.toDelete)}}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps (state) {

  return {
    allergies: state.emergency.allergies,
  }
}

export default withRouter(connect(mapStateToProps)(Allergy)) ;
