import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	acceptInvitationDoctor,
	activePermission,
	cancelPermission,
	doctorInfo,
	removePermission,
	sharedUsers,
	acceptInvitationUser
} from "../../../redux/actions/Share/share";
import { decrypt, encrypt } from "../../../utils/encryption";
import ShowToast from "../../../utils/show-toast";

const PermissionModal = ({
							 type,
							 id,
							 permissionCancelBtn,
							 isModalVisible,
							 ModalVisibility,
							 btnClick,
							 render,
							 object,
						 }) => {
	const dispatch = useDispatch();
	const [loading,setLoading] =useState(false)
	const shareToken = useSelector((state) => state.doctor.shareToken);
	const user_id = useSelector((state) => state.user.userDetails && state.user.userDetails._id);
	// const [modalVisible,setModalVisible] =useState(isModalVisible)


	let userId =localStorage.getItem(encrypt("UserId"))
	userId=userId!==null && userId.length>0 ?decrypt(userId):null;
	let token =localStorage.getItem(encrypt("pendingInviteAcceptToken"));
	token=token!==null && token.length>0 ?decrypt(token):null;


	const handleBtnClick=async()=>{
		setLoading(true)
		if(type==="delete"){
			await dispatch(removePermission(id))
		}
		else if(type==="cancel"){
			await dispatch(cancelPermission(id))
		}
		else if(type==="acceptPendingInvite" ){


			if(user_id === userId){
				await dispatch(acceptInvitationDoctor({token}))
			}
			else if (object && object.role === "patient"){

				await dispatch(acceptInvitationUser(object._id))

			}
			else{
				ShowToast("Check your email to accept invitation.","error")
			}


		}else if(type==="deleteUser"){


		}else if(type==="activePermission"){



		}else if(type==="inactivePermission") {


		}
		else{
			await dispatch(activePermission(id))
		}
		permissionCancelBtn(true)
		ModalVisibility(false)
		setLoading(false)
		btnClick(!render)

	}
	return (
		<div>
			<div id="permissionModal" className={`modal big_modal_dialog ${
				isModalVisible ? "d-block modal-fading" : " d-none"
			}`}>
				<div className="modal-dialog modal-dialog-centered modal-confirm">
					<div className="modal-content">
						<div className="modal-header flex-column">

							{type==="delete" || type==="deleteUser" ?
								<div className="icon-box"> <i className="fas fa-2x fa-trash-alt"></i></div>
								:type==="cancel" || type==="inactivePermission"?
									<div className="border-warning icon-box"><i className="fas fa-2x fa-times text-warning"></i></div>:
									type==="accept"||type==="acceptPendingInvite" || type==="activePermission"?
										<div className="border-success icon-box"><i className="fas fa-2x fa-check text-success"></i></div>:null
							}


							<h4 className="modal-title w-100">Are you sure?</h4>
							<button type="button" onClick={()=>{ModalVisibility(false)}} className="close" id="btn-close" data-dismiss="modal" aria-hidden="true">&times;</button>
						</div>
						<div className="modal-body">
							<p>This will 	{type==="delete" || type==="deleteUser"?
								"delete"
								:type==="cancel" || type==="inactivePermission"?
									"cancel"
									:
									type==="accept"|| type==="acceptPendingInvite" || type==="activePermission"?
										"accept"
										:null
							} the permission . </p>
						</div>
						<div className="modal-footer justify-content-center">
							{/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button> */}
							<Spin spinning={loading}>
								<button type="button" className={`btn ${type==="delete" || type==="deleteUser"?'btn-danger':type==="cancel" || type==="inactivePermission" ?"btn-warning":type==="accept"||type==="acceptPendingInvite" || type==="activePermission"?"btn-success":null}`} onClick={()=>{handleBtnClick()}}>Ok</button>
							</Spin>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PermissionModal;
