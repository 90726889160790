import React, { useState } from "react";
import { DatePicker, Radio } from "antd";

const { RangePicker } = DatePicker;

export default function Filters({ filter, onChange }) {
  const [showDate, setShowDate] = useState(false);

  const handleChange = (e) => {
    if (e.target.value === "range") {
      setShowDate(!showDate);
      return;
    }
    setShowDate(false);
    onChange({ name: e.target.value });
  };

  const handleDateSelected = (val) => {
    onChange({
      name: "range",
      payload: {
        dates: val,
      },
    });
  };

  return (
    <div className="flex items-center flex-col">
      <Radio.Group onChange={handleChange}>
        <Radio.Button value="allTime">All Time</Radio.Button>
        <Radio.Button value="today">Today</Radio.Button>
        <Radio.Button value="7Days">7 Days</Radio.Button>
        <Radio.Button value="30Days">30 Days</Radio.Button>
        <Radio.Button value="range">Range</Radio.Button>
      </Radio.Group>
      {showDate && (
        <div className="flex w-full self-end mt-1">
          <RangePicker onChange={handleDateSelected} />
        </div>
      )}
    </div>
  );
}
