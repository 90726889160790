import React from 'react';

const StatusLabels = (props) => {

    const mystyle = {
        color: "white",
        backgroundColor: props.color,
        borderColor:props.color,
        borderRadius:"5px",
       
        width:"100px"

   };
    return (
        <div className="mt-1 text-center" style={mystyle}>
            
    <p><span className="my-2" >{props.message}</span></p>
            </div>
            
        
    );
};

export default StatusLabels;