import * as actions from "../../actionTypes";

const initialState = {

  cgmReadings:[],
  sortedCgmData:{},
  exportCgmReadings:[],
  currentRecordCalculations:{},
  currentRecordCalculationsForExport:{},
  cgmReadingsForHistory:[],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CGM_RECORD_START:
      return{...state,
      isLoading: true}

    case actions.FETCH_CGM_RECORD_SUCCESS:
      return {
        ...state,

        cgmReadings: action.payLoad,
        isLoading: false
      }


    case actions.FETCH_CGM_RECORD_FAILED:
      return{
        ...state,
        isLoading: false
      }

    case actions.RESET_CGM_RECORD:
        return{
          ...state,
          cgmReadings: [],
          isLoading: false,
        }

      case actions.FETCH_SORTED_CGM_RECORD_START:
        return{...state,
        isLoading: true}

      case actions.FETCH_SORTED_CGM_RECORD_SUCCESS:
        return {
          ...state,
          sortedCgmReadings: action.payLoad,
          isLoading: false
        }


      case actions.FETCH_SORTED_CGM_RECORD_FAILED:
        return{
          ...state,
          isLoading: false
        }

        case actions.FETCH_EXPORT_CGM_RECORD_START:
          return{...state,
          isLoading: true}

        case actions.FETCH_EXPORT_CGM_RECORD_SUCCESS:
          return {
            ...state,
            exportCgmReadings: action.payLoad,
            isLoading: false
          }



        case actions.FETCH_EXPORT_CGM_RECORD_FAILED:
          return{
            ...state,
            isLoading: false
          }



      case actions.CGM_RECORD_CALCULATIONS:
        return {
          ...state,
          currentRecordCalculations: action.payLoad,
          isLoading: false
        }


        case actions.EXPORT_CGM_RECORD_CALCULATIONS:
          return {
            ...state,
            currentRecordCalculationsForExport: action.payLoad,
            isLoading: false
          }

          case actions.FETCH_HISTORY_CGM_RECORD_START:
            return{...state,
            isLoading: true}

          case actions.FETCH_HISTORY_CGM_RECORD_SUCCESS:
            return {
              ...state,

              cgmReadingsForHistory: action.payLoad,
              isLoading: false
            }


          case actions.FETCH_HISTORY_CGM_RECORD_FAILED:
            return{
              ...state,
              isLoading: false
            }

    default:
      return state;
  }
}
