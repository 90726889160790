import http from "../../../utils/http-service";
import { API_BASE_URL, fetchBpDataUrl } from "../../../utils/urls";
import {
  FETCH_BP_START,
  FETCH_BP_FAILED,
  FETCH_BP_SUCCESS,
} from "../../actionTypes";
import {encrypt, decrypt} from "../../../utils/encryption";
import {logout} from "../auth";

export const fetchBpData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_BP_START,
  });
  try {
    // ////("data in action :", data);
    // const res = await http.get(`${API_BASE_URL}${fetchBpDataUrl}/2`, {
    //   params: {
    //     ...data
    //   },
    // });
    //
    // dispatch({ type: FETCH_BP_SUCCESS, payLoad: res.data });
    // ////(res);
    // return res;

    let bp;
    let res;
    if(data.time){
      bp = data.time;

      bp = encrypt(bp);

      let type = 2;
      type = JSON.stringify(type);
      type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBpDataUrl}${type}?time=${bp}`);
    } else if(data.start_date) {

      let bp_start = data.start_date;
      bp_start = encrypt(bp_start);
      let bp_end = data.end_date;
      bp_end = encrypt(bp_end);

      let type = 2;
      type = JSON.stringify(type);
      type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBpDataUrl}${type}?start_date=${bp_start}&end_date=${bp_end}`,);
}
let gift = res.data.data;

gift = decrypt(gift);
gift = JSON.parse(gift);
////(gift);
  // updateUnit(unit, res.data.data)
  dispatch({ type: FETCH_BP_SUCCESS, payLoad: gift });

  return res;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch({
      type: FETCH_BP_FAILED,
    });
  }
};
