import React from "react";
import Tooltip from "../../../../utils/Tooltip";
import Chart7 from "./Chart7";
// import BeReadings from "./BGReadingsNew";

const BGReading = (dataSource) => {

  ////("Bg Reading",dataSource)

  let heading=dataSource.themeType==="bg"?"text-voyger-purple":dataSource.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className={`chart-heading ${heading}`}>{dataSource.themeType==="bg"?"BG Readings":"Inr Readings"}
        <span className='ml-2'><Tooltip themeType={dataSource.themeType}  text="A log book of your blood glucose results starting with a most recent."/></span>
        </div>

      </div>
      <div className="d-flex justify-content-center pb-5 ">

        <Chart7 themeType={dataSource.themeType} dataSource={dataSource}/>
        {/*<BeReadings dataSource={dataSource}/>*/}
      </div>
    </div>
  );
};

export default BGReading;
