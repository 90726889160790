import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import * as _ from "lodash";
import moment from "moment";
import { DatePicker } from "antd";

import HistoricalReadings from "../../historical-readings";
import TempStats from "./temp-stats";
import TempReading from "./temp-readings";
import { fetchTempData } from "../../../../redux/actions/micro-apps/temperature";
import ExportData from "../../Export/ExportData";

import { default as Titles } from "../../../../utils/document-titles";

const { RangePicker } = DatePicker;

const Temperature = () => {
  document.title = Titles.temprature;

  const data = useSelector((state) => state.temperature);
  const device = useSelector((state) =>(state.devices.devices));

  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [tfWsReadings, settfWsReadings] = useState([]);
  // const [length, setLength] = useState();
  const [read, setRead] = useState();
  const [showDate, setShowDate] = useState(false);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("30days");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [rangeBtn, setRangeBtn] = useState(false);
  const [rangeDate, setRangeDate] = useState("");
  const [dateRange,setDateRange]=useState(false);
  ////("Data :",data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (filter !== "" || startDate !== "") {
      fetchReadings();
      setDropMenu(false);
      setRangeDate("")
    }
    return () => {                                                               // second, we return an anonymous clean up function
      setDateRange(false)
    };
   }, [filter, dateRange]);

  useEffect(() => {
    transformHistoricalReadings();
    setStartDate("")
    setEndDate("")
  }, [data.historicalReadings]);

  useEffect(() => {
    transformWsReading();
  }, [data.readings]);
  setTimeout(() => {
    setRangeBtn(true)

  }, 1000);

  const fetchReadings = () => {
    let data = {};
    if (filter) {
      ////("Data Before Fetch :",data);

      data.time = filter;
      ////("Data Before Fetch :",data);

    }
    if (startDate && endDate) {
      data.start_date = endDate;
      data.end_date = startDate;
    }
    ////("Data Before Fetch :",data);

    dispatch(fetchTempData(data));
    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }
  };

  const transformHistoricalReadings = () => {
    ////("This is Called");
    let transformedData = [];
    transformedData = data.historicalReadings
      .map((reading) => {
        ////("ITEM :",reading);

        return reading.data.map((item) => {
          ////(item);
          let date = new Date();
          date.setDate(reading._id);
          date.setHours(item.hour);
          date.setMinutes(item.minutes);
          return {
            date: date,
            reading: item.value["temperature"],

          };
        });
      })
      .flat();
    let myArray = _.sortBy(transformedData.flat(), function (dateObj) {
      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray)
  };

  const transformWsReading = () => {

    let tfWsReadings = data.readings.sort((a, b) => new Date(b.time) - new Date(a.time)).map((item, index) => {

      let reading = {time:0, temperature:0}
      reading.temperature = _.round(item.value.temperature, 1).toFixed(1);

      reading.time = moment(item.time).format("MMMM-DD-YYYY");
      return reading;
    });

    settfWsReadings(tfWsReadings);

  };
  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }
  const onStartDateChange=(e)=>{


    const val=e.target.value

     setStartDate(moment(val).format("YYYY-MM-DD").toString());
     setToDate(moment(val).format("YYYY-MM-DD").toString());
     setRangeDate(moment(val).format("YYYY-MM-DD").toString())


}
const onEndDateChange=(e)=>{

    const val=e.target.value
  if(val){

    setEndDate(moment(val).format("YYYY-MM-DD").toString());
  setFromDate(moment(val).format("YYYY-MM-DD").toString());
  }
}
const onClickBtnOk=()=>{

  if(startDate!==""){
    setDateRange(true)
  }
  else{

  }
}

   const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
    const getDateAsArray = (date) => {
      return moment(date.split(/\D+/), date_format);
    }
    return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') +1;
  }

  return (
    <div>
    <Spin className='d-flex justify-content-center align-items-center h-100 w-100' spinning={data.isLoading}>
    <div className="d-flex my-2 mx-3  p-2 pr-1 justify-content-end align-items-center  border-bottom">
       {device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?<ExportData />:null}

      </div>
      <div className="bg-gray-200 flex flex-col p-1 lg:p-0 pb-5 ">
        {!data.isLoading && (
          <>
            <div className=" row mw-100 m-auto">
            <div className="col-12">
        <div className="py-3 ">
          <div>
            <div className="d-flex filter-btn p-2 align-items-center justify-content-lg-start filter-area justify-content-sm-center justify-content-center">

              <div className='d-flex flex-lg-row align-items-center flex-column'>
              <div className="d-flex align-items-center justify-content-center day-dates p-2">
              <div className="">
                <h4 className="chart-heading m-0">{filter==="" && startDate!=="" && endDate !=="" ?
                  getDaysDiff(toDate, fromDate)+" days":
                  filter=== "30days"? "30 days":
                  filter=== "14days"? "14 days":
                  filter=== "7days"? "07 days":
                  filter=== "today"?filter : null}
                </h4>
              </div>
              {filter==""?<span>
              <span className="p-2">{toDate? toDate.toString(): 0} </span>
                <span className=""> - </span>
                <span className="p-2"> {fromDate? fromDate.toString(): 0}</span>
              </span>:<span>
              <span className="p-2">{fromDate? fromDate.toString(): 0} </span>
                <span className="p-2"> - </span>
                <span className="p-2"> { toDate? toDate: 0}</span>
              </span>}

              </div>
              <div className="d-flex justify-content-center flex-row mt-md-0 mt-2">
              <button type="button" className={ todayFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"}
                      onClick = {
                        () => {
                          setEndDate("");

                          setEndDate("");
                          setDateRange(false)
                          setShowDate(false);
                          setFilter("today");
                          setFilterText("Today");
                          setTodayFilter(true);
                          setWeekFilter(false);
                          setMonthFilter(false);
                          setRangeFilter(false);
                          setDropMenu(false);

                        }
                      }>
                TODAY
              </button>
              <button type="button" className={ weekFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"} onClick = {
                () => {
                  setEndDate("");

                  setEndDate("");
                  setDateRange(false)
                  setShowDate(false);
                  setFilter("7days");
                  setFilterText("Week");
                  setWeekFilter(true);
                  setTodayFilter(false);
                  setMonthFilter(false);
                  setRangeFilter(false);
                  setDropMenu(false);
                }
              } >
                WEEK
              </button>
              <button type="button"

              className={ monthFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"} onClick = {
                () => {
                  setEndDate("");

                  setEndDate("");
                  setDateRange(false)
                  setShowDate(false);
                  setFilter("30days");
                  setFilterText("Month");
                  setWeekFilter(false);
                  setTodayFilter(false);
                  setMonthFilter(true);
                  setRangeFilter(false);
                  setDropMenu(false);
                }
              }>
                MONTH
              </button>
              <button type="button"
               disabled={rangeBtn?false:true}
              className={ rangFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"} onClick = {
                () => {
                  setFilter("");
                  setFilterText("Range");
                  setShowDate(true);
                  setWeekFilter(false);
                  setTodayFilter(false);
                  setMonthFilter(false);
                  setRangeFilter(true);
                  setDropMenu(true);

                }
              }>
                RANGE
              </button>

              </div>
              <div className="d-flex text-center mt-3 justify-content-center">

                    <div className={dropMenu ? " d-block mb-3" : " d-none"}>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                     <div className='d-flex flex-row mb-md-0 mb-2'>
                     <input type="date"
                    onChange={onStartDateChange}
                    ></input>
                    <input type="date"
                    disabled={rangeDate===""?true:false}
                    className="ml-1"
                    onChange={onEndDateChange}
                    min={moment(endDate).format("YYYY-MM-DD")}
                    max={moment().format("YYYY-MM-DD")}
                    ></input>
                       </div>
                    <button className="btn bg-light" onClick={onClickBtnOk}>Go</button>
                </div>

              </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            </div>
            <div className="col-12">

              <div className="row mw-100 m-auto">

                <div className="col-12 col-md-7" style={{minHeight:"500Px"}}>
                  <HistoricalReadings
                    color1="#667eea"
                    color2="#7f9cf5"
                    color3="#4c51bf"
                    name = "Temperature"
                    // name2 = "Diastolic"
                    // name2 = "Heart Rate"
                    id={"tempChartHistoricalReading"}
                    text={"Line graph showing your readings over your selected date range."}
                    dataPoints={tfHistoricalReadings}
                  />
                </div>

                <div className="col-12 col-md-5 mt-md-0 mt-2">
                  <TempStats {...data.pulseStats} tempLost={data.readings}/>
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="row mw-100 m-auto">
                <div className="col-12">
                <TempReading dataSource={tfWsReadings}/>

                </div>
              </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Spin>
    </div>
  );
};

export default Temperature;
