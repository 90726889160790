import React, { useState, useEffect } from "react";
import { getPatient,saveSearchInput,savePatientData } from "../../../redux/actions/doctor";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import {encrypt,decrypt} from "../../../utils/encryption";

const Patients = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [count, setCount] = useState("");
    const [data, setData] = useState("");
    const [input, setInput] = useState(data);
    const [isLoading, setIsLoading] = useState(false);
    const [searched, setItemSearched] = useState("");

    const userData = useSelector((state) => state.doctor.patientsList);
    const Search = useSelector((state) => state.doctor.search);
  
    
   
     //////(userData)
    const handleSubmit = async (e) => {
        try {
            let d=data;
            d=JSON.stringify(data);
         d=encrypt(data)
         localStorage.setItem("search",d)
      
      
          saveSearchInput(data)
          setIsLoading(true);
                const patientList = await dispatch(getPatient(data));
                setIsLoading(false);
          
        } catch (error) {
            setIsLoading(false);
         
        }
        var length = data.length;
        setCount(length);
     
    };
    const fetchRecord=async (row)=>{
     
         let data=row
         data=JSON.stringify(data);
         data=encrypt(data)
         localStorage.setItem("rec",data)
         let result= dispatch(savePatientData(row))
          history.push("/doctor/patient/history")
       
    }
      
    return (
        <div className="container-fluid patient-list">
            <div className="container bg-white rounded my-5 p-3">
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row align-items-center px-3">
                
                    <label htmlFor="inputSearch" className="col-sm-1 col-form-label">
                        Search
                    </label>
                    <div className="col-md-10">
                        <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            defaultValue={localStorage.getItem("search")?decrypt(localStorage.getItem("search")):searched}
                            onChange={(e) => {
                              setData(e.target.value);;
                            }}
                            id="inputSearch"
                            placeholder="Search by Name,Date,Telephone Number ..."
                        />
                    </div>
                    <div className="col-1">
                    <Spin spinning={isLoading}>
                <button disabled={data===null||data===""?true:false} type="button" className="btn btn-primary" onClick={handleSubmit}>
                  <span className="text-lg">Search</span>
                </button>
              </Spin>
                    </div>
               
                </div>
                {/* </form> */}
                <table className="table mt-5 py-4">
                    <thead className="">
                        <tr>
                            <th scope="col">NHS Number</th>

                            <th scope="col">FirstName</th>

                            <th scope="col">LastName</th>

                            <th scope="col">Dob</th>

                            <th scope="col">Email</th>

                            <th scope="col">Address</th>

                            <th scope="col">Postcode</th>

                            <th scope="col">Telephone</th>

                            <th scope="col">View</th>
                        </tr>
                    </thead>

                    <tbody>
                        {userData.data !== null &&
                            userData.data !== undefined &&
                            userData.data.map((row, index) => (
                                <tr key={index}>
                                    <td>NHS Number</td>
                                    <td>{row.firstName}</td>
                                    <td>{row.lastName}</td>
                                    <td>Dob</td>
                                    <td>{row.email}</td>
                                    <td>Address</td>
                                    <td>PostCode</td>
                                    <td>Telephone</td>
                                    <td>
                                        <div className="col">
                                            {/* <Link to="/doctor/patient/history"> */}
                                            <button onClick={()=>fetchRecord(row)}>
                                                <i className="fas fa-eye"></i>
                                                </button>
                                            {/* </Link> */}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        <div className="text-center">
                            {/* <Spin spinning={isLoading} /> */}
                        </div>
                    </tbody>
                </table>

                <div className="row p-2 px-3">
                    <div className="col-md-6">
                        <div className="row justify-content-between">
                            <div className="col-3">
                                <button className="rounded w-100 bg-primary  py-2 text-white">Select</button>
                            </div>
                            <div className="col-3">
                                <button className="rounded w-100 bg-primary py-2 text-white">Appointment</button>
                            </div>
                            <div className="col-3">
                                <button className="rounded w-100 bg-primary  py-2 text-white">Consultation</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row justify-content-end">
                            <div className="col-3">
                                <button className="rounded w-100 bg-primary  py-2 text-white">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Patients;
