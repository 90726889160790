import React from 'react';
import { Table } from 'antd';
import moment from "moment"
import Tooltip from '../../../../utils/Tooltip';



const columns = [
  {
    title: 'Date',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Systolic',
    dataIndex: 'systolic',
    key: 'systolic',
  },
  {
    title: 'Diastolic',
    dataIndex: 'diastolic',
    key: 'diastolic',
  },
  {
    title: 'Heart Rate',
    dataIndex: 'heartRate',
    key: 'heartRate',
  },
];


const BpReading = ({dataSource}) => {
  // const sortedActivities = dataSource.sort((a, b) => new Date (b.time) - new Date (a.time))
  // let sortedArray = sortedActivities.map((item) => {
  //   item.systolic = item.value.systolic;
  //   item.diastolic = item.value.diastolic;
  //   item.heartRate = item.value.heartRate;
  //   item.time = moment(item.time).format("MMMM-DD-YYYY");
  //   return item;
  // });
  
  return (
    <div className="border-radius-chart my-2 md:mr-2 w-full bg-white justify-evenly items-center bp-readings shadow-lg pl-4 pr-4 pb-4 flex flex-col">
      <p className="chart-heading font-weight-bold text-xl w-full text-left pt-2  mb-0">BP Readings
      <span className='ml-2'><Tooltip text="A log book of your blood pressure results starting with a most recent."/></span>
      </p>
      <div className="w-full mx-auto overflow-x-auto">
        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5}}/>
      </div>
    </div>
   );
}

export default BpReading;
