import React, { useState } from "react";
import TotalUsers from "./total-users";
import ActiveUsers from "./active-users";
import UsersGenderChart from "./users-gender-chart";
import UsersAverageAgeChart from "./users-average-age-chart";
import Conditions from "./conditions";
import Demographics from "./demographics";
import UsersByLocation from "./users-geographic-chart";
import Filters from "./filters";

import {
  Devices,
  ActiveDevices,
  TopDevices,
  NotPerformingDevices,
} from "./devices";

export default function AdminDashboard() {
  const [filter, setFilter] = useState({
    name: "",
    payload: {},
  });

  return (
    <div className="bg-gray-200 h-screen flex flex-col p-1 md:p-0">
      <div className="flex flex-row justify-between w-full">
        <Filters filter={filter} onChange={setFilter} />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between w-full mt-3">
        <TotalUsers
          dataPoints={[
            {
              date: new Date().setMonth(1),
              count: 300,
            },
            {
              date: new Date().setMonth(2),
              count: 700,
            },
            {
              date: new Date().setMonth(3),
              count: 1000,
            },
            {
              date: new Date().setMonth(4),
              count: 800,
            },
            {
              date: new Date().setMonth(5),
              count: 500,
            },
            {
              date: new Date().setMonth(6),
              count: 900,
            },
          ]}
        />
        <ActiveUsers
          dataPoints={[
            {
              date: new Date().setMonth(1),
              count: 300,
            },
            {
              date: new Date().setMonth(2),
              count: 700,
            },
            {
              date: new Date().setMonth(3),
              count: 1000,
            },
            {
              date: new Date().setMonth(4),
              count: 800,
            },
            {
              date: new Date().setMonth(5),
              count: 500,
            },
            {
              date: new Date().setMonth(6),
              count: 900,
            },
          ]}
        />
        {/* </div>
      <div className="flex justify-between w-full mt-10"> */}
        <UsersGenderChart
          dataPoints={[
            { label: "Male", value: 400 },
            { label: "Female", value: 700 },
            { label: "Other", value: 125 },
          ]}
        />
        <UsersAverageAgeChart
          dataPoints={[
            {
              date: new Date().setMonth(1),
              averageAge: 30,
            },
            {
              date: new Date().setMonth(2),
              averageAge: 70,
            },
            {
              date: new Date().setMonth(3),
              averageAge: 40,
            },
            {
              date: new Date().setMonth(4),
              averageAge: 60,
            },
            {
              date: new Date().setMonth(5),
              averageAge: 50,
            },
            {
              date: new Date().setMonth(6),
              averageAge: 55,
            },
          ]}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between w-full md:mt-10">
        <Devices
          devices={[
            {
              name: "device 1",
            },
            {
              name: "device 6",
            },
            {
              name: "device 2",
            },
            {
              name: "device 3",
            },
            {
              name: "device 4",
            },
            {
              name: "device 5",
            },
          ]}
        />
        <ActiveDevices
          devices={[
            {
              name: "device 1",
            },
            {
              name: "device 2",
            },
            {
              name: "device 3",
            },
            {
              name: "device 4",
            },
            {
              name: "device 5",
            },
          ]}
        />
        <TopDevices
          devices={[
            {
              name: "device 1",
            },
            {
              name: "device 2",
            },
            {
              name: "device 3",
            },
            {
              name: "device 4",
            },
            {
              name: "device 5",
            },
          ]}
        />
        <NotPerformingDevices
          devices={[
            {
              name: "device 1",
            },
            {
              name: "device 2",
            },
            {
              name: "device 3",
            },
            {
              name: "device 4",
            },
            {
              name: "device 5",
            },
          ]}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-center w-full md:my-10">
        <UsersByLocation
          dataPoints={[
            {
              latitude: 51.507351,
              longitude: -0.127758,
              title: "London",
              count: 200,
            },
            {
              latitude: 53.480759,
              longitude: -2.242631,
              title: "Manchester",
              count: 400,
            },
            {
              latitude: 53.408371,
              longitude: -2.991573,
              title: "Liverpool",
              count: 500,
            },
            {
              latitude: 51.507351,
              longitude: -0.127758,
              title: "London",
              count: 200,
            },
            {
              latitude: 53.480759,
              longitude: -2.242631,
              title: "Manchester",
              count: 400,
            },
            {
              latitude: 53.408371,
              longitude: -2.991573,
              title: "Liverpool",
              count: 500,
            },
            {
              latitude: 51.507351,
              longitude: -0.127758,
              title: "London",
              count: 200,
            },
            {
              latitude: 53.480759,
              longitude: -2.242631,
              title: "Manchester",
              count: 400,
            },
            {
              latitude: 53.408371,
              longitude: -2.991573,
              title: "Liverpool",
              count: 500,
            },
            {
              latitude: 51.507351,
              longitude: -0.127758,
              title: "London",
              count: 200,
            },
          ]}
        />
        <Conditions
          conditions={[
            {
              name: "condition 1",
            },
            {
              name: "condition 6",
            },
            {
              name: "condition 2",
            },
            {
              name: "condition 3",
            },
            {
              name: "condition 4",
            },
            {
              name: "condition 5",
            },
            {
              name: "condition 6",
            },
            {
              name: "condition 7",
            },
            {
              name: "condition 6",
            },
            {
              name: "condition 7",
            },
            {
              name: "condition 6",
            },
            {
              name: "condition 7",
            },
          ]}
        />
        <Demographics
          demographics={[
            {
              name: "demographic 1",
            },
            {
              name: "demographic 6",
            },
            {
              name: "demographic 2",
            },
            {
              name: "demographic 3",
            },
            {
              name: "demographic 4",
            },
            {
              name: "demographic 5",
            },
          ]}
        />
      </div>
    </div>
  );
}
