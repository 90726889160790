import React from "react";
import Tooltip from "../../../../utils/Tooltip";
import Chart5 from "./Chart5";

const AverageBg = ({dataPoints,themeType,id}) => {
// // // console.log("DATA PINTS",dataPoints);
  let heading=themeType==="bg"?"text-voyger-purple":themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between mb-5">
          <div className={`chart-heading ${heading}`}>{themeType==="bg"? "Average Bg":"Average Inr"}
          <span className='ml-2'><Tooltip themeType={themeType} text="Displays your average blood glucose result at your various measurement times"/></span>
          </div>
          {/* <div>
            <i className="fas fa-ellipsis-h"></i>
          </div> */}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Chart5 themeType={themeType} id={id} dataPoints={dataPoints}/>
        </div>
      </div>
    </div>
  );
};

export default AverageBg;
