import * as actions from "../actionTypes/admin";

const INITIAL_STATE = {
  users: [],
  isLoading: false,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_USER_START:
      return { ...state, isLoading: true };
    case actions.FETCH_USER_FAILED:
      return {
        ...state,
         isLoading: false,
         users:[]
        };


    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };


    default:
      return{
        ...state
      }
  }
};
