import React,{useEffect, useState} from "react";
import RepresentativeComplianceChart from "./representative-compliance-rate-chart";
import CheckInsChart from "./checkins-chart";
import NonCompliantVendorChart from "./non-complian-vendor-chart";
import UpcomingAppointments from "./upcoming-appointments";
import AppointmentRequests from "./appointment-requests";
import DocumentTitles from "../../utils/document-titles";

import { Link } from "react-router-dom";
import {
  UserAddOutlined,
  ArrowRightOutlined,
  ExportOutlined,
} from "@ant-design/icons";
// import BloodGlucoseComp from "./Home/BloodGlucoseComp";
// import ProblemsComp from "./Home/ProblemsComp";
// import NotesComp from "./Home/NotesComp";
// import ChatComp from "./Home/ChatComp";

const CheckIns = ({ newCheckIns }) => {


  return (
    <div className="my-3 md:my-0 md:mr-2 w-full bg-white rounded-lg shadow-lg p-4 ">
      <p className="font-bold text-xl">New CheckIns</p>
      <div className="flex justify-center my-20 h-full">
        <div className="mr-1 bg-purple-300 rounded-lg w-32 h-32 flex justify-center items-center">
          <ExportOutlined className="text-4xl" />
        </div>
        <span className="ml-2 flex flex-col">
          <span className="text-3xl font-bold">{newCheckIns}</span>
          <span className="uppercase font-light">Last 30 Days</span>
          <Link to="/newVendors" className="capitalize flex items-center">
            <span className="mr-1">View All</span>
            <ArrowRightOutlined />
          </Link>
        </span>
      </div>
    </div>
  );
};

const NewVendorAdditions = ({ newVendors }) => {
  return (
    <div className="my-3 md:my-0 md:mx-2 w-full bg-white rounded-lg shadow-lg p-4 ">
      <p className="font-bold text-xl">New Vendor Additions</p>
      <div className="flex justify-center my-20 h-full">
        <div className="mr-1 bg-orange-300 rounded-lg w-32 h-32 flex justify-center items-center">
          <UserAddOutlined className="text-4xl" />
        </div>
        <span className="ml-2 flex flex-col">
          <span className="text-3xl font-bold">{newVendors}</span>
          <span className="uppercase font-light">Last 30 Days</span>
          <Link to="/newVendors" className="capitalize flex items-center">
            <span className="mr-1">View All</span>
            <ArrowRightOutlined />
          </Link>
        </span>
      </div>
    </div>
  );
};

export default function Dashboard() {
  const [upcomingDate,setUpcomingDate]=useState(new Date())
  const [filteredData,setFilteredData]=useState([])
  const [uAppointments,setUAppointments]=useState([
    {
      date: "January 06,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 06,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },{
      date: "January 06,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 07,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },{
      date: "January 08,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 08,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },{
      date: "January 08,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 09,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },{
      date: "January 09,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 10,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    
    {
      date: "January 10,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 10,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    }
    ,
    {
      date: "January 11,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    }
    ,
    {
      date: "January 11,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 12,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    }
    ,
    {
      date: "January 12,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    }
    ,
    {
      date: "January 13,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 13,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 13,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    },
    {
      date: "January 13,2021",
      time: "2:30PM",
      location: "Manhattan",
      description: "Appointment with lorem ipsum company",
    }
    
  ])

  document.title = DocumentTitles.dashboard;
  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

useEffect(()=>{
 handleDate(new Date())
},[])
 
  const handleDate=(date)=>{
    setUpcomingDate(date)

    let day=date.getDate();
    let monthNumber=date.getMonth()
    let month=monthNames[date.getMonth()]
    let year=date.getFullYear();
    let arr=[]

    for (let i = 0; i<uAppointments.length; i++) {
      let dateFil = new Date(uAppointments[i].date);
      ////("dateFil=",dateFil)
      let cDay = dateFil.getDate();
      let cMonth = dateFil.getMonth();
      let cYear = dateFil.getFullYear();
      ////("Cday=",cDay)
      ////("CMonth=",cMonth)
      ////("CYear=",cYear)
      ////("day=",day)
      ////("month=",monthNumber)
      ////("year=",year)
      if(day === cDay && monthNumber===cMonth && year===cYear ) {
        arr.push(uAppointments[i])
        
      } 

      ////("filtered",filteredData)
    }
    ////("arr",arr)
    setFilteredData(arr)
  }

 ////("upcomingDate=",upcomingDate)

  return (
   
    <div className='container-fluid rounded'>
      <div className='row h-100' >
        
       
        <div className='col-md-6 col-12 d-flex align-items-stretch'>
        <UpcomingAppointments
          settingDate={handleDate}
          appointments={filteredData}
         
        />
        </div>
        <div className='col-md-6 col-12 '>
  
        
          <div className='col-12'>
       
          </div>
          <div className="col-12">
          <AppointmentRequests
          appointmentRequests={[
            {
              title: "Appointment with lorem ipsum company",
              description: "Appointment with lorem ipsum company",
            },
            {
              title: "Appointment with lorem ipsum company",
              description: "Appointment with lorem ipsum company",
            },
          ]}
        />
          <CheckInsChart
          dataPoints={[
            { date: "03/24", successful: 1000, failed: 500 },
            { date: "04/24", successful: 320, failed: 1000 },
          ]}
          
        />
          </div>
      
        </div>
        </div>

      </div>
   
    
  );
}
