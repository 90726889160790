import React from "react";
import Tooltip from "../../../../../utils/Tooltip";
import AidexTimeRange from "./aidex-time-range";

const AidexTimeRangeBg = ({dataPoints,id,themeType}) => {
  // // // // // console.log("TYpe", dataPoints);
  ////("time range",dataPoints);
  ////("time range",dataPoints);
  // // // // console.log("DATA POINTS IN TRBG",dataPoints, id, themeType);

  let heading=themeType==="bg"?"text-voyger-purple":themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className={`chart-heading ${heading}`}>Time Range
        <span className='ml-2'><Tooltip themeType={themeType} text="Frequency of your blood glucose results with in certain blood glucose ranges"/></span>
        </div>
        {/* <div>
          <i className="fas fa-ellipsis-h"></i>
        </div> */}
      </div>
      <div className="d-flex justify-content-center my-5">
        <AidexTimeRange themeType={themeType} id={id} dataPoints={dataPoints}/>
      </div>
    </div>
  );
};

export default AidexTimeRangeBg;
