import http from "../../utils/http-service";
import { API_BASE_URL, sendFeedback } from "../../utils/urls";
import {decrypt,encrypt} from "../../utils/encryption";
import ShowToast from "../../utils/show-toast";
import { FETCH_VITALS_SUCCESS } from "../actionTypes/patient-dashboard";
import {logout} from "./auth";

export const getHistoricalReadings = async(dispatch) => {
  try{
  return http.get(`${API_BASE_URL}/user/e_dashboard`)
} catch(error){
  if(error.response.status===422 || error.response.status === 401){

    dispatch(logout(true));
    window.location.reload();

  }
}
}

export const fetchVitals = (data) =>async (dispatch) => {
  try {

    let res=await http.get(`${API_BASE_URL}/user/vitals/${data.type}`,{params: {...data}})


    let gift = res.data.data;

    // gift = decrypt(gift);
    // gift = JSON.parse(gift);

    dispatch({ type: FETCH_VITALS_SUCCESS, payLoad: gift });
    return res;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
  }
}

export const sendFeedbackEmail = (data) =>  async (dispatch) => {
  try {

    let feedback=data;
    feedback=JSON.stringify(feedback);
    feedback=encrypt(feedback);

    const result = await http.post(`${API_BASE_URL}${sendFeedback}`,{"data":feedback});

    if (result.status === 200) {
      ShowToast("Thank you for your valuable feedback.", "success");

      let {
        data: { data },
      } = result;
      // data=decrypt(data);
      // data=JSON.parse(data);
    }

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    throw error;
  }
}







// export const fetchVitals = (data) => {

//     let bg;
//     if(data.time){
//       // bg = data.time;
//       bg={"time":data.time}

//     } else if(data.start_date) {

//       let start;
//       start={"start_date":data.start_date};
//       let end;
//       end={"end_date":data.end_date}
//       bg = start + "&" +end;

//     }
//     bg = JSON.stringify(bg);

//     bg = encrypt(bg);

//     let type = data.type;
//     type = JSON.stringify(type);
//     type = encrypt(type);

//   return http.get(`${API_BASE_URL}/user/e_vitals/${type}?type=${type}&${bg}`)
// }
