import React from "react";

const AppointmentRequest = ({ title, description, roundedBorder = false }) => {
  return (
    <div
      className={`w-full p-3 flex flex-1 flex-col md:flex-row md:items-center border-b border-gray-500 ${
        roundedBorder ? "rounded-b-lg" : ""
      }`}
    >
      <div className="md:w-4/6 flex flex-col">
        <span className="text-blue-400 font-semibold">{title}</span>
        <span className="text-sm">{description}</span>
      </div>
      <div className="my-1 md:my-0 md:w-2/6 flex justify-around items-center">
        <button className="mr-1 px-2 py-1 bg-green-400 text-white rounded-sm">
          Accept
        </button>
        <button className="ml-1 px-2 py-1 bg-red-400 text-white rounded-sm">
          Reject
        </button>
      </div>
    </div>
  );
};

export default function AppointmentRequests({ appointmentRequests = [] }) {
  const renderAppointmentRequests = () => {
    return appointmentRequests.map(({ title, description }, index) => (
      <AppointmentRequest title={title} description={description} />
    ));
  };

  return (
    <div className="my-3 md:my-0  w-full bg-white rounded-t-lg shadow-lg p-0">
      <p className="m-0 rounded-t-lg font-bold text-xl bg-gray-100 text-center w-full px-1 py-2">
        Appointment Requests from Vendor Representatives
      </p>
      <div className="content rounded-b-lg flex flex-col flex-1">
        {appointmentRequests.length > 0 ? (
          renderAppointmentRequests()
        ) : (
          <div>You don't have any appointment requests</div>
        )}
      </div>
    </div>
  );
}
