import http from "./aidex-http-service";

// import { API_BASE_URL, fetchCgmRecordUrl,fetchSortedCgmRecord } from "../../utils/urls";

import {
  // For overview
  FETCH_CGM_RECORD_START,
  FETCH_CGM_RECORD_SUCCESS,
  FETCH_CGM_RECORD_FAILED,
  RESET_CGM_RECORD,

  // For sortedCgm
  FETCH_SORTED_CGM_RECORD_START,
  FETCH_SORTED_CGM_RECORD_SUCCESS,
  FETCH_SORTED_CGM_RECORD_FAILED,

  //For export
  FETCH_EXPORT_CGM_RECORD_START,
  FETCH_EXPORT_CGM_RECORD_SUCCESS,
  FETCH_EXPORT_CGM_RECORD_FAILED,
  EXPORT_CGM_RECORD_CALCULATIONS,
// For History
FETCH_HISTORY_CGM_RECORD_START,
FETCH_HISTORY_CGM_RECORD_SUCCESS,
FETCH_HISTORY_CGM_RECORD_FAILED,
// for Time In Range Chart
  CGM_RECORD_CALCULATIONS,

} from "../../actionTypes";
// import {encrypt, decrypt} from "../../../utils/encryption";
// import * as _ from "lodash";
import moment from "moment";


export const fetchCgmData = (data, content) => async(dispatch) => {
  // // // // // console.log("content", content);
  dispatch({
    type: FETCH_CGM_RECORD_START,
  });
  let hoursArr=[];

try {
    let cgm_start = data.timeFrom;
    let cgm_end = data.timeTo;
    // // // // // console.log("cgm_start", cgm_start);
    // // // // // console.log("cgm_end", cgm_end);


// let duration = {
//   timeFrom:cgm_start,
//   timeTo:cgm_end
// }
    const res = await http.post(`https://grxaidex.co.uk/api/portal/cgm-record`,{timeFrom:cgm_start, timeTo: cgm_end});
      // // // // console.log("RESS", res);
    let hiP;
    let loP;
    let avgP;
    let lowVal;
    let highVal;
    let timeRange;
    let bgStats;

    if(res.status===200){
      if(res.data && res.data.content && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        let data = res.data.content.portal_cgm_record;
        let cal=findMinMax(data, "glucose");
        lowVal=cal.min;
        highVal=cal.max;
        let highArray=[];
        let lowArray=[];
        let avgArr=[];
        //Time Range Calculation
        let lowGlucoTR=[];
        let normalTR=[];
        let typeOneTR=[];
        let typeTwoTR=[];
        let uncontrolledTR=[];

        // Time In Range and Glucose Events and BG Stats
        let dataSubsititute = data.sort((a, b)=>{return a.glucose-b.glucose});
        // // // // console.log("dataSubsititute before",dataSubsititute);

        dataSubsititute= dataSubsititute.filter((obj)=>{
        if(obj.glucose>=2){
          return obj
        }
        })
        // // // // // console.log("dataSubsititute after",dataSubsititute);
        let lowestBg=dataSubsititute[0].glucose;
        let highestBg = dataSubsititute[dataSubsititute.length - 1].glucose;
        let averageBloodGlucose;
        let glu=0;
        data.map((reading, index)=>{
          glu = glu + reading.glucose;
        })
        // // // // // console.log("glu", glu);
        // // // // // console.log("data.length", data.length);

        averageBloodGlucose= (glu/(data.length)).toFixed(2);
        // // // // console.log("averageBloodGlucose", averageBloodGlucose);

      bgStats=  {
            "themeType": "bg",
            "unit": 0,
            "average": averageBloodGlucose,
            "lowest": lowestBg,
            "highest": highestBg
        }


        data.filter((reading, index)=>{
          if (reading.glucose >=res.data.content.high){
            // // // // console.log(">12");
            highArray.push(reading);


          } else if (reading.glucose <= res.data.content.low){
            // // // // console.log("<4");

            lowArray.push(reading);

          }else {
            avgArr.push(reading);

          }

          if (reading.glucose >"13.3"){
            // // // // console.log("HIGH GLUCOSE", reading.glucose);
            uncontrolledTR.push(reading);
          } else if (reading.glucose < "3.9"){
            // // // // console.log("LOW GLUCOSE", reading.glucose);

            lowGlucoTR.push(reading);
          }else if (reading.glucose >= "3.9" && reading.glucose <= "4.9") {
            normalTR.push(reading)
          }else if (reading.glucose >= "5.0" && reading.glucose <= "8.0") {
            typeOneTR.push(reading)
          }else if (reading.glucose >= "8.1" && reading.glucose <= "13.3") {
            typeTwoTR.push(reading)
          }else {
            // // // // // console.log("ELSE READING", reading);
          }





          return 0;
        })

        // let dataLength= data.length;
        let highLength = highArray.length;
        // // // // console.log("highLength", highLength);

        let lowLength = lowArray.length;
        // // // // console.log("lowLength", lowLength);

        let avgLength = avgArr.length;
        // // // // console.log("avgLength", avgLength);

        // // // // console.log("highArray", highArray);
        // // // // console.log("lowArray", lowArray);
        // // // // console.log("avgArr", avgArr);

        timeRange = [
          {label:"<3.9",value:lowGlucoTR.length},
          {label:" 3.9 - 4.9",value:normalTR.length},
          {label: "5.0 - 8.0",value:typeOneTR.length},
          {label: "8.1 - 13.3",value:typeTwoTR.length},
          {label: ">13.3",value:uncontrolledTR.length}];





        hiP =  highLength && highLength !== undefined && highLength !== 0? (highArray.length/data.length)* 100:0;
        loP =  lowLength && lowLength !== undefined && lowLength !== 0? (lowArray.length/data.length)* 100:0;
        avgP =  avgLength && avgLength !== undefined && avgLength !== 0? (avgArr.length/data.length)* 100:0;


        // Time IN Range CGM_RECORD_CALCULATIONS

        // // // // console.log("hiP", hiP);
        // // // // console.log("loP", loP);
        // // // // console.log("avgP", avgP);


        dispatch(currentRecord({lowVal,highVal,loP, hiP, avgP}));

      }


      if(res.data && res.data.content && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        hoursArr= makeAGPReadings(res.data.content.portal_cgm_record);

      }
    };

    let gift = res.data;
    // // // // console.log("REACHED");

  dispatch({ type: FETCH_CGM_RECORD_SUCCESS, payLoad: gift.content });

  return {data:gift.content.portal_cgm_record,
    diet:gift.content.diet_record,
    exercise:gift.content.exercise_record,
    insulin:gift.content.insulin_record,
    medicine:gift.content.medication_record,
    calculation:{lowVal,highVal,loP, hiP, avgP,hoursArr},
    timeRange:{timeRange},
    BgStats: bgStats,
  };
} catch (error) {
  dispatch({
    type: FETCH_CGM_RECORD_FAILED,
  });
}
};



export const fetchExportCgmData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_EXPORT_CGM_RECORD_START,
  });
try {
    let cgm_start = data.timeFrom;
    let cgm_end = data.timeTo;

let duration = {
  timeFrom:cgm_start,
  timeTo:cgm_end
}

    const res = await http.post(`https://grxaidex.co.uk/api/portal/cgm-record`,{timeFrom:data.timeFrom, timeTo: data.timeTo});
    let lowVal;
    let highVal;
    let hiP;
    let loP;
    let avgP;
    let hoursArr
    if(res.status===200){
      if(res.data && res.data.content && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        let data = res.data.content.portal_cgm_record;
        let cal=findMinMax(data, "glucose");
        lowVal=cal.min;
        highVal=cal.max;
        let highArray=[];
        let lowArray=[];
        let avgArr=[];

        data.filter((reading, index)=>{
          if (reading.glucose >="12"){
            highArray.push(reading);
          } else if (reading.glucose <= "4"){
            lowArray.push(reading);
          }else {
            avgArr.push(reading)
          }
          return 0;
        })

        // let dataLength= data.length;
        let highLength = highArray.length;
        let lowLength = lowArray.length;
        let avgLength = avgArr.length;




        hiP =  highLength && highLength !== undefined && highLength !== 0? (highArray.length/data.length)* 100:0;
        loP =  lowLength && lowLength !== undefined && lowLength !== 0? (lowArray.length/data.length)* 100:0;
        avgP =  avgLength && avgLength !== undefined && avgLength !== 0? (avgArr.length/data.length)* 100:0;
        hoursArr= makeAGPReadings(res.data.content);

        dispatch(currentRecordforExport({lowVal,highVal,loP, hiP, avgP,fromDate:cgm_start, hoursArr}));

      }
    };

    let gift = res.data;
    // // // // // console.log("GIFT", gift);
  dispatch({ type: FETCH_EXPORT_CGM_RECORD_SUCCESS, payLoad: gift.content });

  return {data:gift.content.portal_cgm_record,
      diet:gift.content.diet_record,
      exercise:gift.content.exercise_record,
      insulin:gift.content.insulin_record,
      medicine:gift.content.medication_record,
      calculation:{lowVal,highVal,loP, hiP, avgP}};

} catch (error) {
  dispatch({
    type: FETCH_EXPORT_CGM_RECORD_FAILED,
  });
}
};


export const currentRecord = (data) => async(dispatch) => {
  dispatch({
    type: CGM_RECORD_CALCULATIONS, payLoad:data
  });

};
export const resetCgmReadings = () => async(dispatch) => {
  dispatch({
    type: RESET_CGM_RECORD
  });

};
export const currentRecordforExport = (data) => async(dispatch) => {
  dispatch({
    type: EXPORT_CGM_RECORD_CALCULATIONS, payLoad:data
  });

};


export const fetchCgmDataForHistory = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_HISTORY_CGM_RECORD_START,
  });
try {
    let cgm_start = data.timeFrom;
    let cgm_end = data.timeTo;

// let duration = {
//   timeFrom:cgm_start,
//   timeTo:cgm_end
// }

    const res = await http.post(`https://grxaidex.co.uk/api/portal/cgm-record`,{timeFrom:data.timeFrom, timeTo: data.timeTo});
    let weeksArr=[];


    if(res.status===200){
      // // // // // console.log("STATUS :", res);
      if(res.data && res.data.content && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        let data = res.data.content.portal_cgm_record;
        let firstWeekStartDate = cgm_start;
        let endDate = moment(new Date())
        let days = endDate.diff(moment(firstWeekStartDate), 'days') ;
        let numberOfWeeks = Math.ceil(days / 7);
        let weekStartDate;
        let weekEndDate;
        for(let i=0; i<numberOfWeeks; i++){
          if(i === 0){
            weekStartDate= firstWeekStartDate;
            weekEndDate = moment(firstWeekStartDate).add(7, 'days');
          }
          let week=[];
          if (data && data.length > 0) {
            data.map((day, index)=> {

            let p1 = moment(weekStartDate);

            let p2 = moment(day.time);

            let p3 = moment(weekEndDate);


            if (p2.isAfter(p1) && p2.isBefore(p3)){
              week.push(day);
            }
            return 0;
          })
          }
          let obj = {weekStartDate:new Date(weekStartDate).toString(),
            readings:[week]}
          weeksArr.push(obj);
          weekStartDate= moment(weekEndDate).add(1, 'days');
          weekEndDate = moment(weekEndDate).add(7, 'days');
        };


      }
    };

    let gift = res.data;

  dispatch({ type: FETCH_HISTORY_CGM_RECORD_SUCCESS, payLoad: weeksArr });
  // // // // // console.log("Weeks ARRAY", weeksArr);
  return weeksArr;
} catch (error) {
  dispatch({
    type: FETCH_HISTORY_CGM_RECORD_FAILED,
  });
}
};

















const findMinMax=(arr,key)=> {
const datas = arr.map((node) => node[key]);
return {
  min: Math.min(...datas),
  max: Math.max(...datas),
}
}

const makeAGPReadings=(data)=>{
  let eachHourData=[];
  let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];

    for(let i =0;i<24; i++){
      let obj = data.filter((dayInner, index)=>{
      return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])

      })
      obj.sort((a, b)=>{return a.glucose-b.glucose});
      eachHourData.push(obj);
    }

  return eachHourData;
}
