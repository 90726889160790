import React, {useEffect, useState } from "react";
import GlucoRxLogo from "../../assets/glucorxwhite.svg";
import { default as Titles } from "../../utils/document-titles";
import { accountActivation } from "../../redux/actions/auth";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import ShowToast from "../../utils/show-toast";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../redux/actions/auth";

 const  AccountActivationComp=()=>{
  document.title = Titles.resendVerificationEmail;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();




  useEffect(()=>{

    // dispatch(logout(false))

  })

  const activation=async()=>{

let url = window.location;

let token= new URLSearchParams(url.search).get('token');

    ////('triggered',token);

      setIsLoading(true);
      // e.preventDefault();
      const result =  dispatch(accountActivation(token));
      ////(result);
      history.push("/auth/login");
      // if check on status

      // ////(result.status)
      let status=result.status
      if(status===200){
        ShowToast(result.data.message,"success");
      }


      setIsLoading(false);


    //   let status=err.response.status

    //   if( status===409){
    //  //("error",err);

    //     ShowToast(err.response.data.message,"success");
    //   }
    //   else{
    //     ShowToast(err.response.data.message,"error");
    //   }
      setIsLoading(false);

  }
  return (
    <div className='container-fluid d-flex flex-column forget-background ' >

    <div className="row justify-center align-item-center my-auto" >
        <div className="col-md-7 col-11 forget-field-box align-items-center py-5">
           <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>
           <div className='d-flex justify-content-center '>



           <button className='forget-submit-btn btn-primary p-3 b-3' onClick={()=>activation()}>Activate!</button>
           </div>
           {/*}<form onSubmit={handleSubmit}>
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
                    <input
                      type="email"
                      value={email}
          onChange={(e) => setEmail(e.target.value)}
                      className="forget-form-control"
                      id="formGroupExampleInput"
                      required
                    />
                </div>
            </div>
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
            <Spin spinning={isLoading}>
                  <button
                        type="submit"
                        className="forget-submit-btn btn-primary "
                      >
                        Signin
                    </button>
                    </Spin>
                </div>
            </div>
            </form>*/}
            <div className="mt-3 text-white">
                <p className="text-center forget-text-font">
                <Link
              to={"/auth/login"}>
              Back to Sign In!
            </Link>
                </p>
            </div>
        </div>


        </div>


</div>
  );
}
export default AccountActivationComp;
