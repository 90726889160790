import http from "../../../utils/http-service";
import { API_BASE_URL, fetchPoDataUrl } from "../../../utils/urls";
import {
  FETCH_PO_START,
  FETCH_PO_FAILED,
  FETCH_PO_SUCCESS,
} from "../../actionTypes";
import {encrypt, decrypt} from "../../../utils/encryption";
import {logout} from "../auth";

export const fetchPoData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_PO_START,
  });
  try {
    // //("data in action :", data);
    // const res = await http.get(`${API_BASE_URL}${fetchpoDataUrl}/2`, {
    //   params: {
    //     ...data
    //   },
    // });
    //
    // dispatch({ type: FETCH_po_SUCCESS, payLoad: res.data });
    // //(res);
    // return res;

    let po;
    let res;
    if(data.time){
      po = data.time;

      po = encrypt(po);

      let type = 4;
      type = JSON.stringify(type);
      type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchPoDataUrl}${type}?time=${po}`);
    } else if(data.start_date) {

      let po_start = data.start_date;
      po_start = encrypt(po_start);
      let po_end = data.end_date;
      po_end = encrypt(po_end);

      let type = 4;
      type = JSON.stringify(type);
      type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchPoDataUrl}${type}?start_date=${po_start}&end_date=${po_end}`,);
}
let gift = res.data.data;

gift = decrypt(gift);
gift = JSON.parse(gift);

//(gift);
  // updateUnit(unit, res.data.data)
  dispatch({ type: FETCH_PO_SUCCESS, payLoad: gift });

  return res;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){
    
      dispatch(logout(true));
      window.location.reload();

    }
    dispatch({
      type: FETCH_PO_FAILED,
    });
  }
};
