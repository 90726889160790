import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import countryList from "../auth/CountriesList";
import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { register, resetEmail } from "../../redux/actions/auth";
import { decrypt } from "../../utils/encryption";
import moment from "moment";
import { fetchUser, updateUser } from "../../redux/actions/user";

const EmailUpdate = () => {
  const data = useSelector((state) => state.user.userDetails);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [password, setPassword] = useState("");


  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEmail(data && data.email && data.email !== "" ? data.email : "");
  },[data]);

  // useEffect(() => {
  //   // // console.log("NEW EMAIL FROM MODAL", newEmail);
  // },[newEmail]);

  const dispatch = useDispatch();




  const resetEmailRequest=async(e)=>{
    e.preventDefault();
    try {
      setIsLoading(true);
      if(email !== newEmail){
        // // console.log("EMAIL", email);
        // // console.log("EMAIL NEW", newEmail);


      let result = await dispatch(
        resetEmail(newEmail)
      );


      setIsLoading(false);
      ShowToast(result.data.message, "success");
      }
    } catch (err) {
      ////(err.response)

      ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="text-center"></div>

      <div id="emailModal" className="modal fading">
        <div className="modal-dialog modal-login">
          <div className="modal-content">
            <div className="modal-header">
              <div className="avatar text-center">
                <i className="fas fa-4x fa-user text-white"></i>
              </div>
              <h4 className="modal-title">Update Email</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body overflow-auto" >
              <form onSubmit={resetEmailRequest} >
                <div className=" ">




                  <div className="row">
                  <div className="form-group col-12">
                    <h1>Please enter your new email.</h1>
                  </div>
                    <div className="form-group col-12">
                      <label className="text-left">Email</label>
                      <input
                        defaultValue={email}
                        type="email"
                        className="registration-form-control"
                        onChange={(e)=>{
                          setNewEmail(e.target.value);
                        }}

                      />


                    </div>

                  </div>





                  <div className="row justify-content-end  d-block">
                    <div className="col-md-4 ml-auto">
                      <Spin spinning={isLoading}>
                        <button
                          type="Submit"
                          className="registration-btn btn-primary "
                        >
                          Save
                        </button>
                      </Spin>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailUpdate;
