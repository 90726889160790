import React,{useState} from "react";
import PatientAddModal from "./PatientAddModal";
import PatientList from "./PatientList/PatientList";

const DoctorDashboard = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const handleSubmit=(e)=>{
      
    }

    var a=3;
  return (
  
        <PatientList/>
   
  );
};

export default DoctorDashboard;
